import React from 'react';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import {
  AbortIcon,
  ArrowIcon,
  EditIcon,
  RemoveIcon
} from 'ecto-common/lib/Icon';
import Link from 'ecto-common/lib/Link/Link';
import styles from './Schedule.module.css';
import T from 'ecto-common/lib/lang/Language';
import { ScheduleStatus } from 'ecto-common/lib/constants';
import { ScheduleResponseModel } from 'ecto-common/lib/API/APIGen';

type ScheduleToolsProps = {
  schedule: ScheduleResponseModel;
  onDeleteClick: React.MouseEventHandler<HTMLDivElement>;
  onEditClick: React.MouseEventHandler<HTMLDivElement>;
  onAbortClick: React.MouseEventHandler<HTMLDivElement>;
  showShortcut: boolean;
};

const ScheduleTools = ({
  schedule,
  onDeleteClick,
  onEditClick,
  onAbortClick,
  showShortcut
}: ScheduleToolsProps) => {
  const { status, scheduleId, signalGroupId } = schedule;

  // Either shortcut OR tools
  if (showShortcut) {
    return (
      <div className={styles.toolArea}>
        <Link
          to={`/power-control/?selectedItem=${signalGroupId}&schedule=${scheduleId}`}
        >
          <Tooltip
            text={T.powercontrol.dispatch.schedule.tooltip.gotoschedule}
            className={styles.icon}
          >
            <ArrowIcon width={20} height={20} className={styles.arrowLink} />
          </Tooltip>
        </Link>
      </div>
    );
  }

  return (
    <div className={styles.toolArea}>
      {onEditClick && status === ScheduleStatus.PENDING && (
        <Tooltip
          text={T.powercontrol.dispatch.schedule.tooltip.editschedule}
          className={styles.icon}
          onClick={onEditClick}
        >
          <EditIcon width={20} height={20} className={styles.edit} />
        </Tooltip>
      )}

      {onAbortClick &&
        (status === ScheduleStatus.RUNNING ||
          status === ScheduleStatus.PENDING) && (
          <Tooltip
            text={T.powercontrol.dispatch.schedule.tooltip.abortschedule}
            className={styles.icon}
            onClick={onAbortClick}
          >
            <AbortIcon width={20} height={20} className={styles.abort} />
          </Tooltip>
        )}

      {/* TODO: Re-enable once logic has been established */}
      {false && onDeleteClick && status === ScheduleStatus.PENDING && (
        <Tooltip
          text={T.powercontrol.dispatch.schedule.tooltip.deleteschedule}
          className={styles.icon}
          onClick={onDeleteClick}
        >
          <RemoveIcon width={20} height={20} className={styles.delete} />
        </Tooltip>
      )}
    </div>
  );
};

export default ScheduleTools;
