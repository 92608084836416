import React, { Fragment } from 'react';

import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

import styles from '../ScheduleModal.module.css';
import T from 'ecto-common/lib/lang/Language';
import { SchedulePresetType } from 'js/components/EMPTools/PowerControl/Dispatch/ScheduleModal';

interface PresetsContainerProps {
  selected: number;
  presets: SchedulePresetType[];
  onPresetChanged(preset: SchedulePresetType, index: number): void;
}

const PresetsContainer = ({
  selected,
  presets,
  onPresetChanged
}: PresetsContainerProps) => {
  const renderPresetData = (preset: SchedulePresetType) => {
    if (preset.isPowerControlTest) {
      return (
        <Fragment>
          <div className={styles.segmentItemRow}>&nbsp;</div>
          <div className={styles.segmentItemRow}>&nbsp;</div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <div className={styles.segmentItemRow}>
          <label>{T.powercontrol.schedule.preset.endoffset}:</label>
          {preset.endOffset}h
        </div>
        <div className={styles.segmentItemRow}>
          <label>{T.powercontrol.schedule.preset.amplitude}:</label>
          {preset.amplitude}%
        </div>
      </Fragment>
    );
  };

  return (
    <div className={styles.presetContainer}>
      <SegmentControl className={styles.segmentControl}>
        {presets.map((preset, index) => (
          <SegmentControlItem
            key={index}
            onClick={(e) => {
              if (e.target instanceof HTMLElement) {
                e.target.blur();
              }
              e.preventDefault();
              onPresetChanged(preset, index);
            }}
            className={styles.segmentItem}
            active={index === selected}
          >
            <div className={styles.segmentItemHeader}>
              <FontAwesomeIcon icon={faCalendarAlt} />
              {preset.name}
            </div>
            {/* <div className={styles.segmentItemRow}><label>{T.powercontrol.schedule.preset.startoffset}:</label>{preset.startOffset}h</div> */}
            {renderPresetData(preset)}
          </SegmentControlItem>
        ))}
      </SegmentControl>
    </div>
  );
};

export default React.memo(PresetsContainer);
