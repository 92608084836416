import {
  JSONPut,
  JSONPost,
  JSONQueryUnparsedReturn,
  JSONFetch,
  TextPut,
  JSONDelete,
  JSONPostUnparsedReturn,
  JSONDeleteBody,
  BlobFetch
} from 'ecto-common/lib/API/API';

import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import queryString from 'query-string';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import {
  BuildResponse,
  BuildResponseListResponse,
  FileInformation,
  ProjectResponse,
  ProjectUpsertRequest,
  UserResponseListResponse,
  WeatherCountryResponseListResponse,
  WeatherStationResponseListResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

const fetchOptions = {
  scopes: () => [getApiEnvironment().scopes.ectoplanner],
  domain: () => getApiEnvironment().urls.ectoplannerUrl
};

const getStationFilter = (countryCode: string) => {
  return "countryCode eq '" + countryCode + "'";
};

type ListProjectsResponse = {
  items?: ProjectResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;

  /** @format int32 */
  total?: number | null;
};

const EctoplannerAPI = {
  getBuildsByProjectId: (
    contextSettings: ApiContextSettings,
    projectId: string
  ) =>
    JSONFetch<BuildResponseListResponse>(
      contextSettings,
      '/EctoGridProjects/' + projectId + '/builds',
      {},
      fetchOptions
    ),
  getBuildResults: (contextSettings: ApiContextSettings, buildId: string) =>
    JSONFetch<object>(
      contextSettings,
      '/EctoGridBuilds/' + buildId + '/results',
      {},
      fetchOptions
    ),
  getBuildExcelLink: (contextSettings: ApiContextSettings, buildId: string) =>
    JSONFetch<FileInformation>(
      contextSettings,
      '/EctoGridBuilds/' + buildId + '/excelfilelink',
      {},
      fetchOptions
    ),
  deleteBuild: (contextSettings: ApiContextSettings, buildId: string) =>
    JSONDelete<void>(
      contextSettings,
      '/EctoGridBuilds/' + buildId + '/',
      {},
      {},
      fetchOptions
    ),
  getFormData: (contextSettings: ApiContextSettings, projectId: string) =>
    JSONFetch<object>(
      contextSettings,
      '/EctoGridProjects/' + projectId + '/formdata',
      {},
      fetchOptions
    ),
  addProjectUser: (
    contextSettings: ApiContextSettings,
    projectId: string,
    userEmail: string
  ) =>
    JSONPostUnparsedReturn(
      contextSettings,
      '/EctoGridProjects/' + projectId + '/users',
      { mailaddresses: [userEmail] },
      {},
      fetchOptions
    ),
  removeProjectUser: (
    contextSettings: ApiContextSettings,
    projectId: string,
    userEmail: string
  ) =>
    JSONDeleteBody<void>(
      contextSettings,
      '/EctoGridProjects/' + projectId + '/users',
      { mailaddresses: [userEmail] },
      {},
      fetchOptions
    ),
  getProjectUsers: (contextSettings: ApiContextSettings, projectId: string) =>
    JSONFetch<UserResponseListResponse>(
      contextSettings,
      '/EctoGridProjects/' + projectId + '/users',
      {},
      fetchOptions
    ),
  saveFormData: (
    contextSettings: ApiContextSettings,
    projectId: string,
    form: EctoplannerForm
  ) =>
    JSONPut<void>(
      contextSettings,
      '/EctoGridProjects/' + projectId + '/formdata',
      form,
      {},
      fetchOptions
    ),
  getFile: (
    contextSettings: ApiContextSettings,
    projectId: string,
    filename: string
  ) =>
    JSONQueryUnparsedReturn(
      contextSettings,
      '/EctoGridProjects/' + projectId + '/timeseriesfile/' + filename,
      null,
      {},
      fetchOptions
    ),
  saveFile: (
    contextSettings: ApiContextSettings,
    projectId: string,
    filename: string,
    content: string
  ) =>
    TextPut(
      contextSettings,
      '/EctoGridProjects/' + projectId + '/timeseriesfile/' + filename,
      content,
      {},
      fetchOptions
    ),
  deleteProject: (contextSettings: ApiContextSettings, projectId: string) =>
    JSONDelete<void>(
      contextSettings,
      '/EctoGridProjects/' + projectId,
      null,
      {},
      fetchOptions
    ),
  updateProject: (
    contextSettings: ApiContextSettings,
    project: ProjectUpsertRequest
  ) =>
    JSONPut<ProjectResponse>(
      contextSettings,
      '/EctoGridProjects/' + project.id,
      project,
      {},
      fetchOptions
    ),
  updateProjectWeatherStation: (
    contextSettings: ApiContextSettings,
    projectId: string,
    weatherStationId: string
  ) =>
    JSONPost<void>(
      contextSettings,
      '/EctoGridProjects/' + projectId + '/weather',
      { weatherStationId },
      {},
      fetchOptions
    ),
  getProjectsAll: (contextSettings: ApiContextSettings) =>
    JSONFetch<ListProjectsResponse>(
      contextSettings,
      '/EctoGridProjects',
      {},
      fetchOptions
    ),
  getProject: (contextSettings: ApiContextSettings, projectId: string) =>
    JSONFetch<ProjectResponse>(
      contextSettings,
      '/EctoGridProjects/' + projectId,
      {},
      fetchOptions
    ), // ListProjectsParams
  exportProject: (contextSettings: ApiContextSettings, projectId: string) =>
    BlobFetch(
      contextSettings,
      '/EctoGridProjects/' + projectId + '/export',
      {},
      fetchOptions
    ),
  buildProject: (
    contextSettings: ApiContextSettings,
    projectId: string,
    description: string
  ) =>
    JSONPost<BuildResponse>(
      contextSettings,
      '/EctoGridProjects/' + projectId + '/builds',
      { projectId, description },
      {},
      fetchOptions
    ),
  getWeatherStations: (
    contextSettings: ApiContextSettings,
    countryCode: string,
    search: string,
    continuationToken: string = null
  ) => {
    let query = {
      $orderBy: 'city',
      $top: 1000,
      continuationToken,
      $filter: getStationFilter(countryCode),
      $search: undefined as string
    };

    if (!isNullOrWhitespace(search)) {
      query.$search = search;
    }

    return JSONFetch<WeatherStationResponseListResponse>(
      contextSettings,
      '/Weather/stations?' + queryString.stringify(query),
      {},
      fetchOptions
    );
  },
  getWeatherCountries: (contextSettings: ApiContextSettings) =>
    JSONFetch<WeatherCountryResponseListResponse>(
      contextSettings,
      '/Weather/countries?' +
        queryString.stringify({ $orderBy: 'countryName' }),
      {},
      fetchOptions
    )
};

export default EctoplannerAPI;
