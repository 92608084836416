import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext
} from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import classNames from 'classnames';
import { useParams } from 'react-router';

import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import Notice from 'ecto-common/lib/Notice/Notice';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import API from 'ecto-common/lib/API/API';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { ScheduleStatus as ScheduleStatusValues } from 'ecto-common/lib/constants';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import useInterval from 'ecto-common/lib/hooks/useInterval';
import {
  formatScheduleDate,
  HOUR_MILLIS
} from 'js/components/EMPTools/PowerControl/Dispatch/utils/dateUtils';

import { ScheduleActions } from 'js/modules/schedule/schedule';
import ScheduleModal from 'js/components/EMPTools/PowerControl/Dispatch/ScheduleModal';
import ScheduleStatus from 'js/components/EMPTools/PowerControl/Dispatch/ScheduleStatus';
import styles from 'js/components/EMPTools/PowerControl/Dispatch/CommonDispatch.module.css';
import commonStyles from 'js/components/EMPTools/PowerControl/Dispatch/CommonDispatch.module.css';
import UUID from 'uuidjs';
import {
  useOperatorSelector,
  useOperatorDispatch
} from 'js/reducers/storeOperator';
import {
  AddOrUpdatePowerControlScheduleRequestModel,
  ScheduleCollectionResponseModel,
  ScheduleResponseModel,
  SignalGroupLiteResponseModel
} from 'ecto-common/lib/API/APIGen';
import { RequestStatusProp } from 'ecto-common/lib/utils/requestStatus';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { useLocation } from 'react-router-dom';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const SIGNAL_NAME_GLOBAL_AMPLITUDE_DESIRED = 'Global Amplitude Desired';
const SIGNAL_NAME_ACUTE_STOP = 'Acute Emergency';

const orderSchedules = (schedules: ScheduleResponseModel[]) =>
  _.orderBy(schedules, 'startDate', 'desc');

type ScheduleState = RequestStatusProp<ScheduleCollectionResponseModel>;

interface ScheduleListProps {
  selectedPowerControl: SignalGroupLiteResponseModel;
  selectedNode?: SingleGridNode;
  showAddSchedule?: boolean;
  onCloseAddSchedule?(): void;
  showConfirmDeleteDialog?: boolean;
  onCloseShowConfirmDeleteDialog?(): void;
}

const ScheduleList = ({
  showAddSchedule,
  onCloseAddSchedule,
  showConfirmDeleteDialog,
  onCloseShowConfirmDeleteDialog,
  selectedNode,
  selectedPowerControl
}: ScheduleListProps) => {
  const dispatch = useOperatorDispatch();
  let { nodeId } = useParams<NodeParams>();
  const location = useLocation();
  const scheduleState: ScheduleState = useOperatorSelector(
    (state) => state.schedule.schedules
  );
  const isRefreshingSchedules = useOperatorSelector(
    (state) => state.schedule.refreshingSchedules
  );
  const [orderedSchedules, setOrderedSchedules] = useState<
    ScheduleResponseModel[]
  >([]);
  const [showEditSchedule, setShowEditSchedule] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState<number>(null);
  const [initialAmplitude, setInitialAmplitude] = useState(0);
  const [startDate, setStartDate] = useState<string>(null);
  const [endDate, setEndDate] = useState<string>(null);
  const [duration, setDuration] = useState<number>(null);
  const [deletingSchedules, setDeletingSchedules] = useState<
    Record<string, ScheduleResponseModel>
  >({});

  const { contextSettings } = useContext(TenantContext);
  const editScheduleId = isEditing ? orderedSchedules[editId].scheduleId : null;
  const editScheduleSourceTypeId = isEditing
    ? orderedSchedules[editId].sourceTypeId
    : null;
  const editScheduleIsAcuteStop =
    _.find(isEditing ? orderedSchedules[editId]?.signals : [], {
      name: SIGNAL_NAME_ACUTE_STOP
    })?.value === 1;

  // We don't want to show the loading state when we are refreshing the schedules from the timer.
  const isFetchingData = scheduleState.isLoading && !isRefreshingSchedules;

  const getSchedules = useCallback(
    (isRefresh = false) =>
      dispatch(
        ScheduleActions.getSchedules(contextSettings, nodeId, 20, isRefresh)
      ),
    [contextSettings, nodeId, dispatch]
  );

  useEffect(() => {
    const newSchedules = orderSchedules(scheduleState?.data?.schedules);

    if (scheduleState.isLoading) {
      return;
    }

    setOrderedSchedules((prevState) => {
      if (_.isEqual(prevState, newSchedules)) {
        return prevState;
      }

      return newSchedules;
    });
  }, [scheduleState?.data?.schedules, scheduleState.isLoading]);

  const refreshData = useCallback(() => {
    if (!isFetchingData && !showAddSchedule && !showEditSchedule) {
      getSchedules(true);
    }
  }, [getSchedules, isFetchingData, showAddSchedule, showEditSchedule]);

  useInterval(refreshData, 15000);

  useEffect(() => {
    getSchedules();
  }, [getSchedules]);

  const onEditClick = useCallback(
    (idx: number) => {
      const schedule = _.get(orderedSchedules, idx);
      const signal = _.find(schedule?.signals, {
        name: SIGNAL_NAME_GLOBAL_AMPLITUDE_DESIRED
      });

      if (!signal) {
        return;
      }

      const value = signal.value;

      const editStartDate = new Date(formatScheduleDate(schedule?.startDate));
      const editEndDate = new Date(formatScheduleDate(schedule?.endDate));
      const dateDifference = editEndDate.valueOf() - editStartDate.valueOf();
      const editDurationOriginal = ((dateDifference / HOUR_MILLIS) * 2) / 2;
      const editDuration =
        editDurationOriginal < 1 ? 0.5 : Math.round(editDurationOriginal);

      setIsEditing(true);
      setShowEditSchedule(true);
      setEditId(idx);
      setInitialAmplitude(value);
      setStartDate(schedule?.startDate);
      setEndDate(schedule?.startDate);
      setDuration(editDuration);
    },
    [orderedSchedules]
  );

  const [deleteMultipleSchedulesIsLoading, deleteMultipleSchedules] =
    usePromiseCall({
      promise: API.PowerControls.deleteSchedules,
      onSuccess: () => {
        onCloseShowConfirmDeleteDialog();
        getSchedules();
        toastStore.addSuccessToast(
          T.powercontrol.dispatch.scheduled.deletemulti.success.toast
        );
      },
      onError: () => {
        toastStore.addErrorToast(
          T.powercontrol.dispatch.scheduled.deletemulti.failure.toast
        );
      }
    });

  const onConfirmDelete = useCallback(() => {
    const pendingSchedules = _.cloneDeep(
      _.filter(scheduleState.data.schedules, {
        status: ScheduleStatusValues.PENDING
      })
    );
    const _deletingSchedules = _.keyBy(pendingSchedules, 'scheduleId');
    setDeletingSchedules(_deletingSchedules);
    const deleteParams = pendingSchedules.map((schedule) => ({
      scheduleId: schedule.scheduleId
    }));
    deleteMultipleSchedules(deleteParams);
  }, [scheduleState, deleteMultipleSchedules]);

  const onModalClose = useCallback(() => {
    onCloseAddSchedule();
    setShowEditSchedule(false);
    setInitialAmplitude(0);
    setIsEditing(false);
    setEditId(null);
    setStartDate(null);
    setEndDate(null);
    setDuration(null);
  }, [onCloseAddSchedule]);

  const [deleteScheduleIsLoading, deleteSchedule] = usePromiseCall({
    promise: API.PowerControls.deleteSchedule,
    onSuccess: () => {
      onCloseShowConfirmDeleteDialog();
      toastStore.addSuccessToast(
        T.powercontrol.dispatch.scheduled.delete.success.toast
      );
      getSchedules();
    },
    onError: () => {
      toastStore.addErrorToast(
        T.powercontrol.dispatch.scheduled.delete.failure.toast
      );
    }
  });

  const onDeleteClick = useCallback(
    (idx: number) => {
      deleteSchedule(orderedSchedules[idx].scheduleId);
    },
    [orderedSchedules, deleteSchedule]
  );

  const [abortScheduleIsLoading, abortSchedule] = usePromiseCall({
    promise: API.PowerControls.abortSchedule,
    onSuccess: () => {
      toastStore.addSuccessToast(
        T.powercontrol.dispatch.scheduled.abort.success.toast
      );
      getSchedules();
    },
    onError: () => {
      toastStore.addErrorToast(
        T.powercontrol.dispatch.scheduled.abort.failure.toast
      );
    }
  });

  const onAbortClick = useCallback(
    (idx: number) => {
      abortSchedule(orderedSchedules[idx].scheduleId);
    },
    [abortSchedule, orderedSchedules]
  );

  const [addSchedulesIsLoading, addSchedules] = usePromiseCall({
    promise: API.PowerControls.addSchedules,
    onSuccess: (_unused, args) => {
      const isMulti = args.length > 1;
      let message;

      if (isMulti) {
        message = isEditing
          ? T.powercontrol.dispatch.scheduled.add.successmulti.edit
          : T.powercontrol.dispatch.scheduled.add.successmulti.create;
      } else {
        message = isEditing
          ? T.powercontrol.dispatch.scheduled.add.success.edit
          : T.powercontrol.dispatch.scheduled.add.success.create;
      }

      toastStore.addSuccessToast(message);
      getSchedules();
      onModalClose();
    },
    onError: () => {
      const message = isEditing
        ? T.powercontrol.dispatch.scheduled.add.failuremulti.create
        : T.powercontrol.dispatch.scheduled.add.failure.create;
      toastStore.addErrorToast(message);
    }
  });

  const onCommitSchedules = useCallback(
    (newSchedules: AddOrUpdatePowerControlScheduleRequestModel[]) => {
      // This method will generate unique ID:s for each new schedule. When editing, we only
      // ever edit one at a time, and we get that ID from the orderedSchedules list as it is not passed
      // to us in the callback. TODO: We should get the ID in the callback, would make the code clearer.
      // Verify that we only ever edit one schedule at a time as otherwise the ID logic would break.
      if (newSchedules.length > 1 && isEditing) {
        console.error(
          'Attempting to edit multiple schedules at once, invalid operation'
        );
        return;
      }

      const _schedules = _.map(newSchedules, (schedule) => ({
        ...schedule,
        id: isEditing ? orderedSchedules[editId].scheduleId : UUID.generate(),
        signalProviderId: selectedPowerControl.signalGroupId
      }));

      addSchedules(_schedules);
    },
    [selectedPowerControl, isEditing, editId, addSchedules, orderedSchedules]
  );

  const renderContent = useMemo(() => {
    if (scheduleState.hasError) {
      return (
        <ErrorNotice className={styles.notice}>
          {T.powercontrol.dispatch.scheduled.fetch.failure}
        </ErrorNotice>
      );
    }

    if (!isFetchingData && _.isEmpty(orderedSchedules)) {
      return (
        <Notice className={styles.notice}>
          {T.powercontrol.dispatch.scheduled.noschedules}
        </Notice>
      );
    }

    const selectedScheduleId = queryString.parse(location.search).schedule;
    return orderedSchedules.map((schedule, idx) => (
      <ScheduleStatus
        isLoading={_.get(deletingSchedules, schedule.scheduleId, null) != null}
        key={`schedule-status-${schedule.scheduleId}`}
        schedule={schedule}
        onEditClick={() => onEditClick(idx)}
        onDeleteClick={() => onDeleteClick(idx)}
        onAbortClick={() => onAbortClick(idx)}
        defaultExpanded={selectedScheduleId === schedule.scheduleId}
      />
    ));
  }, [
    deletingSchedules,
    isFetchingData,
    location.search,
    onAbortClick,
    onDeleteClick,
    onEditClick,
    orderedSchedules,
    scheduleState
  ]);

  const deleteIsLoading =
    deleteScheduleIsLoading || deleteMultipleSchedulesIsLoading;
  const anyIsLoading =
    abortScheduleIsLoading || addSchedulesIsLoading || deleteIsLoading;

  return (
    <div
      className={classNames(
        commonStyles.container,
        commonStyles.schedulesContainer
      )}
    >
      <ConfirmDeleteDialog
        isLoading={deleteIsLoading}
        isOpen={showConfirmDeleteDialog}
        onModalClose={onCloseShowConfirmDeleteDialog}
        onDelete={onConfirmDelete}
      >
        {T.powercontrol.dispatch.scheduled.action.confirmremoveall}
      </ConfirmDeleteDialog>

      <div className={commonStyles.contentArea}>
        <LoadingContainer isLoading={isFetchingData || anyIsLoading}>
          {renderContent}
        </LoadingContainer>
      </div>

      <ScheduleModal
        selectedNode={selectedNode}
        isOpen={showAddSchedule || showEditSchedule}
        isEditing={isEditing}
        initialAmplitude={initialAmplitude}
        onModalClose={onModalClose}
        onCreateSchedules={onCommitSchedules}
        isLoading={anyIsLoading}
        initialStartDate={startDate}
        initialEndDate={endDate}
        scheduleId={editScheduleId}
        editScheduleSourceTypeId={editScheduleSourceTypeId}
        editScheduleIsAcuteStop={editScheduleIsAcuteStop}
        duration={duration}
      />
    </div>
  );
};

export default ScheduleList;
