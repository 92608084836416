import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';
import styles from './Abort.module.css';

const Abort = (props: SvgIconProps) => (
  <SvgIcon viewBox="-2 -2 42 42" {...props}>
    <circle className={styles.cls1} cx="19" cy="19" r="15" />
    <line className={styles.cls1} x1="14" y1="14" x2="24" y2="24" />
    <line className={styles.cls1} x1="24" y1="14" x2="14" y2="24" />
  </SvgIcon>
);

export default Abort;
