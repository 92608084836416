const noiseRel = [
  1.0, 1.03, 0.94, 0.99, 0.94, 0.97, 1.0, 0.97, 1.01, 1.04, 1.05, 1.0, 0.99,
  0.97, 1.07, 1.06, 0.99, 0.93, 0.94, 0.94, 0.95, 1.02, 1.02, 1.0, 1.01, 1.03,
  0.98, 1.06, 1.06, 0.96, 1.05, 1.0, 1.01, 1.01, 0.97, 0.95, 0.97, 1.02, 0.95,
  0.99, 0.99, 0.98, 1.03, 0.99, 0.98, 1.03, 1.0, 1.03, 1.05, 0.97, 1.0, 0.94,
  1.03, 1.1, 1.07, 1.06, 0.99, 0.91, 1.04, 0.96, 0.98, 1.01, 1.05, 1.0, 1.02,
  1.08, 0.98, 1.03, 1.02, 0.89, 1.04, 0.96, 1.01, 0.98, 1.06, 1.04, 1.02, 1.01,
  0.97, 1.09, 1.0, 0.89, 0.96, 0.98, 1.0, 0.99, 1.0, 1.0, 1.06, 0.99, 0.98,
  0.95, 1.01, 0.97, 0.98, 1.06, 1.04, 1.02, 0.94, 1.02, 0.98, 1.0, 0.97, 1.08,
  0.94, 0.94, 1.06, 1.03, 0.9, 0.94, 1.01, 0.97, 0.99, 0.96, 1.04, 1.06, 1.04,
  1.02, 0.92, 0.97, 1.02, 1.03, 0.95, 0.96, 1.06, 1.02, 0.98, 0.98, 0.98, 1.04,
  1.02, 1.01, 0.94, 0.96, 1.01, 1.0, 0.95, 1.01, 1.0, 0.97, 1.03, 1.0, 0.95,
  1.06, 0.92, 1.01, 0.97, 0.99, 1.01, 0.96, 1.02, 1.03, 1.1, 0.98, 0.99, 1.03,
  1.02, 1.06, 0.89, 1.05, 1.01, 1.03, 1.05, 1.06, 1.01, 0.97, 0.94, 1.0, 1.03,
  1.01, 0.93, 0.9, 0.99, 1.05, 0.94, 1.02, 0.97, 1.03, 1.0, 1.02, 0.98, 0.94,
  1.05, 0.99, 1.03, 1.01, 1.03, 1.0, 1.04, 0.99, 0.97, 0.98, 0.97, 0.94, 1.05,
  1.02, 1.01, 0.92, 1.02, 1.05, 1.0, 1.04, 1.01, 1.02, 0.97, 1.0, 1.05, 0.96,
  0.98, 0.95, 1.03, 1.02, 0.99, 0.98, 1.09, 1.02, 0.99, 0.96, 0.97, 1.07, 1.01,
  0.95, 1.01, 1.01, 0.99, 0.99, 1.01, 0.98, 1.04, 0.95, 1.07, 0.9, 0.99, 1.01,
  1.08, 1.01, 1.03, 1.04, 1.0, 1.0, 1.03, 0.98, 0.99, 0.97, 0.98, 1.0, 1.1, 1.0,
  1.01, 0.98, 0.95, 1.08, 1.01, 1.04, 0.96, 0.94, 0.99, 1.0, 1.04, 1.02, 1.02,
  0.89, 1.04, 1.03, 0.97, 0.93, 0.97, 1.0, 0.95, 1.0, 0.99, 1.03, 0.98, 1.02,
  0.98, 1.07, 1.01, 0.98, 1.01, 0.96, 1.13, 1.0, 1.06, 0.95, 1.01, 0.96, 1.04,
  0.99, 1.0, 1.05, 1.09, 1.02, 1.03, 1.09, 1.0, 0.98, 0.98, 0.96, 0.98, 1.02,
  1.0, 1.07, 1.0, 0.93, 1.09, 0.99, 0.91, 1.0, 1.03, 1.06, 0.99, 0.95, 1.02,
  1.02, 0.98, 0.98, 1.01, 0.95, 0.97, 0.91, 0.89, 1.04, 1.06, 1.0, 1.06, 1.01,
  0.93, 1.01, 1.03, 1.05, 0.97, 0.96, 1.06, 1.01, 1.04, 1.01, 1.0, 0.99, 0.94,
  0.95, 1.09, 1.03, 0.99, 0.98, 0.94, 1.01, 1.01, 1.07, 0.9, 1.03, 0.98, 0.99,
  1.0, 0.97, 0.96, 1.05, 0.95, 1.06, 1.02, 1.03, 1.07, 1.0, 0.99, 0.94, 0.94,
  0.97, 1.05, 1.06, 0.98, 0.98, 1.04, 1.0, 1.0, 0.98, 0.99, 1.03, 1.03, 1.0,
  1.0, 0.98, 1.0, 0.97, 1.0, 0.99, 1.01, 0.89, 1.01, 0.93, 1.0, 0.97, 0.97,
  1.08, 1.02, 1.03, 0.99, 1.0, 0.97, 1.04, 1.01, 0.97, 1.07, 0.98, 1.01, 1.06,
  1.08, 0.92, 1.01, 1.03, 1.04, 1.01, 0.99, 1.0, 1.01, 0.96, 1.0, 0.98, 1.01,
  0.98, 0.92, 1.03, 1.03, 0.99, 1.0, 0.92, 1.06, 1.01, 1.06, 0.98, 1.01, 1.03,
  0.99, 1.05, 0.98, 1.01, 0.93, 1.09, 0.92, 0.94, 1.01, 1.0, 1.03, 0.99, 1.02,
  1.01, 0.98, 1.03, 0.99, 0.96, 1.04, 0.95, 1.08, 0.95, 0.97, 0.99, 0.97, 1.02,
  0.99, 0.97, 1.07, 1.01, 0.95, 1.09, 0.93, 0.99, 1.02, 1.0, 1.06, 0.97, 0.99,
  0.96, 0.99, 1.0, 1.0, 0.92, 0.92, 0.98, 1.07, 1.03, 1.01, 0.98, 0.99, 0.99,
  0.99, 1.05, 1.03, 0.97, 0.95, 0.95, 1.06, 0.94, 0.92, 0.97, 0.99, 1.01, 1.0,
  1.0, 1.0, 0.98, 1.05, 0.99, 0.98, 0.98, 1.02, 0.97, 1.02, 0.97, 0.98, 1.05,
  1.02, 0.98, 0.97, 0.99, 1.0, 1.0, 1.03, 1.06, 0.94, 0.98, 1.02, 1.03, 1.05,
  0.96, 1.04, 0.99, 1.0, 1.05, 0.93, 1.06, 0.96, 0.95, 1.09, 0.98, 1.01, 1.02,
  1.01, 0.99, 0.93, 0.98, 1.07, 1.08, 0.99, 1.02, 0.96, 0.99, 1.05, 1.04, 0.93,
  0.94, 0.99, 0.96, 1.04, 1.03, 0.97, 1.01, 1.0, 0.98, 1.0, 1.07, 1.04, 0.98,
  1.03, 0.98, 1.0, 0.9, 1.03, 1.05, 1.04, 1.01, 0.99, 0.97, 1.0, 0.92, 0.9,
  0.97, 1.06, 0.93, 0.95, 1.0, 0.97, 1.04, 1.0, 0.97, 0.98, 1.06, 1.02, 0.91,
  0.95, 1.0, 0.98, 1.1, 1.03, 0.92, 1.03, 1.05, 1.01, 1.04, 0.95, 1.02, 1.03,
  0.97, 0.96, 0.94, 1.02, 1.04, 0.99, 1.01, 1.07, 1.02, 1.04, 0.99, 0.94, 1.0,
  1.03, 0.94, 1.03, 0.98, 1.0, 1.07, 1.02, 1.03, 0.99, 1.03, 1.02, 0.99, 0.98,
  1.0, 1.06, 0.98, 1.01, 1.02, 1.03, 0.98, 1.02, 0.96, 0.97, 0.97, 1.02, 1.04,
  1.02, 1.0, 0.96, 0.94, 1.06, 0.96, 0.99, 1.04, 0.96, 0.96, 1.03, 0.93, 0.99,
  0.99, 0.95, 1.0, 1.11, 1.01, 0.97, 1.05, 1.03, 1.06, 1.01, 1.0, 0.93, 0.93,
  0.99, 0.98, 1.0, 1.05, 0.99, 0.96, 0.96, 0.98, 1.1, 1.01, 0.94, 1.05, 1.02,
  1.01, 1.0, 0.97, 0.99, 1.03, 0.94, 0.96, 0.98, 0.98, 0.93, 0.97, 0.99, 0.96,
  1.07, 0.95, 1.07, 1.02, 1.03, 1.03, 0.95, 0.99, 1.05, 1.01, 1.01, 1.04, 0.95,
  0.97, 1.02, 0.99, 1.03, 1.01, 1.01, 1.0, 0.98, 1.0, 1.08, 1.03, 1.01, 1.08,
  1.0, 0.9, 0.92, 0.99, 0.99, 0.95, 0.98, 1.0, 1.01, 0.97, 0.99, 0.99, 0.98,
  1.01, 0.99, 1.01, 1.08, 0.95, 0.99, 1.03, 0.98, 0.97, 0.95, 1.03, 1.01, 1.01,
  0.97, 0.99, 1.01, 1.01, 0.97, 0.95, 0.99, 1.02, 1.04, 1.01, 1.04, 0.99, 1.03,
  1.0, 0.99, 0.98, 1.02, 1.03, 1.03, 1.06, 0.99, 0.97, 0.99, 1.04, 0.96, 1.03,
  1.03, 0.89, 1.03, 0.98, 1.04, 0.94, 1.03, 1.09, 0.92, 0.95, 0.97, 1.02, 1.06,
  0.97, 1.01, 1.02, 0.99, 1.05, 0.95, 0.95, 1.03, 0.98, 0.99, 0.96, 1.03, 1.02,
  1.0, 0.96, 0.99, 0.97, 1.06, 1.02, 1.0, 1.0, 0.94, 0.94, 0.98, 0.96, 0.97,
  1.04, 1.0, 0.97, 0.95, 1.06, 1.02, 0.97, 0.93, 1.02, 1.0, 1.02, 0.89, 1.02,
  0.98, 0.91, 1.0, 1.1, 1.05, 0.99, 0.99, 1.0, 1.07, 1.01, 0.99, 1.0, 0.98,
  0.95, 1.0, 1.07, 0.94, 0.98, 0.92, 0.96, 0.96, 0.97, 0.95, 0.95, 0.94, 1.04,
  1.0, 1.06, 1.01, 0.97, 0.95, 1.04, 0.99, 0.95, 0.99, 1.03, 1.04, 0.96, 1.04,
  0.95, 0.99, 0.98, 1.02, 1.02, 0.94, 1.05, 1.06, 1.05, 1.03, 0.98, 0.97, 1.0,
  0.97, 0.98, 1.06, 1.0, 0.97, 1.05, 1.02, 1.01, 1.03, 0.96, 1.01, 1.03, 1.02,
  0.97, 1.02, 0.98, 1.01, 1.01, 0.91, 1.07, 0.96, 1.02, 1.05, 1.05, 0.98, 1.01,
  0.97, 1.01, 0.94, 0.98, 0.98, 1.0, 0.99, 1.01, 1.01, 0.95, 1.08, 1.04, 0.95,
  1.01, 1.02, 1.0, 1.0, 1.01, 0.99, 1.05, 1.01, 1.03, 1.01, 1.04, 0.95, 0.95,
  1.08, 1.11, 0.97, 0.95, 0.95, 0.98, 0.99, 0.99, 1.04, 1.07, 1.0, 1.04, 1.03,
  0.98, 0.99, 1.02, 0.98, 1.04, 1.05, 0.96, 1.0, 1.0, 0.98, 1.02, 0.98, 1.1,
  1.04, 0.93, 1.02, 1.0, 1.0, 0.88, 1.02, 1.01, 1.05, 1.05, 1.03, 1.02, 1.0,
  0.92, 1.02, 1.0, 0.97, 1.01, 1.0, 0.97, 1.01, 0.98, 0.98, 1.02, 0.93, 1.07,
  0.98, 1.05, 0.95, 0.97, 1.07, 1.03, 1.05, 0.98, 1.04, 1.01, 0.9, 0.97, 1.02,
  1.0, 0.97, 0.99, 0.95, 0.94, 0.94, 1.01, 0.99, 1.03, 1.09, 0.97, 1.0, 1.01,
  1.01, 1.0, 1.06, 0.98, 1.08, 1.03, 1.03, 1.04, 1.04, 1.02, 1.0, 0.94, 0.98,
  1.02, 1.0, 1.02, 1.04, 1.05, 1.01, 1.04, 1.02, 1.03, 1.01, 1.06, 1.07, 1.0,
  1.0, 0.97, 0.99, 1.07, 1.01, 1.01, 0.99, 0.98, 1.01, 1.01, 0.99, 1.08, 1.03,
  1.06, 0.96, 1.0, 0.97, 1.05, 1.03, 1.0, 1.05, 1.01, 1.01, 1.01, 0.92, 1.0,
  1.03, 1.06, 1.03, 0.99, 0.98, 0.93, 1.01, 1.05, 0.98, 0.97, 0.93, 1.05, 1.04,
  0.99, 1.05, 1.0, 0.97, 1.0, 1.05, 1.0, 0.99, 1.02, 1.05, 1.02, 1.07, 1.04,
  1.1, 1.06, 0.92, 0.99, 0.97, 1.01, 0.96, 0.94, 1.01, 0.95, 1.0, 1.07, 0.98,
  1.02, 1.01, 0.98, 1.01, 0.96, 0.94, 0.95, 0.95, 0.96, 1.0, 0.97, 0.95, 1.01,
  1.04, 0.97, 0.98, 0.94, 1.02, 0.98, 0.95, 0.96, 0.95, 1.05, 1.03, 1.0, 1.05,
  1.01, 1.03, 0.96, 1.03, 1.07, 1.03, 0.93, 1.0, 1.0, 1.01, 1.07, 1.06, 0.97,
  0.99, 1.0, 1.04, 0.99, 1.06, 0.95, 0.96, 1.02, 1.05, 0.98, 1.09, 0.98, 1.02,
  0.94, 0.97, 1.01, 0.99, 0.98, 0.96, 0.99, 0.96, 1.01, 1.02, 1.02, 1.03, 0.95,
  1.05, 1.0, 0.99, 0.97, 1.02, 0.96, 1.04, 0.92, 1.02, 1.01, 0.98, 1.02, 1.01,
  0.96, 1.0, 1.03, 0.98, 0.98, 0.97, 1.1, 1.03, 1.01, 1.0, 1.02, 1.0, 0.98,
  1.02, 0.96, 0.99, 1.07, 1.0, 1.04, 0.98, 0.88, 1.04, 1.0, 0.96, 0.99, 0.97,
  1.06, 0.99, 1.07, 1.07, 1.03, 0.95, 1.01, 0.94, 0.93, 1.0, 1.0, 1.01, 1.05,
  1.1, 1.05, 1.0, 1.0, 1.03, 1.01, 1.0, 0.96, 0.99, 0.96, 0.99, 0.95, 0.99,
  0.98, 0.97, 1.09, 1.0, 1.03, 1.01, 0.98, 1.04, 1.02, 1.06, 1.01, 0.9, 1.01,
  1.0, 1.11, 1.02, 1.1, 1.02, 1.0, 0.96, 0.98, 0.95, 1.06, 0.99, 0.99, 1.03,
  0.98, 1.05, 0.99, 1.04, 1.0, 0.97, 0.98, 0.9, 0.96, 1.01, 0.94, 1.02, 0.99,
  1.0, 0.97, 0.97, 1.0, 1.0, 1.05, 0.98, 1.0, 1.04, 1.02, 1.01, 1.02, 1.01,
  0.97, 0.94, 1.01, 0.98, 0.97, 0.99, 1.01, 0.98, 0.97, 0.99, 0.96, 0.96, 0.96,
  0.97, 0.99, 1.02, 1.04, 1.04, 1.04, 1.01, 1.04, 0.89, 1.05, 1.04, 1.0, 1.01,
  1.02, 1.03, 1.0, 0.98, 1.01, 1.03, 0.99, 1.06, 1.04, 0.97, 1.0, 1.08, 0.97,
  0.93, 0.94, 1.0, 0.97, 0.99, 1.04, 0.96, 1.0, 0.98, 1.05, 0.99, 1.05, 0.96,
  0.97, 0.97, 0.99, 0.97, 0.97, 1.03, 0.99, 1.08, 0.98, 0.97, 1.01, 0.98, 1.01,
  1.02, 0.95, 1.03, 1.05, 0.95, 0.96, 1.04, 0.93, 0.96, 1.05, 1.01, 0.93, 0.97,
  1.0, 1.0, 0.93, 0.95, 0.96, 1.0, 1.01, 1.08, 1.04, 1.02, 1.0, 0.96, 0.97,
  0.96, 1.07, 1.05, 1.01, 0.99, 1.02, 1.01, 0.99, 1.06, 1.0, 1.05, 0.96, 1.01,
  1.0, 1.02, 1.03, 0.97, 1.03, 1.09, 0.97, 0.97, 0.99, 0.99, 0.99, 1.04, 0.99,
  1.0, 0.99, 1.03, 0.94, 1.06, 1.02, 1.03, 0.98, 0.99, 0.99, 0.96, 1.02, 1.03,
  1.0, 0.98, 1.02, 1.1, 1.04, 1.01, 1.0, 0.99, 1.04, 1.01, 0.93, 1.06, 0.99,
  1.01, 0.97, 0.98, 1.0, 0.99, 1.01, 1.04, 1.0, 1.0, 0.98, 0.94, 1.06, 0.97,
  1.03, 1.0, 1.02, 0.98, 1.05, 1.01, 0.99, 1.01, 0.99, 1.06, 1.0, 1.05, 1.04,
  0.96, 0.99, 1.03, 0.95, 0.95, 1.05, 1.01, 0.99, 1.02, 1.02, 0.99, 1.03, 0.97,
  0.99, 0.98, 1.0, 0.99, 0.95, 0.99, 1.08, 0.95, 0.97, 1.0, 1.03, 0.97, 1.02,
  1.06, 1.02, 0.98, 1.0, 0.97, 0.99, 0.97, 0.94, 0.98, 0.99, 0.98, 1.0, 1.05,
  1.04, 1.0, 1.08, 1.01, 1.04, 1.0, 1.0, 0.96, 0.96, 1.03, 0.97, 1.02, 0.96,
  0.95, 0.97, 0.98, 0.99, 0.98, 0.95, 1.05, 1.03, 1.0, 1.07, 1.03, 1.02, 1.02,
  0.95, 0.97, 0.99, 1.01, 0.93, 1.02, 1.01, 0.97, 1.03, 0.9, 1.03, 0.97, 0.98,
  1.1, 1.04, 0.96, 1.0, 1.0, 1.05, 0.97, 1.11, 1.06, 1.07, 1.0, 0.98, 1.01,
  0.99, 1.0, 1.03, 1.01, 1.02, 1.08, 0.98, 0.97, 0.97, 1.02, 0.99, 0.98, 0.95,
  1.11, 1.03, 1.0, 0.96, 1.01, 1.03, 0.92, 1.04, 1.03, 1.03, 0.99, 1.06, 1.02,
  0.94, 0.91, 0.95, 1.03, 0.99, 0.96, 0.97, 1.0, 1.02, 1.09, 0.99, 1.0, 0.99,
  1.09, 0.97, 0.97, 0.99, 1.0, 1.01, 0.95, 1.0, 1.01, 0.94, 1.0, 0.99, 1.0,
  0.96, 0.97, 0.99, 1.05, 1.05, 0.95, 0.96, 0.96, 1.01, 0.99, 0.98, 1.03, 1.0,
  1.06, 0.99, 1.04, 0.9, 0.97, 0.99, 0.98, 1.02, 0.99, 1.02, 0.99, 1.02, 1.01,
  1.07, 0.97, 0.99, 1.03, 1.01, 0.94, 0.99, 1.0, 1.0, 1.0, 1.03, 1.01, 0.89,
  1.0, 0.95, 0.93, 0.94, 1.04, 1.02, 0.91, 0.95, 0.99, 1.0, 0.98, 0.96, 1.0,
  0.98, 1.02, 0.97, 1.01, 0.99, 1.01, 1.01, 1.03, 0.95, 0.98, 0.96, 1.01, 1.06,
  0.98, 1.01, 1.0, 1.0, 1.01, 0.93, 1.0, 1.0, 0.96, 0.98, 1.05, 0.99, 1.06,
  1.05, 0.99, 1.05, 1.07, 0.96, 1.0, 0.98, 0.96, 0.98, 0.93, 1.04, 1.02, 1.02,
  0.92, 1.03, 1.07, 1.01, 1.0, 0.95, 1.02, 1.01, 0.96, 1.0, 0.94, 0.99, 1.0,
  1.03, 1.02, 0.96, 1.03, 1.02, 0.96, 0.96, 0.97, 1.02, 0.89, 1.01, 1.04, 1.02,
  1.0, 1.0, 0.99, 0.96, 0.98, 1.02, 1.06, 1.03, 1.05, 1.03, 0.9, 0.97, 1.0,
  1.01, 0.96, 0.97, 1.01, 0.99, 1.0, 0.99, 0.98, 1.0, 1.03, 1.03, 1.03, 0.95,
  1.0, 0.96, 1.06, 1.07, 0.96, 1.0, 0.99, 1.01, 0.98, 0.98, 0.93, 0.95, 1.05,
  1.04, 0.98, 0.98, 1.01, 1.03, 0.95, 0.99, 1.0, 1.0, 0.98, 1.03, 1.04, 0.96,
  0.93, 0.96, 0.97, 1.03, 1.03, 1.09, 0.99, 1.04, 1.04, 1.07, 1.0, 1.02, 1.0,
  1.06, 0.93, 1.03, 1.01, 1.02, 0.97, 1.02, 1.03, 1.03, 0.97, 1.05, 1.06, 1.06,
  1.01, 1.02, 0.96, 1.02, 1.05, 1.03, 0.95, 1.03, 0.99, 1.06, 1.01, 1.06, 0.97,
  1.02, 1.01, 1.0, 1.06, 0.99, 1.0, 1.01, 0.95, 1.07, 1.01, 0.94, 0.99, 1.02,
  0.93, 1.05, 0.96, 0.98, 1.03, 0.91, 0.99, 1.0, 0.9, 1.01, 1.09, 0.91, 0.96,
  1.02, 0.9, 1.05, 0.95, 1.06, 1.0, 1.04, 0.93, 0.96, 0.98, 0.98, 1.03, 0.98,
  0.93, 0.99, 0.99, 1.01, 1.01, 0.97, 1.05, 0.92, 1.04, 1.03, 1.05, 0.88, 0.99,
  1.0, 1.01, 0.94, 1.01, 1.01, 0.97, 1.05, 1.03, 1.0, 1.0, 1.04, 1.0, 1.02,
  0.97, 0.88, 1.05, 1.0, 1.0, 1.09, 1.0, 0.99, 1.0, 1.08, 1.0, 1.0, 1.0, 0.99,
  0.99, 1.03, 0.94, 1.01, 0.98, 1.0, 0.96, 1.01, 0.96, 0.99, 0.98, 1.01, 0.93,
  0.97, 0.99, 1.03, 1.03, 1.01, 1.01, 1.04, 0.98, 1.04, 0.97, 1.02, 0.98, 0.98,
  0.96, 1.04, 0.96, 1.07, 1.06, 1.07, 1.02, 1.06, 0.97, 0.94, 0.97, 0.98, 0.95,
  1.0, 0.99, 0.99, 1.03, 0.96, 1.05, 0.91, 1.04, 0.95, 0.97, 1.04, 1.0, 1.01,
  0.97, 0.97, 1.06, 0.99, 1.02, 0.99, 1.02, 0.93, 1.0, 1.0, 0.99, 0.92, 0.95,
  1.05, 1.01, 0.99, 0.95, 0.99, 0.95, 1.02, 0.98, 1.01, 1.03, 1.03, 1.06, 0.98,
  1.0, 0.99, 1.02, 0.99, 0.97, 1.01, 0.9, 1.0, 1.02, 0.96, 1.04, 0.93, 1.03,
  0.95, 1.04, 0.9, 0.97, 1.01, 0.95, 1.02, 1.01, 1.01, 1.03, 1.05, 1.01, 0.96,
  0.97, 1.01, 0.99, 1.1, 1.04, 1.02, 0.95, 1.05, 1.04, 1.0, 1.0, 1.0, 1.02,
  1.01, 0.85, 0.99, 1.02, 1.0, 0.99, 0.96, 1.03, 1.05, 0.99, 1.01, 1.03, 0.99,
  0.9, 0.94, 0.99, 0.97, 0.97, 1.01, 1.03, 1.0, 0.99, 1.01, 1.01, 1.02, 1.04,
  0.99, 0.99, 0.94, 1.05, 0.99, 0.96, 1.01, 1.02, 1.01, 0.91, 1.01, 0.94, 1.03,
  0.99, 0.92, 1.02, 0.96, 0.97, 0.99, 1.0, 0.97, 0.99, 1.03, 0.98, 0.96, 0.98,
  0.96, 1.06, 1.0, 1.02, 1.03, 1.01, 0.93, 0.93, 1.0, 0.95, 1.03, 1.07, 1.0,
  1.04, 1.0, 1.03, 1.03, 0.98, 0.99, 1.0, 0.94, 1.07, 0.97, 0.94, 0.99, 1.04,
  1.03, 1.04, 1.05, 0.99, 0.95, 0.97, 1.0, 0.95, 1.08, 0.99, 1.02, 0.97, 1.03,
  0.98, 1.0, 0.98, 1.03, 1.02, 1.0, 0.97, 0.95, 0.98, 1.02, 0.98, 0.98, 0.93,
  1.0, 1.02, 0.94, 0.97, 1.02, 1.0, 1.01, 0.98, 0.98, 1.02, 1.02, 1.07, 1.03,
  1.07, 1.03, 1.02, 0.98, 1.01, 0.98, 1.01, 0.95, 1.01, 1.07, 0.96, 1.07, 1.04,
  1.02, 0.97, 1.05, 0.99, 1.05, 0.96, 0.98, 0.99, 1.03, 1.03, 0.99, 1.0, 0.89,
  0.97, 0.88, 0.97, 1.0, 1.04, 0.96, 0.96, 1.03, 1.02, 0.98, 1.05, 1.02, 1.03,
  1.02, 0.92, 1.01, 0.95, 0.97, 1.05, 0.93, 0.89, 0.93, 0.99, 0.98, 1.06, 0.97,
  1.05, 1.01, 1.0, 1.02, 0.99, 1.0, 1.01, 1.0, 0.98, 0.98, 0.97, 0.98, 0.94,
  0.95, 1.05, 0.97, 1.01, 0.91, 1.07, 1.06, 1.05, 0.97, 1.03, 1.1, 1.01, 0.99,
  0.96, 1.01, 0.98, 0.98, 1.07, 0.91, 1.0, 1.02, 0.93, 0.94, 1.04, 0.98, 1.0,
  0.94, 0.98, 0.95, 0.96, 0.91, 0.92, 0.95, 0.96, 0.99, 1.05, 0.94, 0.99, 0.98,
  1.0, 0.94, 0.99, 0.99, 1.03, 1.08, 1.04, 1.04, 0.96, 1.04, 1.06, 0.99, 0.94,
  1.1, 1.01, 1.01, 0.97, 1.03, 1.03, 1.03, 0.99, 1.02, 0.93, 1.0, 1.0, 1.02,
  0.95, 0.98, 0.98, 0.86, 1.02, 1.03, 0.97, 1.08, 0.98, 1.0, 0.95, 1.03, 0.96,
  1.0, 1.06, 1.04, 1.04, 0.98, 0.92, 1.06, 0.94, 1.0, 1.01, 1.0, 0.98, 0.94,
  1.03, 0.94, 1.0, 1.01, 1.03, 1.0, 0.98, 0.99, 1.02, 0.99, 1.08, 1.05, 0.94,
  0.95, 1.01, 0.96, 1.03, 1.05, 1.02, 1.03, 0.89, 0.97, 0.96, 0.99, 0.91, 0.98,
  1.01, 1.03, 1.05, 0.92, 1.07, 1.01, 0.96, 1.03, 1.02, 0.97, 0.99, 1.02, 0.99,
  1.03, 0.98, 1.05, 0.96, 1.02, 0.98, 1.06, 0.97, 1.04, 1.03, 1.06, 0.99, 0.99,
  1.02, 1.04, 1.01, 0.98, 1.02, 1.03, 1.04, 1.01, 1.02, 1.01, 0.97, 1.03, 0.99,
  0.97, 1.02, 0.96, 1.09, 1.05, 1.03, 1.01, 1.06, 0.99, 1.01, 0.97, 0.98, 1.0,
  0.96, 1.0, 0.97, 0.96, 1.05, 0.91, 0.98, 0.92, 0.98, 0.98, 0.99, 1.0, 1.04,
  0.98, 1.0, 1.05, 0.99, 1.0, 1.03, 1.01, 1.04, 0.93, 1.0, 0.99, 0.96, 0.93,
  1.04, 1.0, 0.95, 0.99, 0.98, 0.99, 1.0, 1.01, 0.96, 0.98, 1.03, 1.02, 1.04,
  0.99, 0.94, 1.07, 0.99, 0.99, 0.96, 0.99, 0.95, 1.0, 1.03, 1.0, 1.04, 0.97,
  0.97, 0.98, 0.99, 0.93, 0.99, 1.02, 0.99, 1.06, 0.97, 1.04, 0.98, 0.94, 0.96,
  1.06, 0.91, 0.95, 0.99, 1.01, 0.95, 1.03, 0.97, 0.98, 1.01, 1.02, 1.01, 1.0,
  0.91, 0.97, 0.97, 0.98, 1.01, 0.99, 0.92, 0.98, 0.96, 0.97, 0.97, 1.01, 0.96,
  1.05, 0.98, 1.05, 0.93, 0.97, 1.0, 1.0, 0.98, 0.99, 1.08, 0.97, 1.03, 1.01,
  1.06, 0.92, 1.03, 0.94, 0.97, 0.97, 0.95, 1.03, 0.99, 0.89, 0.99, 0.98, 1.0,
  0.99, 1.01, 0.95, 0.95, 1.03, 1.1, 0.99, 1.02, 0.93, 0.94, 0.99, 0.99, 1.01,
  1.0, 1.0, 0.96, 0.97, 0.96, 0.95, 0.94, 1.08, 0.99, 1.06, 1.03, 1.01, 1.07,
  0.94, 1.02, 1.02, 1.03, 1.0, 0.98, 0.97, 0.97, 1.05, 0.97, 1.03, 1.01, 0.99,
  1.02, 1.01, 1.0, 0.98, 0.97, 1.02, 1.11, 1.02, 0.98, 0.96, 0.99, 1.0, 1.05,
  1.02, 0.98, 0.98, 1.06, 1.02, 0.98, 1.01, 1.04, 0.99, 1.02, 1.01, 0.98, 1.03,
  0.97, 0.97, 1.0, 0.96, 1.09, 0.97, 0.96, 1.04, 1.02, 0.93, 1.01, 1.02, 1.06,
  0.98, 1.0, 1.01, 0.99, 1.02, 1.04, 1.01, 1.01, 1.0, 1.05, 1.05, 1.0, 1.01,
  0.99, 0.96, 1.06, 0.96, 0.98, 0.99, 1.02, 1.02, 1.01, 1.01, 1.0, 0.98, 1.03,
  0.95, 0.97, 0.96, 1.0, 1.0, 1.02, 1.06, 1.06, 0.98, 1.01, 1.01, 1.01, 0.98,
  0.99, 1.0, 0.98, 0.97, 0.96, 0.91, 1.05, 1.01, 0.97, 0.99, 1.04, 1.0, 0.98,
  1.01, 1.0, 0.98, 1.04, 1.02, 1.01, 1.08, 0.96, 0.98, 0.97, 0.93, 0.93, 1.01,
  0.96, 1.06, 0.99, 0.98, 1.03, 1.07, 0.99, 0.99, 1.03, 1.0, 1.0, 1.0, 1.07,
  1.05, 1.06, 1.04, 0.99, 1.04, 1.06, 0.93, 0.99, 1.02, 1.09, 1.09, 0.94, 0.98,
  1.01, 0.98, 0.99, 1.04, 0.94, 1.0, 1.01, 0.93, 1.0, 1.01, 0.95, 1.01, 1.0,
  1.0, 0.94, 1.02, 1.0, 0.97, 0.96, 0.96, 1.0, 0.94, 0.93, 1.06, 1.07, 0.96,
  0.9, 1.01, 1.06, 1.0, 1.0, 1.01, 0.96, 1.01, 1.05, 0.96, 1.02, 0.96, 0.99,
  1.0, 0.99, 1.06, 1.04, 0.98, 1.0, 0.98, 1.0, 1.07, 0.99, 1.01, 0.95, 1.05,
  1.04, 1.03, 1.01, 1.08, 1.02, 0.99, 1.09, 1.01, 0.97, 0.97, 1.03, 1.01, 1.02,
  1.04, 1.03, 1.0, 0.99, 1.01, 1.05, 0.92, 0.94, 0.97, 0.95, 1.01, 1.01, 1.01,
  0.93, 0.97, 0.98, 1.04, 0.95, 0.93, 1.06, 1.07, 1.01, 0.99, 1.04, 0.96, 0.93,
  1.0, 0.92, 1.0, 1.01, 0.97, 0.9, 0.94, 1.02, 0.97, 1.03, 1.02, 0.95, 1.0,
  1.03, 0.96, 1.06, 1.01, 0.96, 0.98, 1.01, 1.01, 1.07, 0.99, 1.03, 0.99, 1.01,
  1.05, 0.99, 0.95, 1.04, 1.04, 1.01, 1.01, 0.98, 1.02, 1.05, 1.03, 1.05, 0.98,
  0.99, 0.96, 1.04, 1.01, 1.05, 1.02, 1.03, 1.0, 1.02, 0.97, 1.03, 1.01, 0.93,
  1.01, 0.97, 0.98, 0.99, 1.02, 1.08, 0.96, 0.97, 1.02, 1.03, 0.95, 1.06, 0.96,
  0.95, 1.03, 1.0, 1.02, 0.99, 0.96, 1.04, 1.01, 1.1, 0.95, 0.98, 1.0, 0.96,
  1.04, 1.02, 1.0, 1.0, 0.95, 0.95, 0.98, 1.05, 1.06, 1.07, 0.98, 0.99, 1.0,
  0.99, 1.0, 1.0, 0.99, 0.97, 1.02, 1.1, 0.93, 1.02, 0.98, 1.09, 1.03, 1.05,
  0.98, 1.03, 0.97, 1.04, 0.99, 0.99, 0.96, 1.03, 1.0, 1.05, 1.0, 0.98, 0.98,
  0.97, 1.0, 1.01, 0.97, 1.01, 0.92, 1.05, 0.91, 0.91, 1.02, 1.04, 0.96, 1.06,
  1.0, 1.09, 1.03, 1.02, 0.99, 1.07, 0.93, 1.05, 1.03, 1.04, 0.92, 1.02, 1.03,
  1.0, 1.02, 1.0, 1.0, 0.99, 0.99, 1.02, 0.99, 0.97, 1.03, 1.01, 1.04, 0.9,
  1.02, 0.93, 1.01, 1.06, 0.99, 0.94, 0.86, 1.01, 0.91, 1.02, 0.97, 1.02, 1.03,
  1.04, 0.94, 0.98, 0.93, 1.03, 0.94, 1.01, 0.96, 0.93, 1.05, 0.98, 1.05, 1.06,
  1.0, 1.05, 0.97, 0.96, 1.02, 0.99, 0.94, 0.99, 1.06, 0.98, 1.04, 0.89, 1.02,
  1.05, 1.02, 1.07, 1.01, 0.91, 1.03, 0.99, 1.01, 0.93, 0.95, 0.99, 1.01, 1.01,
  1.0, 1.1, 1.01, 1.02, 1.02, 0.93, 0.94, 0.97, 1.01, 1.0, 0.99, 0.97, 1.01,
  1.03, 1.02, 1.03, 0.99, 1.0, 1.05, 1.04, 0.96, 1.01, 1.0, 1.08, 1.0, 1.04,
  0.99, 0.95, 1.0, 1.0, 1.05, 1.04, 1.01, 1.01, 1.07, 0.96, 1.04, 0.97, 0.98,
  0.99, 0.97, 1.04, 1.05, 0.97, 1.01, 1.0, 0.96, 1.0, 0.99, 0.99, 0.99, 0.97,
  1.0, 1.03, 0.97, 0.98, 0.98, 1.01, 0.95, 1.04, 0.98, 0.98, 1.02, 0.99, 0.97,
  1.01, 0.99, 1.03, 0.96, 0.98, 1.04, 0.97, 0.95, 1.04, 1.01, 1.03, 0.94, 0.97,
  0.99, 1.01, 1.01, 1.01, 1.02, 1.06, 1.0, 1.02, 1.0, 1.06, 1.03, 0.97, 1.04,
  0.99, 0.98, 0.99, 1.04, 1.04, 1.02, 0.96, 1.01, 0.96, 0.99, 0.93, 0.99, 1.01,
  0.99, 0.96, 1.02, 1.02, 1.0, 1.0, 0.97, 0.99, 0.95, 0.98, 1.04, 0.95, 1.02,
  1.09, 1.02, 1.01, 0.97, 1.03, 0.96, 1.05, 1.05, 1.02, 0.98, 1.02, 0.94, 1.02,
  1.01, 1.02, 0.97, 0.94, 1.03, 1.03, 1.03, 0.99, 0.99, 0.99, 1.0, 0.98, 1.04,
  1.04, 0.94, 1.08, 1.09, 1.03, 1.01, 1.07, 1.02, 0.98, 0.97, 0.95, 0.98, 1.08,
  1.02, 0.94, 1.06, 0.97, 0.96, 0.99, 0.96, 1.0, 0.99, 0.99, 1.01, 0.98, 1.07,
  0.95, 0.96, 0.99, 1.1, 0.96, 0.99, 0.95, 1.05, 1.03, 0.92, 1.04, 1.01, 1.02,
  1.03, 0.99, 1.03, 1.0, 0.93, 0.96, 0.99, 0.96, 0.94, 1.03, 0.96, 1.01, 1.02,
  1.02, 1.0, 1.09, 0.98, 1.03, 1.05, 1.04, 0.99, 0.97, 1.02, 1.0, 0.95, 1.02,
  1.04, 1.02, 0.96, 0.99, 0.94, 1.03, 0.92, 1.0, 0.98, 1.03, 1.05, 1.05, 1.04,
  1.02, 0.99, 0.92, 0.98, 1.0, 0.95, 1.08, 0.98, 1.02, 1.0, 0.94, 0.94, 1.05,
  1.06, 0.99, 0.99, 1.04, 0.97, 0.96, 1.04, 1.05, 1.03, 0.99, 1.0, 1.01, 1.05,
  1.02, 1.04, 0.98, 0.97, 1.05, 0.97, 1.02, 1.02, 0.97, 1.02, 1.04, 0.95, 1.06,
  1.02, 1.04, 1.03, 0.99, 1.02, 1.04, 0.99, 1.0, 0.98, 0.98, 1.01, 1.04, 0.96,
  1.05, 1.01, 1.02, 1.03, 0.89, 0.96, 1.01, 1.01, 0.96, 0.98, 1.01, 1.05, 1.01,
  0.98, 0.99, 0.99, 1.07, 1.04, 1.02, 1.04, 1.01, 1.0, 0.97, 1.0, 0.97, 1.0,
  1.0, 0.97, 1.04, 1.07, 1.05, 1.01, 1.0, 1.06, 0.97, 0.92, 1.03, 1.0, 0.97,
  0.99, 1.01, 1.0, 1.11, 1.03, 1.03, 1.03, 0.98, 1.02, 1.05, 0.98, 1.02, 0.98,
  1.05, 1.05, 1.02, 0.99, 1.03, 0.96, 1.0, 1.01, 1.08, 1.02, 1.02, 0.99, 0.97,
  1.05, 1.02, 1.0, 1.04, 1.01, 0.98, 1.02, 0.93, 0.97, 1.0, 1.06, 0.97, 1.04,
  1.07, 0.97, 0.98, 0.95, 1.04, 1.05, 0.99, 1.03, 0.96, 1.0, 1.02, 1.0, 1.07,
  1.04, 0.93, 1.0, 0.93, 0.99, 1.06, 1.0, 0.97, 1.05, 0.99, 1.0, 0.98, 1.04,
  0.98, 0.95, 0.97, 0.98, 1.04, 1.05, 1.01, 0.99, 1.01, 1.04, 1.0, 1.03, 1.0,
  0.99, 1.06, 1.03, 0.97, 1.05, 1.0, 0.95, 1.0, 0.97, 0.94, 0.97, 1.01, 1.06,
  0.99, 0.97, 1.0, 0.94, 1.02, 0.98, 1.01, 0.99, 1.0, 1.03, 0.96, 1.03, 1.01,
  0.96, 0.91, 1.03, 0.98, 1.0, 1.01, 1.12, 1.09, 0.98, 0.98, 1.01, 0.9, 0.98,
  0.97, 0.93, 1.06, 0.9, 0.98, 0.98, 0.97, 1.01, 0.99, 0.97, 0.98, 1.01, 1.03,
  0.97, 1.05, 1.08, 0.97, 0.97, 1.01, 0.97, 1.04, 1.01, 0.94, 0.96, 0.94, 1.02,
  0.96, 1.05, 1.0, 1.06, 0.94, 0.98, 1.04, 1.01, 0.98, 1.01, 1.0, 1.01, 1.02,
  1.08, 0.99, 1.07, 0.98, 1.03, 0.92, 0.99, 0.97, 1.04, 0.99, 1.06, 0.96, 0.99,
  1.05, 1.03, 0.89, 1.04, 0.95, 0.95, 0.94, 1.03, 1.07, 1.01, 0.97, 1.01, 1.03,
  0.99, 0.99, 0.98, 0.89, 0.95, 0.98, 1.0, 0.98, 0.99, 0.94, 1.01, 0.91, 0.99,
  1.03, 1.03, 0.98, 1.03, 1.02, 1.0, 0.97, 1.01, 1.07, 1.0, 0.93, 1.03, 0.96,
  1.02, 1.02, 0.97, 0.97, 1.02, 1.02, 0.97, 1.0, 1.0, 1.0, 0.96, 0.97, 1.04,
  1.03, 1.04, 1.02, 1.08, 0.97, 1.0, 0.93, 1.01, 0.95, 1.06, 0.97, 0.98, 1.06,
  1.03, 0.94, 1.07, 0.99, 0.94, 0.99, 0.96, 1.07, 1.01, 0.95, 1.01, 1.0, 1.02,
  0.98, 1.01, 0.94, 0.93, 0.97, 1.01, 1.02, 0.88, 1.04, 0.98, 0.98, 0.98, 0.95,
  1.06, 1.0, 0.95, 1.02, 1.02, 0.99, 1.04, 1.05, 0.97, 1.01, 0.94, 0.97, 1.01,
  1.02, 1.01, 1.01, 1.0, 0.99, 0.99, 0.97, 0.98, 1.01, 0.9, 1.0, 1.01, 0.97,
  0.99, 1.05, 0.94, 1.1, 0.99, 0.97, 1.02, 0.98, 0.99, 0.98, 1.02, 1.0, 1.05,
  0.96, 0.96, 0.93, 1.03, 1.0, 1.01, 0.99, 0.99, 1.01, 0.95, 0.98, 1.0, 0.9,
  0.97, 0.99, 1.02, 1.01, 0.98, 1.08, 0.94, 0.99, 1.02, 1.02, 1.03, 0.96, 0.99,
  0.95, 1.03, 1.01, 1.0, 1.01, 1.03, 0.99, 0.97, 1.01, 1.02, 1.0, 0.98, 1.01,
  0.95, 0.99, 0.99, 1.0, 1.0, 0.92, 1.0, 1.02, 0.96, 0.91, 1.01, 1.03, 1.0,
  1.01, 1.03, 1.01, 1.02, 1.01, 0.97, 0.97, 1.04, 1.09, 1.0, 1.03, 1.02, 1.03,
  1.05, 1.01, 1.03, 1.08, 0.94, 1.07, 0.97, 1.03, 1.04, 0.97, 1.0, 0.96, 1.04,
  0.99, 0.99, 1.0, 1.01, 1.01, 1.0, 1.03, 0.95, 0.98, 0.96, 1.04, 1.01, 0.99,
  0.95, 1.01, 1.02, 1.02, 0.94, 1.01, 1.01, 1.05, 0.94, 1.09, 1.01, 1.05, 1.04,
  0.96, 1.02, 0.99, 1.04, 0.99, 0.95, 1.02, 1.01, 1.05, 0.97, 0.93, 0.96, 1.12,
  1.01, 1.04, 1.02, 1.0, 1.03, 1.06, 0.98, 1.01, 0.98, 1.07, 1.0, 1.09, 1.08,
  1.0, 0.95, 1.05, 0.94, 1.01, 1.03, 1.01, 0.96, 1.06, 0.95, 1.05, 0.98, 1.0,
  1.04, 1.06, 1.02, 0.95, 1.07, 0.91, 1.0, 0.95, 0.94, 1.0, 0.98, 0.97, 0.96,
  1.0, 1.06, 1.0, 0.95, 0.93, 0.97, 1.0, 0.99, 0.96, 1.02, 1.0, 0.98, 1.06,
  1.13, 1.0, 1.02, 1.01, 0.95, 0.98, 0.97, 1.03, 1.0, 0.92, 1.0, 0.94, 0.95,
  1.02, 1.01, 0.97, 0.97, 0.94, 1.01, 1.07, 1.0, 1.03, 0.98, 0.95, 0.99, 1.03,
  0.98, 0.97, 0.98, 0.98, 1.03, 0.97, 1.02, 0.93, 1.03, 0.94, 1.0, 1.01, 1.08,
  1.04, 0.95, 0.97, 1.02, 0.94, 0.91, 1.01, 1.05, 0.96, 0.96, 0.95, 1.05, 0.92,
  1.0, 0.95, 1.03, 0.99, 0.97, 1.02, 0.99, 1.01, 1.04, 0.95, 0.94, 1.08, 1.05,
  0.96, 1.05, 0.93, 0.93, 0.99, 0.98, 1.0, 0.98, 0.98, 1.04, 1.07, 1.0, 0.98,
  0.97, 0.98, 1.03, 0.98, 1.01, 1.09, 0.92, 1.01, 1.03, 1.03, 1.0, 0.93, 0.98,
  0.97, 0.95, 1.04, 1.05, 0.93, 0.97, 1.07, 0.97, 1.0, 0.99, 0.99, 0.97, 1.03,
  0.97, 0.97, 0.98, 0.99, 1.0, 1.05, 1.04, 1.0, 0.98, 0.97, 1.02, 0.99, 0.99,
  1.05, 0.97, 0.98, 0.97, 0.98, 1.03, 1.0, 1.0, 0.94, 1.03, 0.99, 1.0, 1.05,
  1.06, 0.99, 1.04, 0.93, 1.09, 0.95, 1.02, 1.03, 1.04, 1.0, 1.01, 1.04, 1.04,
  1.02, 1.02, 1.04, 0.97, 1.04, 0.97, 1.05, 1.01, 0.94, 0.97, 0.97, 0.97, 0.98,
  1.02, 1.0, 1.12, 0.99, 1.01, 1.01, 1.0, 1.03, 1.0, 1.0, 0.98, 0.98, 0.95,
  0.96, 0.99, 1.02, 0.99, 0.92, 0.99, 0.94, 1.0, 0.99, 1.01, 1.03, 1.04, 0.96,
  0.98, 0.97, 1.05, 1.02, 0.96, 1.01, 1.03, 0.92, 1.01, 1.03, 0.99, 1.05, 1.07,
  0.99, 1.0, 1.02, 0.96, 1.03, 1.03, 0.99, 0.96, 0.98, 0.96, 1.04, 0.99, 0.96,
  1.0, 0.95, 0.98, 0.96, 1.03, 1.02, 0.99, 0.95, 1.01, 0.94, 0.95, 0.93, 0.94,
  1.05, 1.01, 1.06, 0.95, 0.95, 1.08, 1.01, 1.02, 1.08, 0.98, 1.03, 1.07, 1.02,
  0.99, 1.06, 1.07, 1.08, 1.04, 0.99, 0.95, 1.06, 1.0, 0.99, 0.94, 0.98, 1.03,
  1.07, 0.99, 1.02, 0.95, 1.02, 0.99, 1.0, 0.99, 1.06, 1.04, 0.99, 0.93, 0.99,
  0.97, 1.02, 1.02, 1.0, 1.05, 1.05, 0.97, 1.01, 1.04, 1.05, 1.05, 0.99, 0.99,
  0.98, 1.04, 1.0, 1.02, 1.08, 0.96, 1.0, 1.01, 1.02, 0.98, 1.06, 1.04, 0.99,
  1.04, 1.01, 0.96, 0.98, 1.01, 1.03, 1.07, 0.94, 1.04, 0.98, 1.01, 1.01, 0.97,
  1.0, 0.99, 0.92, 1.05, 0.97, 0.97, 1.01, 0.96, 1.04, 0.99, 0.99, 1.0, 0.94,
  1.01, 0.98, 1.03, 0.97, 1.07, 0.99, 1.02, 1.06, 1.07, 1.09, 0.97, 0.98, 1.01,
  0.88, 1.01, 0.92, 1.13, 0.96, 0.99, 0.95, 0.98, 0.97, 1.01, 0.98, 0.95, 1.05,
  0.94, 1.03, 1.05, 1.04, 1.01, 1.06, 1.01, 1.0, 0.98, 1.05, 1.02, 1.06, 1.01,
  1.0, 1.03, 0.96, 1.03, 1.03, 1.03, 0.98, 1.04, 0.97, 1.05, 1.0, 1.02, 1.03,
  0.96, 1.02, 1.03, 1.0, 0.97, 0.99, 0.99, 1.08, 1.01, 1.09, 0.93, 1.04, 0.97,
  0.92, 0.99, 0.98, 1.02, 1.02, 0.99, 0.98, 1.0, 0.95, 0.96, 0.98, 1.03, 1.01,
  0.93, 0.98, 1.01, 1.05, 0.96, 1.0, 1.06, 1.08, 1.02, 1.02, 1.02, 1.01, 1.05,
  1.0, 0.93, 1.01, 0.99, 1.0, 1.0, 1.0, 1.03, 1.0, 0.92, 0.96, 1.02, 0.98, 0.95,
  0.96, 1.04, 1.03, 1.01, 1.02, 0.96, 0.99, 1.01, 1.02, 1.0, 1.07, 0.96, 1.01,
  0.98, 0.87, 1.01, 0.87, 0.97, 0.99, 0.96, 1.05, 1.0, 1.06, 1.01, 1.04, 1.02,
  0.97, 0.97, 0.91, 1.01, 0.95, 1.0, 0.97, 0.96, 1.01, 1.01, 1.02, 1.02, 0.99,
  1.08, 0.98, 0.96, 1.04, 0.99, 1.03, 0.97, 1.03, 0.97, 0.95, 1.0, 1.08, 1.03,
  1.02, 1.04, 0.99, 1.01, 0.97, 0.94, 1.03, 1.0, 0.99, 1.05, 0.98, 1.01, 1.0,
  1.04, 0.99, 1.02, 0.95, 1.0, 0.92, 1.01, 0.98, 0.98, 0.94, 1.05, 1.01, 0.99,
  0.98, 1.02, 1.07, 0.96, 1.09, 1.0, 1.07, 0.97, 1.0, 1.0, 0.98, 1.01, 0.94,
  1.0, 0.96, 0.96, 0.93, 1.01, 1.07, 0.94, 1.01, 1.1, 1.04, 0.98, 0.96, 1.01,
  1.0, 1.03, 0.99, 0.92, 1.09, 0.96, 1.02, 0.98, 0.92, 0.96, 0.91, 0.98, 0.92,
  0.98, 1.06, 0.99, 1.02, 0.99, 1.06, 0.97, 1.02, 1.01, 1.04, 1.02, 1.07, 0.97,
  1.03, 0.98, 1.02, 0.99, 0.98, 0.95, 1.08, 1.04, 0.97, 1.04, 1.01, 1.01, 1.01,
  1.0, 1.03, 0.97, 0.97, 1.02, 0.95, 0.94, 0.93, 0.98, 0.94, 1.0, 1.09, 0.95,
  1.04, 1.02, 0.97, 0.97, 0.95, 1.02, 0.9, 0.98, 1.02, 0.99, 0.97, 1.01, 1.04,
  0.95, 1.0, 1.0, 0.97, 1.05, 1.06, 0.99, 0.99, 1.04, 0.99, 0.99, 0.97, 1.02,
  1.01, 1.01, 0.94, 1.01, 1.06, 1.02, 0.98, 1.0, 1.0, 0.96, 1.0, 1.06, 1.04,
  0.95, 0.94, 0.96, 1.01, 0.96, 1.0, 0.97, 0.99, 0.99, 0.99, 1.02, 0.95, 1.06,
  1.05, 0.96, 1.0, 1.01, 0.96, 0.98, 0.99, 1.01, 0.99, 1.09, 0.98, 1.02, 1.05,
  1.03, 1.02, 0.95, 0.95, 1.04, 1.01, 0.97, 1.01, 1.06, 1.05, 1.06, 1.0, 1.02,
  0.93, 0.96, 0.98, 0.98, 1.02, 0.97, 0.96, 0.98, 0.97, 1.05, 0.99, 1.04, 1.08,
  1.05, 1.01, 1.02, 1.01, 1.03, 0.96, 1.05, 0.94, 0.98, 1.04, 1.03, 0.99, 0.97,
  1.02, 1.0, 1.04, 0.94, 0.99, 0.98, 0.94, 1.03, 0.95, 1.04, 1.0, 1.04, 0.98,
  0.94, 1.1, 1.08, 1.05, 1.11, 1.0, 0.99, 1.01, 0.98, 0.97, 1.04, 1.02, 1.05,
  0.97, 1.02, 1.03, 0.94, 0.97, 0.98, 1.0, 1.0, 1.0, 0.93, 1.01, 0.94, 1.0,
  1.03, 1.01, 0.95, 1.02, 1.01, 0.97, 0.99, 1.0, 0.99, 1.01, 1.0, 0.98, 1.08,
  1.0, 1.05, 1.0, 1.0, 0.92, 0.99, 0.95, 1.02, 0.96, 1.0, 0.96, 1.02, 0.97,
  1.04, 1.07, 1.02, 1.03, 0.96, 0.98, 1.0, 1.06, 0.94, 1.01, 0.97, 1.04, 1.06,
  0.96, 0.96, 1.0, 1.04, 0.99, 1.02, 1.03, 1.01, 1.06, 1.0, 1.03, 0.99, 0.93,
  1.01, 1.01, 0.97, 0.99, 0.99, 1.01, 1.02, 1.06, 1.0, 1.0, 0.99, 1.1, 0.98,
  1.0, 1.0, 1.03, 0.96, 1.04, 1.06, 0.99, 0.97, 1.09, 1.0, 1.04, 0.99, 0.96,
  0.98, 1.0, 1.06, 1.03, 0.95, 1.02, 0.97, 0.98, 0.99, 1.01, 1.02, 0.97, 1.01,
  0.99, 1.02, 0.93, 1.02, 0.97, 1.0, 0.96, 0.98, 0.95, 1.01, 0.95, 0.97, 1.02,
  0.99, 0.97, 1.05, 1.01, 0.99, 1.05, 1.0, 0.95, 1.01, 1.04, 1.03, 1.0, 0.98,
  0.98, 0.99, 1.01, 1.06, 1.01, 1.01, 0.99, 1.0, 0.96, 1.03, 1.03, 1.0, 1.03,
  1.01, 1.03, 1.05, 1.02, 0.92, 0.95, 0.96, 1.02, 1.0, 0.99, 1.01, 1.0, 0.94,
  0.98, 1.02, 1.01, 0.95, 0.96, 0.99, 0.98, 0.99, 0.97, 0.93, 1.03, 1.02, 0.99,
  0.97, 1.02, 1.0, 1.11, 0.94, 1.06, 1.03, 0.95, 0.98, 0.91, 0.95, 1.05, 0.96,
  0.95, 1.01, 1.05, 1.0, 0.99, 0.93, 0.96, 0.97, 0.98, 0.97, 1.03, 1.02, 0.96,
  1.01, 1.02, 1.05, 1.07, 1.03, 0.97, 0.93, 1.0, 0.96, 1.11, 0.98, 0.98, 0.98,
  1.01, 0.99, 0.98, 0.99, 0.97, 0.98, 1.13, 1.04, 0.95, 1.03, 1.04, 0.94, 0.98,
  0.94, 0.97, 0.98, 0.99, 1.02, 0.99, 1.01, 1.0, 1.01, 0.95, 1.0, 0.99, 1.01,
  0.99, 1.02, 0.97, 0.98, 0.97, 0.99, 0.97, 0.96, 0.96, 1.05, 0.95, 0.99, 0.99,
  0.93, 1.03, 0.98, 1.07, 1.05, 1.05, 1.03, 1.03, 1.0, 1.04, 1.02, 0.96, 1.06,
  1.03, 1.0, 0.96, 0.98, 1.04, 1.0, 1.01, 1.08, 0.96, 0.99, 1.0, 0.95, 0.99,
  1.01, 0.99, 1.05, 1.01, 0.97, 1.0, 1.05, 1.0, 0.94, 1.01, 1.01, 0.99, 0.99,
  0.96, 0.95, 1.08, 1.0, 1.0, 0.95, 1.0, 1.08, 1.06, 1.0, 1.03, 0.96, 1.05,
  1.09, 1.03, 1.01, 1.02, 1.0, 1.05, 1.08, 1.01, 1.02, 1.09, 1.04, 1.0, 1.02,
  1.04, 0.96, 1.0, 0.93, 0.97, 1.03, 1.09, 0.97, 1.02, 1.09, 0.95, 1.06, 1.0,
  0.95, 1.04, 1.09, 1.04, 1.0, 0.91, 0.94, 1.0, 0.92, 1.08, 1.04, 1.07, 0.98,
  1.04, 1.01, 1.07, 0.92, 0.97, 0.94, 1.04, 1.02, 0.94, 0.98, 1.0, 1.04, 1.04,
  0.92, 0.96, 1.0, 1.01, 1.06, 0.92, 0.96, 1.01, 1.03, 0.92, 0.98, 0.97, 1.04,
  0.99, 1.04, 0.91, 1.0, 0.99, 0.97, 1.01, 1.02, 1.06, 0.92, 1.0, 1.05, 0.96,
  0.98, 0.99, 0.95, 0.94, 1.04, 0.98, 1.01, 0.95, 0.97, 0.96, 1.04, 1.0, 1.09,
  1.03, 0.99, 0.97, 0.98, 1.0, 1.02, 0.97, 1.04, 1.03, 1.02, 0.97, 1.1, 1.05,
  0.99, 0.96, 1.03, 1.01, 0.91, 0.97, 1.04, 0.97, 1.01, 1.0, 1.02, 0.91, 1.04,
  1.0, 0.95, 1.0, 0.92, 0.99, 1.05, 0.99, 1.01, 1.01, 1.06, 0.93, 0.97, 1.0,
  1.02, 1.05, 1.0, 1.0, 1.06, 1.02, 0.97, 0.95, 0.96, 1.07, 1.07, 1.0, 1.02,
  0.92, 1.0, 1.02, 1.02, 1.01, 1.0, 0.97, 0.95, 0.95, 0.98, 0.99, 1.02, 0.95,
  1.05, 1.0, 1.01, 1.02, 1.01, 1.01, 1.03, 0.96, 0.96, 0.92, 0.99, 0.99, 1.0,
  1.11, 0.99, 0.95, 1.0, 1.01, 1.06, 0.94, 0.93, 1.06, 0.97, 1.03, 1.07, 0.95,
  1.01, 1.01, 1.0, 1.0, 0.99, 1.02, 1.05, 1.05, 1.02, 1.08, 0.99, 0.96, 0.99,
  1.02, 1.02, 1.02, 1.06, 0.97, 1.12, 1.01, 1.02, 0.97, 1.05, 1.07, 0.96, 1.0,
  1.02, 0.95, 1.02, 0.93, 0.95, 0.97, 0.97, 1.1, 0.95, 0.98, 1.04, 1.02, 0.99,
  0.94, 0.98, 0.92, 1.0, 1.0, 0.96, 0.93, 0.99, 1.03, 1.02, 0.98, 0.92, 1.08,
  1.03, 0.93, 1.03, 0.97, 1.07, 1.02, 1.06, 1.01, 0.99, 1.01, 1.03, 0.97, 1.04,
  1.06, 1.02, 1.06, 0.98, 0.89, 0.97, 0.96, 1.03, 1.02, 1.03, 1.0, 1.05, 0.98,
  1.02, 1.05, 0.97, 0.96, 1.02, 1.01, 1.02, 1.02, 1.08, 1.0, 1.02, 0.99, 0.95,
  1.03, 0.93, 0.95, 0.96, 1.0, 0.97, 1.04, 1.0, 0.98, 1.03, 0.97, 0.99, 0.98,
  1.04, 1.03, 1.03, 0.91, 1.01, 0.98, 1.06, 0.97, 1.02, 1.0, 1.05, 0.97, 1.02,
  0.91, 1.02, 1.02, 1.0, 1.03, 0.97, 1.03, 0.98, 0.99, 1.04, 1.0, 1.04, 0.95,
  0.96, 0.95, 0.91, 0.96, 1.04, 1.04, 0.99, 1.01, 0.94, 0.99, 0.95, 1.09, 1.04,
  1.03, 1.02, 1.05, 0.96, 1.02, 0.98, 0.97, 1.02, 0.99, 1.02, 1.02, 0.98, 0.98,
  0.98, 1.02, 1.0, 0.98, 0.99, 0.95, 0.99, 1.02, 0.93, 0.94, 1.06, 1.03, 1.0,
  1.01, 1.02, 1.06, 1.03, 1.03, 1.02, 1.0, 1.05, 1.01, 1.0, 1.02, 0.96, 1.01,
  1.08, 0.96, 0.99, 0.98, 0.97, 1.02, 1.04, 1.01, 1.02, 0.97, 1.06, 1.0, 1.09,
  0.97, 0.98, 1.05, 0.99, 1.03, 1.07, 0.99, 0.97, 1.02, 1.03, 1.01, 0.94, 1.07,
  1.04, 0.97, 0.97, 0.96, 1.02, 1.04, 1.02, 1.03, 1.02, 1.04, 1.0, 0.99, 1.02,
  0.94, 0.99, 1.05, 1.06, 1.01, 0.98, 0.99, 0.96, 0.94, 1.03, 1.02, 1.1, 1.0,
  1.0, 1.01, 1.07, 0.96, 1.0, 0.93, 0.96, 0.98, 1.0, 1.08, 0.97, 0.93, 0.96,
  1.04, 0.91, 0.99, 0.97, 0.99, 1.05, 1.0, 1.02, 0.99, 0.97, 1.01, 1.01, 1.01,
  0.96, 0.94, 1.02, 0.95, 0.99, 0.96, 1.05, 1.02, 1.01, 1.0, 0.98, 1.03, 1.03,
  1.04, 1.02, 0.96, 1.01, 1.08, 1.01, 0.98, 1.04, 1.0, 0.96, 0.94, 0.96, 1.03,
  1.04, 1.03, 0.96, 0.97, 0.98, 0.99, 1.04, 0.98, 0.97, 1.03, 0.98, 0.97, 1.02,
  0.99, 0.96, 0.97, 0.97, 1.0, 0.95, 0.96, 0.91, 1.04, 0.96, 1.04, 1.0, 1.02,
  0.93, 1.02, 0.98, 1.09, 0.98, 0.93, 0.95, 0.94, 0.98, 1.03, 1.0, 1.06, 1.03,
  0.97, 1.01, 1.0, 0.99, 1.06, 1.03, 0.94, 0.96, 0.94, 1.0, 1.0, 1.03, 0.96,
  1.01, 0.97, 1.05, 1.03, 0.99, 1.0, 0.96, 0.93, 1.09, 1.01, 1.01, 1.03, 0.99,
  1.0, 0.94, 1.07, 1.0, 1.1, 0.97, 0.95, 1.01, 0.98, 1.06, 0.98, 0.94, 0.97,
  0.98, 1.0, 0.96, 1.03, 0.97, 1.04, 1.05, 1.0, 1.03, 0.98, 0.99, 1.06, 1.0,
  1.02, 1.01, 1.01, 1.0, 1.0, 0.99, 0.98, 0.95, 1.08, 0.99, 0.99, 1.01, 1.05,
  0.98, 1.01, 1.0, 1.07, 1.06, 1.07, 1.0, 1.03, 1.06, 1.04, 0.97, 1.0, 0.94,
  1.06, 0.98, 1.07, 0.97, 1.02, 0.94, 1.04, 1.06, 0.99, 0.91, 0.97, 0.97, 0.99,
  0.97, 1.05, 1.01, 0.9, 1.03, 1.02, 0.97, 0.99, 0.99, 0.95, 1.05, 0.96, 0.99,
  1.07, 0.95, 1.02, 1.05, 0.98, 1.02, 0.97, 1.02, 0.96, 0.99, 0.99, 0.9, 1.0,
  1.0, 1.03, 1.07, 0.99, 0.97, 0.98, 0.98, 1.01, 0.89, 1.05, 1.05, 1.07, 0.91,
  0.97, 1.03, 1.04, 1.05, 1.02, 0.98, 0.89, 0.98, 1.04, 1.03, 0.98, 1.04, 1.0,
  0.96, 1.04, 0.99, 0.97, 0.99, 1.1, 1.03, 0.98, 0.98, 0.97, 0.99, 0.97, 0.99,
  0.97, 0.98, 0.91, 0.99, 1.02, 1.0, 1.05, 0.92, 1.04, 1.02, 1.07, 1.08, 1.01,
  0.97, 1.08, 1.06, 0.95, 1.0, 1.01, 1.06, 1.06, 1.03, 0.95, 1.04, 0.99, 0.98,
  1.01, 0.96, 0.97, 1.04, 1.02, 1.07, 1.01, 0.99, 1.05, 0.97, 0.98, 0.98, 0.95,
  0.99, 0.95, 1.05, 1.06, 1.04, 0.99, 1.04, 1.04, 1.04, 1.0, 0.99, 0.97, 1.04,
  1.02, 1.03, 0.98, 0.99, 1.01, 1.01, 0.99, 0.99, 1.01, 1.0, 1.02, 0.94, 0.99,
  0.99, 1.07, 0.98, 1.06, 0.93, 0.99, 0.94, 1.08, 0.98, 0.98, 1.02, 0.95, 1.03,
  1.04, 0.99, 0.96, 0.9, 1.01, 0.98, 0.9, 1.04, 1.02, 1.06, 1.05, 1.06, 0.99,
  1.01, 0.97, 0.99, 0.94, 1.08, 1.04, 1.02, 1.01, 0.98, 1.01, 0.97, 0.99, 0.97,
  1.01, 0.93, 0.99, 0.96, 1.03, 1.0, 1.01, 0.95, 1.0, 0.98, 0.97, 1.01, 1.05,
  1.01, 0.94, 0.96, 0.99, 1.06, 1.08, 1.04, 1.0, 0.97, 1.02, 0.94, 1.0, 1.07,
  1.03, 0.99, 1.0, 1.04, 1.03, 1.05, 0.97, 1.05, 1.05, 1.02, 0.97, 1.06, 0.98,
  1.03, 0.95, 1.09, 0.92, 1.0, 0.96, 0.98, 1.01, 0.99, 0.93, 0.98, 1.01, 0.99,
  0.99, 0.95, 1.05, 0.94, 0.97, 0.96, 0.96, 0.99, 1.02, 1.03, 0.98, 0.98, 0.99,
  1.0, 0.97, 0.99, 0.93, 1.02, 1.04, 0.91, 1.05, 1.0, 0.94, 1.0, 0.93, 0.99,
  0.96, 1.05, 1.06, 1.06, 0.98, 1.01, 1.03, 0.96, 0.94, 0.94, 0.98, 1.01, 1.05,
  0.95, 0.95, 1.02, 0.95, 0.96, 1.06, 0.96, 0.96, 0.98, 0.96, 0.95, 1.02, 1.09,
  0.97, 0.97, 1.03, 0.97, 1.0, 1.04, 1.03, 0.95, 0.99, 0.97, 1.03, 1.07, 1.01,
  1.04, 0.94, 1.02, 0.99, 1.03, 0.95, 1.0, 0.93, 1.0, 1.01, 1.03, 0.97, 0.98,
  0.99, 0.94, 1.07, 0.96, 1.02, 0.96, 1.0, 1.04, 0.99, 1.05, 1.0, 1.01, 1.06,
  1.08, 1.04, 0.99, 0.99, 1.05, 1.05, 1.07, 0.98, 1.06, 0.95, 1.05, 0.99, 0.98,
  1.02, 0.97, 0.98, 1.0, 1.04, 1.0, 0.95, 1.04, 1.02, 1.04, 1.08, 0.98, 1.02,
  1.09, 0.98, 0.99, 0.96, 1.06, 0.96, 0.92, 1.01, 1.02, 0.94, 1.08, 0.93, 0.96,
  1.02, 1.03, 0.96, 1.06, 1.05, 0.96, 0.97, 1.02, 1.06, 0.99, 0.94, 1.08, 0.94,
  0.97, 1.03, 0.98, 1.04, 1.07, 0.95, 1.01, 0.95, 0.98, 1.05, 1.01, 1.01, 1.05,
  0.99, 1.01, 0.96, 1.02, 1.06, 1.03, 0.99, 0.99, 1.07, 1.02, 1.07, 0.95, 0.99,
  1.05, 1.02, 0.93, 0.97, 1.01, 0.99, 1.0, 0.99, 0.99, 1.03, 1.07, 1.02, 0.94,
  1.0, 1.0, 1.03, 0.96, 0.93, 1.05, 0.98, 0.96, 0.93, 1.0, 1.0, 0.98, 0.97,
  0.93, 0.97, 0.94, 0.95, 0.94, 0.96, 1.01, 1.02, 1.04, 1.03, 1.07, 1.04, 0.98,
  0.95, 0.95, 0.98, 0.99, 1.02, 1.0, 1.05, 1.01, 1.02, 1.03, 1.03, 1.02, 0.96,
  1.02, 1.04, 1.06, 1.0, 0.95, 0.92, 0.93, 1.02, 1.05, 1.08, 0.97, 1.0, 0.98,
  0.97, 1.03, 1.02, 0.95, 1.03, 0.99, 1.04, 0.95, 0.98, 1.08, 1.03, 1.0, 1.03,
  0.9, 1.02, 0.96, 0.99, 1.02, 0.92, 0.98, 1.01, 0.97, 0.98, 1.07, 0.99, 0.96,
  0.91, 0.99, 0.95, 1.04, 0.98, 0.99, 1.01, 1.0, 1.01, 0.89, 1.03, 1.02, 1.03,
  0.89, 0.97, 0.98, 1.0, 1.01, 1.0, 0.98, 0.98, 1.03, 1.0, 0.9, 0.97, 0.95,
  0.89, 1.03, 1.01, 0.97, 1.0, 1.04, 1.08, 0.96, 1.03, 0.98, 1.03, 0.98, 0.96,
  1.02, 1.02, 0.94, 1.07, 0.94, 0.96, 0.98, 0.94, 0.97, 0.99, 0.96, 0.98, 0.99,
  1.04, 1.02, 0.97, 1.05, 1.01, 1.04, 0.99, 0.98, 1.0, 0.9, 1.0, 1.09, 0.96,
  1.0, 0.97, 0.99, 0.97, 1.03, 0.96, 1.05, 1.09, 1.02, 1.01, 1.02, 0.98, 1.01,
  1.05, 1.07, 1.03, 1.02, 1.07, 0.98, 0.99, 0.98, 1.0, 1.02, 1.05, 0.99, 1.04,
  1.06, 0.98, 0.95, 1.01, 1.08, 0.99, 1.01, 0.95, 0.94, 1.03, 0.97, 0.95, 1.03,
  1.04, 0.92, 1.04, 1.08, 1.03, 0.99, 0.96, 0.96, 0.96, 1.02, 1.05, 0.96, 1.03,
  0.93, 0.99, 1.0, 0.93, 0.97, 1.01, 1.07, 1.03, 1.01, 1.01, 1.04, 1.02, 1.1,
  1.02, 1.0, 1.01, 1.0, 0.97, 1.03, 1.0, 1.05, 1.01, 1.01, 0.98, 1.02, 0.95,
  0.98, 1.01, 1.02, 1.02, 0.99, 1.04, 1.08, 1.03, 1.0, 0.97, 1.0, 0.99, 1.1,
  0.98, 1.03, 0.91, 1.04, 1.0, 1.01, 1.01, 1.09, 0.99, 1.0, 1.01, 1.02, 0.98,
  0.94, 0.95, 0.99, 1.03, 0.97, 1.02, 0.95, 1.08, 1.04, 1.07, 1.03, 0.99, 0.97,
  0.98, 1.0, 0.96, 1.05, 0.99, 0.99, 1.02, 1.02, 1.02, 1.04, 1.02, 1.05, 1.01,
  0.98, 1.02, 0.95, 0.94, 1.06, 1.05, 0.96, 1.01, 1.0, 1.04, 0.98, 0.93, 0.97,
  0.98, 1.01, 0.97, 0.98, 0.95, 1.0, 0.99, 1.06, 0.98, 1.04, 0.94, 0.93, 1.02,
  0.96, 0.93, 1.0, 0.99, 1.01, 1.0, 0.97, 0.97, 0.95, 1.03, 1.05, 1.0, 1.02,
  1.01, 1.02, 1.04, 0.95, 0.96, 0.94, 1.01, 1.07, 0.98, 1.0, 1.07, 0.99, 1.06,
  1.04, 1.02, 1.09, 1.04, 0.93, 1.01, 1.05, 0.97, 1.02, 0.97, 0.97, 1.01, 1.01,
  0.97, 1.0, 1.0, 0.99, 1.0, 0.99, 1.02, 0.99, 1.04, 1.0, 0.98, 0.93, 0.95,
  0.98, 1.04, 0.97, 1.02, 1.0, 1.02, 1.03, 1.07, 0.98, 1.05, 1.08, 1.01, 0.98,
  0.99, 1.09, 0.97, 0.98, 1.02, 0.93, 1.02, 1.04, 1.0, 0.98, 0.98, 1.0, 1.04,
  1.02, 0.98, 1.02, 1.04, 0.94, 1.09, 1.06, 1.03, 0.92, 0.95, 1.05, 0.96, 0.97,
  1.04, 0.99, 1.01, 1.04, 1.0, 0.99, 0.99, 1.0, 0.95, 1.0, 0.99, 0.99, 1.05,
  1.05, 1.04, 0.99, 0.99, 1.02, 1.08, 1.04, 0.98, 1.06, 0.99, 1.03, 1.05, 1.02,
  0.99, 1.02, 0.98, 1.0, 1.08, 0.98, 1.01, 0.95, 1.03, 0.99, 0.98, 1.01, 0.98,
  0.99, 0.97, 0.99, 1.01, 0.96, 1.01, 1.04, 1.0, 1.08, 1.03, 0.97, 1.01, 1.03,
  0.98, 1.02, 0.98, 0.94, 0.95, 1.02, 0.97, 1.04, 0.97, 1.01, 1.02, 1.01, 1.04,
  0.99, 1.02, 0.99, 1.02, 1.05, 1.0, 0.92, 1.05, 1.03, 1.0, 1.02, 1.0, 1.01,
  0.97, 1.02, 0.99, 1.01, 0.94, 1.02, 0.99, 1.0, 1.04, 0.97, 1.04, 0.94, 1.03,
  1.04, 0.96, 1.09, 1.04, 1.03, 1.01, 1.06, 0.94, 1.03, 0.99, 1.08, 1.05, 0.88,
  1.08, 1.0, 1.0, 0.98, 1.07, 1.0, 1.03, 0.96, 1.03, 1.03, 1.06, 1.05, 0.97,
  1.02, 1.09, 0.94, 0.96, 0.97, 1.04, 1.01, 0.97, 0.96, 1.0, 1.05, 1.0, 1.04,
  1.04, 0.97, 1.03, 1.04, 1.0, 1.0, 0.94, 1.03, 0.99, 1.03, 0.97, 0.98, 0.98,
  0.99, 1.04, 0.9, 0.98, 0.99, 1.0, 0.91, 0.97, 0.97, 1.05, 0.93, 0.97, 1.02,
  1.02, 0.95, 0.94, 0.94, 1.02, 1.0, 1.05, 0.98, 1.0, 1.06, 1.04, 0.96, 1.02,
  0.97, 1.05, 0.98, 0.95, 1.01, 1.02, 0.94, 0.94, 0.99, 0.95, 0.99, 1.03, 0.97,
  0.93, 1.04, 0.99, 0.98, 0.98, 0.91, 1.01, 1.0, 0.95, 1.02, 1.04, 0.96, 1.03,
  1.02, 1.01, 0.98, 1.03, 0.96, 1.04, 0.98, 1.02, 1.04, 1.02, 0.96, 1.06, 0.97,
  1.0, 0.96, 1.03, 1.0, 0.95, 0.99, 0.99, 1.07, 1.0, 1.01, 1.05, 1.03, 1.01,
  0.98, 1.03, 0.97, 0.99, 0.99, 0.97, 1.0, 1.06, 1.0, 0.99, 0.99, 1.0, 1.01,
  0.98, 1.04, 0.97, 1.03, 0.97, 0.95, 0.96, 1.02, 1.05, 0.97, 0.99, 1.03, 0.97,
  0.98, 1.01, 1.0, 0.95, 1.03, 1.03, 1.05, 1.0, 0.92, 1.02, 1.0, 0.93, 0.95,
  0.87, 1.04, 0.98, 0.97, 1.01, 0.94, 0.99, 1.06, 1.04, 1.02, 1.08, 1.02, 1.04,
  1.03, 0.96, 0.91, 0.95, 1.04, 1.03, 1.0, 1.0, 0.97, 1.09, 0.96, 0.98, 1.03,
  0.92, 1.03, 0.99, 0.97, 0.97, 1.07, 0.99, 1.05, 0.99, 0.92, 0.97, 1.0, 1.03,
  0.99, 0.93, 0.95, 1.07, 0.93, 1.03, 1.03, 1.07, 0.87, 0.96, 1.02, 1.02, 1.04,
  1.03, 0.98, 1.0, 1.03, 1.02, 1.09, 0.96, 1.07, 1.03, 0.97, 1.01, 0.97, 1.04,
  1.02, 1.01, 0.97, 1.04, 0.93, 0.96, 0.99, 1.0, 0.98, 0.91, 1.02, 1.0, 0.99,
  1.04, 0.98, 0.92, 1.0, 0.93, 1.12, 0.97, 1.0, 1.02, 0.98, 1.01, 1.0, 0.96,
  0.97, 1.07, 1.02, 1.02, 0.91, 1.05, 0.99, 1.08, 1.03, 1.02, 1.0, 0.97, 1.04,
  1.04, 1.02, 1.0, 1.04, 0.99, 1.04, 1.0, 1.02, 1.06, 0.98, 0.99, 1.03, 0.96,
  0.96, 0.99, 1.0, 0.99, 1.05, 0.99, 0.97, 0.93, 0.99, 1.05, 1.06, 1.07, 1.04,
  1.07, 0.89, 0.97, 0.99, 0.98, 1.07, 0.98, 0.97, 0.93, 0.91, 0.94, 0.96, 1.06,
  1.08, 1.07, 0.96, 1.01, 1.07, 1.0, 1.03, 0.99, 1.01, 0.93, 0.97, 0.98, 0.92,
  0.93, 1.01, 1.02, 1.01, 1.01, 0.98, 0.98, 0.97, 0.96, 1.0, 0.99, 1.0, 0.99,
  1.04, 1.07, 0.91, 1.04, 0.98, 1.0, 1.04, 0.92, 1.01, 1.0, 1.05, 0.99, 1.01,
  1.0, 0.93, 1.01, 1.04, 0.96, 1.05, 1.01, 1.02, 1.02, 1.03, 0.99, 1.05, 0.96,
  0.96, 1.01, 1.04, 1.02, 0.99, 0.98, 0.99, 0.95, 1.0, 1.03, 1.06, 0.99, 0.99,
  1.06, 0.95, 0.96, 0.88, 1.0, 0.98, 1.0, 1.0, 0.97, 1.03, 0.99, 0.92, 0.99,
  1.03, 0.93, 1.02, 0.94, 1.05, 0.98, 0.98, 1.03, 0.98, 1.06, 0.98, 1.03, 0.91,
  0.93, 0.95, 0.97, 0.97, 0.99, 1.01, 1.01, 0.94, 0.98, 0.97, 0.96, 0.97, 1.04,
  1.03, 1.06, 0.99, 1.04, 0.98, 1.02, 0.94, 0.96, 1.07, 1.09, 0.97, 1.0, 1.05,
  0.92, 1.02, 1.05, 1.02, 0.98, 1.01, 0.94, 0.94, 0.99, 0.99, 1.04, 0.98, 1.03,
  0.97, 1.0, 1.01, 1.0, 0.99, 1.03, 1.05, 1.01, 1.0, 1.02, 1.01, 1.0, 1.05,
  1.03, 1.01, 0.95, 1.0, 0.92, 1.01, 0.97, 0.95, 1.01, 1.03, 1.0, 0.99, 1.03,
  0.99, 1.01, 1.05, 1.03, 1.05, 1.02, 1.09, 0.97, 1.07, 0.99, 0.98, 0.97, 1.09,
  0.96, 0.95, 0.94, 0.97, 1.0, 0.94, 0.92, 0.98, 0.99, 0.99, 1.01, 0.96, 1.03,
  1.02, 1.0, 0.99, 1.06, 0.95, 0.97, 1.09, 1.05, 0.98, 0.99, 1.02, 1.0, 0.99,
  1.01, 0.98, 1.01, 0.98, 0.98, 1.01, 1.02, 1.01, 0.92, 1.0, 1.02, 1.02, 1.01,
  1.03, 0.96, 0.98, 1.03, 0.97, 1.04, 0.98, 1.01, 1.01, 0.91, 0.98, 0.95, 1.04,
  1.03, 1.05, 0.99, 0.94, 1.05, 1.04, 1.08, 1.03, 0.97, 1.03, 0.95, 0.99, 1.01,
  1.01, 0.95, 1.07, 1.04, 0.93, 0.94, 0.99, 0.96, 0.99, 0.91, 0.95, 1.03, 0.95,
  0.93, 1.02, 0.96, 1.03, 1.02, 1.03, 1.02, 1.04, 1.04, 0.96, 1.0, 0.94, 1.05,
  1.01, 1.08, 0.98, 0.99, 1.0, 1.03, 0.97, 1.02, 0.98, 1.03, 0.96, 1.01, 1.0,
  0.97, 1.0, 1.03, 1.01, 0.92, 0.99, 1.02, 0.99, 1.03, 1.0, 0.99, 0.95, 1.01,
  1.06, 0.99, 1.1, 1.02, 0.99, 1.0, 0.96, 1.01, 0.94, 0.94, 0.91, 0.96, 0.97,
  1.02, 0.92, 1.0, 0.95, 1.04, 1.08, 1.01, 0.98, 1.01, 1.0, 0.96, 1.03, 1.02,
  0.92, 0.97, 0.95, 1.03, 0.96, 1.0, 0.97, 1.07, 0.99, 0.97, 1.04, 0.98, 1.01,
  0.92, 0.98, 1.03, 1.01, 0.95, 0.99, 1.02, 1.02, 1.02, 1.05, 1.01, 1.06, 0.98,
  1.0, 1.04, 1.03, 1.02, 0.99, 1.01, 1.0, 1.08, 0.98, 0.88, 0.93, 1.0, 0.92,
  1.08, 1.03, 0.93, 1.04, 1.02, 0.99, 1.01, 0.98, 1.01, 0.98, 0.97, 1.02, 1.01,
  0.91, 0.92, 1.05, 0.92, 1.05, 1.05, 0.99, 1.01, 1.08, 0.99, 1.03, 0.96, 1.01,
  0.97, 1.06, 0.99, 1.0, 1.05, 0.99, 1.0, 0.98, 1.0, 0.98, 0.97, 0.98, 1.01,
  1.06, 0.97, 1.06, 0.99, 0.97, 0.89, 0.98, 1.09, 1.01, 1.01, 1.01, 1.04, 1.0,
  0.93, 0.99, 1.0, 1.0, 0.98, 1.0, 0.98, 1.08, 0.99, 0.98, 0.96, 1.0, 1.02,
  0.94, 0.98, 1.07, 1.01, 0.95, 1.01, 1.03, 0.92, 1.0, 0.99, 1.03, 0.99, 0.99,
  1.06, 0.97, 0.95, 1.0, 1.03, 1.04, 1.02, 0.96, 1.03, 1.04, 1.04, 0.92, 0.95,
  1.09, 0.99, 1.09, 0.98, 0.94, 1.03, 0.99, 0.95, 0.99, 0.97, 1.0, 0.95, 1.02,
  0.98, 1.06, 0.95, 1.03, 0.94, 1.07, 1.04, 1.03, 1.04, 1.02, 0.97, 1.06, 1.02,
  1.04, 0.94, 1.02, 1.04, 1.02, 0.99, 1.05, 1.03, 0.96, 1.03, 1.02, 0.97, 1.04,
  0.99, 1.01, 1.0, 0.95, 1.05, 0.94, 0.92, 1.04, 0.96, 0.97, 1.04, 0.99, 1.03,
  1.04, 1.04, 0.93, 1.0, 0.91, 0.96, 0.93, 0.97, 1.0, 1.03, 1.03, 1.0, 1.03,
  1.03, 0.99, 0.98, 1.01, 1.08, 1.03, 0.95, 0.95, 1.0, 0.98, 1.0, 0.95, 1.0,
  0.94, 1.01, 1.06, 0.96, 1.03, 1.06, 0.96, 0.99, 0.97, 0.98, 1.01, 1.02, 1.0,
  1.06, 0.92, 1.05, 1.04, 1.06, 1.01, 1.05, 0.96, 0.98, 1.06, 1.0, 0.93, 0.96,
  1.02, 1.07, 1.03, 1.0, 1.0, 1.0, 1.02, 0.92, 1.05, 1.01, 1.02, 1.0, 1.01,
  1.03, 0.95, 0.93, 1.05, 1.01, 1.06, 0.99, 1.03, 0.94, 0.99, 0.98, 0.94, 0.94,
  0.98, 0.94, 1.05, 1.04, 0.96, 0.98, 0.98, 0.93, 1.02, 0.94, 1.0, 0.96, 0.99,
  0.99, 0.94, 0.99, 1.08, 0.96, 0.98, 1.03, 1.04, 0.95, 1.02, 1.06, 0.96, 1.02,
  0.98, 1.02, 1.05, 0.99, 1.02, 1.03, 0.94, 1.06, 0.98, 1.0, 1.03, 1.05, 1.08,
  0.96, 1.03, 1.01, 0.97, 0.96, 0.96, 1.0, 1.04, 1.04, 0.98, 0.97, 1.01, 1.0,
  0.96, 1.0, 0.97, 0.97, 1.04, 1.05, 1.04, 1.02, 0.99, 0.99, 0.94, 0.98, 0.99,
  0.95, 1.01, 0.99, 1.01, 1.02, 0.95, 0.97, 0.93, 1.01, 0.94, 1.02, 1.05, 0.98,
  0.96, 0.99, 1.11, 0.97, 0.94, 0.98, 1.01, 0.96, 0.94, 1.03, 1.1, 1.02, 1.0,
  1.04, 0.98, 1.02, 1.05, 1.02, 0.94, 1.05, 1.0, 1.05, 0.92, 0.96, 1.01, 1.0,
  0.93, 1.02, 0.97, 0.97, 0.92, 1.03, 0.96, 1.02, 1.0, 0.96, 1.04, 0.99, 0.97,
  0.94, 0.97, 0.95, 1.01, 1.04, 0.99, 0.98, 0.95, 1.04, 0.95, 0.97, 0.97, 1.03,
  0.96, 0.99, 0.99, 1.03, 1.05, 0.95, 0.92, 0.94, 0.98, 0.98, 1.02, 1.02, 1.02,
  1.0, 1.01, 0.97, 1.02, 0.97, 0.94, 0.95, 0.98, 1.01, 1.04, 1.08, 0.97, 1.07,
  0.97, 0.98, 0.9, 1.01, 1.06, 1.0, 0.99, 0.96, 1.03, 1.01, 1.02, 1.02, 0.99,
  1.05, 0.96, 0.98, 1.06, 0.97, 0.96, 1.01, 1.05, 1.04, 1.03, 1.0, 0.97, 1.01,
  0.91, 1.0, 1.03, 1.03, 1.07, 1.02, 1.05, 1.01, 1.02, 1.08, 1.04, 0.94, 1.01,
  0.99, 1.03, 1.04, 1.04, 0.96, 0.99, 0.94, 0.95, 0.99, 1.02, 1.07, 1.03, 1.0,
  0.96, 0.99, 0.99, 1.01, 1.0, 1.03, 0.96, 1.06, 0.98, 1.01, 0.95, 0.98, 0.97,
  1.09, 0.97, 1.01, 1.0, 0.91, 1.02, 1.02, 1.02, 0.99, 1.03, 1.02, 1.01, 1.0,
  0.98, 1.04, 0.99, 0.98, 1.0, 1.0, 1.0, 1.01, 1.02, 1.04, 1.02, 1.02, 0.95,
  1.03, 1.06, 0.97, 1.01, 0.99, 1.0, 0.99, 1.04, 1.01, 1.06, 0.97, 0.95, 0.96,
  1.04, 1.04, 0.97, 0.97, 1.04, 0.97, 1.01, 1.04, 1.01, 0.97, 0.99, 1.0, 1.01,
  0.96, 1.02, 0.98, 1.05, 1.0, 1.01, 0.97, 0.99, 1.02, 0.97, 1.03, 0.98, 0.98,
  1.01, 0.94, 1.08, 1.01, 1.04, 1.05, 0.9, 1.05, 1.02, 1.0, 1.0, 1.0, 0.98,
  1.01, 1.02, 0.99, 1.06, 1.04, 0.98, 0.93, 0.95, 1.06, 0.99, 0.99, 1.06, 1.04,
  0.97, 1.03, 1.01, 0.96, 0.99, 0.98, 1.02, 0.96, 0.96, 0.99, 0.97, 1.01, 0.97,
  0.97, 0.96, 1.0, 0.98, 0.98, 0.99, 1.08, 0.98, 1.0, 0.96, 1.01, 0.98, 0.92,
  1.02, 0.97, 0.96, 1.0, 1.02, 1.05, 0.93, 1.0, 1.0, 0.97, 1.07, 1.05, 1.01,
  0.99, 1.03, 0.95, 0.98, 1.08, 0.98, 1.04, 1.0, 0.94, 0.98, 1.04, 1.0, 1.0,
  1.0, 0.94, 1.06, 1.0, 0.95, 1.0, 1.01, 1.0, 1.0, 1.08, 1.08, 1.03, 0.98, 1.0,
  1.05, 1.01, 0.96, 0.95, 1.06, 1.04, 0.98, 1.04, 1.05, 0.97, 0.93, 0.99, 1.03,
  1.04, 1.06, 0.94, 1.07, 0.93, 1.04, 0.97, 1.04, 0.98, 1.08, 1.03, 1.02, 1.02,
  1.03, 0.99, 0.99, 1.02, 0.96, 0.94, 0.99, 1.02, 0.95, 0.89, 1.06, 1.05, 1.0,
  1.01, 1.01, 1.04, 1.03, 0.97, 1.02, 1.01, 1.01, 0.95, 1.03, 1.03, 1.01, 1.05,
  1.02, 1.0, 1.0, 0.98, 0.96, 0.99, 1.01, 1.01, 0.95, 0.93, 0.93, 1.01, 0.93,
  1.0, 0.98, 0.97, 1.04, 1.01, 0.98, 0.97, 1.03, 1.04, 0.95, 1.03, 1.0, 0.96,
  0.97, 1.01, 1.01, 1.01, 1.04, 1.01, 0.98, 1.04, 1.02, 0.99, 0.98, 1.0, 1.04,
  0.97, 1.0, 1.01, 0.95, 1.02, 1.04, 1.02, 0.98, 1.07, 0.97, 0.92, 1.03, 0.99,
  1.03, 0.97, 0.97, 1.03, 1.01, 1.0, 0.94, 1.0, 0.95, 1.07, 1.03, 0.96, 0.98,
  0.97, 1.05, 0.99, 1.07, 1.0, 1.03, 0.92, 0.96, 1.04, 1.0, 0.97, 0.98, 1.03,
  1.02, 1.0, 1.01, 1.0, 1.0, 1.01, 1.06, 0.97, 1.02, 1.02, 1.01, 1.0, 0.98,
  0.97, 0.97, 0.96, 0.96, 1.04, 1.03, 1.0, 0.95, 0.91, 1.06, 1.01, 1.02, 1.0,
  0.97, 0.96, 1.08, 0.97, 0.99, 1.04, 1.01, 0.94, 0.96, 1.02, 1.02, 1.04, 1.01,
  0.98, 0.97, 0.99, 1.03, 1.08, 1.05, 1.01, 1.01, 1.0, 0.95, 0.98, 0.98, 0.98,
  0.96, 0.95, 1.0, 0.99, 1.0, 1.02, 1.1, 0.97, 0.99, 1.01, 1.02, 1.0, 0.9, 1.02,
  1.01, 0.99, 1.05, 0.99, 0.99, 1.01, 1.0, 1.06, 1.05, 1.03, 1.03, 1.07, 0.99,
  1.01, 1.0, 1.1, 0.95, 0.99, 1.03, 1.02, 1.01, 0.99, 1.0, 1.04, 1.03, 0.99,
  1.01, 0.98, 1.03, 0.96, 0.98, 0.94, 0.97, 1.05, 1.0, 1.04, 0.99, 1.04, 1.02,
  1.01, 1.09, 0.98, 0.93, 0.98, 0.95, 0.97, 0.95, 0.96, 0.96, 1.06, 1.05, 0.94,
  0.98, 0.93, 1.0, 0.97, 1.05, 1.04, 0.94, 1.02, 1.01, 1.01, 1.01, 1.06, 0.9,
  1.03, 0.96, 1.11, 0.99, 1.06, 0.98, 1.01, 1.01, 1.02, 0.92, 1.0, 0.94, 1.04,
  0.99, 0.95, 1.03, 1.03, 1.01, 1.03, 0.97, 0.96, 1.05, 0.98, 0.96, 0.91, 1.03,
  1.08, 0.98, 0.96, 1.01, 0.98, 1.01, 1.05, 0.99, 0.96, 1.11, 1.02, 1.08, 1.03,
  0.99, 0.94, 1.07, 1.0, 1.01, 1.0, 1.03, 1.02, 1.05, 1.06, 0.94, 0.97, 1.01,
  0.96, 0.99, 1.05, 0.95, 0.97, 0.97, 1.02, 1.02, 1.02, 0.98, 0.97, 0.98, 1.02,
  0.98, 1.02, 0.93, 1.01, 0.94, 1.05, 0.97, 0.97, 1.01, 0.98, 1.04, 1.04, 1.0,
  1.04, 1.0, 0.99, 1.0, 1.03, 0.97, 0.93, 0.95, 0.96, 1.02, 0.93, 1.03, 0.98,
  0.99, 0.98, 1.0, 1.05, 1.01, 0.94, 1.03, 1.01, 1.03, 1.01, 1.02, 1.0, 0.97,
  0.96, 0.97, 1.02, 0.93, 1.05, 0.98, 0.9, 1.01, 0.97, 1.08, 1.04, 1.01, 0.99,
  1.02, 1.05, 0.97, 1.06, 1.04, 1.0, 0.96, 1.04, 0.93, 1.01, 1.02, 1.01, 1.0,
  1.01, 0.99, 1.07, 0.95, 1.04, 0.96, 1.0, 1.05, 1.0, 1.0, 1.03, 0.98, 1.07,
  0.97, 1.03, 1.02, 0.93, 0.96, 0.99, 0.95, 0.94, 0.99, 0.9, 1.01, 0.97, 0.94,
  1.02, 0.98, 0.93, 1.05, 0.95, 0.99, 1.06, 1.06, 1.04, 1.02, 1.03, 1.07, 0.97,
  0.99, 1.06, 0.99, 0.94, 1.02, 0.96, 0.95, 1.0, 0.96, 1.03, 0.99, 1.03, 0.97,
  1.01, 1.05, 0.97, 1.0, 0.96, 0.99, 1.01, 1.01, 1.0, 1.03, 0.93, 1.03, 1.05,
  0.97, 1.02, 0.99, 1.02, 0.99, 0.98, 0.97, 1.0, 1.01, 1.06, 1.01, 1.0, 0.95,
  1.02, 1.03, 0.98, 0.94, 1.03, 0.99, 0.96, 1.03, 1.09, 0.97, 0.95, 1.07, 1.0,
  1.01, 1.01, 0.99, 1.1, 1.06, 0.91, 1.02, 0.98, 1.03, 0.97, 1.02, 0.95, 0.98,
  1.04, 1.01, 1.05, 0.92, 1.06, 1.0, 1.0, 1.04, 0.94, 1.03, 0.98, 0.94, 1.1,
  1.03, 1.0, 0.99, 1.04, 1.06, 1.01, 1.02, 0.9, 0.98, 0.98, 0.95, 0.96, 1.1,
  1.08, 0.99, 0.96, 1.01, 1.05, 0.99, 1.04, 0.97, 0.94, 0.98, 0.96, 0.97, 0.98,
  1.03, 1.01, 1.0, 0.96, 0.95, 0.97, 0.99, 1.02, 0.99, 0.98, 0.99, 0.94, 0.96,
  1.0, 1.01, 1.03, 1.05, 1.01, 0.96, 0.97, 1.03, 1.02, 0.94, 1.06, 0.98, 1.0,
  1.01, 0.95, 1.06, 0.97, 0.98, 1.02, 1.03, 0.99, 1.01, 0.98, 1.02, 0.98, 0.96,
  0.94, 1.03, 1.0, 1.01, 1.01, 1.02, 1.02, 1.09, 1.02, 1.01, 1.1, 0.98, 0.94,
  1.03, 0.99, 0.95, 1.06, 1.04, 0.98, 0.99, 0.96, 1.04, 0.88, 0.99, 0.94, 0.99,
  0.98, 1.01, 0.9, 1.0, 0.99, 1.02, 1.04, 0.94, 1.03, 1.06, 1.03, 0.98, 0.98,
  1.02, 0.97, 0.98, 1.04, 0.97, 1.02, 1.0, 1.04, 0.94, 1.03, 0.98, 0.96, 0.97,
  1.02, 1.0, 0.98, 1.0, 1.01, 0.97, 1.05, 1.0, 0.97, 1.07, 0.92, 0.97, 1.01,
  0.99, 1.02, 1.08, 0.98, 1.0, 1.01, 1.01, 1.03, 1.0, 0.99, 0.95, 1.0, 1.06,
  1.0, 1.06, 0.97, 1.03, 1.0, 0.94, 1.05, 0.97, 0.98, 0.98, 1.01, 1.05, 0.93,
  1.07, 0.96, 0.93, 1.02, 1.01, 1.02, 1.04, 0.98, 1.0, 0.94, 1.01, 1.02, 1.02,
  0.99, 0.98, 1.03, 0.99, 0.89, 1.02, 1.0, 1.0, 0.92, 0.92, 1.01, 1.08, 0.96,
  0.98, 1.01, 1.09, 1.01, 0.93, 1.05, 1.05, 1.1, 1.05, 0.96, 1.09, 0.98, 0.94,
  1.04, 1.0, 1.01, 0.99, 0.98, 0.97, 1.0, 0.94, 1.04, 1.05, 0.98, 1.01, 1.02,
  1.04, 0.94, 0.98, 0.95, 0.97, 0.99, 1.03, 0.94, 0.99, 1.07, 1.08, 1.0, 0.95,
  1.06, 0.94, 1.03, 1.0, 1.0, 0.91, 0.96, 0.98, 0.91, 1.05, 1.01, 0.98, 1.01,
  0.99, 1.05, 0.96, 0.98, 1.06, 0.97, 0.97, 1.0, 0.98, 1.02, 0.96, 0.97, 1.04,
  1.01, 1.0, 0.96, 1.02, 0.89, 0.96, 0.92, 1.03, 1.0, 1.04, 1.05, 0.99, 0.99,
  0.97, 1.03, 1.09, 1.07, 1.0, 0.98, 0.92, 1.0, 0.94, 1.04, 0.96, 1.05, 1.01,
  1.07, 0.97, 1.03, 1.05, 1.08, 1.03, 1.02, 0.97, 1.01, 0.93, 0.97, 1.08, 0.94,
  1.02, 1.06, 1.03, 0.97, 1.02, 0.97, 0.96, 1.0, 1.08, 0.98, 0.93, 0.98, 1.02,
  0.99, 0.98, 1.04, 0.96, 0.98, 1.0, 1.06, 0.98, 1.06, 0.95, 1.02, 0.91, 0.96,
  1.02, 0.93, 1.02, 1.02, 0.99, 0.99, 1.02, 0.99, 0.99, 1.01, 1.01, 0.99, 1.06,
  1.04, 0.96, 1.01, 1.0, 0.99, 1.03, 1.0, 1.04, 0.98, 1.05, 1.04, 1.0, 1.04,
  0.99, 1.0, 1.05, 1.02, 0.94, 1.07, 0.92, 1.0, 1.04, 1.08, 0.98, 1.08, 0.98,
  1.04, 1.0, 0.98, 0.97, 0.97, 0.96, 1.06, 0.96, 0.98, 1.02, 1.05, 0.94, 0.94,
  1.03, 0.98, 1.01, 1.01, 0.99, 1.06, 1.03, 1.03, 1.03, 0.95, 1.06, 0.99, 0.98,
  0.99, 1.08, 1.07, 0.95, 0.97, 0.97, 0.97, 0.99, 0.9, 0.98, 1.02, 0.96, 0.99,
  1.07, 1.03, 1.03, 0.97, 1.03, 1.03, 1.03, 0.99, 1.02, 0.9, 1.04, 0.98, 0.95,
  0.97, 1.03, 0.92, 0.92, 1.02, 1.05, 1.0, 1.02, 1.0, 1.07, 1.01, 0.92, 1.04,
  0.96, 1.01, 1.01, 1.04, 1.02, 1.06, 0.91, 1.05, 0.98, 0.96, 1.02, 0.97, 1.04,
  0.98, 1.0, 1.04, 1.02, 1.02, 1.02, 1.04, 1.01, 1.02, 0.95, 1.01, 1.08, 1.07,
  0.98, 1.0, 1.0, 1.02, 0.99, 1.03, 1.06, 1.04, 1.05, 1.05, 0.99, 0.98, 1.01,
  1.06, 1.04, 1.02, 1.08, 0.98, 0.92, 1.06, 1.01, 1.0, 0.99, 1.0, 0.94, 0.94,
  0.99, 1.05, 0.96, 0.95, 0.96, 1.09, 1.01, 0.95, 1.0, 0.98, 1.02, 1.02, 1.0,
  1.0, 1.01, 0.92, 0.99, 1.0, 0.99, 0.98, 0.94, 1.04, 0.97, 0.94, 1.1, 1.01,
  1.08, 0.92, 0.96, 0.94, 1.07, 1.02, 0.99, 0.98, 1.05, 0.98, 0.97, 1.03, 1.02,
  0.99, 1.04, 0.96, 1.03, 1.05, 0.98, 1.05, 1.06, 0.96, 1.03, 1.01, 0.98, 0.97,
  0.95, 1.03, 0.98, 1.01, 0.93, 0.97, 0.97, 1.01, 0.96, 1.01, 1.04, 0.94, 0.98,
  1.07, 0.97, 1.03, 0.95, 0.98, 1.04, 0.95, 0.95, 0.95, 0.92, 1.05, 0.97, 0.93,
  1.01, 0.99, 1.01, 1.11, 1.02, 0.96, 1.0, 0.94, 0.94, 1.01, 1.01, 0.97, 0.97,
  0.99, 1.05, 0.98, 1.01, 1.06, 1.06, 1.03, 1.0, 0.93, 0.93, 0.99, 1.03, 0.98,
  0.95, 0.98, 1.0, 1.01, 0.97, 1.03, 1.0, 1.01, 1.05, 0.99, 1.04, 1.0, 1.02,
  0.96, 1.0, 1.0, 1.03, 1.01, 1.0, 0.98, 0.92, 0.97, 1.04, 1.02, 1.03, 1.01,
  0.99, 1.0, 1.08, 0.94, 0.94, 0.88, 1.04, 1.02, 1.03, 0.97, 0.99, 1.04, 1.03,
  1.06, 0.95, 0.92, 1.01, 1.02, 0.98, 1.02, 1.05, 0.97, 0.99, 0.96, 0.96, 1.03,
  1.0, 1.03, 0.94, 1.0, 1.01, 0.99, 0.98, 0.95, 0.95, 0.97, 1.09, 0.95, 0.98,
  1.07, 1.02, 1.03, 0.95, 1.01, 1.0, 0.94, 0.96, 1.0, 1.02, 0.99, 1.0, 1.04,
  1.02, 0.98, 1.0, 0.9, 1.03, 0.95, 0.99, 1.04, 1.07, 0.95, 0.88, 0.99, 0.93,
  1.03, 1.12, 0.97, 1.04, 0.88, 1.0, 1.03, 1.05, 1.0, 1.04, 1.0, 1.0, 0.98,
  0.97, 0.97, 0.95, 1.05, 0.98, 0.94, 1.02, 1.01, 1.01, 0.96, 1.03, 0.96, 1.02,
  1.06, 1.03, 1.0, 1.0, 0.97, 0.99, 0.95, 0.97, 1.06, 1.0, 1.1, 1.02, 1.06,
  1.01, 1.04, 1.03, 1.03, 1.02, 1.06, 1.08, 1.0, 1.01, 1.05, 1.05, 1.02, 0.99,
  0.99, 1.03, 1.01, 0.99, 0.99, 1.01, 1.06, 1.04, 1.0, 0.97, 1.02, 1.04, 1.02,
  1.01, 1.03, 0.96, 1.03, 0.99, 1.02, 0.97, 0.99, 0.94, 1.03, 1.01, 1.02, 1.04,
  1.0, 1.03, 1.02, 1.01, 1.03, 1.0, 0.96, 1.03, 1.06, 1.01, 0.88, 1.01, 0.96,
  1.02, 0.99, 1.01, 1.04, 1.04, 0.94, 0.88, 0.99, 0.96, 0.93, 1.0, 1.02, 0.99,
  1.09, 1.01, 0.93, 0.96, 1.07, 0.95, 1.0, 0.96, 1.06, 0.96, 0.95, 0.98, 1.04,
  0.98, 1.0, 0.99, 0.95, 0.99, 0.99, 0.98, 0.95, 0.99, 1.06, 0.98, 0.98, 1.03,
  1.05, 0.98, 1.01, 0.95, 1.01, 1.07
];

export default noiseRel;
