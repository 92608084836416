import React, { useState } from 'react';
import LogViewContent from './LogViewContent';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import { SearchType } from './useLogViewToolbar';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './LogViewModal.module.css';

type LogViewModalProps = {
  isOpen: boolean;
  onModalClose: () => void;
  nodeId: string;
  selectedSignalIds: string[];
};

const LogViewModal = ({
  isOpen,
  onModalClose,
  nodeId,
  selectedSignalIds
}: LogViewModalProps) => {
  const [search, setSearch] = useState<SearchType>({});

  return (
    <ActionModal
      wide
      messageBodyClassName={styles.logViewModalBody}
      title={T.location.tabs.logs}
      headerIcon={Icons.Logs}
      isOpen={isOpen}
      onModalClose={onModalClose}
      onConfirmClick={onModalClose}
    >
      <LogViewContent
        nodeId={nodeId}
        search={search}
        selectedSignalIds={selectedSignalIds}
        onSearchChanged={setSearch}
      />
    </ActionModal>
  );
};

export default LogViewModal;
