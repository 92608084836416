import React, { useEffect, useState } from 'react';
import T from 'ecto-common/lib/lang/Language';
import styles from './ToolbarDateTimePicker.module.css';

import moment, { Moment } from 'moment';

// Needed so that the DateTime component can use timezone correctly

import ToolbarMenuItem from 'ecto-common/lib/Toolbar/ToolbarMenuItem';
import ToolbarMenuDivider from 'ecto-common/lib/Toolbar/ToolbarMenuDivider';
import DateInput from 'ecto-common/lib/DateInput/DateInput';

interface ToolbarDateTimeRangePickerProps {
  onChangeFrom?(newDate: Moment): void;
  onChangeTo?(newDate: Moment): void;
  dateFrom?: number;
  dateTo?: number;
  expandingItemWidth?: boolean;
}

const ToolbarDateTimeRangePicker = ({
  onChangeFrom,
  onChangeTo,
  dateFrom,
  dateTo,
  expandingItemWidth = false
}: ToolbarDateTimeRangePickerProps) => {
  const [fromDate, setFromDate] = useState<Moment>(null);
  const [toDay, setToDay] = useState<Moment>(null);

  useEffect(() => {
    if (dateFrom !== -1 && dateTo !== -1) {
      setFromDate(moment.utc(dateFrom));
      setToDay(moment.utc(dateTo));
    }
  }, [dateFrom, dateTo]);

  return (
    <>
      <ToolbarMenuItem className={styles.toolbarMenuItemDateInputWrapper}>
        <DateInput
          value={fromDate}
          onChange={onChangeFrom}
          isValidDate={(d) => d.isBefore(toDay)}
          label={T.common.dateinput.from}
          className={styles.toolbarMenuItemLeftInput}
          expandingWidth={expandingItemWidth}
        />
      </ToolbarMenuItem>

      <ToolbarMenuDivider />

      <ToolbarMenuItem className={styles.toolbarMenuItemDateInputWrapper}>
        <DateInput
          value={toDay}
          onChange={onChangeTo}
          isValidDate={(d) => d.isAfter(fromDate)}
          label={T.common.dateinput.to}
          className={styles.toolbarMenuItemRightInput}
          expandingWidth={expandingItemWidth}
        />
      </ToolbarMenuItem>
    </>
  );
};

export default ToolbarDateTimeRangePicker;
