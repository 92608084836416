import { ResourceType } from 'ecto-common/lib/constants';
import { generatePath } from 'react-router';
import { Route } from 'ecto-common/lib/types/Route';

export const UserDashboardsSubPage = 'userdashboards';
export const LocationRoute: Route = {
  path: '/:tenantId/home/:nodeId/:page/:subPage?/:itemId?',
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const NoLocationRoute: Route = {
  path: '/:tenantId/noLocations',
  exact: true,
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const ExportGraphRoute: Route = {
  path: '/:tenantId/graphs/exports/:exportId',
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const ExportDataRoute: Route = {
  path: '/:tenantId/exportdata',
  resourceTypes: [ResourceType.CORE],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const HelpRoute: Route = { path: '/:tenantId/help' };
export const EctoplannerRoute: Route = {
  path: '/:tenantId/ectoplanner/:projectId?/:buildId?/:section?/:itemId?',
  resourceTypes: [ResourceType.ECTOPLANNER],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};
export const EctoplannerResultsRoute: Route = {
  path: '/:tenantId/ectoplannerResults/:projectId/:itemId',
  resourceTypes: [ResourceType.ECTOPLANNER],
  hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
};

export type ExportDataParams = {
  tenantId: string;
  exportId?: string;
};

export type EctoplannerParams = {
  tenantId: string;
  projectId?: string;
  buildId?: string;
  section?: string;
  itemId?: string;
};

export const ectoplannerMasterBuildId = 'current';

export const getEctoplannerUrl = (
  tenantId: string,
  projectId: string,
  buildId: string = ectoplannerMasterBuildId,
  section: string = undefined,
  itemId: string = undefined
) => {
  let suffix = '';
  if (projectId == null) {
    suffix = '/';
  }

  return (
    generatePath(EctoplannerRoute.path, {
      tenantId,
      buildId,
      projectId,
      section,
      itemId
    }) + suffix
  );
};

export const getEctoplannerResultsUrl = (
  tenantId: string,
  projectId: string,
  itemId: string
) => {
  return generatePath(EctoplannerResultsRoute.path, {
    tenantId,
    projectId,
    itemId
  });
};

export const getJobsUrl = (
  tenantId: string,
  nodeId: string,
  jobId?: string,
  runId?: string
) => {
  return generatePath(LocationRoute.path, {
    tenantId,
    nodeId,
    subPage: jobId,
    itemId: runId,
    page: 'jobs'
  });
};

export const getSignalsUrl = (
  tenantId: string,
  nodeId: string,
  providerId: string
) => {
  return generatePath(LocationRoute.path, {
    tenantId,
    nodeId,
    subPage: providerId,
    page: 'signalproviders'
  });
};

export const getAlarmUrl = (
  tenantId: string,
  nodeId: string,
  subPage: string
) => {
  return generatePath(LocationRoute.path, {
    tenantId,
    nodeId,
    subPage,
    page: 'alarms'
  });
};

export const getAlarmUrlV2 = (
  tenantId: string,
  nodeId: string,
  subPage: string
) => {
  return generatePath(LocationRoute.path, {
    tenantId,
    nodeId,
    subPage,
    page: 'newalarms'
  });
};

export const getUserDashboardsUrl = (
  tenantId: string,
  nodeId: string,
  userDashboardId: string
) => {
  return generatePath(LocationRoute.path, {
    tenantId,
    nodeId,
    subPage: userDashboardId,
    page: 'userdashboards'
  });
};
