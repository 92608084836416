import React from 'react';
import styles from './ComponentColumn.module.css';
import classNames from 'classnames';

interface ComponentColumnProps {
  /**
   *  The content to show in the column
   */
  children?: React.ReactNode;
  /**
   *   Whether or not to center children horizontally
   */
  center?: boolean;
}

/**
 * A ComponentColumn lays out its children in a vertical sequence with proper margins.
 */
const ComponentColumn = ({ children, center }: ComponentColumnProps) => {
  return (
    <div
      className={classNames(styles.componentColumn, center && styles.center)}
    >
      {children}
    </div>
  );
};

ComponentColumn.defaultProps = {
  center: true
};

export default ComponentColumn;
