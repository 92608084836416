import React from 'react';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import _ from 'lodash';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import { TelemetryValueResponseModel } from '../../API/APIGen';

const IMPACT_RANGE = Object.freeze({
  max: 0.5,
  min: -0.5
});

const convertToGraphValue = ({ time, value }: TelemetryValueResponseModel) => [
  new Date(time).getTime(),
  value
];

const impactRangeData = (data: TelemetryValueResponseModel[]) => {
  const maxValue = _.maxBy(data, 'value');
  const minValue = _.minBy(data, 'value');

  return {
    max: _.get(maxValue, 'value'),
    min: _.get(minValue, 'value')
  };
};

const createConfig = (
  data: TelemetryValueResponseModel[],
  loading: boolean
) => {
  // 10% of full range for margin for the graph
  const rangeMarginDiff = (IMPACT_RANGE.max - IMPACT_RANGE.min) * 0.01;
  const rangeMargin = {
    max: IMPACT_RANGE.max + rangeMarginDiff,
    min: IMPACT_RANGE.min - rangeMarginDiff
  };

  const lineWidth = 2;
  const gridLineWidth = 0.5;
  const outsideAreaColor = '#ea1c0a20';
  const rangeOptions = {
    color: '#ea1c0ac0',
    width: lineWidth,
    zIndex: 1
  };

  const noData = _.isEmpty(data) && !loading;

  const impactedRange = impactRangeData(data);
  const isInsideMaxRange = impactedRange.max > IMPACT_RANGE.max;
  const isInsideMinRange = impactedRange.min < IMPACT_RANGE.min;

  const config: Highcharts.Options = {
    scrollbar: { liveRedraw: false, enabled: false },
    navigator: { enabled: false },
    credits: { enabled: false },
    tooltip: { enabled: false },
    rangeSelector: { inputEnabled: false, enabled: false },
    plotOptions: { series: { enableMouseTracking: false } },
    time: {
      useUTC: false,
      timezone: DEFAULT_TIMEZONE
    },
    xAxis: {
      type: 'datetime',
      lineWidth: noData ? 0 : gridLineWidth,
      ordinal: false
    },
    loading: {
      hideDuration: 1000,
      showDuration: 1000
    },
    yAxis: {
      max: rangeMargin.max,
      min: rangeMargin.min,
      ceiling: rangeMargin.max,
      floor: rangeMargin.min,
      crosshair: false,
      gridLineWidth: noData ? 0 : gridLineWidth,
      minorGridLineWidth: noData ? 0 : gridLineWidth,
      plotBands: noData
        ? []
        : _.compact([
            isInsideMaxRange && {
              from: rangeMargin.max,
              to: 10,
              color: outsideAreaColor
            },
            isInsideMinRange && {
              from: -10,
              to: rangeMargin.min,
              color: outsideAreaColor
            }
          ]),
      labels: {
        enabled: false
      },
      plotLines: noData
        ? []
        : [
            { value: IMPACT_RANGE.min, ...rangeOptions },
            { value: IMPACT_RANGE.max, ...rangeOptions }
          ]
    },
    series: [
      {
        data: _.map(data, convertToGraphValue),
        type: 'line',
        lineWidth: lineWidth,
        color: '#1ea2b1',
        showInNavigator: false,
        showInLegend: false
      }
    ],
    chart: {
      margin: [5, 0, 20, 0],
      height: 150
    }
  };

  return config;
};

const isLoading = false;

const config: Highcharts.Options = createConfig(
  [
    {
      time: '2020-06-05T00:00:00Z',
      value: 0.3598587777777777
    },
    {
      time: '2020-06-05T01:00:00Z',
      value: 0.4638587777777777
    },
    {
      time: '2020-06-05T02:00:00Z',
      value: 0.5678587777777777
    },
    {
      time: '2020-06-05T03:00:00Z',
      value: 0.6718587777777777
    },
    {
      time: '2020-06-05T04:00:00Z',
      value: 0.7758587777777777
    },
    {
      time: '2020-06-05T05:00:00Z',
      value: 0.8538587777777776
    },
    {
      time: '2020-06-05T06:00:00Z',
      value: 0.9188587777777777
    },
    {
      time: '2020-06-05T07:00:00Z',
      value: 0.9838587777777776
    }
  ],
  !isLoading
);

const StockChartExample = () => (
  <StockChart isLoading={isLoading} config={config} />
);

StockChartExample.class = 'StockChart';

export default StockChartExample;
