import React, { useState, useMemo } from 'react';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import Flex, { FlexItem } from 'ecto-common/lib/Layout/Flex';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import colors from 'ecto-common/lib/styles/variables/colors';
import { EctoplannerDemandGraphs } from 'js/components/Ectoplanner/EctoplannerDemandGraphs';
import {
  EctoplannerDemandType,
  EctoplannerDemandTypes,
  EctoplannerEnergyType,
  EctoplannerEnergyTypes,
  PlotType,
  PlotTypes
} from 'js/components/Ectoplanner/EctoplannerTypes';
import T from 'ecto-common/lib/lang/Language';
import { HOURS_IN_YEAR } from './EctoplannerTypes';
import ArrayUtils from 'js/components/Ectoplanner/Calculation/utils/arrayUtils';
import styles from './EctoplannerEnergyGraphs.module.css';
import {
  EctoplannerForm,
  EctoplannerFormBuilding
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

let energyKindColors: Record<EctoplannerDemandType, string> = {
  heating: colors.heatingColor,
  cooling: colors.coolingColor,
  electricity: colors.electricityColor,
  heatingcooling: colors.heatingColor
};

let energyKindBackupColors: Record<EctoplannerDemandType, string> = {
  heating: colors.heatingSecondaryColor,
  cooling: colors.coolingSecondaryColor,
  electricity: colors.electricitySecondaryColor,
  heatingcooling: colors.coolingColor
};

let energyKindNames: Record<EctoplannerDemandType, string> = {
  heating: T.ectoplanner.form.building.params.sections.heating,
  cooling: T.ectoplanner.form.building.params.sections.cooling,
  electricity: T.ectoplanner.form.building.params.sections.electricity,
  heatingcooling: T.ectoplanner.form.building.params.sections.heatingcooling
};

const PlotTypeOptions: GenericSelectOption<PlotType>[] = [
  {
    value: PlotTypes.Monthly,
    label: T.ectoplanner.energygraphs.plotoptions.monthly
  },
  {
    value: PlotTypes.Annual,
    label: T.ectoplanner.energygraphs.plotoptions.annual
  },
  {
    value: PlotTypes.DurationCurve,
    label: T.ectoplanner.energygraphs.plotoptions.durationcurve
  }
];

const DemandOptions: GenericSelectOption<EctoplannerDemandType>[] = [
  { value: EctoplannerDemandTypes.Heating, label: energyKindNames.heating },
  { value: EctoplannerDemandTypes.Cooling, label: energyKindNames.cooling },
  {
    value: EctoplannerDemandTypes.Electricity,
    label: energyKindNames.electricity
  }
];

const DemandOptionsWithCombinedHeatingAndCooling: GenericSelectOption<EctoplannerDemandType>[] =
  [
    {
      value: EctoplannerDemandTypes.CombinedHeatingCooling,
      label: energyKindNames.heatingcooling
    },
    ...DemandOptions
  ];

const EnergyOptions: GenericSelectOption<EctoplannerEnergyType>[] = [
  {
    value: EctoplannerEnergyTypes.Demand,
    label: T.ectoplanner.energygraphs.energyoptions.demand
  },
  {
    value: EctoplannerEnergyTypes.Import,
    label: T.ectoplanner.energygraphs.energyoptions.import
  }
];

const EnergyOptionsWithCOP: GenericSelectOption<EctoplannerEnergyType>[] = [
  ...EnergyOptions,
  {
    value: EctoplannerEnergyTypes.COP,
    label: T.ectoplanner.energygraphs.energyoptions.cop
  }
];

function replaceNull(arr: number[]): number[] {
  if (arr == null) {
    return [];
  }

  return arr.map((x) => (x == null ? 0 : x));
}

const safeArraySum = (arr: number[], arr2: number[]): number[] => {
  if (arr == null || arr.length === 0) {
    return arr2;
  }

  if (arr2 == null || arr2.length === 0) {
    return arr;
  }

  return ArrayUtils.arraySum(arr, arr2);
};

const emptyArray: number[] = [];

export const EctoplannerBuildingEnergyGraphs = ({
  building
}: {
  building: EctoplannerFormBuilding;
}) => {
  const [currentDemandType, setCurrentDemandType] =
    useState<EctoplannerDemandType>(EctoplannerDemandTypes.Heating);
  const [currentEnergyType, setCurrentEnergyType] =
    useState<EctoplannerEnergyType>(EctoplannerEnergyTypes.Demand);
  const [currentPlotType, setCurrentPlotType] = useState<PlotType>(
    PlotTypes.Monthly
  );

  const backupTechHeatingConstant =
    building.params.calculations.bes.hpShLowExCap;

  const backupTechCoolingConstant =
    building.params.lowExCoolingOption === 'lowExDrc'
      ? building.params.calculations.bes.drcLowExCap
      : building.params.calculations.bes.ccLowExCap;

  const [backupSeriesHeating, backupSeriesCooling] = useMemo(() => {
    if (
      backupTechHeatingConstant == null ||
      backupTechCoolingConstant == null
    ) {
      return [null, null];
    }

    return [
      new Array<number>(HOURS_IN_YEAR).fill(backupTechHeatingConstant),
      new Array<number>(HOURS_IN_YEAR).fill(backupTechCoolingConstant)
    ];
  }, [backupTechHeatingConstant, backupTechCoolingConstant]);

  const energyOptionsToUse =
    currentDemandType !== EctoplannerDemandTypes.Electricity
      ? EnergyOptionsWithCOP
      : EnergyOptions;

  const energyOptionValue = energyOptionsToUse.find(
    (x) => x.value === currentEnergyType
  );

  if (energyOptionValue == null) {
    setCurrentEnergyType(EctoplannerEnergyTypes.Demand);
  }

  let demandGraphPlotType = currentPlotType;
  if (currentEnergyType === EctoplannerEnergyTypes.COP) {
    if (demandGraphPlotType === PlotTypes.Annual) {
      demandGraphPlotType = PlotTypes.AnnualCOP;
    } else if (demandGraphPlotType === PlotTypes.Monthly) {
      demandGraphPlotType = PlotTypes.MonthlyCOP;
    } else if (demandGraphPlotType === PlotTypes.DurationCurve) {
      demandGraphPlotType = PlotTypes.DurationCurveCOP;
    }
  }

  const demandOptionsToUse = building.params.useTransversal
    ? DemandOptionsWithCombinedHeatingAndCooling
    : DemandOptions;

  let [heatDemandGraph, coolDemandGraph, elDemandGraph] = useMemo(() => {
    return [
      safeArraySum(
        building.params.calculations.demandProfiles.spaceHeatProfile,
        building.params.calculations.demandProfiles.dhwProfile
      ),
      safeArraySum(
        building.params.calculations.demandProfiles.spaceCoolProfile,
        building.params.calculations.demandProfiles.processCoolProfile
      ),
      safeArraySum(
        building.params.calculations.demandProfiles.plugLoadsProfile,
        building.params.calculations.demandProfiles.emobProfile
      )
    ];
  }, [building.params.calculations.demandProfiles]);

  let [primaryGraph, secondaryGraph] = useMemo(() => {
    switch (currentDemandType) {
      case EctoplannerDemandTypes.Cooling:
        switch (currentEnergyType) {
          case EctoplannerEnergyTypes.Import:
            return [
              building.params.calculations.bes.coolImportProfile,
              emptyArray
            ];
          case EctoplannerEnergyTypes.COP:
            return [
              building.params.calculations.copProfiles?.cooling ?? emptyArray,
              emptyArray
            ];
          default:
          case EctoplannerEnergyTypes.Demand:
            return [
              coolDemandGraph,
              currentPlotType === PlotTypes.DurationCurve
                ? backupSeriesCooling
                : emptyArray
            ];
        }
      case EctoplannerDemandTypes.Electricity:
        switch (currentEnergyType) {
          case EctoplannerEnergyTypes.Import:
            return [
              building.params.calculations.bes.elImportProfile,
              emptyArray
            ];
          case EctoplannerEnergyTypes.COP:
            return [emptyArray, emptyArray];
          default:
          case EctoplannerEnergyTypes.Demand:
            return [elDemandGraph, emptyArray];
        }
      case EctoplannerDemandTypes.CombinedHeatingCooling:
        switch (currentEnergyType) {
          case EctoplannerEnergyTypes.Import:
            return [
              building.params.calculations.bes.heatImportProfile,
              building.params.calculations.bes.coolImportProfile
            ];
          case EctoplannerEnergyTypes.COP:
            return [
              safeArraySum(
                replaceNull(
                  building.params?.calculations?.copProfiles?.heating
                ),
                replaceNull(building.params?.calculations?.copProfiles?.cooling)
              ),
              emptyArray
            ];
          default:
          case EctoplannerEnergyTypes.Demand:
            return [heatDemandGraph, coolDemandGraph];
        }
      default:
      case EctoplannerDemandTypes.Heating:
        switch (currentEnergyType) {
          case EctoplannerEnergyTypes.Import:
            return [
              building.params.calculations.bes.heatImportProfile,
              emptyArray
            ];
          case EctoplannerEnergyTypes.COP:
            return [
              building.params?.calculations?.copProfiles?.heating ?? emptyArray,
              emptyArray
            ];
          default:
          case EctoplannerEnergyTypes.Demand:
            return [
              heatDemandGraph,
              currentPlotType === PlotTypes.DurationCurve
                ? backupSeriesHeating
                : emptyArray
            ];
        }
    }
  }, [
    building.params.calculations.copProfiles,
    building.params.calculations.bes,
    currentDemandType,
    currentEnergyType,
    heatDemandGraph,
    coolDemandGraph,
    elDemandGraph,
    currentPlotType,
    backupSeriesHeating,
    backupSeriesCooling
  ]);

  let primaryGraphZones: Highcharts.SeriesZonesOptionsObject[] = useMemo(() => {
    if (
      currentEnergyType === EctoplannerEnergyTypes.COP &&
      currentPlotType === PlotTypes.DurationCurve &&
      currentDemandType === EctoplannerDemandTypes.CombinedHeatingCooling
    ) {
      let heatingCop = building.params?.calculations?.copProfiles?.heating;
      let coolingCop = building.params?.calculations?.copProfiles?.cooling;

      if (coolingCop == null || coolingCop.length === 0) {
        if (heatingCop == null || heatingCop.length === 0) {
          return [
            {
              color: colors.blackColor
            }
          ];
        }

        return [
          {
            color: colors.heatingColor
          }
        ];
      } else if (heatingCop == null || heatingCop.length === 0) {
        [
          {
            color: colors.coolingColor
          }
        ];
      } else if (heatingCop.length !== coolingCop.length) {
        return [
          {
            color: colors.blackColor
          }
        ];
      }

      heatingCop = [...heatingCop].sort((a, b) => b - a);
      coolingCop = [...coolingCop].sort((a, b) => b - a);

      let zones: Highcharts.SeriesZonesOptionsObject[] = [];
      for (let i = 0; i < heatingCop.length; i++) {
        let heatingVal = heatingCop[i];
        let coolingVal = coolingCop[i];
        const hasHeating = (heatingVal ?? 0) !== 0;
        const hasCooling = (coolingVal ?? 0) !== 0;
        let color = colors.blackColor;

        if (hasHeating) {
          if (hasCooling) {
            color = colors.successColor;
          } else {
            color = colors.heatingColor;
          }
        } else if (hasCooling) {
          color = colors.coolingColor;
        }

        if (i === 0) {
          zones.push({
            color
          });
        } else {
          let lastZone = zones[zones.length - 1];
          if (lastZone.color !== color) {
            lastZone.value = i;
            zones.push({
              color
            });
          }
        }
      }

      return zones;
    }

    return [];
  }, [
    building.params?.calculations?.copProfiles?.cooling,
    building.params?.calculations?.copProfiles?.heating,
    currentDemandType,
    currentEnergyType,
    currentPlotType
  ]);

  let primaryName = energyKindNames[currentDemandType];
  if (
    currentDemandType === EctoplannerDemandTypes.CombinedHeatingCooling &&
    currentPlotType !== PlotTypes.DurationCurve
  ) {
    primaryName = energyKindNames.heating;
  }

  let secondaryName = useMemo(() => {
    if (currentDemandType === EctoplannerDemandTypes.CombinedHeatingCooling) {
      return energyKindNames.cooling;
    } else if (
      currentEnergyType === EctoplannerEnergyTypes.Demand &&
      currentPlotType === PlotTypes.DurationCurve
    ) {
      if (currentDemandType === EctoplannerDemandTypes.Heating) {
        return T.ectoplanner.energygraphs.breakpointheatpump;
      } else if (currentDemandType === EctoplannerDemandTypes.Cooling) {
        return T.ectoplanner.energygraphs.breakpointchiller;
      }
    }
  }, [currentDemandType, currentEnergyType, currentPlotType]);

  const key = (primaryGraph?.length ?? 0) + '-' + (secondaryGraph?.length ?? 0);

  return (
    <>
      <Flex>
        <FlexItem grow={1}>
          <KeyValueSelectableInput<GenericSelectOption<EctoplannerDemandType>>
            keyText={T.ectoplanner.energygraphs.energydemand}
            options={demandOptionsToUse}
            value={demandOptionsToUse.find(
              (x) => x.value === currentDemandType
            )}
            onChange={(val) => setCurrentDemandType(val.value)}
          />
        </FlexItem>
        <FlexItem grow={1}>
          <KeyValueSelectableInput<GenericSelectOption<EctoplannerEnergyType>>
            keyText={T.ectoplanner.energygraphs.energytype}
            options={energyOptionsToUse}
            value={energyOptionValue}
            onChange={(val) => setCurrentEnergyType(val.value)}
          />
        </FlexItem>
        <FlexItem grow={1}>
          <KeyValueSelectableInput<GenericSelectOption<PlotType>>
            keyText={T.ectoplanner.energygraphs.plottype}
            options={PlotTypeOptions}
            value={PlotTypeOptions.find((x) => x.value === currentPlotType)}
            onChange={(val) => setCurrentPlotType(val.value)}
          />
        </FlexItem>
      </Flex>
      <EctoplannerDemandGraphs
        key={key}
        primaryGraph={primaryGraph}
        primaryColor={energyKindColors[currentDemandType]}
        primaryName={primaryName}
        primaryGraphZones={primaryGraphZones}
        secondaryGraph={secondaryGraph}
        secondaryColor={energyKindBackupColors[currentDemandType]}
        secondaryName={secondaryName}
        stack
        disableLegend
        plotType={demandGraphPlotType}
      />
    </>
  );
};

const EctoplannerNetworkOverviewGraphTypes = {
  AllBuildingDemands: 'AllBuildingDemands',
  ImportFromGrid: 'ImportFromGrid',
  BalancingUnitLoads: 'BalancingUnitLoads'
} as const;

type EctoplannerNetworkOverviewGraphType =
  keyof typeof EctoplannerNetworkOverviewGraphTypes;

const GraphTypeOptions: GenericSelectOption<EctoplannerNetworkOverviewGraphType>[] =
  [
    {
      value: EctoplannerNetworkOverviewGraphTypes.AllBuildingDemands,
      label: T.ectoplanner.buildingsummary.buildingdemands
    },
    {
      value: EctoplannerNetworkOverviewGraphTypes.ImportFromGrid,
      label: T.ectoplanner.buildingsummary.importfromgrid
    },
    {
      value: EctoplannerNetworkOverviewGraphTypes.BalancingUnitLoads,
      label: T.ectoplanner.buildingsummary.balancingunitloads
    }
  ];

export const EctoplannerNetworkOverviewEnergyGraphs = ({
  form
}: {
  form: EctoplannerForm;
}) => {
  const [currentDemandType, setCurrentDemandType] =
    useState<EctoplannerDemandType>(
      EctoplannerDemandTypes.CombinedHeatingCooling
    );
  const [currentPlotType, setCurrentPlotType] = useState<PlotType>(
    PlotTypes.Annual
  );

  const [currentGraphType, setCurrentGraphType] =
    useState<EctoplannerNetworkOverviewGraphType>(
      EctoplannerNetworkOverviewGraphTypes.ImportFromGrid
    );

  // Balancing unit loads
  let heatingData: number[];
  let coolingData: number[];
  let electricityData: number[];

  switch (currentGraphType) {
    case EctoplannerNetworkOverviewGraphTypes.BalancingUnitLoads:
      heatingData = form.calculations.ehLoadProfiles.heat;
      coolingData = form.calculations.ehLoadProfiles.cool;
      electricityData = form.calculations.ehLoadProfiles.elec;
      break;
    case EctoplannerNetworkOverviewGraphTypes.ImportFromGrid:
      heatingData = form.calculations.allBuildingImports.heat;
      coolingData = form.calculations.allBuildingImports.cool;
      electricityData = form.calculations.allBuildingImports.elec;
      break;
    case EctoplannerNetworkOverviewGraphTypes.AllBuildingDemands:
    default:
      heatingData = form.calculations.allBuildingDemands.heat;
      coolingData = form.calculations.allBuildingDemands.cool;
      electricityData = form.calculations.allBuildingDemands.elec;
      break;
  }

  const primaryGraph = useMemo(() => {
    if (
      currentDemandType === EctoplannerDemandTypes.Heating ||
      currentDemandType === EctoplannerDemandTypes.CombinedHeatingCooling
    ) {
      return heatingData;
    } else if (currentDemandType === EctoplannerDemandTypes.Cooling) {
      return coolingData;
    }

    return electricityData;
  }, [currentDemandType, electricityData, heatingData, coolingData]);

  const secondaryGraph = useMemo(() => {
    if (currentDemandType === EctoplannerDemandTypes.CombinedHeatingCooling) {
      return coolingData;
    }

    return null;
  }, [coolingData, currentDemandType]);

  const key = primaryGraph?.length ?? 0;

  return (
    <>
      <Flex className={styles.buttonRow}>
        <FlexItem grow={1}>
          <KeyValueSelectableInput<GenericSelectOption<EctoplannerDemandType>>
            keyText={T.ectoplanner.energygraphs.energydemand}
            options={DemandOptionsWithCombinedHeatingAndCooling}
            value={DemandOptionsWithCombinedHeatingAndCooling.find(
              (x) => x.value === currentDemandType
            )}
            onChange={(val) => setCurrentDemandType(val.value)}
          />
        </FlexItem>
        <FlexItem grow={1}>
          <KeyValueSelectableInput<
            GenericSelectOption<EctoplannerNetworkOverviewGraphType>
          >
            keyText={T.ectoplanner.energygraphs.energytype}
            options={GraphTypeOptions}
            value={GraphTypeOptions.find((x) => x.value === currentGraphType)}
            onChange={(val) => setCurrentGraphType(val.value)}
          />
        </FlexItem>
        <FlexItem grow={1}>
          <KeyValueSelectableInput<GenericSelectOption<PlotType>>
            keyText={T.ectoplanner.energygraphs.plottype}
            options={PlotTypeOptions}
            value={PlotTypeOptions.find((x) => x.value === currentPlotType)}
            onChange={(val) => setCurrentPlotType(val.value)}
          />
        </FlexItem>
      </Flex>
      <EctoplannerDemandGraphs
        key={key}
        primaryGraph={primaryGraph}
        secondaryGraph={secondaryGraph}
        primaryColor={energyKindColors[currentDemandType]}
        primaryName={energyKindNames[currentDemandType]}
        secondaryColor={energyKindBackupColors[currentDemandType]}
        stack
        disableLegend
        plotType={currentPlotType}
      />
    </>
  );
};
