import _ from 'lodash';
import { AllDataSources } from 'ecto-common/lib/Dashboard/datasources';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { InitialDashboardFileData } from 'ecto-common/lib/DashboardEditor/DashboardConstants';
import UUID from 'uuidjs';
import { findPanelInsertionPos } from 'ecto-common/lib/DashboardEditor/DashboardEditor';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import { getPathFromModelKeyFunc } from 'ecto-common/lib/ModelForm/formUtils';
import { BaseModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  DashboardCopyPanelToPersonalFormState,
  DashboardPanel
} from 'ecto-common/lib/Dashboard/Panel';
import { DashboardResponseModel } from 'ecto-common/lib/API/APIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import panelDefinitions from 'ecto-common/lib/Dashboard/panels';
import { modelFormSectionsToModels } from 'ecto-common/lib/ModelForm/formUtils';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { PanelTarget } from 'ecto-common/lib/Dashboard/Panel';
import IdentityServiceAPIGenV2, {
  GetUserDashboardsResponse
} from 'ecto-common/lib/API/IdentityServiceAPIGenV2';

const hasNodeIdParam = (model: BaseModelDefinition) =>
  _.last(getPathFromModelKeyFunc(model.key)) === 'nodeId';
const hasNodeIdsParam = (model: BaseModelDefinition) =>
  _.last(getPathFromModelKeyFunc(model.key)) === 'nodeIds';

/**
 * Determines if any of the targets (variables) in the panel has fixed node
 * parameters, i.e. it is locked to only fetch data from specific nodes regardless
 * of the users position in the navigation hierarchy.
 */
export const hasFixedTargets = (panel: DashboardPanel) => {
  if (panel == null) {
    return false;
  }

  const panelTargets = _.values(panel.targets);
  const componentData = panelDefinitions[panel.type]?.data;

  for (const target of panelTargets) {
    if (_.isObject(target) && target.sourceType) {
      let dataSourceSectionsArgs =
        componentData?.dataSourceSectionsConfig?.[target.sourceType] ?? {};

      const sections = AllDataSources[target.sourceType].sections(
        dataSourceSectionsArgs
      );
      const models = modelFormSectionsToModels(sections);

      if (_.find(models, hasNodeIdParam)) {
        return !isNullOrWhitespace(target.nodeId);
      } else if (_.find(models, hasNodeIdsParam)) {
        return !_.isEmpty(target.nodeIds ?? []);
      }
    }
  }

  return false;
};

/**
 * Converts all of the dynamic targets (variables) in the panel with node sources
 * (that fetch data based on which node you are currently visiting) to only fetch
 * data from a specific hardcoded node instead.
 */
export const convertTargetsToFixed = (
  panelType: string,
  targets: Record<string, PanelTarget>,
  currentNodeId: string
) => {
  const newTargets = _.cloneDeep(targets);
  const componentData = panelDefinitions[panelType]?.data;

  _.forEach(newTargets, (target) => {
    if (_.isObject(target) && target.sourceType) {
      let dataSourceSectionsArgs =
        componentData?.dataSourceSectionsConfig?.[target.sourceType] ?? {};
      const sections = AllDataSources[target.sourceType].sections(
        dataSourceSectionsArgs
      );

      const models = modelFormSectionsToModels(sections);

      let nodeIdModel = _.find(models, hasNodeIdParam);
      if (nodeIdModel) {
        if (isNullOrWhitespace(target.nodeId)) {
          target.nodeId = currentNodeId;
        }
      }

      let nodeIdsModel = _.find(models, hasNodeIdsParam);

      if (nodeIdsModel) {
        if (_.isEmpty(target.nodeIds ?? [])) {
          target.nodeIds = [currentNodeId];
        }
      }
    }
  });

  return newTargets;
};

/**
 * Patches a personal dashboard file to add a new panel.
 *
 * @param panel The panel to add
 * @param useFixedLocation If the function should convert dynamic node sources to use a hardcoded node ID
 * @param currentNodeId The current node ID in the navigation hierarchy (used to patch to fixed)
 * @param dashboardId The ID of the dashboard to patch
 * @param panelTitle The new title of the panel
 *
 * @returns {*} A cancellable promise
 */
export const patchDashboardFile = (
  contextSettings: ApiContextSettings,
  dashboardId: string,
  panel: DashboardPanel,
  useFixedLocation: boolean,
  currentNodeId: string,
  panelTitle: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let _curPromise: Promise<any> =
    IdentityServiceAPIGenV2.User.getUserDashboards.promise(
      contextSettings,
      null
    );

  let ret = _curPromise
    .then((dashboardResponse: GetUserDashboardsResponse) => {
      let dashboard = _.find(dashboardResponse?.dashboards, {
        id: dashboardId
      });

      if (dashboard == null) {
        dashboard = {
          id: dashboardId,
          jsonData: { ...InitialDashboardFileData }
        };
      }

      const newPanel: DashboardPanel = {
        ...panel,
        id: UUID.generate(),
        title: panelTitle,
        gridPos: {
          ...panel.gridPos,
          ...findPanelInsertionPos(dashboard.jsonData.panels, panel.gridPos.w)
        }
      };

      if (useFixedLocation) {
        newPanel.targets = convertTargetsToFixed(
          newPanel.type,
          newPanel.targets,
          currentNodeId
        );
      }

      dashboard.jsonData.panels.push(newPanel);
      _curPromise =
        IdentityServiceAPIGenV2.User.createOrUpdateUserDashboards.promise(
          contextSettings,
          { dashboards: [dashboard] },
          null
        );
      return _curPromise;
    })
    .then(() => {
      _curPromise = null;
      return Promise.resolve();
    })
    .catch((e) => {
      _curPromise = null;
      throw e;
    });

  return ret;
};

/**
 * Creates suitable ModelForm models for copying a dashboard panel into an personal dashboard.
 *
 * @param userDashboards The available dashboards to choose from
 * @param panel The panel that should be copied into the personal dashboard.
 *
 * @returns An array of ModelForm models
 */
export const createCopyPanelToPersonalModels = (
  userDashboards: DashboardResponseModel[],
  panel: DashboardPanel
): ModelDefinition<DashboardCopyPanelToPersonalFormState>[] => {
  let models: ModelDefinition<DashboardCopyPanelToPersonalFormState>[] = [
    {
      key: (input) => input.title,
      modelType: ModelType.TEXT,
      label: T.dashboard.newpaneltitle,
      placeholder: T.dashboard.newpaneltitle,
      autoFocus: true,
      hasError: isNullOrWhitespace
    },
    {
      key: (input) => input.dashboard,
      modelType: ModelType.OPTIONS,
      label: T.dashboard.selectuserdashboardtitle,
      placeholder: T.dashboard.selectuserdashboardplaceholder,
      options: userDashboards.map((dashboard) => ({
        label: dashboard.name,
        value: dashboard
      })),
      isMultiOption: false,
      withCreatableOption: true,
      hasError: (v) => v == null
    }
  ];

  if (!hasFixedTargets(panel)) {
    models.push({
      key: (input) => input.useFixedLocation,
      modelType: ModelType.BOOL,
      label: T.dashboard.usefixedlocation
    });
  }

  return models;
};
