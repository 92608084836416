import React from 'react';
import styles from './EctoplannerResultTitle.module.css';

interface EctoplannerResultTitleProps {
  children?: React.ReactNode;
}

const EctoplannerResultTitle = ({ children }: EctoplannerResultTitleProps) => {
  return <div className={styles.title}>{children}</div>;
};

export default React.memo(EctoplannerResultTitle);
