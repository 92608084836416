import React, { useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import _ from 'lodash';
import queryString from 'query-string';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import LogView from 'js/components/Logs/LogView';
import useLogViewToolbar, {
  SearchType
} from 'js/components/Logs/useLogViewToolbar';
import T from 'ecto-common/lib/lang/Language';
import HelpPaths from 'ecto-common/help/tocKeys';

const LogPage = () => {
  const history = useHistory();
  const params = useParams<NodeParams>();
  const location = useLocation();

  const search = queryString.parse(location.search);
  const onSearchChanged = useCallback(
    (newSearch: SearchType) => {
      const newLocation = {
        ...location,
        search: queryString.stringify({
          ...search,
          ...newSearch
        })
      };

      history.push(newLocation);
    },
    [history, location, search]
  );

  const { nodeId } = params;
  const {
    toolbarItems,
    selectedActityLogTypes,
    sortDirection,
    orderBy,
    onSortChange,
    currentPage,
    searchString
  } = useLogViewToolbar({ nodeId, search, onSearchChanged });

  const onPageChange = useCallback(
    (newPage: number) => {
      onSearchChanged({ page: newPage });
    },
    [onSearchChanged]
  );

  return (
    <ToolbarContentPage
      title={T.location.tabs.logs}
      toolbarItems={toolbarItems}
      helpPath={HelpPaths.docs.operator.logs}
    >
      <LogView
        nodeId={nodeId}
        activityLogTypes={selectedActityLogTypes}
        sortDirection={sortDirection}
        orderBy={orderBy}
        onSortChange={onSortChange}
        currentPage={currentPage}
        searchString={searchString}
        onPageChange={onPageChange}
      />
    </ToolbarContentPage>
  );
};

export default LogPage;
