import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { getFullPathFromMap } from 'ecto-common/lib/utils/locationUtils';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import Schedule, { formatScheduleStatus } from './Schedule';
import styles from './ScheduleStatus.module.css';
import T from 'ecto-common/lib/lang/Language';
import { formatScheduleDate } from './utils/dateUtils';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import {
  ScheduleHistoryResponseModel,
  ScheduleResponseModel
} from 'ecto-common/lib/API/APIGen';

const columns: DataTableColumnProps<ScheduleHistoryResponseModel>[] = [
  {
    label: T.powercontrol.dispatch.schedulestatus.column.date,
    dataKey: 'date',
    minWidth: 50,
    maxWidth: 150,
    dataFormatter: (date) => formatScheduleDate(date)
  },
  {
    label: T.powercontrol.dispatch.schedulestatus.column.status,
    dataKey: 'status',
    width: 100,
    flexGrow: 0,
    dataFormatter: (status: string) => formatScheduleStatus(status)
  },
  {
    label: T.powercontrol.dispatch.schedulestatus.column.user,
    dataKey: 'user',
    width: 50
  }
];

type ScheduleStatusProps = {
  schedule: ScheduleResponseModel;
  showShortcut?: boolean;
  defaultExpanded: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onAbortClick: () => void;
  isLoading: boolean;
};

const ScheduleStatus = ({
  schedule,
  onEditClick,
  onDeleteClick,
  onAbortClick,
  showShortcut = false,
  isLoading,
  defaultExpanded
}: ScheduleStatusProps) => {
  const nodeMap = useOperatorSelector((state) => state.general.nodeMap);
  const [showDetails, setShowDetails] = useState<boolean>(defaultExpanded);

  const onScheduleClick = useCallback(() => {
    setShowDetails((oldShowDetails) => !oldShowDetails);
  }, []);

  const _closeDetails = useCallback(() => {
    setShowDetails(false);
  }, []);

  return (
    <>
      <LoadingContainer isLoading={isLoading} showSpinner={false}>
        <div className={styles.schedule}>
          <Schedule
            className={classNames(
              styles.title,
              showDetails && styles.showDetails
            )}
            onClick={onScheduleClick}
            schedule={schedule}
            locationPath={getFullPathFromMap(nodeMap, schedule.locationId)}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            onAbortClick={onAbortClick}
            showShortcut={showShortcut}
          />
        </div>
      </LoadingContainer>

      <ActionModal
        isOpen={showDetails}
        onModalClose={_closeDetails}
        title={T.powercontrol.dispatch.scheduled.events}
        headerIcon={Icons.Calendar}
        onConfirmClick={_closeDetails}
        disableCancel
      >
        <DataTable<ScheduleHistoryResponseModel>
          columns={columns}
          data={schedule.history}
        />
      </ActionModal>
    </>
  );
};

export default React.memo(ScheduleStatus);
