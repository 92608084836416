import React, { useState, useMemo } from 'react';
import _ from 'lodash';
import SearchableTable from 'ecto-common/lib/SearchableTable/SearchableTable';
import T from 'ecto-common/lib/lang/Language';
import { BuildingTemperatureImpactPreviewResponseModel } from 'ecto-common/lib/API/APIGen';

function useSearchResult<ItemType>(
  array: ItemType[],
  property: keyof ItemType,
  searchString: string
) {
  return useMemo(() => {
    const adjustString = (str: string) => _.toLower(_.trim(str));
    const trimmedSearchString = adjustString(searchString);
    if (_.isEmpty(trimmedSearchString)) {
      return array;
    }

    return _.filter(
      array,
      (item) =>
        adjustString(_.get(item, property, '')).indexOf(trimmedSearchString) !==
        -1
    );
  }, [searchString, property, array]);
}

const columns = [
  {
    label: T.common.nodename,
    dataKey: 'nodeName'
  },
  {
    label: T.common.affected,
    dataKey: 'meteorologyDataMissing',
    width: 80,
    flexGrow: 0,
    dataFormatter: (meteorologyDataMissing: boolean) => {
      return <>{meteorologyDataMissing ? T.common.no : T.common.yes}</>;
    }
  }
];

interface TemperatureImpactBuildingsTableProps {
  buildings: BuildingTemperatureImpactPreviewResponseModel[];
  disabled?: boolean;
}

const TemperatureImpactBuildingsTable = (
  props: TemperatureImpactBuildingsTableProps
) => {
  const [searchString, setSearchString] = useState('');
  const buildings = useSearchResult(props.buildings, 'nodeName', searchString);

  return (
    <SearchableTable
      disabled={props.disabled}
      data={buildings}
      columns={columns}
      noDataText={
        T.powercontrol.dispatch.schedule.temperatureimpact.affectedbuildings
          .empty
      }
      onSearch={setSearchString}
      onClickRow={_.noop}
    />
  );
};

export default React.memo(TemperatureImpactBuildingsTable);
