// init:  Initial state in App.js

//import ehAttrList from "./ehAttrList"
import demAttrList from './demAttrList';
import mainAttrList from './mainAttrList';

function getState(mode) {
  let attrList = mainAttrList.getMainAttrList();

  // Add 'energy demands' attributes
  attrList = attrList.concat(demAttrList.getDemAttrList());

  // Add 'energy hub' attributes
  // NOT RELEVANT: attrList = attrList.concat(ehAttrList.getEhAttrList())

  if ((mode === 'init') | (mode === 'resetDem') | (mode === 'resetEh')) {
    let state = {};
    attrList.forEach((attrDict) => {
      if (attrDict['m'].includes(mode)) {
        state[attrDict.name] = attrDict.val;
      }
    });
    return state;
  }

  if (
    (mode === 'sMain') |
    (mode === 'sBldg') |
    (mode === 'sOpt') |
    (mode === 'desOpt') |
    (mode === 'opOpt')
  ) {
    let params = [];
    attrList.forEach((attrDict) => {
      if (attrDict['m'].includes(mode)) {
        params.push(attrDict.name);
      }
    });
    return params;
  }
}

const exportedFunctions = { getState };
export default exportedFunctions;
