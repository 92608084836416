import React from 'react';
import _ from 'lodash';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

interface KeyValueSingleOptionProps {
  dataKey: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value(value: any): string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any[];
  placeholder?: string;
  data: object;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOptionsChanged?(key: string, value: any): void;
}

export const KeyValueSingleOption = ({
  dataKey,
  label,
  value,
  options,
  placeholder,
  data,
  onOptionsChanged,
  disabled
}: KeyValueSingleOptionProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createOption = (v: any) => ({
    label: value(v),
    value: v
  });
  return (
    <KeyValueSelectableInput
      key={dataKey}
      keyText={label}
      value={createOption(_.get(data, dataKey))}
      onChange={(e: GenericSelectOption) =>
        onOptionsChanged && onOptionsChanged(dataKey, e.value)
      }
      placeholder={placeholder}
      options={_.map(options, createOption)}
      disabled={disabled}
    />
  );
};
