import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Icons from 'ecto-common/lib/Icons/Icons';
import _ from 'lodash';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import T from 'ecto-common/lib/lang/Language';
import EctoplannerResults from 'js/components/Ectoplanner/EctoplannerResults';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import moment from 'moment';

import { useHistory, useParams } from 'react-router-dom';
import {
  EctoplannerParams,
  getEctoplannerUrl,
  ectoplannerMasterBuildId
} from 'js/utils/routeConstants';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelFormDialog from 'ecto-common/lib/ModelForm/ModelFormDialog';
import NavLinkFix from 'ecto-common/lib/NavLinkFix/NavLinkFix';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import CollapsingSegmentControlPicker, {
  OptionWithIcon
} from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import {
  downloadBlob,
  downloadBlobTextWithoutUTF8BOM
} from 'ecto-common/lib/utils/downloadBlob';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { stripCalculationData } from 'js/components/Ectoplanner/EctoplannerResultType';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import EctoplannerAPIGen, {
  BuildResponse,
  BuildResponseListResponse,
  ProjectResponse,
  SetBuildDescriptionRequest,
  WeatherCountryResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { DropdownButtonOptionType } from 'ecto-common/lib/DropdownButton/DropdownButton';
import {
  citiesAndAirTempPromise,
  compileFormPromise,
  migrateEctoplannerBuildings
} from 'js/components/Ectoplanner/EctoplannerModelUtils';
import {
  OptionWithIconAndView,
  useEctoplannerFormCalculation,
  useEctoplannerFormOptions,
  useEctoplannerFormValidation,
  useEctoplannerValidation
} from './useEctoplannerFormOptions';
import EctoplannerFormErrorsModal from 'js/components/Ectoplanner/EctoplannerFormErrorsModal';
import styles from './EditEctoplannerProject.module.css';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import ToolbarMenuDivider from 'ecto-common/lib/Toolbar/ToolbarMenuDivider';
import {
  EctoplannerBuildStatus,
  calculateEctoplannerChecksum,
  isEctoplannerStatusInProgress
} from 'js/components/Ectoplanner/EctoplannerTypes';
import { ectoplannerBuildTimedOut } from './EctoplannerTypes';
import ToolbarMenuDropdownButton from 'ecto-common/lib/Toolbar/ToolbarMenuDropdownButton';
import createEctoplannerGraphStore, {
  EctoplannerGraphStoreContext,
  EctoplannerGraphStoreType,
  emptyEctoplannerGraphStore
} from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerGraphStore';
import { useStore } from 'zustand';
import Button from 'ecto-common/lib/Button/Button';
import { EctoplannerNavigationTree } from 'js/components/Ectoplanner/EctoplannerNavigationControls';
import ToolbarNavControl from 'ecto-common/lib/ToolbarContentPage/ToolbarNavControl';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';
import Spinner from 'ecto-common/lib/Spinner/Spinner';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';
import ShareEctoplannerProjectModal from 'js/components/Ectoplanner/ShareEctoplannerProjectModal';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import { ObjectValues } from 'ecto-common/lib/utils/typescriptUtils';

export const defaultGetEctoplannerBuildArgs = {
  $top: 1000
};

// To be compatible with ModelFormDialog
type SetBuildDescriptionRequestWithId = SetBuildDescriptionRequest & {
  id: string;
};

const buildModels: ModelDefinition<SetBuildDescriptionRequestWithId>[] = [
  {
    key: (input) => input.description,
    modelType: ModelType.TEXT,
    label: T.ectoplanner.calculations.description,
    hasError: isNullOrWhitespace,
    autoFocus: true
  }
];

const CreateFormProjectTypes = {
  BlankProject: 'blankproject',
  CopyProject: 'copyproject',
  Version: 'version'
} as const;

type CreateFormProjectType = ObjectValues<typeof CreateFormProjectTypes>;

type CreateFormSettings = {
  id: string;
  description: string;
  type: CreateFormProjectType;
};

const saveFormPromise = ({
  contextSettings,
  projectId,
  buildId,
  formData,
  lastWeatherStationId
}: {
  buildId: string;
  projectId: string;
  formData: EctoplannerForm;
  lastWeatherStationId: string;
  contextSettings: ApiContextSettings;
}): Promise<unknown> => {
  const formBody = JSON.stringify(stripCalculationData(formData));

  let formPromise = buildIdIsNullOrMaster(buildId)
    ? EctoplannerAPIGen.EctoGridProjects.formdataUpdate.promise(
        contextSettings,
        {
          projectId
        },
        formBody,
        null
      )
    : EctoplannerAPIGen.EctoGridProjects.buildsFormdataUpdate.promise(
        contextSettings,
        {
          buildId,
          projectId
        },
        formBody,
        null
      );

  if (
    lastWeatherStationId !== formData.location.city.id &&
    buildIdIsNullOrMaster(buildId)
  ) {
    return Promise.all([
      formPromise,
      EctoplannerAPIGen.EctoGridProjects.weatherCreate.promise(
        contextSettings,
        {
          projectId
        },
        {
          weatherStationId: formData.location.city.id
        },
        null
      )
    ] as const);
  }

  return formPromise;
};

const buildIdIsNullOrMaster = (buildId: string) => {
  return buildId == null || buildId === ectoplannerMasterBuildId;
};

const saveAndBuildPromise = ({
  contextSettings,
  projectId,
  buildId,
  formData,
  hasChanges,
  lastWeatherStationId,
  checksum
}: {
  projectId: string;
  buildId: string;
  formData: EctoplannerForm;
  hasChanges: boolean;
  lastWeatherStationId: string;
  contextSettings: ApiContextSettings;
  checksum: string;
}) => {
  const doBuildPromise = () => {
    if (buildIdIsNullOrMaster(buildId)) {
      return EctoplannerAPIGen.EctoGridProjects.buildsMasterBuildCreate.promise(
        contextSettings,
        {
          projectId
        },
        {
          Checksum: checksum
        },
        null
      );
    }

    return EctoplannerAPIGen.EctoGridProjects.buildsRecalculateCurrentVersionCreate.promise(
      contextSettings,
      {
        projectId,
        buildId
      },
      {
        Checksum: checksum
      },
      null
    );
  };

  if (hasChanges) {
    return saveFormPromise({
      contextSettings,
      projectId,
      buildId,
      formData,
      lastWeatherStationId
    }).then(() => {
      return doBuildPromise();
    });
  }

  return doBuildPromise();
};

const SectionCalculations = 'calculations';
const SectionGraphs = 'graphs';

interface EditEctoplannerProjectProps {
  projectName?: string;
  allProjects: ProjectResponse[];
  weatherCountries: WeatherCountryResponse[];
  form: EctoplannerForm;
  isLoadingProjects: boolean;
  isLoadingBuildsForProject: boolean;
  isLoadingForm: boolean;
  setForm: Dispatch<SetStateAction<EctoplannerForm>>;
  setFormFromUserInput: Dispatch<SetStateAction<EctoplannerForm>>;
  clearHasChanges: () => void;
  hasChanges: boolean;
  allBuilds: BuildResponse[];
  onRecalculate: (buildResponse: BuildResponse) => void;
  changedForms: Record<string, boolean>;
}

const allStores: Record<string, Record<string, EctoplannerGraphStoreType>> = {};

const EditEctoplannerProject = ({
  allProjects,
  projectName = '',
  weatherCountries,
  form,
  isLoadingForm,
  isLoadingProjects,
  isLoadingBuildsForProject,
  setForm,
  setFormFromUserInput,
  clearHasChanges,
  hasChanges,
  allBuilds,
  changedForms,
  onRecalculate
}: EditEctoplannerProjectProps) => {
  const params = useParams<EctoplannerParams>();
  const history = useHistory();

  const builds = useMemo(() => {
    return allBuilds.filter((build) => build.projectId === params.projectId);
  }, [allBuilds, params.projectId]);

  const [isShowingShareDialog, showShareDialog, hideShareDialog] =
    useDialogState(false);
  const [showingErrorDialog, showErrorDialog, hideErrorDialog] =
    useDialogState(false);
  const [isShowingDeleteDialog, showDeleteDialog, hideDeleteDialog] =
    useDialogState(false);

  const [updateDescriptionRequest, setUpdateDescriptionRequest] =
    useState<SetBuildDescriptionRequestWithId>(null);
  const isEditingProject = buildIdIsNullOrMaster(params.buildId);
  const selectedBuild = isEditingProject
    ? builds.find((build) => build.isMasterBuild)
    : builds.find((build) => build.id === params.buildId);
  const [newBuild, setNewBuild] = useState<CreateFormSettings>(null);
  const clearNewBuild = useCallback(() => setNewBuild(null), []);

  const buildId = selectedBuild?.id;
  const collectionsQuery =
    EctoplannerAPIGen.EctoGridBuilds.graphsDetail.useQuery(
      {
        buildId
      },
      {
        enabled: buildId != null,
        refetchOnWindowFocus: false
      }
    );

  const [storeRef, setStoreRef] = useState<EctoplannerGraphStoreType>(null);

  const storeBuildId = useStore(
    storeRef ?? emptyEctoplannerGraphStore,
    (state) => state.buildId
  );

  if (collectionsQuery.data != null) {
    if (storeRef == null || storeBuildId !== buildId) {
      let newStore =
        allStores[params.projectId]?.[buildId] ??
        createEctoplannerGraphStore(
          collectionsQuery.data.items,
          buildId,
          false
        );

      allStores[params.projectId] = {
        ...allStores[params.projectId],
        [buildId]: newStore
      };

      setStoreRef(newStore);
    }
  }

  const { tenantId, contextSettings } = useContext(TenantContext);

  const currentWeatherStationId = form?.location?.city?.id;

  const citiesAndAirTempQuery = useQuery(
    ['citiesAndAirTemp', currentWeatherStationId],
    ({ signal }) => {
      return citiesAndAirTempPromise({
        weatherStationId: currentWeatherStationId,
        signal,
        contextSettings
      });
    },
    {
      enabled: currentWeatherStationId != null,
      refetchOnWindowFocus: false
    }
  );

  const editProjectNameMutation =
    EctoplannerAPIGen.EctoGridProjects.nameCreate.useMutation(
      {
        projectId: params.projectId
      },
      {
        onSuccess: () => {
          setUpdateDescriptionRequest(null);
          queryClient.invalidateQueries(
            EctoplannerAPIGen.EctoGridProjects.listProjects.path(
              contextSettings
            )
          );
        },
        onError: () => {
          toastStore.addErrorToast(T.common.unknownerror);
        }
      }
    );

  const addProjectMutation =
    EctoplannerAPIGen.EctoGridProjects.create.useMutation({
      onSuccess: (res) => {
        setNewBuild(null);
        queryClient.invalidateQueries(
          EctoplannerAPIGen.EctoGridProjects.listProjects.path(contextSettings)
        );

        if (res.id !== params.projectId) {
          history.push(getEctoplannerUrl(tenantId, res.id));
        }
      },
      onError: () => {
        toastStore.addErrorToast(T.common.unknownerror);
      }
    });

  const editBuildDescriptionMutation =
    EctoplannerAPIGen.EctoGridBuilds.descriptionCreate.useMutation(
      {
        buildId: updateDescriptionRequest?.id
      },
      {
        onSuccess: (_ununused, args) => {
          setUpdateDescriptionRequest(null);
          queryClient.setQueryData<BuildResponseListResponse>(
            [
              ...EctoplannerAPIGen.EctoGridProjects.buildsDetail.path(
                contextSettings,
                {
                  projectId: params.projectId
                }
              ),
              defaultGetEctoplannerBuildArgs
            ],
            (oldData) => {
              let indexToUpdate = oldData.items.findIndex(
                (item) => item.id === selectedBuild.id
              );

              if (indexToUpdate !== -1) {
                let newItems = [...oldData.items];
                newItems[indexToUpdate] = {
                  ...newItems[indexToUpdate],
                  description: args.description
                };

                return {
                  ...oldData,
                  items: newItems
                };
              }

              return oldData;
            }
          );
        },
        onError: () => {
          setUpdateDescriptionRequest(null);
          toastStore.addErrorToast(T.common.unknownerror);
        }
      }
    );

  const deleteProjectMutation =
    EctoplannerAPIGen.EctoGridProjects.delete.useMutation(
      {
        projectId: params.projectId
      },
      {
        onSuccess: () => {
          const nextProject = allProjects.find(
            (x) => x.id !== params.projectId
          );

          hideDeleteDialog();

          queryClient.invalidateQueries(
            EctoplannerAPIGen.EctoGridProjects.listProjects.path(
              contextSettings
            )
          );

          history.push(getEctoplannerUrl(tenantId, nextProject?.id));
        },
        onError: (e) => {
          // TODO: Improve error signature
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((e as any).response?.status === HttpStatus.CONFLICT) {
            toastStore.addErrorToast(T.ectoplanner.share.failedtodeleteproject);
          } else {
            toastStore.addErrorToast(T.common.unknownerror);
          }
        }
      }
    );

  const deleteBuildMutation =
    EctoplannerAPIGen.EctoGridBuilds.delete.useMutation(
      {
        buildId: params.buildId
      },
      {
        onSuccess: () => {
          queryClient.setQueryData<BuildResponseListResponse>(
            [
              ...EctoplannerAPIGen.EctoGridProjects.buildsDetail.path(
                contextSettings,
                {
                  projectId: params.projectId
                }
              ),
              defaultGetEctoplannerBuildArgs
            ],
            (oldData) => {
              const newItems = oldData.items.filter(
                (item) => item.id !== params.buildId
              );
              return {
                ...oldData,
                items: newItems
              };
            }
          );

          history.push(
            getEctoplannerUrl(
              tenantId,
              params.projectId,
              ectoplannerMasterBuildId,
              params.section,
              params.itemId
            )
          );
          hideDeleteDialog();
        }
      }
    );

  const confirmDelete = useCallback(() => {
    if (isEditingProject) {
      deleteProjectMutation.mutate();
    } else {
      deleteBuildMutation.mutate();
    }
  }, [deleteBuildMutation, deleteProjectMutation, isEditingProject]);

  // const buildItem = _.find(builds, ['id', params.buildId]);

  const [airTemp, cityData] = citiesAndAirTempQuery.data ?? [null, null, null];

  const [hasErrors, formErrors, formErrorSections] =
    useEctoplannerFormValidation({
      form,
      cityData,
      projectId: params.projectId
    });

  const [triggerCalculation, checksum] = useEctoplannerFormCalculation({
    form,
    setForm,
    hasErrors,
    cityData,
    airTemp,
    buildId
  });
  const setFormAndCalculate = useCallback(
    (newForm: React.SetStateAction<EctoplannerForm>) => {
      // Form has changed from user input: Trigger rebuild.
      setForm(newForm);
      triggerCalculation();
    },
    [setForm, triggerCalculation]
  );

  const setFormFromUserInputLocal = useCallback(
    (newForm: React.SetStateAction<EctoplannerForm>) => {
      // Form has changed from user input: Trigger rebuild.
      setFormFromUserInput(newForm);
      triggerCalculation();
    },
    [setFormFromUserInput, triggerCalculation]
  );
  const lastFetchedWeatherStationId = useRef<string>(null);

  const weatherStationId = form?.location?.city?.id;

  useEffect(() => {
    // Delay calculating the form until we have gotten the new air temp and city data that corresponds to the
    // weather station. After that, we can trigger the new calculation.
    if (
      weatherStationId != null &&
      cityData != null &&
      airTemp != null &&
      lastFetchedWeatherStationId.current !== weatherStationId
    ) {
      if (lastFetchedWeatherStationId.current != null) {
        // We have changed weather stations, so we need to migrate all of the buildings to
        // the new weather station. They will receive new heating profiles based on that city.
        // Then, the form will be calculated with the new city data.
        setFormAndCalculate((oldForm: EctoplannerForm) => ({
          ...oldForm,
          buildings: migrateEctoplannerBuildings(oldForm.buildings, cityData)
        }));
      } else {
        // No need to migrate buildings, just perform initial calculation (since form is stripped of
        // calculation data to conserve space we do not include calculation data when saving to server).
        setFormAndCalculate((oldForm: EctoplannerForm) => ({
          ...oldForm
        }));
      }
      lastFetchedWeatherStationId.current = weatherStationId;
    }
  }, [airTemp, cityData, setFormAndCalculate, weatherStationId]);

  const calculateProfilesAndSaveMutation = useMutation(compileFormPromise, {
    onSuccess: (data) => {
      setForm(data.form);
      saveMutation.mutate({
        contextSettings,
        projectId: params.projectId,
        buildId: params.buildId,
        formData: data.form,
        lastWeatherStationId: lastWeatherStationId.current
      });
    }
  });

  const exportMutation =
    EctoplannerAPIGen.EctoGridProjects.exportDetail.useMutation(
      { projectId: params.projectId },
      {
        onSuccess: (blob) => {
          let timestamp = moment().format('YYYY-MM-DD HH.mm');
          downloadBlob(
            blob,
            'Ectoplanner ' + projectName + ' - ' + timestamp + '.zip'
          );
        },
        onError: (e) => {
          console.error(e);
          toastStore.addErrorToast(T.ectoplanner.failedtoloadproject);
        }
      }
    );

  const lastWeatherStationId = useRef(null);
  const queryClient = useQueryClient();
  const addVersionMutation =
    EctoplannerAPIGen.EctoGridProjects.createBuild.useMutation(
      {
        projectId: params.projectId
      },
      {
        onSuccess: (build) => {
          queryClient.setQueryData<BuildResponseListResponse>(
            [
              ...EctoplannerAPIGen.EctoGridProjects.buildsDetail.path(
                contextSettings,
                {
                  projectId: params.projectId
                }
              ),
              defaultGetEctoplannerBuildArgs
            ],
            (oldData) => {
              return {
                ...oldData,
                items: [build, ...oldData.items]
              };
            }
          );

          history.push(
            getEctoplannerUrl(
              tenantId,
              params.projectId,
              build.id,
              params.section
            )
          );
          setNewBuild(null);
        }
      }
    );

  const buildMutation = useMutation(saveAndBuildPromise, {
    onSuccess: (buildResponse) => {
      clearHasChanges();
      setNewBuild(null);
      onRecalculate(buildResponse);
    },
    onError: () => {
      toastStore.addErrorToast(T.ectoplanner.calculateerror);
    }
  });

  const saveMutation = useMutation(saveFormPromise, {
    onSuccess: () => {
      lastWeatherStationId.current = form.location.city.id;
      clearHasChanges();
    },
    onError: (e) => {
      toastStore.addErrorToast(T.ectoplanner.saveerror);
      console.error(e);
    }
  });

  const save = useCallback(() => {
    if (hasErrors) {
      saveMutation.mutate({
        contextSettings,
        projectId: params.projectId,
        buildId: params.buildId,
        formData: form,
        lastWeatherStationId: lastWeatherStationId.current
      });
    } else {
      calculateProfilesAndSaveMutation.mutate({
        calculationIndex: 0, // Calculation index not used here since the promise will always use the result
        inputForm: form,
        cityData,
        airTemp
      });
    }
  }, [
    hasErrors,
    saveMutation,
    contextSettings,
    params.projectId,
    params.buildId,
    form,
    calculateProfilesAndSaveMutation,
    cityData,
    airTemp
  ]);

  const formOptions = useEctoplannerFormOptions({
    projectId: params.projectId,
    form: form,
    setForm,
    setFormFromUserInput: setFormFromUserInputLocal,
    formErrors,
    weatherCountries,
    cityData
  });
  let statusInProgress = false;
  let timedOutProgress = false;

  if (selectedBuild != null) {
    const buildStatus = selectedBuild.status as EctoplannerBuildStatus;
    statusInProgress = isEctoplannerStatusInProgress(buildStatus);
    timedOutProgress =
      statusInProgress && ectoplannerBuildTimedOut(selectedBuild);
  }
  const calculationIsLoading = statusInProgress && !timedOutProgress;

  const loadingCitiesAndAirTemp =
    currentWeatherStationId != null && citiesAndAirTempQuery.isLoading;
  const isLoading =
    isLoadingForm ||
    saveMutation.isLoading ||
    exportMutation.isLoading ||
    loadingCitiesAndAirTemp;

  const [validationComponent, hasNoTechs] = useEctoplannerValidation({
    form,
    hasErrors,
    currentWeatherStationId
  });

  const calculationDisabled =
    buildMutation.isLoading ||
    calculationIsLoading ||
    isLoading ||
    form?.buildings?.length === 0 ||
    hasNoTechs;

  const confirmCalculate = useCallback(() => {
    if (
      params.section !== SectionCalculations &&
      params.section !== SectionGraphs
    ) {
      history.push(
        getEctoplannerUrl(
          tenantId,
          params.projectId,
          params.buildId,
          SectionCalculations
        )
      );
    }

    // The other checksum is calculated using the calculation web worker, and might
    // not have been recalculated at this point if the user is quick to hit
    // calculate. So redo it just in case so we don't get invalid checksums
    // for the build.
    const newChecksum = calculateEctoplannerChecksum(form);

    buildMutation.mutate({
      contextSettings,
      projectId: params.projectId,
      buildId: params.buildId,
      formData: form,
      hasChanges,
      lastWeatherStationId: lastWeatherStationId.current,
      checksum: newChecksum
    });
  }, [
    params.section,
    params.buildId,
    buildMutation,
    contextSettings,
    params.projectId,
    form,
    hasChanges,
    history,
    tenantId
  ]);

  const calculate = useCallback(() => {
    if (Object.keys(formErrorSections).length === 0) {
      confirmCalculate();
    } else {
      showErrorDialog();
    }
  }, [confirmCalculate, formErrorSections, showErrorDialog]);

  const formIsUpToDate =
    isNullOrWhitespace(checksum) || checksum === selectedBuild?.checksum;
  const isRunningCalculation = calculationIsLoading || buildMutation.isLoading;

  const sections: OptionWithIconAndView[] = useMemo(() => {
    let result = [
      ...formOptions,
      {
        icon: <Icons.Table />,
        value: SectionCalculations,
        label: <>{T.ectoplanner.results.title}</>,
        view: (
          <EctoplannerResults
            isRunningCalculation={isRunningCalculation}
            selectedBuild={selectedBuild}
            projectId={params.projectId}
            formIsUpToDate={formIsUpToDate}
          />
        )
      }
    ];

    return result;
  }, [
    formIsUpToDate,
    formOptions,
    isRunningCalculation,
    params.projectId,
    selectedBuild
  ]);

  let curSection = _.findIndex(sections, ['value', params.section]);
  curSection = curSection === -1 ? 0 : curSection;

  const confirmAddNewVersion = useCallback(
    (build: CreateFormSettings) => {
      if (build.type === CreateFormProjectTypes.BlankProject) {
        addProjectMutation.mutate({
          name: build.description,
          exampleWeatherYear: 2021
        });
      } else if (build.type === CreateFormProjectTypes.CopyProject) {
        addProjectMutation.mutate({
          name: build.description,
          exampleWeatherYear: 2021,
          copySourceId: isEditingProject ? params.projectId : params.buildId,
          copyWeatherStationId: form?.location?.city?.id
        });
      } else if (build.type === CreateFormProjectTypes.Version) {
        addVersionMutation.mutate({
          description: build.description,
          projectId: params.projectId,
          calculateMasterBuild: false,
          sourceBuildId:
            params.buildId === ectoplannerMasterBuildId ? null : params.buildId,
          startBuild: false
        });
      }
    },
    [
      addProjectMutation,
      addVersionMutation,
      form?.location?.city?.id,
      isEditingProject,
      params.buildId,
      params.projectId
    ]
  );

  const newBuildModels: ModelDefinition<CreateFormSettings>[] = useMemo(() => {
    let sourceText =
      selectedBuild && !selectedBuild.isMasterBuild
        ? selectedBuild?.description
        : projectName;
    let sourceHelpText =
      params.projectId == null
        ? ''
        : T.format(T.ectoplanner.datacopyformat, sourceText).join('');

    return _.compact([
      {
        key: (input) => input.description,
        label: T.ectoplanner.calculations.builds.description,
        modelType: ModelType.TEXT,
        hasError: isNullOrWhitespace,
        autoFocus: true
      },
      {
        key: (input) => input.type,
        label: T.ectoplanner.newtype,
        modelType: ModelType.CHECKBOX_OPTIONS,
        onDidUpdate: (_name, value, lastInput) => {
          if (
            lastInput.description !== T.ectoplanner.newbuildname &&
            lastInput.description !== T.ectoplanner.newprojectname
          ) {
            return [];
          }

          if (value === CreateFormProjectTypes.Version) {
            return [[(input) => input.description, T.ectoplanner.newbuildname]];
          }

          return [[(input) => input.description, T.ectoplanner.newprojectname]];
        },
        options: _.compact([
          params.projectId != null && {
            label: T.ectoplanner.newtypes.version,
            value: CreateFormProjectTypes.Version
          },
          params.projectId != null && {
            label: T.ectoplanner.newtypes.copyproject,
            value: CreateFormProjectTypes.CopyProject
          },
          {
            label: T.ectoplanner.newtypes.blankproject,
            value: CreateFormProjectTypes.BlankProject
          }
        ]),
        helpText: (value: string) => {
          return value === CreateFormProjectTypes.BlankProject
            ? undefined
            : sourceHelpText;
        }
      }
    ]);
  }, [params.projectId, projectName, selectedBuild]);

  const onChangeValue = useCallback(
    (newValue: OptionWithIcon) => {
      history.push(
        getEctoplannerUrl(
          tenantId,
          params.projectId,
          params.buildId,
          newValue.value
        )
      );
    },
    [history, tenantId, params.projectId, params.buildId]
  );

  const exportOptions: DropdownButtonOptionType[] = useMemo(() => {
    return _.compact([
      {
        label: T.ectoplanner.exportproject,
        icon: <Icons.File />,
        action: () => exportMutation.mutate()
      },
      currentWeatherStationId != null && {
        label: T.ectoplanner.exportairtemp,
        icon: <Icons.Temperature />,
        action: () => {
          _.defer(() => {
            downloadBlobTextWithoutUTF8BOM(
              airTemp,
              'airTemp_' + currentWeatherStationId + '.txt'
            );
          });
        },
        isEnabled: airTemp != null
      },
      currentWeatherStationId != null && {
        label: T.ectoplanner.exportcitydata,
        icon: <Icons.Building />,
        action: () => {
          _.defer(() => {
            downloadBlobTextWithoutUTF8BOM(
              JSON.stringify(cityData, null, 2),
              'cityData_' + currentWeatherStationId + '.json'
            );
          });
        },
        isEnabled: cityData != null
      },
      {
        label: T.ectoplanner.exportformdata,
        icon: <Icons.File />,
        action: () => {
          _.defer(() => {
            downloadBlobTextWithoutUTF8BOM(
              JSON.stringify(form, null, 2),
              'formdata.json'
            );
          });
        },
        isEnabled: cityData != null
      }
    ]);
  }, [airTemp, cityData, currentWeatherStationId, exportMutation, form]);

  const toolbarItems = useMemo(() => {
    let addText = T.common.add + '...';

    return (
      <>
        <ToolbarItem>
          <ToolbarMenu>
            <ToolbarMenuButton
              title={addText}
              onClick={() => {
                setNewBuild({
                  description:
                    params.projectId === null
                      ? T.ectoplanner.newprojectname
                      : T.ectoplanner.newbuildname,
                  type:
                    params.projectId == null
                      ? CreateFormProjectTypes.BlankProject
                      : CreateFormProjectTypes.Version,
                  id: null
                });
              }}
              icon={<Icons.Add />}
              tooltipText={addText}
            />
            <ToolbarMenuDivider />
            <ToolbarMenuButton
              icon={<Icons.Save />}
              disabled={
                !hasChanges ||
                saveMutation.isLoading ||
                params.projectId == null
              }
              onClick={save}
              tooltipText={T.common.save}
            />
            <ToolbarMenuButton
              icon={<Icons.Edit />}
              disabled={params.projectId == null}
              onClick={() => {
                setUpdateDescriptionRequest({
                  description: selectedBuild?.description ?? projectName,
                  id: isEditingProject ? params.projectId : params.buildId
                });
              }}
              tooltipText={T.ectoplanner.calculations.editdescription}
            />
            <ToolbarMenuButton
              icon={<Icons.Share />}
              disabled={params.projectId == null}
              onClick={showShareDialog}
              tooltipText={T.common.share}
            />
            <ToolbarMenuDropdownButton
              disabled={isLoading || params.projectId == null}
              options={exportOptions}
              tooltipText={T.ectoplanner.export}
            >
              <Icons.Download />
            </ToolbarMenuDropdownButton>
            <ToolbarMenuButton
              onClick={() => {
                const urlToClipboard =
                  window.location.origin +
                  getEctoplannerUrl(
                    tenantId,
                    params.projectId,
                    params.buildId,
                    'calculations'
                  );
                navigator.clipboard.writeText(urlToClipboard);
                toastStore.addSuccessToast(T.common.copytoclipboard.success);
              }}
              icon={<Icons.Email />}
              tooltipText={T.ectoplanner.calculations.copylinktoclipboard}
              disabled={params.projectId == null}
            />

            <ToolbarMenuDivider />
            <ToolbarMenuButton
              onClick={showDeleteDialog}
              icon={<Icons.Delete />}
              tooltipText={T.common.delete}
              disabled={selectedBuild == null && params.projectId == null}
            />
          </ToolbarMenu>
        </ToolbarItem>
        {params.projectId != null && (
          <ToolbarItem expanding>
            <CollapsingSegmentControlPicker
              options={sections}
              value={sections[curSection]}
              onChangeValue={onChangeValue}
            />
          </ToolbarItem>
        )}

        <ToolbarItem>
          <Button
            loading={calculationIsLoading || buildMutation.isLoading}
            disabled={calculationDisabled || params.projectId == null}
            onClick={calculate}
          >
            <Icons.Calculator />
            {T.ectoplanner.calculate}
          </Button>
        </ToolbarItem>
      </>
    );
  }, [
    hasChanges,
    saveMutation.isLoading,
    params.projectId,
    params.buildId,
    save,
    showShareDialog,
    isLoading,
    exportOptions,
    showDeleteDialog,
    selectedBuild,
    sections,
    curSection,
    onChangeValue,
    calculationIsLoading,
    buildMutation.isLoading,
    calculationDisabled,
    calculate,
    projectName,
    isEditingProject,
    tenantId
  ]);

  const [projectTreeOpen, setProjectTreeOpen] = useState(true);

  const setProjectOpenWithResize = useCallback((newIsOpen: boolean) => {
    setProjectTreeOpen(newIsOpen);
    _.defer(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }, []);

  const navTitle = (
    <div className={styles.navTitle}>
      <CopyToClipboardTooltip valueToCopy={projectName}>
        <label>{projectName}</label>
      </CopyToClipboardTooltip>
    </div>
  );

  return (
    <ToolbarContentPage
      wrapContent={
        params.projectId != null && params.section !== 'calculations'
      }
      title={
        <>
          <NavLinkFix to={getEctoplannerUrl(tenantId, null, null)}>
            {T.ectoplanner.title}
          </NavLinkFix>{' '}
        </>
      }
      navigationTree={
        projectTreeOpen && (
          <EctoplannerNavigationTree
            builds={allBuilds}
            allProjects={allProjects}
            isLoadingBuildsForProject={isLoadingBuildsForProject}
            changedForms={changedForms}
          />
        )
      }
      navigationControl={
        allProjects.length === 0 ? undefined : (
          <div className={styles.versionContainer}>
            <ToolbarNavControl
              title={navTitle}
              icon={params.projectId != null && <Icons.File />}
              subtitle={
                selectedBuild?.description ??
                (params.projectId != null &&
                  isEditingProject &&
                  T.ectoplanner.currentversion)
              }
              isOpen={projectTreeOpen}
              setIsOpen={setProjectOpenWithResize}
            />
          </div>
        )
      }
      showLocationPicker={false}
      toolbarItems={toolbarItems}
    >
      {allProjects.length === 0 && !isLoadingProjects && (
        <NoDataMessage message={T.ectoplanner.noprojectshelp} />
      )}
      {((isLoadingForm && params.section !== 'calculations') ||
        isLoadingProjects) && <Spinner />}
      {(!isLoading || form != null) &&
        params.projectId != null &&
        validationComponent}

      {params.projectId != null && (
        <EctoplannerGraphStoreContext.Provider
          value={storeRef ?? emptyEctoplannerGraphStore}
        >
          {sections[curSection].view}
        </EctoplannerGraphStoreContext.Provider>
      )}

      <ModelFormDialog
        isSavingInput={
          addVersionMutation.isLoading || addProjectMutation.isLoading
        }
        addTitle={(input) =>
          input?.type === CreateFormProjectTypes.Version
            ? T.ectoplanner.addnewversion
            : T.ectoplanner.form.shared.addproject
        }
        actionText={T.common.add}
        input={newBuild}
        onModalClose={clearNewBuild}
        saveAsArray={false}
        models={newBuildModels}
        saveInput={confirmAddNewVersion}
      />
      <EctoplannerFormErrorsModal
        isOpen={showingErrorDialog}
        onModalClose={hideErrorDialog}
        formErrorSections={formErrorSections}
      />
      <ConfirmDeleteDialog
        onModalClose={hideDeleteDialog}
        isOpen={isShowingDeleteDialog}
        itemName={T.ectoplanner.calculations.genericbuildname}
        onDelete={confirmDelete}
        isLoading={
          deleteBuildMutation.isLoading || deleteProjectMutation.isLoading
        }
      >
        {isEditingProject
          ? T.ectoplanner.confirmdeleteproject
          : T.ectoplanner.confirmdeleteversion}
      </ConfirmDeleteDialog>

      <ModelFormDialog<SetBuildDescriptionRequestWithId, false>
        title={T.ectoplanner.calculations.editdescription}
        onModalClose={() => setUpdateDescriptionRequest(null)}
        input={updateDescriptionRequest}
        models={buildModels}
        saveAsArray={false}
        saveInput={(newInput) => {
          if (buildIdIsNullOrMaster(buildId)) {
            editProjectNameMutation.mutate({
              name: newInput.description
            });
          } else {
            editBuildDescriptionMutation.mutate({
              description: newInput.description
            });
          }
        }}
        isLoading={
          editBuildDescriptionMutation.isLoading ||
          editProjectNameMutation.isLoading
        }
      />

      <ShareEctoplannerProjectModal
        onModalClose={hideShareDialog}
        isOpen={isShowingShareDialog}
        projectName={projectName}
      />
    </ToolbarContentPage>
  );
};

export default EditEctoplannerProject;
