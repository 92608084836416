import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import EditEctoplannerProject, {
  defaultGetEctoplannerBuildArgs
} from 'js/components/Ectoplanner/EditEctoplannerProject';
import { Prompt, matchPath, useHistory, useParams } from 'react-router-dom';
import {
  EctoplannerParams,
  EctoplannerRoute,
  ectoplannerMasterBuildId,
  getEctoplannerUrl
} from 'js/utils/routeConstants';
import EctoplannerAPIGen, {
  BuildResponse,
  BuildResponseListResponse,
  ProjectResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import T from 'ecto-common/lib/lang/Language';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import _ from 'lodash';
import { insertEctoplannerDefaultValues } from './EctoplannerModelUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import {
  BuildEvent,
  useBuildUpdates
} from 'js/components/Ectoplanner/useBuildUpdates';

const emptyProjects: ProjectResponse[] = [];
const emptyBuilds: BuildResponse[] = [];
const ECTOPLANNER_LAST_PROJECT = 'ectoplanner_last_project';

const useRememberLastProject = () => {
  const params = useParams<EctoplannerParams>();
  const ref = useRef(params.projectId);
  if (ref.current !== params.projectId) {
    ref.current = params.projectId;
    localStorage.setItem(ECTOPLANNER_LAST_PROJECT, params.projectId);
  }
};

const EctoplannerProjectContent = ({
  projectId,
  buildId
}: {
  projectId: string;
  buildId: string;
}) => {
  const allProjectsQuery =
    EctoplannerAPIGen.EctoGridProjects.listProjects.useQuery({});

  useRememberLastProject();

  const weatherCountriesQuery =
    EctoplannerAPIGen.Weather.countriesList.useQuery(
      {},
      {
        refetchOnWindowFocus: false
      }
    );
  const { contextSettings } = useContext(TenantContext);
  const [changedForms, setChangedForms] = useState<Record<string, boolean>>({});
  const [migratedForms, setMigratedForms] = useState<Record<string, boolean>>(
    {}
  );

  const [projectVersionStore, setProjectVersionStore] = useState<
    Record<string, EctoplannerForm>
  >({});
  const params = useParams<EctoplannerParams>();
  let versionId =
    params.buildId != null && params.buildId !== ectoplannerMasterBuildId
      ? params.buildId
      : params.projectId;

  const setForm: Dispatch<SetStateAction<EctoplannerForm>> = useCallback(
    (updater) => {
      if (_.isFunction(updater)) {
        setProjectVersionStore((oldStore) => ({
          ...oldStore,
          [versionId]: updater(oldStore[versionId])
        }));
      } else {
        setProjectVersionStore((oldStore) => ({
          ...oldStore,
          [versionId]: updater
        }));
      }
    },
    [versionId]
  );

  const setFormFromUserInput: Dispatch<SetStateAction<EctoplannerForm>> =
    useCallback(
      (updater) => {
        setForm(updater);
        setChangedForms((oldChangedForms) => ({
          ...oldChangedForms,
          [versionId]: true
        }));
      },
      [setForm, versionId]
    );

  const [buildStatuses, setBuildStatuses] = useState<
    Record<string, [string, string]>
  >({});

  const queryClient = useQueryClient();

  const onRecalculate = useCallback(
    (buildResponse: BuildResponse) => {
      queryClient.setQueryData<BuildResponseListResponse>(
        [
          ...EctoplannerAPIGen.EctoGridProjects.buildsDetail.path(
            contextSettings,
            {
              projectId: params.projectId
            }
          ),
          defaultGetEctoplannerBuildArgs
        ],
        (oldData) => {
          let newItems = [...oldData.items];
          let existingIndex = oldData.items.findIndex(
            (item) => item.id === buildResponse.id
          );
          if (existingIndex === -1) {
            newItems.push(buildResponse);
          } else {
            newItems[existingIndex] = buildResponse;
          }

          return {
            ...oldData,
            items: newItems
          };
        }
      );
    },
    [contextSettings, params.projectId, queryClient]
  );

  useBuildUpdates(
    useCallback((event: BuildEvent) => {
      setBuildStatuses((oldStatuses) => ({
        ...oldStatuses,
        [event.buildId]: [event.status, new Date().toISOString()]
      }));
    }, []),
    null
  );

  const buildsQuery = EctoplannerAPIGen.EctoGridProjects.buildsDetail.useQuery(
    {
      projectId: projectId
    },
    defaultGetEctoplannerBuildArgs,
    {
      refetchOnWindowFocus: false
    }
  );

  const [visitedProjectIds, setVisitedProjectIds] = useState<string[]>(
    params.projectId ? [params.projectId] : []
  );

  if (params.projectId && !visitedProjectIds.includes(params.projectId)) {
    setVisitedProjectIds((oldVisitedProjectIds) => [
      ...oldVisitedProjectIds,
      params.projectId
    ]);
  }

  const builds: BuildResponse[] = useMemo(() => {
    const allBuilds = [...(buildsQuery.data?.items ?? emptyBuilds)];

    for (let visitedProjectId of visitedProjectIds) {
      if (visitedProjectId !== params.projectId) {
        const queryData = queryClient.getQueryData<BuildResponseListResponse>([
          ...EctoplannerAPIGen.EctoGridProjects.buildsDetail.path(
            contextSettings,
            { projectId: visitedProjectId }
          ),
          defaultGetEctoplannerBuildArgs
        ]);
        if (queryData != null) {
          allBuilds.push(...queryData.items);
        }
      }
    }

    let orderedBuilds = _.orderBy(allBuilds, 'created', 'desc');

    return orderedBuilds.map((build) => {
      let status = buildStatuses[build.id];
      if (status != null) {
        let [statusValue, lastUpdated] = status;
        return {
          ...build,
          status: statusValue,
          lastUpdated:
            lastUpdated > build.lastUpdated ? lastUpdated : build.lastUpdated
        };
      }
      return build;
    });
  }, [
    buildsQuery.data?.items,
    visitedProjectIds,
    params.projectId,
    queryClient,
    contextSettings,
    buildStatuses
  ]);

  const formData = projectVersionStore[versionId];

  const getFormQuery = useQuery(
    ['EctoplannerForm', projectId, buildId],
    ({ signal }) => {
      let promise =
        buildId != null && buildId !== ectoplannerMasterBuildId
          ? EctoplannerAPIGen.EctoGridBuilds.formdataDetail.promise(
              contextSettings,
              {
                buildId
              },
              signal
            )
          : EctoplannerAPIGen.EctoGridProjects.formdataDetail.promise(
              contextSettings,
              { projectId: projectId },
              signal
            );

      return promise.catch((error) => {
        if (error?.response.status === HttpStatus.NOT_FOUND) {
          return {};
        }

        throw error;
      });
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: formData == null
    }
  );

  if (projectVersionStore[versionId] == null && getFormQuery.data != null) {
    const [loadedForm, loadedFormHasChanges] = insertEctoplannerDefaultValues(
      getFormQuery.data as unknown as EctoplannerForm
    );

    setProjectVersionStore((oldStore) => ({
      ...oldStore,
      [versionId]: loadedForm
    }));
    setMigratedForms((oldChangedProjects) => ({
      ...oldChangedProjects,
      [versionId]: loadedFormHasChanges
    }));
  }

  const clearHasChanges = useCallback(() => {
    setMigratedForms((oldChangedProjects) => ({
      ...oldChangedProjects,
      [versionId]: false
    }));

    setChangedForms((oldChangedProjects) => ({
      ...oldChangedProjects,
      [versionId]: false
    }));
  }, [versionId]);

  const project = _.find(allProjectsQuery.data?.items, { id: projectId });
  const history = useHistory();
  const { tenantId } = useContext(TenantContext);

  const allProjects = useMemo(() => {
    return sortByLocaleCompare(
      _.filter(
        allProjectsQuery.data?.items ?? emptyProjects,
        (x) =>
          x.projectType == null ||
          x.projectType === '' ||
          x.projectType === 'ectoplanner'
      ),
      'name'
    );
  }, [allProjectsQuery.data?.items]);

  if (allProjects.length > 0 && params.projectId == null) {
    let projectToSwitch = allProjects[0];
    let lastProjectId = localStorage.getItem(ECTOPLANNER_LAST_PROJECT);
    if (lastProjectId != null) {
      projectToSwitch =
        allProjects.find((otherProject) => otherProject.id === lastProjectId) ??
        allProjects[0];
    }

    history.push(getEctoplannerUrl(tenantId, projectToSwitch.id));
  }

  const someProjectHasChanges = _.some(changedForms);

  if (weatherCountriesQuery.data != null) {
    return (
      <>
        <EditEctoplannerProject
          projectName={project?.name}
          changedForms={changedForms}
          allProjects={allProjects}
          weatherCountries={weatherCountriesQuery.data.items}
          form={formData}
          isLoadingProjects={allProjectsQuery.isLoading}
          isLoadingBuildsForProject={buildsQuery.isLoading}
          isLoadingForm={formData == null && getFormQuery.isLoading}
          setForm={setForm}
          setFormFromUserInput={setFormFromUserInput}
          clearHasChanges={clearHasChanges}
          hasChanges={
            changedForms[versionId] === true ||
            migratedForms[versionId] === true
          }
          allBuilds={builds}
          onRecalculate={onRecalculate}
        />
        <Prompt
          message={(info) => {
            const newParams = matchPath<EctoplannerParams>(
              info.pathname,
              EctoplannerRoute
            )?.params;

            return newParams == null && someProjectHasChanges
              ? T.admin.form.unsavedstate
              : true;
          }}
        />
      </>
    );
  } else if (
    allProjectsQuery.isLoading ||
    weatherCountriesQuery.isLoading ||
    getFormQuery.isLoading ||
    buildsQuery.isLoading
  ) {
    return <LoadingContainer isLoading />;
  } else if (
    allProjectsQuery.error != null ||
    weatherCountriesQuery.error != null ||
    getFormQuery.error != null ||
    buildsQuery.error != null
  ) {
    return <ErrorNotice>{T.ectoplanner.failedtoloadproject} </ErrorNotice>;
  }
};

const EctoplannerPage = () => {
  const params = useParams<EctoplannerParams>();
  return (
    <EctoplannerProjectContent
      projectId={params.projectId}
      buildId={params.buildId}
    />
  );
};

export default EctoplannerPage;
