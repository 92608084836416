import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import T from 'ecto-common/lib/lang/Language';
import React, { useCallback, useMemo, useState } from 'react';
import AddLogButton from './AddLogButton';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { ActivityLogType } from 'ecto-common/lib/API/APIGen';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import { ActivityLogTypeTranslations } from 'js/containers/ActivityLogTypeTranslations';
import _ from 'lodash';
import { SortDirectionType } from 'ecto-common/lib/DataTable/SortDirection';
import { findInTree } from 'ecto-common/lib/utils/locationUtils';

export type SearchType = {
  page?: number | string;
  orderBy?: string;
  sortDirection?: SortDirectionType;
  searchString?: string;
};

type UseLogViewToolbarProps = {
  nodeId: string;
  search?: SearchType;
  onSearchChanged?: (search: SearchType) => void;
  enableLogTypeSelection?: boolean;
  enableAdd?: boolean;
};

type UseLogViewToolbarResult = {
  onSortChange: (orderBy: string, sortDirection: SortDirectionType) => void;
  selectedActityLogTypes: ActivityLogType[];
  currentPage: number;
  orderBy: string;
  sortDirection: SortDirectionType;
  searchString: string;
  toolbarItems: React.ReactNode;
};

const useLogViewToolbar = ({
  nodeId,
  search,
  onSearchChanged,
  enableLogTypeSelection = true,
  enableAdd = true
}: UseLogViewToolbarProps): UseLogViewToolbarResult => {
  const nodeTree = useOperatorSelector((state) => state.general.nodeTree);
  const enums = useOperatorSelector((state) => state.general.enums);
  const activityLogTypeOptions: GenericSelectOption<ActivityLogType>[] =
    useMemo(() => {
      return enums.activityLogType.map((value) => ({
        value,
        label: ActivityLogTypeTranslations[value]
      }));
    }, [enums.activityLogType]);

  const updateSeach = useCallback(
    (newSearch: SearchType) => {
      onSearchChanged?.({ ...search, ...newSearch });
    },
    [onSearchChanged, search]
  );

  const onSortChange = useCallback(
    (orderBy: string, sortDirection: SortDirectionType) => {
      updateSeach({
        orderBy,
        sortDirection
      });
    },
    [updateSeach]
  );

  const onSearchInput = useCallback(
    (text: string) => {
      updateSeach({
        searchString: text || undefined
      });
    },
    [updateSeach]
  );

  const { page, orderBy, sortDirection, searchString } = search;
  const currentPage = _.parseInt((page as string) ?? '0') ?? 0;

  const node = nodeId && findInTree(nodeTree, (item) => item.nodeId === nodeId);

  const [selectedActityLogTypes, setSelectedActivityLogTypes] = useState<
    ActivityLogType[]
  >([]);

  const onActivityLogTypesChange = useCallback(
    (options: GenericSelectOption<ActivityLogType>[]) => {
      setSelectedActivityLogTypes(options.map((option) => option.value));
    },
    []
  );

  const selectedOptions = useMemo(
    () =>
      activityLogTypeOptions.filter((option) =>
        _.some(
          selectedActityLogTypes,
          (selectedType) => selectedType === option.value
        )
      ),
    [activityLogTypeOptions, selectedActityLogTypes]
  );

  const toolbarItems = useMemo(
    () => (
      <>
        <ToolbarFlexibleSpace />
        {enableLogTypeSelection && (
          <ToolbarItem>
            <Select
              onChange={onActivityLogTypesChange}
              options={activityLogTypeOptions}
              placeholder={T.logtype.alltypes}
              value={selectedOptions}
              isMulti
            />
          </ToolbarItem>
        )}
        <ToolbarItem>
          <ToolbarSearch value={searchString} onChange={onSearchInput} />
        </ToolbarItem>
        {enableAdd && (
          <ToolbarItem>
            <AddLogButton
              disabled={!node || !(node as SingleGridNode).parentId}
              currentNodeId={nodeId}
            />
          </ToolbarItem>
        )}
      </>
    ),
    [
      activityLogTypeOptions,
      enableAdd,
      enableLogTypeSelection,
      node,
      nodeId,
      onActivityLogTypesChange,
      onSearchInput,
      searchString,
      selectedOptions
    ]
  );

  return {
    onSortChange,
    selectedActityLogTypes,
    currentPage,
    orderBy,
    sortDirection,
    searchString,
    toolbarItems
  };
};

export default useLogViewToolbar;
