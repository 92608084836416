import _ from 'lodash';
import {
  convertToGraphValue,
  IMPACT_RANGE,
  impactRangeData
} from '../utils/signals';
import colors from 'ecto-common/lib/styles/variables/colors';
import { formatNumberUnit, Unit } from 'ecto-common/lib/utils/stringUtils';
import { yAxisFormatter } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import { SignalValueResponseModel3 } from 'ecto-common/lib/API/APIGen';
import { Moment } from 'moment';

const createConfig = (
  start: Moment,
  end: Moment,
  data: SignalValueResponseModel3[],
  loading: boolean
): Highcharts.Options => {
  // 10% of full range for margin for the graph
  const rangeMarginDiff = (IMPACT_RANGE.max - IMPACT_RANGE.min) * 0.01;

  const rangeMargin = {
    max: IMPACT_RANGE.max + rangeMarginDiff,
    min: IMPACT_RANGE.min - rangeMarginDiff
  };

  const lineWidth = 2;
  const gridLineWidth = 0.5;
  const outsideAreaColor = '#ea1c0a20';
  const rangeOptions: Highcharts.YAxisPlotLinesOptions = {
    color: colors.failureColorTransparent,
    width: lineWidth,
    zIndex: 1,
    dashStyle: 'Dash'
  };

  const dateRangeOptions = {
    color: colors.darkSide2ColorTransparent,
    width: 2
  };
  const noData = _.isEmpty(data) && !loading;
  const series = _.map(data, convertToGraphValue);
  const startDateXAxisValue = new Date(start.toDate()).getTime();
  const endDateXAxisValue = new Date(end.toDate()).getTime();

  const impactedRange = impactRangeData(data);

  const isInsideMaxRange = impactedRange.max > IMPACT_RANGE.max;
  const isInsideMinRange = impactedRange.min < IMPACT_RANGE.min;
  return {
    tooltip: {
      headerFormat: '{point.key}',
      pointFormatter: function () {
        return formatNumberUnit(this.y, Unit.CELCIUS);
      },
      shared: true
    },
    annotations: [
      {
        visible: true,
        labelOptions: {
          backgroundColor: colors.semiTransparentWhite,
          verticalAlign: 'top',
          y: 10
        },
        labels: [
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: new Date(impactedRange.maxTime).getTime(),
              y: impactedRange.max
            },
            text: 'max'
          },
          {
            point: {
              xAxis: 0,
              yAxis: 0,
              x: new Date(impactedRange.minTime).getTime(),
              y: impactedRange.min
            },
            text: 'min'
          }
        ]
      }
    ],
    xAxis: {
      type: 'datetime',
      lineWidth: noData ? 0 : gridLineWidth,
      ordinal: false,

      plotLines: [
        {
          value: startDateXAxisValue,
          ...dateRangeOptions
        },
        {
          value: endDateXAxisValue,
          ...dateRangeOptions
        }
      ]
    },
    loading: {
      hideDuration: 300,
      showDuration: 300
    },
    yAxis: {
      max: rangeMargin.max,
      min: rangeMargin.min,
      ceiling: rangeMargin.max,
      floor: rangeMargin.min,
      crosshair: false,
      gridLineWidth: noData ? 0 : gridLineWidth,
      minorGridLineWidth: noData ? 0 : gridLineWidth,
      plotBands: noData
        ? []
        : _.compact([
            isInsideMaxRange && {
              from: rangeMargin.max,
              to: 10,
              color: outsideAreaColor
            },
            isInsideMinRange && {
              from: -10,
              to: rangeMargin.min,
              color: outsideAreaColor
            }
          ]),
      labels: {
        formatter: function () {
          if (
            _.inRange(
              _.isNumber(this.value) ? this.value : _.parseInt(this.value),
              IMPACT_RANGE.min - 0.0001,
              IMPACT_RANGE.max + 0.0001
            )
          ) {
            return yAxisFormatter(this);
          }
        },
        align: 'right',
        x: -10,
        y: -4
      },
      plotLines: noData
        ? []
        : [
            { value: IMPACT_RANGE.min, ...rangeOptions },
            { value: IMPACT_RANGE.max, ...rangeOptions }
          ],
      title: {
        align: 'high',
        offset: -22,
        text: Unit.CELCIUS,
        rotation: 0,
        y: 10
      }
    },
    series: [
      {
        data: series,
        type: 'line',
        lineWidth: lineWidth,
        color: colors.accent1Color
      }
    ],
    chart: {
      margin: [5, 0, 33, 0],
      height: 180
    },
    legend: {
      enabled: false
    },
    navigator: {
      enabled: false
    }
  };
};

export default createConfig;
