import ArrayUtils from './arrayUtils';
import copData from '../data/copData';
import { round2Decimal } from './mathUtils';

const a = {
  networkOption: 'separate',
  buildings: [
    {
      name: 'Building 1',
      buildingType: 'office',
      buildingSubtype: 'office',
      checkCalcSpaceHeat: true,
      checkCalcDhw: true,
      checkCalcSpaceCool: true,
      checkCalcProcessCool: true,
      checkCalcPlugLoads: true,
      checkCalcEmob: true,
      spaceHeatProfileUploaded: false,
      dhwProfileUploaded: false,
      spaceCoolProfileUploaded: false,
      processCoolProfileUploaded: false,
      plugLoadsProfileUploaded: false,
      emobProfileUploaded: false,
      spaceHeatProfile: [],
      dhwProfile: [],
      spaceCoolProfile: [],
      processCoolProfile: [],
      plugLoadsProfile: [],
      emobProfile: []
    }
  ]
};

// Main calculation function
function calcBes(newBuilding, mainState) {
  // If demand not active, use zero array for calculation
  if (!newBuilding.checkCalcSpaceHeat) {
    newBuilding['spaceHeatProfile'] = ArrayUtils.zeros(8760);
  }
  if (!newBuilding.checkCalcDhw) {
    newBuilding['dhwProfile'] = ArrayUtils.zeros(8760);
  }
  if (!newBuilding.checkCalcSpaceCool) {
    newBuilding['spaceCoolProfile'] = ArrayUtils.zeros(8760);
  }
  if (!newBuilding.checkCalcProcessCool) {
    newBuilding['processCoolProfile'] = ArrayUtils.zeros(8760);
  }
  if (!newBuilding.checkCalcPlugLoads) {
    newBuilding['plugLoadsProfile'] = ArrayUtils.zeros(8760);
  }
  if (!newBuilding.checkCalcEmob) {
    newBuilding['emobProfile'] = ArrayUtils.zeros(8760);
  }

  // Separated networks
  if (mainState.networkOption === 'separate') {
    newBuilding = calcBesSeparate(newBuilding); //TODO: Delete the seperate calculations in the future.
  }

  // Low-ex network, the ectogrid networks.
  if (mainState.networkOption === 'lowEx') {
    if (newBuilding.useTransversal) {
      newBuilding = calcBesTransveral(newBuilding, mainState);
    } else {
      newBuilding = calcBesLowEx(newBuilding, mainState);
    }
  }
  return newBuilding;
}

// Calculation of building energy system (BES) for separate networks
function calcBesSeparate(newBuilding) {
  // Heat exchanger for heating
  let profileSumHeat = ArrayUtils.arraySum(
    newBuilding.spaceHeatProfile,
    newBuilding.dhwProfile
  );
  let maxVal = ArrayUtils.max(profileSumHeat);
  let sumVal = ArrayUtils.sum(profileSumHeat);
  newBuilding['hxHeatCap'] = Math.round(maxVal);
  newBuilding['hxHeatGen'] = Math.round(sumVal / 1000);
  newBuilding['heatImportProfile'] = ArrayUtils.roundArray(profileSumHeat, 1);

  // Heat exchanger for cooling
  let profileSumCool = ArrayUtils.arraySum(
    newBuilding.spaceCoolProfile,
    newBuilding.processCoolProfile
  );
  maxVal = ArrayUtils.max(profileSumCool);
  sumVal = ArrayUtils.sum(profileSumCool);
  newBuilding['hxCoolCap'] = Math.round(maxVal);
  newBuilding['hxCoolGen'] = Math.round(sumVal / 1000);
  newBuilding['coolImportProfile'] = ArrayUtils.roundArray(profileSumCool, 1);

  // Electricity import
  newBuilding['elImportProfile'] = ArrayUtils.roundArray(
    ArrayUtils.arraySum(newBuilding.plugLoadsProfile, newBuilding.emobProfile),
    2
  );

  return newBuilding;
}

// Calculation of building energy system (BES) for separate networks
function calcBesLowEx(newBuilding, mainState) {
  ////////////////////////////////////////////////
  // Summarize the raw demands profiles into heating, cooling, and electricity
  let profileSumHeat = ArrayUtils.arraySum(
    newBuilding.spaceHeatProfile,
    newBuilding.dhwProfile
  );
  let profileSumCool = ArrayUtils.arraySum(
    newBuilding.spaceCoolProfile,
    newBuilding.processCoolProfile
  );
  let profileSumEl = ArrayUtils.arraySum(
    newBuilding.plugLoadsProfile,
    newBuilding.emobProfile
  );

  newBuilding['heatDemandProfile'] = profileSumHeat;
  newBuilding['coolDemandProfile'] = profileSumCool;
  newBuilding['elDemandProfile'] = profileSumEl;
  ////////////////////////////////////////////////

  // Space heating (heat pump + heating rod)
  let heatGenHeatPump = []; // Generated heat by heat pump
  let elDemHeatPumpSh = []; // Electricity demand of heat pump
  let heatEvapHeatPumpSh = []; // Heat to evaporator of heat pump
  let elDemElHeater = []; // Electricity demand of electric heater
  let maxVal = ArrayUtils.max(newBuilding.spaceHeatProfile);
  let sumVal = ArrayUtils.sum(newBuilding.spaceHeatProfile);
  if (newBuilding.checkCalcSpaceHeat) {
    // Calculate COP profile
    let copProfile = calcHeatingCopProfile(
      Number(newBuilding.besLowExHpSpaceHeatSupplyTemp),
      Number(newBuilding.besLowExHpSpaceHeatCop),
      Number(newBuilding.besLowExHpSpaceHeatCarnotEta),
      newBuilding.heatPumpTypeCopSpaceHeat,
      newBuilding.besLowExCopSpaceHeat,
      newBuilding.besLowExHpSpaceHeatSupplyTempSliding,
      mainState
    );

    // Electric heating rod active
    if (newBuilding.besLowExElHeaterEnabled) {
      let elHeaterShLowExCap = Math.round(
        (Number(newBuilding.besLowExElHeaterMaxLoad) * maxVal) / 100
      );
      let hpShLowExCap = maxVal - elHeaterShLowExCap;
      for (let t = 0; t < 8760; t++) {
        heatGenHeatPump.push(
          Math.min(newBuilding.spaceHeatProfile[t], hpShLowExCap)
        );
        elDemHeatPumpSh.push(heatGenHeatPump[t] / copProfile[t]);
        heatEvapHeatPumpSh.push(heatGenHeatPump[t] - elDemHeatPumpSh[t]);
        elDemElHeater.push(
          newBuilding.spaceHeatProfile[t] - heatGenHeatPump[t]
        );
      }
      newBuilding['elHeaterShLowExCap'] = elHeaterShLowExCap; // in KW
      newBuilding['hpShLowExCap'] = hpShLowExCap;
      newBuilding['elHeaterShLowExGen'] =
        (sumVal - ArrayUtils.sum(heatGenHeatPump)) / 1000; // the space heating met by the electrical heating rod
    }
    // Only heat pump
    else {
      newBuilding['elHeaterShLowExCap'] = 0;
      newBuilding['elHeaterShLowExGen'] = 0;
      for (let t = 0; t < 8760; t++) {
        heatGenHeatPump.push(newBuilding.spaceHeatProfile[t]);
        elDemHeatPumpSh.push(heatGenHeatPump[t] / copProfile[t]);
        heatEvapHeatPumpSh.push(heatGenHeatPump[t] - elDemHeatPumpSh[t]);
        elDemElHeater.push(0);
      }
    }
    newBuilding['copProfileSpaceHeat'] = copProfile; // TODO
    newBuilding['hpShLowExCap'] = ArrayUtils.max(heatGenHeatPump);
    newBuilding['hpShLowExGen'] = ArrayUtils.sum(heatGenHeatPump) / 1000; // in MWh
    newBuilding['hpShLowExElDem'] = ArrayUtils.sum(elDemHeatPumpSh) / 1000; // in MWh
    newBuilding['hpShLowExScop'] = // Seasonal COP, or year-around COP
      newBuilding['hpShLowExGen'] / newBuilding['hpShLowExElDem'];
  } else {
    newBuilding['elHeaterShLowExCap'] = 0;
    newBuilding['elHeaterShLowExGen'] = 0;
    newBuilding['hpShLowExCap'] = 0;
    newBuilding['hpShLowExGen'] = 0;
    newBuilding['hpShLowExElDem'] = 0;
    newBuilding['hpShLowExScop'] = 0;
    newBuilding['copProfileSpaceHeat'] = null;
    heatEvapHeatPumpSh = ArrayUtils.zeros(8760);
    elDemHeatPumpSh = ArrayUtils.zeros(8760);
    elDemElHeater = ArrayUtils.zeros(8760);
    heatGenHeatPump = ArrayUtils.zeros(8760);
  }
  //console.log(ArrayUtils.sum(elDemHeatPumpSh) / 1000)

  ////////////////////////////////////////////////
  // Domestic hot water heat pump
  let elDemHeatPumpDhw = []; // Electricity demand of heat pump
  let heatEvapHeatPumpDhw = []; // Heat to evaporator of heat pump
  if (newBuilding.checkCalcDhw) {
    maxVal = ArrayUtils.max(newBuilding.dhwProfile);
    sumVal = ArrayUtils.sum(newBuilding.dhwProfile);

    // Calculate COP profile
    let copProfile = calcHeatingCopProfile(
      Number(newBuilding.besLowExHpDhwSupplyTemp),
      Number(newBuilding.besLowExHpDhwCop),
      Number(newBuilding.besLowExHpDhwCarnotEta),
      newBuilding.heatPumpTypeCopDhw,
      newBuilding.besLowExCopDhw,
      false, // No sliding supply temperature curve for dhw
      mainState
    );
    for (let t = 0; t < 8760; t++) {
      elDemHeatPumpDhw.push(newBuilding.dhwProfile[t] / copProfile[t]);
      heatEvapHeatPumpDhw.push(newBuilding.dhwProfile[t] - elDemHeatPumpDhw[t]);
    }
    newBuilding['copProfileDhw'] = copProfile;
    newBuilding['hpDhwLowExCap'] = maxVal;
    newBuilding['hpDhwLowExGen'] = sumVal / 1000; // MWh
    newBuilding['hpDhwLowExElDem'] = ArrayUtils.sum(elDemHeatPumpDhw) / 1000; // MWh
    newBuilding['hpDhwLowExScop'] =
      newBuilding['hpDhwLowExGen'] / newBuilding['hpDhwLowExElDem'];
  } else {
    newBuilding['hpDhwLowExCap'] = 0;
    newBuilding['hpDhwLowExGen'] = 0;
    newBuilding['hpDhwLowExElDem'] = 0;
    newBuilding['hpDhwLowExScop'] = 0;
    newBuilding['copProfileDhw'] = null;
    heatEvapHeatPumpDhw = ArrayUtils.zeros(8760);
    elDemHeatPumpDhw = ArrayUtils.zeros(8760);
  }

  // Sum import profiles of space heating and domestic hot water
  let grossHeatImportProfile = ArrayUtils.arraySum(
    heatEvapHeatPumpSh,
    heatEvapHeatPumpDhw
  );
  let elDemHp = ArrayUtils.arraySum(elDemHeatPumpSh, elDemHeatPumpDhw); // The electricity used by heat pump
  let elDemHeatLowEx = ArrayUtils.arraySum(elDemHp, elDemElHeater); // The electricity used by heat pump and electrical heater
  // heat pump KPI that includes both space heating and DWH
  let hpGen = ArrayUtils.arraySum(newBuilding.dhwProfile, heatGenHeatPump); // The hp generated heating profile
  let elDemLowExHp = ArrayUtils.sum(elDemHp); // in kWh
  let hpLowExScop = null; //The overall COP of heat pump, include both DWT and Space Heating.
  if (elDemLowExHp > 0) {
    hpLowExScop =
      ArrayUtils.sum(hpGen) / // in kWh
      ArrayUtils.sum(elDemHp); // in kWh
  }
  newBuilding['elDemLowExHp'] = elDemLowExHp / 1000; // MWh
  newBuilding['hpLowExCap'] = ArrayUtils.max(hpGen); // kWh
  newBuilding['hpLowExGen'] = ArrayUtils.sum(hpGen) / 1000; // Mwh
  newBuilding['hpLowExScop'] = hpLowExScop; // accuracy two decimals

  // The overall heating profiles.
  if (ArrayUtils.sum(elDemHeatLowEx) > 0) {
    // if there are electricity consumption
    newBuilding['copProfileHeating'] = ArrayUtils.divArray(
      ArrayUtils.arraySum(newBuilding.spaceHeatProfile, newBuilding.dhwProfile),
      elDemHeatLowEx
    ); // the overall heating COP profile
  } else {
    // Otherwise, a null value
    newBuilding['copProfileHeating'] = null;
  }

  ////////////////////////////////////////////////
  // Cooling

  let elDemCoolLowEx = [];

  // First: Calculate decentral peak-load chiller with cooling tower, and reduce cooling profile accordingly
  maxVal = ArrayUtils.max(profileSumCool);
  sumVal = ArrayUtils.sum(profileSumCool);
  let coolGenBaseCooling = []; // Direct cooling, or chiller
  let coolGenPeakChiller = []; // Peak-load chiller

  if (newBuilding.besLowExPeakChillerEnabled) {
    let peakChillerLowExCap =
      Math.round(Number(newBuilding.besLowExPeakChillerMaxLoad) * maxVal) / 100;
    let baseCoolLowExCap = maxVal - peakChillerLowExCap;

    for (let t = 0; t < 8760; t++) {
      coolGenBaseCooling.push(Math.min(profileSumCool[t], baseCoolLowExCap));
      coolGenPeakChiller.push(profileSumCool[t] - coolGenBaseCooling[t]);
      elDemCoolLowEx.push(
        coolGenPeakChiller[t] / newBuilding.besLowExPeakChillerCop
      );
    }
    newBuilding['peakChillerLowExCap'] = peakChillerLowExCap;
    newBuilding['peakChillerLowExGen'] =
      ArrayUtils.sum(coolGenPeakChiller) / 1000;
    newBuilding['peakChillerLowExElDEm'] =
      ArrayUtils.sum(elDemCoolLowEx) / 1000;
    newBuilding['peakChillerLowExScop'] =
      newBuilding['peakChillerLowExGen'] / newBuilding['peakChillerLowExElDEm'];

    // Update cooling demand profile: For further calculation, only the proportion that is not covered by the peak-load chiller
    profileSumCool = coolGenBaseCooling;
  } else {
    elDemCoolLowEx = ArrayUtils.zeros(8760);
    newBuilding['peakChillerLowExCap'] = 0;
    newBuilding['peakChillerLowExGen'] = 0;
    newBuilding['peakChillerLowExElDEm'] = 0;
    newBuilding['peakChillerLowExScop'] = 0;
  }

  // Second: Calculate direct cooling or low-ex-chiller
  maxVal = ArrayUtils.max(profileSumCool);
  sumVal = ArrayUtils.sum(profileSumCool);

  let grossCoolImportProfile = [];
  newBuilding['drcLowExCap'] = 0;
  newBuilding['drcLowExGen'] = 0;
  // Calculate COP profile
  let copProfile = calcCoolingCopProfile(
    Number(newBuilding.besLowExCcSupplyTemp),
    Number(newBuilding.besLowExCcCop),
    Number(newBuilding.besLowExCcCarnotEta),
    newBuilding.chillerTypeCopCool,
    newBuilding.besLowExCopCool,
    mainState
  );

  // Chiller
  newBuilding['ccLowExCap'] = maxVal;
  let elDemChiller = []; // Electricity demand of chiller
  let heatCondChiller = []; // Heat from condenser of chiller
  for (let t = 0; t < 8760; t++) {
    elDemChiller.push(profileSumCool[t] / copProfile[t]);
    heatCondChiller.push(profileSumCool[t] + elDemChiller[t]);
  }
  newBuilding['ccLowExGen'] = sumVal / 1000;
  newBuilding['ccLowExElDem'] = ArrayUtils.sum(elDemChiller) / 1000;
  newBuilding['ccLowExScop'] =
    newBuilding['ccLowExGen'] / newBuilding['ccLowExElDem'];

  grossCoolImportProfile = heatCondChiller;
  elDemCoolLowEx = ArrayUtils.arraySum(elDemChiller, elDemCoolLowEx);

  // The overall cooling profiles.
  if (newBuilding['ccLowExElDem'] + newBuilding['peakChillerLowExElDEm'] > 0) {
    // if there is electricity consumption
    newBuilding['copProfileCooling'] = ArrayUtils.divArray(
      ArrayUtils.arraySum(
        newBuilding.spaceCoolProfile,
        newBuilding.processCoolProfile
      ),
      elDemCoolLowEx
    );
  } else {
    // Otherwise, a null value
    newBuilding['copProfileCooling'] = null;
  }

  ////////////////////////////////////////////////
  newBuilding['copCombinedHeatAndCool'] = null;
  //console.log(newBuilding['copCombinedHeatAndCool']);

  ////////////////////////////////////////////////
  // Electricity KPIs
  const elDemBldg = ArrayUtils.arraySum(
    newBuilding.plugLoadsProfile,
    newBuilding.emobProfile
  );
  const elDemEquip = ArrayUtils.arraySum(elDemHeatLowEx, elDemCoolLowEx);
  newBuilding['elImportProfile'] = ArrayUtils.roundArray(
    ArrayUtils.arraySum(elDemEquip, elDemBldg),
    2
  );

  // Calculate net heat and cold from network (after internal building balancing)
  newBuilding = calcBldgBalancing(
    newBuilding,
    grossHeatImportProfile,
    grossCoolImportProfile
  );

  return newBuilding;
}

// Calculation of building energy system (BES) for ectogrid system
function calcBesTransveral(newBuilding, mainState) {
  /* Calculation steps:
        1. Include the scenarios of peak load reduction by additional equipment (electrical rod and air cooled chiller). This step will modify the demand profiles.
        2. Calculate the machine COP based on the modified demands profiles. 
        3. For each time step, compare the coolind demand and heating demand. This will decide if energy will be taken from or injected into ectogrid. 
        4. Get the max delivered heating. This will decide the capacity of the heat pump. 
      
      Assumptions:
        1. This function assumes that the profiles of space heating, DHW and cooling are arrays with 8760 values. This function can process the arrays of zero.   
      Known Issue:
        2. This method assumes in the first step that any demands above the predefined value will be met by the peak load device. However, In cases when both heating and cooling demands are 
            above the predefined value. It is a matter of dominance, i.e. one of the peak load device will not be needed. 
    */
  //////////////////////////////////////////////
  let profileSumHeat = ArrayUtils.arraySum(
    newBuilding.spaceHeatProfile,
    newBuilding.dhwProfile
  );
  let profileSumCool = ArrayUtils.arraySum(
    newBuilding.spaceCoolProfile,
    newBuilding.processCoolProfile
  );
  let profileSumEl = ArrayUtils.arraySum(
    newBuilding.plugLoadsProfile,
    newBuilding.emobProfile
  );

  newBuilding['heatDemandProfile'] = profileSumHeat;
  newBuilding['coolDemandProfile'] = profileSumCool;
  newBuilding['elDemandProfile'] = profileSumEl;
  ////////////////////////////////////////////////
  // Step 1: Calculate the energy delivered by the transversal HP, given the existence of peak load provider.
  // Space heating (heat pump + heating rod)
  let transversalHpHeatGen = []; // Generated heat supplied to radiators and hot water by the transveral heat pump
  let transversalHpSpaceHeatingGen = []; // Generated heat supplied to raditor by the transversal heat pump
  let transversalHpDhwGen = []; // Generated heat supplied to hot water by the transveral heat pump
  let elDemTransversalElHeaterProfile = []; // Electricity demand of electric heater with the transversal setup
  let maxVal = ArrayUtils.max(newBuilding.spaceHeatProfile);
  let sumVal = ArrayUtils.sum(newBuilding.spaceHeatProfile);
  let sumValDhw = ArrayUtils.sum(newBuilding.dhwProfile);
  //console.log(newBuilding.besTransversalElHeaterEnabled)
  //console.log(newBuilding.spaceHeatProfile)
  //console.log(maxVal)

  // The delivered Space Heating and delivered DHW by transversal heat pump
  // Electric heating rod active for space heating
  if (newBuilding.besTransversalElHeaterEnabled) {
    let elHeaterShTransversalCap =
      (Number(newBuilding.besTransersalElHeaterMaxLoad) * maxVal) / 100; // electrical heater capacity
    let transversalHpSpaceHeatingCap = maxVal - elHeaterShTransversalCap; // The transversal heat pump maximal space heating capacity
    for (let t = 0; t < 8760; t++) {
      transversalHpSpaceHeatingGen.push(
        Math.min(newBuilding.spaceHeatProfile[t], transversalHpSpaceHeatingCap)
      );
      transversalHpDhwGen.push(newBuilding.dhwProfile[t]); // assume all dhw should be met by transversal heat pump
      transversalHpHeatGen.push(
        transversalHpSpaceHeatingGen[t] + transversalHpDhwGen[t]
      );
      elDemTransversalElHeaterProfile.push(
        newBuilding.spaceHeatProfile[t] - transversalHpSpaceHeatingGen[t]
      );
    }
    newBuilding['elHeaterShTransversalCap'] = elHeaterShTransversalCap;
  }

  // Only transversal heat pump
  else {
    for (let t = 0; t < 8760; t++) {
      transversalHpSpaceHeatingGen.push(newBuilding.spaceHeatProfile[t]);
      transversalHpDhwGen.push(newBuilding.dhwProfile[t]);
      transversalHpHeatGen.push(
        newBuilding.spaceHeatProfile[t] + newBuilding.dhwProfile[t]
      );
      elDemTransversalElHeaterProfile.push(0);
    }
    newBuilding['elHeaterShTransversalCap'] = 0;
    newBuilding['elDemTransversalElHeater'] = 0;
  }

  // Make KPIs for the heating side
  newBuilding['transversalHpHeatGen'] = transversalHpHeatGen;
  newBuilding['transversalHpHeatGenMax'] = ArrayUtils.max(transversalHpHeatGen); // kWh
  newBuilding['transversalHpHeatGenSum'] =
    ArrayUtils.sum(transversalHpHeatGen) / 1000; //MWh
  newBuilding['transversalElHeaterGenSum'] =
    ArrayUtils.sum(elDemTransversalElHeaterProfile) / 1000; //MWh
  newBuilding['elDemTransversalElHeater'] =
    newBuilding['transversalElHeaterGenSum']; // assume the electrical heater efficiency is 100%
  newBuilding['transversalElHeaterGenMax'] = ArrayUtils.max(
    elDemTransversalElHeaterProfile
  );

  ////////////////////////////////////////////////
  // The delivered cooling
  maxVal = ArrayUtils.max(profileSumCool);
  sumVal = ArrayUtils.sum(profileSumCool);
  //console.log(sumVal)
  //console.log(profileSumCool)
  let transversalHpCoolGen = []; // Generated cool by the transversal HP supplied to cooling cycles.
  let coolGenTransversalPeakChiller = []; // Cooling provided by Peak-load chiller
  let elDemTransversalPeakChillerProfile = []; // The electricity demand of the peak chiller

  if (newBuilding.besTransversalPeakChillerEnabled) {
    let transveralPeakChillerCap =
      (Number(newBuilding.besTransversalPeakChillerMaxLoad) * maxVal) / 100;
    let transversalHpCoolingCap = maxVal - transveralPeakChillerCap;
    //console.log(transveralPeakChillerCap)
    //console.log(transversalHpCoolingCap)

    for (let t = 0; t < 8760; t++) {
      transversalHpCoolGen.push(
        Math.min(profileSumCool[t], transversalHpCoolingCap)
      );
      coolGenTransversalPeakChiller.push(
        profileSumCool[t] - transversalHpCoolGen[t]
      );
      elDemTransversalPeakChillerProfile.push(
        coolGenTransversalPeakChiller[t] /
          newBuilding.besTransversalPeakChillerCop
      );
    }
    newBuilding['transveralPeakChillerCap'] = Math.round(
      transveralPeakChillerCap
    );
    newBuilding['coolGenTransversalPeakChillerSum'] =
      ArrayUtils.sum(coolGenTransversalPeakChiller) / 1000;
    newBuilding['coolGenTransversalPeakChillerMax'] = ArrayUtils.max(
      coolGenTransversalPeakChiller
    );
    newBuilding['elDemTransversalPeakChiller'] =
      ArrayUtils.sum(elDemTransversalPeakChillerProfile) / 1000;
    newBuilding['transveralPeakChillerCop'] =
      newBuilding['coolGenTransversalPeakChillerSum'] /
      newBuilding['elDemTransversalPeakChiller'];
  } else {
    for (let t = 0; t < 8760; t++) {
      transversalHpCoolGen.push(profileSumCool[t]);
      coolGenTransversalPeakChiller.push(0);
      elDemTransversalPeakChillerProfile.push(0);
    }

    newBuilding['transveralPeakChillerCap'] = 0;
    newBuilding['coolGenTransversalPeakChillerSum'] = 0;
    newBuilding['coolGenTransversalPeakChillerMax'] = 0;
    newBuilding['elDemTransversalPeakChiller'] = 0;
    newBuilding['transveralPeakChillerCop'] = null;
  }

  // make KPIs for the cooling side
  newBuilding['transversalHpCoolGenMax'] = ArrayUtils.max(transversalHpCoolGen); // kWh
  newBuilding['transversalHpCoolGenSum'] =
    ArrayUtils.sum(transversalHpCoolGen) / 1000; //MWh

  ////////////////////////////////////////////////
  // Step 2: Calculate the transversal HP COP Profile
  // Calling the funciton defined below
  let copProfile = calcTransversalCopProfile(
    Number(newBuilding.besTransversalSpaceHeatSupplyTemp),
    newBuilding.besTransversalSpaceHeatSupplyTempSliding,
    Number(newBuilding.besTransversalDhwSupplyTemp),
    Number(newBuilding.besTransversalCoolSupplyTemp),
    transversalHpSpaceHeatingGen,
    transversalHpDhwGen,
    transversalHpCoolGen,
    Number(newBuilding.besTransversalHpConstCop),
    Number(newBuilding.besTransversalHpCarnotEta),
    newBuilding.besTransversalHpType,
    newBuilding.besTransversalHpCopType,
    mainState
  );

  ////////////////////////////////////////////////
  // Step 3: Compare the supplied heating and cooling profile, decide the gross energy from/to the ectogrid
  // For each time stamp, run the comparision
  let transversalHpElProfile = []; // The electricity demand of the transversal HP
  let currHeating = 0;
  let currCooling = 0;
  let currMachineCop = 0;
  let transversalBesHeatFromGrid = [];
  let transversalBesHeatToGrid = [];
  for (let t = 0; t < 8760; t++) {
    currHeating = transversalHpSpaceHeatingGen[t] + transversalHpDhwGen[t];
    currCooling = transversalHpCoolGen[t];
    currMachineCop = copProfile[t];

    if (currHeating / currMachineCop >= currCooling / (currMachineCop - 1)) {
      // The condition that heat dominates
      transversalHpElProfile.push(currHeating / currMachineCop);
      transversalBesHeatFromGrid.push(
        (currHeating / currMachineCop) * (currMachineCop - 1) - currCooling
      ); // need to take heat from ectogrid
      transversalBesHeatToGrid.push(0); // The otherside is zero
    } else {
      transversalHpElProfile.push(currCooling / (currMachineCop - 1));
      transversalBesHeatFromGrid.push(0); // no heat is taken from grid
      transversalBesHeatToGrid.push(
        (currCooling / (currMachineCop - 1)) * currMachineCop - currHeating
      ); // Heat is pushed to the ectogrid
    }
  }
  //console.log(transversalBesHeatFromGrid)
  //console.log(transversalBesHeatToGrid)
  // Electricity used by Transversal Heat pump, ancillary electricity is not included
  newBuilding['transversalHpElDem'] =
    ArrayUtils.sum(transversalHpElProfile) / 1000; //MWh

  ////////////////////////////////////////////////
  // Step 4: Decide the capacity of the heat pump
  // The transversal heat pump is decided by the heating side: the max delivery of heating to both the space heating and hot water.
  let transversalHpHeatSide = ArrayUtils.arraySum(
    transversalBesHeatToGrid,
    transversalHpHeatGen
  );
  newBuilding['transveralHpCap'] = ArrayUtils.max(transversalHpHeatSide);

  ////////////////////////////////////////////////
  // Step 5: Make KPIs, make sure the transversal HP calculations can be compatible
  newBuilding['grossHeatImportProfile'] = [];
  newBuilding['grossCoolImportProfile'] = [];
  newBuilding['heatImportProfile'] = ArrayUtils.roundArray(
    transversalBesHeatFromGrid,
    2
  );
  newBuilding['coolImportProfile'] = ArrayUtils.roundArray(
    transversalBesHeatToGrid,
    2
  );
  newBuilding['elImportProfile'] = ArrayUtils.roundArray(
    ArrayUtils.arraySum(
      transversalHpElProfile,
      elDemTransversalElHeaterProfile,
      elDemTransversalPeakChillerProfile,
      newBuilding.plugLoadsProfile,
      newBuilding.emobProfile
    ),
    2
  );

  // The transversal HP seasonal COP
  newBuilding['transversalHpScop'] =
    (newBuilding['transversalHpHeatGenSum'] +
      newBuilding['transversalHpCoolGenSum']) /
    newBuilding['transversalHpElDem'];

  // calculate the overall COP profiles.
  let energyDeviceElProfile = ArrayUtils.arraySum(
    transversalHpElProfile,
    elDemTransversalElHeaterProfile,
    elDemTransversalPeakChillerProfile
  );

  newBuilding['copProfileCooling'] = ArrayUtils.divArray(
    profileSumCool,
    energyDeviceElProfile
  );
  newBuilding['copProfileHeating'] = ArrayUtils.divArray(
    profileSumHeat,
    energyDeviceElProfile
  );
  newBuilding['copCombinedHeatAndCool'] = ArrayUtils.divArray(
    ArrayUtils.arraySum(profileSumCool, profileSumHeat),
    energyDeviceElProfile
  );

  //console.log(ArrayUtils.sum(transversalBesHeatToGrid))
  //console.log(newBuilding["heatImportProfile"])
  return newBuilding;
}

// Balancing in buildings
function calcBldgBalancing(
  newBuilding,
  grossHeatImportProfile,
  grossCoolImportProfile
) {
  let netHeatImportProfile = [];
  let netCoolImportProfile = [];
  let wasteHeatUsedProfile = [];

  // Loop over times steps
  for (let t = 0; t < 8760; t++) {
    if (grossHeatImportProfile[t] >= grossCoolImportProfile[t]) {
      netHeatImportProfile.push(
        grossHeatImportProfile[t] - grossCoolImportProfile[t]
      );
      netCoolImportProfile.push(0);
      wasteHeatUsedProfile.push(grossCoolImportProfile[t]);
    } else {
      netHeatImportProfile.push(0);
      netCoolImportProfile.push(
        grossCoolImportProfile[t] - grossHeatImportProfile[t]
      );
      wasteHeatUsedProfile.push(grossHeatImportProfile[t]);
    }
  }
  newBuilding['heatImportProfile'] = netHeatImportProfile;
  newBuilding['coolImportProfile'] = netCoolImportProfile;

  newBuilding['wasteHeatUsedLowEx'] =
    ArrayUtils.sum(wasteHeatUsedProfile) / 1000;
  newBuilding['docBldgLowEx'] =
    (2 * (newBuilding['wasteHeatUsedLowEx'] * 1000)) /
    (ArrayUtils.sum(grossHeatImportProfile) +
      ArrayUtils.sum(grossCoolImportProfile));

  newBuilding['grossHeatImportProfile'] = grossHeatImportProfile;
  newBuilding['grossCoolImportProfile'] = grossCoolImportProfile;
  2;

  return newBuilding;
}

// Calculate COP profile cooling
function calcCoolingCopProfile(
  supplyTemp,
  constCop,
  carnotEta,
  chillerType,
  calcMethod,
  mainState
) {
  let copProfile = [];
  if (calcMethod === 'const') {
    copProfile = ArrayUtils.createConstantArray(8760, constCop);
  } else if (mainState.netwTempProfileUploaded) {
    for (let t = 0; t < 8760; t++) {
      if (calcMethod === 'productData') {
        copProfile.push(
          Math.round(
            (copData.getProductDataCop(
              Number(mainState.netwTempProfile[t]),
              supplyTemp,
              chillerType,
              'chiller'
            ) -
              1) *
              100
          ) / 100
        );
      } else if (calcMethod === 'carnot') {
        copProfile.push(
          Math.round(
            (calcCarnotCop(
              Number(mainState.netwTempProfile[t]),
              supplyTemp,
              carnotEta / 100
            ) -
              1) *
              100
          ) / 100
        );
      }
    }
  } else {
    if (calcMethod === 'productData') {
      copProfile = ArrayUtils.createConstantArray(
        8760,
        Math.round(
          (copData.getProductDataCop(
            Number(mainState.meanNetwTemp),
            supplyTemp,
            chillerType,
            'chiller'
          ) -
            1) *
            100
        ) / 100
      );
    } else if (calcMethod === 'carnot') {
      copProfile = ArrayUtils.createConstantArray(
        8760,
        Math.round(
          (calcCarnotCop(
            Number(mainState.meanNetwTemp),
            supplyTemp,
            carnotEta / 100
          ) -
            1) *
            100
        ) / 100
      );
    }
  }
  return copProfile;
}

// Calculate COP profile transversal
// The transversal COP profile is dependent on the heat demand and dhw demands profils.
function calcTransversalCopProfile(
  heatSupplyTemp,
  slidingSupplyTemp,
  dhwSupplyTemp,
  coldSupplyTemp,
  heatSupplyProfile,
  dhwSupplyProfile,
  coolSupplyProfile,
  constCop,
  carnotEta,
  heatPumpType,
  calcMethod,
  mainState
) {
  let copProfile = [];
  // Constant COP
  if (calcMethod === 'const') {
    copProfile = ArrayUtils.createConstantArray(8760, constCop);

    // Variable COP
  } else {
    let currHeatSupplyTemp = 0;
    let currColdSupplyTemp = 0;
    let currDhwSupplyTemp = 0;
    let currWeightedHeatingSuppltTemp = 0;
    let currNetwTemp = 0;
    const minAirTemp = ArrayUtils.min(mainState.weatherAirTemp);
    let currGridTemp = { warmPipeTemp: 0, coldPipeTemp: 0 };

    for (let t = 0; t < 8760; t++) {
      // Get heating supply temperature (may depend on ambient air temperature)
      currHeatSupplyTemp = getCurrSupplyTempHeat(
        mainState.weatherAirTemp[t],
        heatSupplyTemp,
        slidingSupplyTemp,
        minAirTemp
      );

      // Get cooling supuply temeprature, fixed over the year
      currColdSupplyTemp = coldSupplyTemp;

      // Get the dhw supply temperature, fixed over the year
      currDhwSupplyTemp = dhwSupplyTemp;

      // Get network temperature
      currGridTemp = getCurrWarmColdPipeTemp(mainState, t);
      let currCondensorTemp = null;
      let currEvaporatorTemp = null;

      //console.log(currHeatSupplyTemp)
      //console.log(currGridTemp)
      // Decide the condensor temperature
      // when the load is too low (zero), consider as zeros, the weighted temperature should be the same as pipe
      if (heatSupplyProfile[t] + dhwSupplyProfile[t] <= 0.000001) {
        currCondensorTemp = currGridTemp.warmPipeTemp;
      } else {
        currWeightedHeatingSuppltTemp =
          (heatSupplyProfile[t] * currHeatSupplyTemp +
            dhwSupplyProfile[t] * currDhwSupplyTemp) /
          (heatSupplyProfile[t] + dhwSupplyProfile[t]);
        currCondensorTemp = Math.max(
          currWeightedHeatingSuppltTemp,
          currGridTemp.warmPipeTemp
        );
      }

      // Decide the evaporator temperature
      if (coolSupplyProfile[t] <= 0.000001) {
        currEvaporatorTemp = currGridTemp.coldPipeTemp;
      } else {
        currEvaporatorTemp = Math.min(
          currColdSupplyTemp,
          currGridTemp.coldPipeTemp
        );
      }
      //console.log(currCondensorTemp)
      //console.log(currEvaporatorTemp)

      // Calculate COP according to selected method
      if (calcMethod === 'productData') {
        copProfile.push(
          Math.round(
            copData.getProductDataCop(
              currCondensorTemp,
              currEvaporatorTemp,
              heatPumpType,
              'heat_pump'
            ) * 100
          ) / 100
        );
      } else if (calcMethod === 'carnot') {
        copProfile.push(
          Math.round(
            calcCarnotCop(
              currCondensorTemp,
              currEvaporatorTemp,
              carnotEta / 100
            ) * 100
          ) / 100
        );
      }
    }
  }

  return copProfile;
}

// Calculate COP profile heating
function calcHeatingCopProfile(
  supplyTemp,
  constCop,
  carnotEta,
  heatPumpType,
  calcMethod,
  slidingSupplyTemp,
  mainState
) {
  let copProfile = [];
  // Constant COP
  if (calcMethod === 'const') {
    copProfile = ArrayUtils.createConstantArray(8760, constCop);

    // Variable COP
  } else {
    let currSupplyTemp = 0;
    let currNetwTemp = 0;
    const minAirTemp = ArrayUtils.min(mainState.weatherAirTemp);

    for (let t = 0; t < 8760; t++) {
      // Get heating supply temperature (may depend on ambient air temperature)
      currSupplyTemp = getCurrSupplyTempHeat(
        mainState.weatherAirTemp[t],
        supplyTemp,
        slidingSupplyTemp,
        minAirTemp
      );

      // Get network temperature
      currNetwTemp = getCurrNetwTemp(mainState, t);

      // Calculate COP according to selected method
      if (calcMethod === 'productData') {
        copProfile.push(
          Math.round(
            copData.getProductDataCop(
              currSupplyTemp,
              currNetwTemp,
              heatPumpType,
              'heat_pump'
            ) * 100
          ) / 100
        );
      } else if (calcMethod === 'carnot') {
        copProfile.push(
          Math.round(
            calcCarnotCop(currSupplyTemp, currNetwTemp, carnotEta / 100) * 100
          ) / 100
        );
      }
    }
  }

  return copProfile;
}

// Calculate supply temperature (space heating) based on the ambient air temperature for current time stamp t
// Assumptions: T_supply is 25 °C when outdoor temperature is 15 °C;
//              T_supply is max when outdoor temperature is at (annual) minimum temperature
function getCurrSupplyTempHeat(
  currAirTemp,
  supplyTemp,
  slidingSupplyTemp,
  minAirTemp
) {
  if (slidingSupplyTemp) {
    const supplyTempMin = 25; // °C
    const airTempMax = 15; // °C
    if (currAirTemp > airTempMax) {
      return supplyTempMin;
    } else {
      return (
        supplyTempMin +
        ((supplyTemp - supplyTempMin) / (airTempMax - minAirTemp)) *
          (airTempMax - currAirTemp)
      );
    }
  } else {
    return supplyTemp;
  }
}

// Get network temperature of current time stamp t
function getCurrNetwTemp(mainState, t) {
  if (mainState.netwTempProfileUploaded) {
    return Number(mainState.netwTempProfile[t]);
  } else {
    return Number(mainState.meanNetwTemp);
  }
}

// Get network temperature of current time stamp t
function getCurrWarmColdPipeTemp(mainState, t) {
  if (mainState.netwTempProfileUploaded) {
    return {
      warmPipeTemp: Number(
        mainState.netwTempProfile[t] + mainState.netTempDiff / 2
      ),
      coldPipeTemp: Number(
        mainState.netwTempProfile[t] - mainState.netTempDiff / 2
      )
    };
  } else {
    return {
      warmPipeTemp: Number(mainState.warmPipeTemp),
      coldPipeTemp: Number(mainState.coldPipeTemp)
    };
  }
}

// Carnot calculation, machine COP
function calcCarnotCop(warmTemp, coldTemp, etaEx) {
  if (warmTemp < coldTemp) {
    return 16;
  } else {
    return Math.min((etaEx * (warmTemp + 273.15)) / (warmTemp - coldTemp), 16);
  }
}

const exportedFunctions = { calcBes, calcCarnotCop };
export default exportedFunctions;
