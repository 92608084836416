import React from 'react';
import styles from '../AcknowledgeButton.module.css';
import Button from 'ecto-common/lib/Button/Button';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { AlarmModel } from 'ecto-common/lib/API/AlarmsAPIGen';
import { AcknowledgeAlarmModalV2 } from 'ecto-common/lib/Alarms/V2/AcknowledgeAlarmModalV2';

type AcknowledgeButtonProps = {
  node: SingleGridNode;
  alarm: AlarmModel;
};

const AcknowledgeButtonV2 = ({ node, alarm }: AcknowledgeButtonProps) => {
  const [isAcknowledgeModalOpen, showAcknowledgeModal, hideAcknowledgeModal] =
    useDialogState(false);
  const isAcknowledged = alarm.acknowledgedAt != null;

  const { acknowledgedBy, acknowledgedComment, canAcknowledge } = alarm;

  if (isAcknowledged) {
    return (
      <div>
        <strong>{acknowledgedBy || T.common.unknown}</strong>
        {': '}
        {acknowledgedComment || <em>{T.alarms.acknowledgedwithoutcomment}</em>}
      </div>
    );
  }

  return (
    <div>
      <Button
        className={styles.button}
        onClick={showAcknowledgeModal}
        disabled={!canAcknowledge}
      >
        <Icons.Checkmark />
        {T.alarms.acknowledge.action}
      </Button>

      <AcknowledgeAlarmModalV2
        isOpen={isAcknowledgeModalOpen}
        onModalClose={hideAcknowledgeModal}
        node={node}
        alarm={alarm}
        onSuccess={hideAcknowledgeModal}
      />
    </div>
  );
};

export default AcknowledgeButtonV2;
