import React, { Dispatch, SetStateAction } from 'react';
import _ from 'lodash';
import SignalSelectorGeneric from 'ecto-common/lib/SignalSelector/SignalSelectorGeneric';
import styles from './ExportDataSignals.module.css';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import UUID from 'uuidjs';
import {
  FullSignalProviderResponseModel,
  SignalProviderSignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ExportOptionsType } from 'js/components/ExportData/ExportOptions';

type ExportDataSignalsProps = {
  options: ExportOptionsType;
  setOptions: Dispatch<SetStateAction<ExportOptionsType>>;
};

const ExportDataSignals = ({ options, setOptions }: ExportDataSignalsProps) => {
  const _addNewSignal = (
    signal: SignalProviderSignalResponseModel,
    signalProvider: FullSignalProviderResponseModel
  ) => {
    setOptions((oldOptions) => ({
      ...oldOptions,
      selectedSignals: oldOptions.selectedSignals.concat([
        {
          item: signal,
          chartSignalId: UUID.generate(),
          group: _.omit(signalProvider, 'signals'),
          parent: null
        }
      ])
    }));
  };

  const _onRemoveSignal = (signal: ChartSignal) => {
    setOptions((oldOptions) => ({
      ...oldOptions,
      selectedSignals: _.reject(oldOptions.selectedSignals, [
        'chartSignalId',
        signal.chartSignalId
      ])
    }));
  };

  const _onRemoveAllSignals = () => {
    setOptions((oldOptions) => ({
      ...oldOptions,
      selectedSignals: []
    }));
  };

  return (
    <SignalSelectorGeneric
      className={styles.signalSelector}
      nodeId={null}
      selectedSignals={options.selectedSignals}
      addNewSignal={_addNewSignal}
      onRemoveSignal={_onRemoveSignal}
      onRemoveAllSignals={_onRemoveAllSignals}
      setSelectedSignals={_.noop}
    />
  );
};

export default React.memo(ExportDataSignals);
