import { DashboardCollectionViewResponseModel } from 'ecto-common/lib/API/APIGen';
import { SET_DEFAULT_DASHBOARD_COLLECTION } from 'js/actions/actionTypes';

export function setDefaultDashboardCollection(
  collection: DashboardCollectionViewResponseModel
) {
  return {
    type: SET_DEFAULT_DASHBOARD_COLLECTION,
    payload: collection
  };
}
