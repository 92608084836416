import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import styles from './ProgressBar.module.css';
import { ObjectValues } from 'ecto-common/lib/utils/typescriptUtils';

export const ProgressBarSizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

export type ProgressBarSize = ObjectValues<typeof ProgressBarSizes>;

interface ProgressBarProps {
  /**
   * A value between 0 and 100 that determines the level of progress to show.
   */
  percentage: number;
  /**
   * Used to override the appearance of the progress bar container. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Used to override the appearance of the inner progress bar. Should be a valid CSS class name.*
   */
  fillerClassName?: string;

  /**
   * The size of the progress bar.
   */
  size?: ProgressBarSize;
}

/**
 * A progress bar can be used to show incrementally changing progress.
 */
const ProgressBar = ({
  percentage,
  className,
  fillerClassName,
  size = ProgressBarSizes.SMALL
}: ProgressBarProps) => {
  const [resetCounter, setResetCounter] = useState(0);

  useEffect(() => {
    if (percentage === 0) {
      setResetCounter((currentValue) => currentValue + 1);
    }
  }, [percentage]);

  let width = 0;
  let height = 0;

  switch (size) {
    case ProgressBarSizes.SMALL:
      width = 100;
      height = 10;
      break;
    case ProgressBarSizes.MEDIUM:
      width = 200;
      height = 15;
      break;
    case ProgressBarSizes.LARGE:
      width = 300;
      height = 20;
      break;
    default:
      break;
  }

  return (
    <div
      className={classNames(styles.progressBar, className)}
      style={{ width, height }}
    >
      <div
        className={classNames(styles.progressBarFiller, fillerClassName)}
        key={resetCounter}
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

export default ProgressBar;
