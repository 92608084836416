import React from 'react';
import AlarmView from 'ecto-common/lib/Alarms/AlarmView';
import { useOperatorSelector } from 'js/reducers/storeOperator';

const AlarmsPage = () => {
  const nodeId = useOperatorSelector((state) => state.general.nodeId);
  return <AlarmView nodeId={nodeId} />;
};

export default AlarmsPage;
