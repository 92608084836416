import { eventHubRetryPolicy } from 'ecto-common/lib/EventHubConnection/EventHubService';
import { useEffect } from 'react';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import _ from 'lodash';
import { handleMSALNeedsInteraction } from '../hooks/useAuthentication';
import { ApiContextSettings } from '../API/APIUtils';

type SimpleEventHubMethod = {
  method: string;
  handler: (data: unknown) => void;
};

// This hook can be used when connecting to an EventHub different from our main event hub.
// It is intended to be used on demand rather than an always-on hub like our EventHubService.
export const useSimpleEventHubConnection = (
  contextSettings: ApiContextSettings,
  onError: (err: unknown) => void,
  url: string,
  scopes: string[],
  msalConfiguration: IPublicClientApplication,
  currentAccount: AccountInfo,
  methods: SimpleEventHubMethod[]
) => {
  useEffect(() => {
    let stopping = false;
    let _lastToken: string = null;

    const accessTokenFactory = () => {
      const accessTokenRequest = { scopes: scopes, account: currentAccount };

      if (stopping) {
        // In this case we are disconnecting due to user logging out, if we trigger
        // acquireTokenSilent then the logout will be cancelled. Instead, try to use
        // the previously acquired token for the cleanup phase (the SDK will do another
        // call to the event hub to delete the connection properly)
        return Promise.resolve(_lastToken);
      }

      return msalConfiguration
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          _lastToken = response?.accessToken;
          return _lastToken ?? '';
        })
        .catch((error) => {
          handleMSALNeedsInteraction(
            error,
            msalConfiguration,
            accessTokenRequest
          );
          console.error(error);
          return '';
        });
    };

    const tenantId = contextSettings.tenantId;

    const connection = new HubConnectionBuilder()
      .withUrl(url + '?tenant_id=' + tenantId, { accessTokenFactory })
      .configureLogging(LogLevel.Critical)
      .withAutomaticReconnect(eventHubRetryPolicy)
      .build();

    for (let method of methods) {
      connection.on(method.method, (event) => {
        method.handler(event);
      });
    }

    connection.start().catch((err) => {
      console.error(err);
      onError?.(err);
    });

    return () => {
      stopping = true;
      connection.stop().then(_.noop);
    };
  }, [
    url,
    scopes,
    msalConfiguration,
    currentAccount,
    methods,
    onError,
    contextSettings.tenantId
  ]);
};
