import React, { useContext, useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { getNewSignalCollectionName } from 'js/modules/signalCollections/signalCollections';
import { SignalCollectionActions } from 'js/modules/signalCollections/signalCollections';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import ToolbarMenuDivider from 'ecto-common/lib/Toolbar/ToolbarMenuDivider';
import {
  useOperatorSelector,
  useOperatorDispatch
} from 'js/reducers/storeOperator';
import useUndoShortcuts from 'ecto-common/lib/hooks/useUndoShortcuts';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
interface GraphToolbarMenuProps {
  showExport(): void;
  showMinMaxSettings(): void;
  showSignalSelector(): void;
}

const GraphToolbarMenu = ({
  showExport,
  showMinMaxSettings,
  showSignalSelector
}: GraphToolbarMenuProps) => {
  const dispatch = useOperatorDispatch();
  const signalCollections = useOperatorSelector(
    (state) => state.signalCollections.signalCollections
  );
  const tempSignalCollections = useOperatorSelector(
    (state) => state.signalCollections.tempCollections
  );
  const changedSignalCollections = useOperatorSelector(
    (state) => state.signalCollections.changedSignalCollections
  );
  const signalCollectionActions = useMemo(
    () => bindActionCreators(SignalCollectionActions, dispatch),
    [dispatch]
  );
  const selectedSignalCollectionId = useOperatorSelector(
    (state) => state.signalCollections.selectedSignalCollectionId
  );
  const canUndo = useOperatorSelector(
    (state) => state.signalCollections.canUndo
  );
  const canRedo = useOperatorSelector(
    (state) => state.signalCollections.canRedo
  );
  const { contextSettings } = useContext(TenantContext);

  useUndoShortcuts(
    signalCollectionActions.undoCurrentCollection,
    signalCollectionActions.redoCurrentCollection
  );

  return (
    <ToolbarMenu>
      <ToolbarMenuButton
        tooltipText={T.graphs.signalselectorbutton}
        icon={<Icons.Edit />}
        onClick={showSignalSelector}
      />
      <ToolbarMenuDivider />
      <ToolbarMenuButton
        tooltipText={T.common.save}
        icon={<Icons.Save />}
        onClick={() =>
          signalCollectionActions.saveSignalCollectionSignals(contextSettings)
        }
        disabled={
          changedSignalCollections[selectedSignalCollectionId] !== true &&
          tempSignalCollections[selectedSignalCollectionId] !== true
        }
      />
      <ToolbarMenuButton
        tooltipText={T.common.undo}
        icon={<Icons.Undo />}
        onClick={signalCollectionActions.undoCurrentCollection}
        disabled={!canUndo}
      />
      <ToolbarMenuButton
        tooltipText={T.common.redo}
        icon={<Icons.Redo />}
        onClick={signalCollectionActions.redoCurrentCollection}
        disabled={!canRedo}
      />
      <ToolbarMenuButton
        tooltipText={T.graphs.deletebutton}
        icon={<Icons.Delete />}
        onClick={() =>
          signalCollectionActions.prepareToDeleteSignalCollection(
            selectedSignalCollectionId
          )
        }
      />
      <ToolbarMenuDivider />
      <ToolbarMenuButton
        tooltipText={T.graphs.editmodal.addtitle}
        icon={<Icons.NewDocument />}
        onClick={() => {
          signalCollectionActions.createSignalCollection(
            getNewSignalCollectionName(signalCollections)
          );
          showSignalSelector();
        }}
      />
      <ToolbarMenuButton
        tooltipText={T.graphs.duplicatebutton}
        icon={<Icons.Copy />}
        onClick={() => signalCollectionActions.duplicateSignalCollection()}
      />
      <ToolbarMenuDivider />
      <ToolbarMenuButton
        tooltipText={T.graphs.exportdialog.title}
        icon={<Icons.Download />}
        onClick={showExport}
      />
      <ToolbarMenuDivider />
      <ToolbarMenuButton
        tooltipText={T.graphs.minmax.title}
        icon={<Icons.MinMax />}
        onClick={showMinMaxSettings}
      />
    </ToolbarMenu>
  );
};

export default GraphToolbarMenu;
