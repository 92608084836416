import React, { useCallback, useState } from 'react';
import CreatableSelect from 'ecto-common/lib/Select/CreatableSelect';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

const CreatableSelectExample = () => {
  const [options, setOptions] = useState<GenericSelectOption<string>[]>([
    {
      label: 'First option',
      value: '1'
    },
    {
      label: 'Second option',
      value: '2'
    }
  ]);

  const [selectedValue, setSelectedValue] = useState<string>(null);

  const updateValue = useCallback(
    (e: GenericSelectOption) => {
      if (e.value === 'create-new') {
        const newVal =
          Math.max(...options.map((option) => parseInt(option.value, 10))) + 1;
        setOptions(
          options.concat([
            {
              label: 'New option ' + newVal,
              value: newVal + ''
            }
          ])
        );
        setSelectedValue(newVal + '');
      } else {
        setSelectedValue(e.value);
      }
    },
    [options]
  );

  return (
    <div style={{ width: '200px' }}>
      <CreatableSelect
        createValue="create-new"
        options={options}
        value={options.find((x) => x.value === selectedValue)}
        onChange={updateValue}
      />
    </div>
  );
};

CreatableSelectExample.description =
  'We need to set div width explicitly as CreatableSelect has no minimum width (only expands to container width). Should be fixed.';
CreatableSelectExample.class = 'CreatableSelect';

export default CreatableSelectExample;
