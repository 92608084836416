import ArrayUtils from './arrayUtils';

// Calculates the ground temperature from aambien air temperature and depth
function calcGroundTemp(weatherAirTemp, depth) {
  // Do not overwrite original air temperature later
  const T_air = [...weatherAirTemp];

  // If depth = 0
  if (depth === 0) {
    return T_air;
  }

  let span;
  if ((depth > 0) & (depth <= 0.1)) {
    span = getMovingAverageSpan(depth, 0.2, -35.83518938, 0.8);
  } else if ((depth > 0.1) & (depth <= 0.2)) {
    span = lin(depth, 690, 61);
  } else if (depth > 0.2) {
    span = getMovingAverageSpan(
      depth,
      270.48461128,
      -1.67138281,
      -300.84791287
    );
  }

  // Limit equals appox. 2.1 m
  if (span > T_air.length) {
    span = T_air.length;
  }

  const alpha = 2 / (span + 1);

  const T_air_extend = [...T_air];

  // Append last span-th values of air temperature at the beginning
  for (let t = 0; t <= span - 1; t++) {
    T_air_extend.unshift(T_air[8760 - (t + 1)]);
  }

  let T_soil = [];
  for (let t = 0; t < 8760 + span; t++) {
    if (t === 0) {
      T_soil.push(T_air_extend[t]);
    } else {
      T_soil.push(
        alpha * T_air_extend[t] + (1 - alpha) * T_soil[T_soil.length - 1]
      );
    }
  }

  // Take last 8760 values
  T_soil = T_soil.slice(span, 8760 + span);

  // Constant temperature offset
  const offSet = 1.3;
  const T_offset = ArrayUtils.createConstantArray(8760, offSet);
  T_soil = ArrayUtils.arraySum(T_soil, T_offset);

  // Set negative values to zero
  let T_soil_pos = [];
  for (let t = 0; t < 8760; t++) {
    T_soil_pos.push(Math.max(T_soil[t], 0));
  }

  // Round array
  T_soil_pos = ArrayUtils.roundArray(T_soil_pos, 2);

  return T_soil_pos;
}

// Detemine span for moving average from given depth (exponential sections)
function lin(x, a, b) {
  return Math.round(a * x - b);
}

// Detemine span for moving average from given depth (exponential sections)
function getMovingAverageSpan(x, a, b, c) {
  return Math.round(a * Math.exp(-b * x) + c);
}

// Calculates heat losses/gains for low-ex network
function calcLowExHeatLosses(state, groundTempProfile) {
  // Calculate heat transfer coefficient
  const lambdaGround = Number(state.thConductivityGround); // W/(m*K)
  const depth = Number(state.installationDepth); // m
  const d = Number(state.heatLossPipeDiameter) / 1000; // m
  const R_0 = 0.0685; // m2*K/W
  const conv_pipe = 3600; // W/(m2*K)
  const lambda_PE = 0.4; // W(m*K)
  let t = 0.0237; // m

  const kInv =
    1 / conv_pipe + // Convection in pipe
    (((+d / 2) * 1) / lambda_PE) * Math.log((d + 2 * t) / d) + // Thermal resistance PE pipe
    (((d / 2) * 1) / lambdaGround) *
      Math.log((4 * (depth + R_0 * lambdaGround)) / (d + 2 * t)); // Thermal resistance soil

  const k = 1 / kInv;

  let meanNetwTempProfile;
  if (state.netwTempProfileUploaded) {
    meanNetwTempProfile = state.netwTempProfile;
  } else {
    meanNetwTempProfile = ArrayUtils.createConstantArray(
      8760,
      Number(state.meanNetwTemp)
    );
  }
  let lossesOnePipe;
  let lossesBothPipes = [];

  for (let t = 0; t < 8760; t++) {
    lossesOnePipe =
      k *
      Math.PI *
      d *
      state.networkLength *
      (Number(meanNetwTempProfile[t]) - groundTempProfile[t]); // kW
    lossesBothPipes.push(2 * lossesOnePipe); // kW
  }

  // Round values
  lossesBothPipes = ArrayUtils.roundArray(lossesBothPipes, 0);

  return lossesBothPipes;
}

const exportedFunctions = { calcGroundTemp, calcLowExHeatLosses };
export default exportedFunctions;
