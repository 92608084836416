import ArrayUtils from './arrayUtils';
import noiseRel from '../data/noiseRel';
import noiseAbs from '../data/noiseAbs';

function overlayProfiles(baseProfile, dayProfiles, input) {
  // Type profile is [0,0,0,1,2,0,0...]; length: 365 (366)
  // 0: weekday, 1: saturday, 2: sunday/holiday
  const resDict = getDayTypeProfile(input);
  const dayTypeProfile = resDict['dayTypeProfile'];
  const dictTypes = resDict['dictTypes'];

  // Add dayProfile with zeros
  dayProfiles['zeros'] = ArrayUtils.createConstantArray(24, 0);

  // Check if in selected country time change happens
  let forwardDay, backwardDay;
  const shiftDict = {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6
  };

  if (input.considerTimeChange) {
    if (input.doChange) {
      // Shift days that change is always from saturday to sunday
      forwardDay = input.forwardDay - shiftDict[input.firstDayOfYear];
      backwardDay = input.backwardDay - shiftDict[input.firstDayOfYear];
    }
  }

  // Get total energy of each day of year for scaling
  // dayEnergy looks like [20.89, 20.89, 54.19, ...] in kWh; length: 365
  const dayEnergy = getDayEnergy(
    baseProfile,
    dayTypeProfile,
    dayProfiles,
    dictTypes
  );

  // Normalize day profiles
  let normDayProfile = {};
  for (const dayType of Object.keys(dictTypes)) {
    normDayProfile[dayType] = ArrayUtils.normalizeProfile(dayProfiles[dayType]);
  }

  // Build up annual profile
  let finalProfile = [];
  let scaledDayProfile = [];
  for (let d = 0; d < 365; d++) {
    if (dayTypeProfile[d] === dictTypes['workday']) {
      scaledDayProfile = ArrayUtils.scaleLin(
        normDayProfile['workday'],
        dayEnergy[d]
      ); // scaleLin(profile, factor)
    } else if (dayTypeProfile[d] === dictTypes['saturday']) {
      scaledDayProfile = ArrayUtils.scaleLin(
        normDayProfile['saturday'],
        dayEnergy[d]
      );
    } else if (dayTypeProfile[d] === dictTypes['sunday']) {
      scaledDayProfile = ArrayUtils.scaleLin(
        normDayProfile['sunday'],
        dayEnergy[d]
      );
    } else if (dayTypeProfile[d] === dictTypes['zeros']) {
      scaledDayProfile = ArrayUtils.scaleLin(
        normDayProfile['zeros'],
        dayEnergy[d]
      );
    }
    // Push value of every hour of the day
    for (let t = 0; t < 24; t++) {
      // No time change
      if (!input.considerTimeChange) {
        finalProfile.push(scaledDayProfile[t]);
        // Time change enabled
      } else if (d >= forwardDay && d < backwardDay) {
        if (t + 1 < scaledDayProfile.length) {
          finalProfile.push(scaledDayProfile[t + 1]);
        } else {
          finalProfile.push(scaledDayProfile[0]);
        }
      } else {
        finalProfile.push(scaledDayProfile[t]);
      }
    }
  }
  return finalProfile;
}

function getDayTypeProfile(input) {
  let dayTypeProfile = [];

  // Initialize dictTypes
  const dictTypes = { workday: 0, saturday: 1, sunday: 2, zeros: 9 };

  let currWeekDay = input.firstDayOfYear;
  for (let d = 0; d < 365; d++) {
    if (currWeekDay === 'monday') {
      dayTypeProfile.push(dictTypes['workday']);
      currWeekDay = 'tuesday';
    } else if (currWeekDay === 'tuesday') {
      dayTypeProfile.push(dictTypes['workday']);
      currWeekDay = 'wednesday';
    } else if (currWeekDay === 'wednesday') {
      dayTypeProfile.push(dictTypes['workday']);
      currWeekDay = 'thursday';
    } else if (currWeekDay === 'thursday') {
      dayTypeProfile.push(dictTypes['workday']);
      currWeekDay = 'friday';
    } else if (currWeekDay === 'friday') {
      dayTypeProfile.push(dictTypes['workday']);
      currWeekDay = 'saturday';
    } else if (currWeekDay === 'saturday') {
      dayTypeProfile.push(dictTypes['saturday']);
      currWeekDay = 'sunday';
    } else if (currWeekDay === 'sunday') {
      dayTypeProfile.push(dictTypes['sunday']);
      currWeekDay = 'monday';
    }
  }

  // Overwrite holidays
  if (input.considerHolidays) {
    dayTypeProfile = overwriteHolidays(dayTypeProfile, input.holidaysList);
    // If building is school, also overwrite all school holidays
    if (input.buildingType === 'school') {
      dayTypeProfile = overwriteHolidays(
        dayTypeProfile,
        input.schoolHolidaysList
      );
    }
  }

  // // Overwrite zero-days (e.g. days oustide of heating period)
  // if (demandType === "spaceHeating" & input.checkHeatingPeriod) {
  //     dayTypeProfile = overwriteZeroDays(dayTypeProfile, input.heatingPeriodBegin, input.heatingPeriodEnd)
  // }

  // // Overwrite zero-days (e.g. days oustide of cooling period)
  // if (demandType === "spaceCooling" & input.checkCoolingPeriod) {
  //     dayTypeProfile = overwriteZeroDays(dayTypeProfile, input.coolingPeriodBegin, input.coolingPeriodEnd)
  // }

  const resDict = {
    dayTypeProfile: dayTypeProfile,
    dictTypes: dictTypes
  };

  return resDict;
}

function overwriteHolidays(dayTypeProfile, holidaysList) {
  let newTypeProfile = dayTypeProfile;
  for (let d = 0; d < dayTypeProfile.length; d++) {
    // If day is included in holiday list
    if (holidaysList.includes(d)) {
      // console.log("Set d=" + d + " to holiday.")
      newTypeProfile[d] = 2; // day type "2" is sunday
    }
  }
  return newTypeProfile;
}

// Get holidays as day values in list, e.g. [0, 120, 275, 357, 358, 359, 364]
function convertHolidays(holidaysFixedDate, holidaysVarDate, firstDayOfYear) {
  // Get off-set data
  const monthsOffset = getMonthsOffset();
  const dayOffSet = getDayOffSet();

  let holidaysList = [];

  // Fix date holidays: For example, 25.12.
  Object.keys(holidaysFixedDate).forEach((month) => {
    let listDays = holidaysFixedDate[month];
    listDays.forEach((day) => {
      holidaysList.push(monthsOffset[month] + day);
    });
  });

  // Variable date holidays: For example, easter.
  let dayIdx = null;
  Object.keys(holidaysVarDate).forEach((month) => {
    let listDays = holidaysVarDate[month];
    listDays.forEach((day) => {
      dayIdx = monthsOffset[month] + day + dayOffSet[firstDayOfYear];
      if (dayIdx > 364) {
        holidaysList.push(dayIdx - 365);
      } else {
        holidaysList.push(dayIdx);
      }
    });
  });

  return holidaysList;
}

// Get school holidays as day values in list, e.g. [0, 120, 275, 357, 358, 359, 364]
function convertSchoolHolidays(schoolHolidays, firstDayOfYear) {
  // Get off-set data
  const monthsOffset = getMonthsOffset();
  const dayOffSet = getDayOffSet();

  let schoolHolidaysList = [];

  // Variable date school holidays
  let dayIdx = null;
  Object.keys(schoolHolidays).forEach((month) => {
    let listDays = schoolHolidays[month];
    listDays.forEach((day) => {
      dayIdx = monthsOffset[month] + day + dayOffSet[firstDayOfYear];
      if (dayIdx > 364) {
        schoolHolidaysList.push(dayIdx - 365);
      } else {
        schoolHolidaysList.push(dayIdx);
      }
    });
  });

  return schoolHolidaysList;
}

// Get month offset for holiday list compilation
function getMonthsOffset() {
  return {
    jan: -1,
    feb: 30,
    mar: 58,
    apr: 89,
    may: 119,
    jun: 150,
    jul: 180,
    aug: 211,
    sep: 242,
    oct: 272,
    nov: 303,
    dec: 333
  };
}

// Get day offset for holiday list compilation
function getDayOffSet() {
  return {
    monday: 0,
    tuesday: 1,
    wednesday: 2,
    thursday: 3,
    friday: 4,
    saturday: 5,
    sunday: 6
  };
}

function getDayEnergy(baseProfile, dayTypeProfile, dayProfiles, dictTypes) {
  // Convert profile with 8760 values to profile with 365
  // Total sum of both profiles is the same
  const baseProfileDaily = convertHoursToDays(baseProfile);

  // Calculate annual energy
  const totalEnergyBaseProfile = ArrayUtils.sum(baseProfileDaily);

  // Count days of each day type, e.g. {0: 254, 1: 52, 2: 59, 9: 0}, 254 working days
  const dayTypeCounts = ArrayUtils.countElements(dayTypeProfile, dictTypes);
  let numberDays = {};
  let sumProfiles = {};
  let totSum = {};
  for (const dayType of Object.keys(dictTypes)) {
    // Number of workdays / saturdays / sundays
    numberDays[dayType] = dayTypeCounts[dictTypes[dayType]];
    // console.log("Number of " + dayType + ": " + numberDays[dayType])

    // Sum of day profiles
    sumProfiles[dayType] = ArrayUtils.sum(dayProfiles[dayType]);
    // console.log("sumProfiles " + dayType + ": " + sumProfiles[dayType])

    // Calculate (number of days) x (profile sum)
    totSum[dayType] = numberDays[dayType] * sumProfiles[dayType];
  }

  // Total sum over all day types
  let totSumAll =
    totSum['workday'] + totSum['saturday'] + totSum['sunday'] + totSum['zeros'];
  let energyShareDayType = {};
  let energyDayTypeTarget = {};
  let energyDayTypeBaseProfil = {};
  for (const dayType of Object.keys(dictTypes)) {
    // Calculate how much of the total annual energy is assigned to each of the day types
    energyShareDayType[dayType] = totSum[dayType] / totSumAll;

    // Calculate how much energy must be distributed to the days of a specific day type
    energyDayTypeTarget[dayType] =
      energyShareDayType[dayType] * totalEnergyBaseProfile;

    // Calculate the energy of the baseProfile at the position of the specific dayType
    energyDayTypeBaseProfil[dayType] = binSumProfile(
      baseProfileDaily,
      dayTypeProfile,
      dictTypes[dayType]
    );
  }

  // Scale every day
  let dayEnergy = [];
  for (let t = 0; t < baseProfileDaily.length; t++) {
    if (baseProfileDaily[t] === 0) {
      dayEnergy.push(0);
    } else if (dayTypeProfile[t] === dictTypes['workday']) {
      dayEnergy.push(
        (baseProfileDaily[t] / energyDayTypeBaseProfil['workday']) *
          energyDayTypeTarget['workday']
      );
    } else if (dayTypeProfile[t] === dictTypes['saturday']) {
      dayEnergy.push(
        (baseProfileDaily[t] / energyDayTypeBaseProfil['saturday']) *
          energyDayTypeTarget['saturday']
      );
    } else if (dayTypeProfile[t] === dictTypes['sunday']) {
      dayEnergy.push(
        (baseProfileDaily[t] / energyDayTypeBaseProfil['sunday']) *
          energyDayTypeTarget['sunday']
      );
    } else if (dayTypeProfile[t] === dictTypes['zeros']) {
      if (energyDayTypeBaseProfil['zeros'] !== 0) {
        dayEnergy.push(
          (baseProfileDaily[t] / energyDayTypeBaseProfil['zeros']) *
            energyDayTypeTarget['zeros']
        );
      } else {
        dayEnergy.push(0);
      }
    }
  }
  return dayEnergy;
}

// Sum of array entries which are "0"/"1"/"2"/"9" at the corresponding position of the dayTypeProfile
function binSumProfile(profile, dayTypeProfile, val) {
  // Check if profiles are of the same length
  if (profile.length !== dayTypeProfile.length) {
    // console.log("Error: Profiles are not of the same length.")
    return null;
  }

  let sumVal = 0;
  for (let t = 0; t < profile.length; t++) {
    if (dayTypeProfile[t] === val) {
      sumVal += profile[t];
    }
  }

  return sumVal;
}

// Converts 8760 to 365 array
function convertHoursToDays(baseProfile) {
  // Initialize variables
  let baseProfileDaily = [];

  // Parse hourly profile
  for (let d = 0; d < 365; d++) {
    let sumVal = ArrayUtils.sum(baseProfile.slice(d * 24, d * 24 + 24));
    baseProfileDaily.push(sumVal);
  }
  return baseProfileDaily;
}

function overwriteZeros(profile, periodBegin, periodEnd) {
  const monthsOffset = {
    1: -1,
    2: 31 * 24 - 1,
    3: 59 * 24 - 1,
    4: 90 * 24 - 1,
    5: 120 * 24 - 1,
    6: 151 * 24 - 1,
    7: 181 * 24 - 1,
    8: 212 * 24 - 1,
    9: 243 * 24 - 1,
    10: 273 * 24 - 1,
    11: 304 * 24 - 1,
    12: 334 * 24 - 1
  };

  const indexBegin = monthsOffset[periodBegin];
  let indexEnd =
    periodEnd === 12 ? monthsOffset[1] : monthsOffset[periodEnd + 1];
  if (periodEnd >= periodBegin) {
    indexEnd = periodEnd === 12 ? 8759 : monthsOffset[periodEnd + 1];
  }
  let newProfile = [];

  for (let t = 0; t < 8760; t++) {
    if (periodEnd < periodBegin) {
      // Northern hemisphere (no heating over summer)
      if (t <= indexBegin && t > indexEnd) {
        // console.log("Northern hemisphere." + "Zu Null: " + t)
        newProfile.push(0);
      } else {
        newProfile.push(profile[t]);
      }
    } else {
      // Southern hemisphere (no heating over winter)
      if (t <= indexBegin || t > indexEnd) {
        // console.log("Southern hemisphere." + "Zu Null: " + t)
        newProfile.push(0);
      } else {
        newProfile.push(profile[t]);
      }
    }
  }
  return newProfile;
}

// Calculate base profile as seasonal cosine profile
function calcCosineBaseProfile(airTemp, hourlyDem, amplitude) {
  // Calculate mean temperature in winter and summer
  const TMeanDecFeb = ArrayUtils.mean(
    ArrayUtils.slicePlus(airTemp, -744, 1415)
  );
  const TMeanJunAug = ArrayUtils.mean(
    ArrayUtils.slicePlus(airTemp, 3624, 5831)
  );

  // Create profile
  let baseProfile = [];
  const xRange = Array.from(Array(8760), (x, i) => (i / 8760) * 2 * Math.PI);
  if (TMeanJunAug - TMeanDecFeb > 6) {
    const seasonShift = ((15 * 24) / 8760) * Math.PI; // -> Minimum centered around 15. July (northern hemisphere)
    xRange.forEach((x) => {
      baseProfile.push((Math.cos(x - seasonShift) * amplitude + 1) * hourlyDem);
    });
  } else if (TMeanDecFeb - TMeanJunAug > 6) {
    const seasonShift = ((15 * 24) / 8760) * Math.PI; // -> Maximum centered around 15. July (southern hemisphere)
    xRange.forEach((x) => {
      baseProfile.push(
        (Math.cos(x - seasonShift) * -1 * amplitude + 1) * hourlyDem
      );
    });
  } else {
    baseProfile = ArrayUtils.createConstantArray(8760, hourlyDem);
  }
  return baseProfile;
}

// Gaussian noise
function overlayNoise(profile, relCoeff, absPreCoeff) {
  // Calculate maximum value
  const absCoeff = absPreCoeff * ArrayUtils.max(profile);

  // Calculate profile with noise
  let newProfile = [];
  for (let t = 0; t < profile.length; t++) {
    newProfile.push(
      profile[t] * (relCoeff * noiseRel[t] + absCoeff * noiseAbs[t])
    );
  }

  // Scale so that sum remains the same
  const newSum = ArrayUtils.sum(newProfile);
  const oldSum = ArrayUtils.sum(profile);
  if (!(newSum === 0)) {
    newProfile = ArrayUtils.scaleLin(newProfile, oldSum / newSum);
  }

  return newProfile;
}

const exportedFunctions = {
  overlayProfiles,
  overlayNoise,
  convertHolidays,
  convertSchoolHolidays,
  overwriteZeros,
  calcCosineBaseProfile
};
export default exportedFunctions;
