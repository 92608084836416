import _ from 'lodash';
import { Dispatch, SetStateAction, useCallback } from 'react';

function useOnUpdateFormInput<ObjectType extends object>(
  setInput: Dispatch<SetStateAction<ObjectType>>
) {
  return useCallback(
    (name: string[], value: unknown) => {
      setInput((oldInput) => {
        const newInput = { ...oldInput };
        _.set(newInput, name, value);
        return newInput;
      });
    },
    [setInput]
  );
}

export default useOnUpdateFormInput;
