import {
  SET_DEFAULT_DASHBOARD_COLLECTION,
  SET_NODE_DASHBOARD_COLLECTION_RELATIONS
} from 'js/actions/actionTypes';
import { GenericActionType } from 'ecto-common/lib/types/EctoCommonTypes';
import { DashboardCollectionResponseModel } from 'ecto-common/lib/API/APIGen';

type OperatorProps = {
  nodeDashboardCollectionMap: Record<string, string>;
  defaultDashboardCollection: DashboardCollectionResponseModel;
};

const initialState: OperatorProps = {
  nodeDashboardCollectionMap: {},
  defaultDashboardCollection: null
};

function operator(
  state = initialState,
  action: GenericActionType = {}
): OperatorProps {
  switch (action.type) {
    case SET_NODE_DASHBOARD_COLLECTION_RELATIONS: {
      return {
        ...state,
        nodeDashboardCollectionMap: action.payload
      };
    }
    case SET_DEFAULT_DASHBOARD_COLLECTION: {
      return {
        ...state,
        defaultDashboardCollection: action.payload
      };
    }
    default:
      return state;
  }
}

export default operator;
