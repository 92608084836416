import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';

import GraphsPage from 'js/containers/GraphsPage';
import AlarmsPage from 'js/containers/AlarmsPage';
import LogPage from 'js/containers/LogPage';
import PowerControlPage from 'js/containers/PowerControlPage';
import SignalProvidersPage from 'js/containers/SignalProvidersPage';
import DashboardPage from 'js/components/DashboardPage/DashboardPage';
import PersonalDashboardPage from 'js/components/DashboardPage/PersonalDashboardPage';
import { UserDashboardsSubPage } from 'js/utils/routeConstants';
import Jobs from 'js/components/Jobs/Jobs';
import AlarmsPageV2 from '../../containers/AlarmsPageV2';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';

const sections = [
  {
    route: UserDashboardsSubPage,
    title: T.location.tabs.dashboard,
    icon: <Icons.Favorite />,
    renderContent: () => <PersonalDashboardPage />
  },
  {
    route: 'dashboard',
    title: T.location.tabs.dashboard,
    icon: <Icons.Dashboard />,
    renderContent: () => <DashboardPage />
  },
  {
    route: 'jobs',
    title: T.location.tabs.jobs,
    icon: <Icons.DataJobs />,
    renderContent: () => <Jobs />
  },
  {
    route: 'graphs',
    title: T.location.tabs.graphs,
    icon: <Icons.BarGraph />,
    renderContent: (_curRoute: string) => <GraphsPage />
  },
  {
    route: 'tools',
    title: T.location.tabs.tools,
    icon: <Icons.Tool />,
    renderContent: (_curRoute: string, selectedNode: SingleGridNode) => (
      <PowerControlPage selectedNode={selectedNode} />
    )
  },
  {
    route: 'alarms',
    title: T.location.tabs.alarms,
    icon: <Icons.AlarmBell />,
    renderContent: (_curRoute: string, _selectedNode: SingleGridNode) => (
      <AlarmsPage />
    )
  },
  {
    route: 'newalarms',
    title: T.location.tabs.alarms + ' V2',
    icon: <Icons.AlarmBell />,
    renderContent: (_curRoute: string, _selectedNode: SingleGridNode) => (
      <AlarmsPageV2 />
    )
  },
  {
    route: 'signalproviders',
    title: T.location.tabs.signalproviders,
    icon: <Icons.Signal />,
    renderContent: (_curRoute: string, selectedNode: SingleGridNode) => (
      <SignalProvidersPage selectedNode={selectedNode} />
    )
  },
  {
    route: 'logs',
    title: T.location.tabs.logs,
    icon: <Icons.Logs />,
    renderContent: (_curRoute: string, _selectedNode: SingleGridNode) => (
      <LogPage />
    )
  },
  {
    route: 'graphs',
    title: T.location.tabs.graphs,
    icon: <Icons.Graph />,
    renderContent: () => <GraphsPage />
  }
];

export default sections;
