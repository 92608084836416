import React, {
  ChangeEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';

import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';

import styles from '../AlarmConfirmAllModal.module.css';
import AlarmsAPIGen from 'ecto-common/lib/API/AlarmsAPIGen';
import { useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

interface AlarmConfirmAllModalPropsV2 {
  nodeId: string;
  isOpen?: boolean;
  onModalClose: () => void;
  searchPhrase?: string;
}

const AlarmConfirmAllModalV2 = ({
  nodeId,
  isOpen,
  onModalClose,
  searchPhrase
}: AlarmConfirmAllModalPropsV2) => {
  const [acknowledgeAllComment, setAcknowledgeAllComment] = useState('');
  const { contextSettings } = useContext(TenantContext);

  useEffect(() => {
    if (isOpen) {
      setAcknowledgeAllComment('');
    }
  }, [isOpen]);

  const queryClient = useQueryClient();

  const confirmMutation = AlarmsAPIGen.Alarms.acknowledgeAlarms.useMutation({
    onSuccess: () => {
      toastStore.addSuccessToast(T.alarms.acknowledgeallstatus.success);
      queryClient.invalidateQueries(
        AlarmsAPIGen.Alarms.listAlarms.path(contextSettings)
      );
      queryClient.invalidateQueries(
        AlarmsAPIGen.Alarms.listAlarmEvents.path(contextSettings)
      );
      onModalClose();
    },
    onError: () => {
      toastStore.addErrorToast(T.alarms.acknowledgeallstatus.failure);
    }
  });

  const onConfirmAllAlarms = useCallback(() => {
    confirmMutation.mutate({
      nodeId,
      comment: acknowledgeAllComment,
      filter: searchPhrase
    });
  }, [acknowledgeAllComment, confirmMutation, nodeId, searchPhrase]);

  const disableActionButton = isNullOrWhitespace(acknowledgeAllComment);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setAcknowledgeAllComment(event.target.value);
    },
    []
  );

  return (
    <ActionModal
      messageBodyClassName={styles.confirmBody}
      className={styles.confirmAllDialog}
      onModalClose={onModalClose}
      isOpen={isOpen}
      isLoading={confirmMutation.isLoading}
      title={T.alarms.page.heading.acknowledgeallalarms}
      headerIcon={Icons.Alarm}
      actionText={T.alarms.acknowledge.action}
      disableActionButton={disableActionButton}
      onConfirmClick={onConfirmAllAlarms}
    >
      <>{T.alarms.acknowledge.all.prompt}</>

      <TextInput
        autoFocus
        wrapperClassName={styles.acknowledgeAllCommentInput}
        value={acknowledgeAllComment}
        onChange={onChange}
        placeholder={T.alarms.acknowledge.comment}
      />
    </ActionModal>
  );
};

export default AlarmConfirmAllModalV2;
