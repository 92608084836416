/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateAlarmRequest {
  /** @format uuid */
  nodeId?: string;
  /** @format uuid */
  signalId?: string;
  /**
   * @format int32
   * @min 1
   * @max 1000
   */
  severity: number;
  /** @minLength 1 */
  message: string;
}

/**
 * @title OpenAPI Document on Azure Functions
 * @version 1.0.0
 * @baseUrl https://funapp-ec-alarm-manualproxy-hfkk.azurewebsites.net/api
 *
 * This is the OpenAPI Document on Azure Functions
 */

import { APIGenType } from './APIGenType';
import { ContentType, Method, apiEndpoint } from './APIUtils';

const apiTitle = APIGenType.ManualAlarmsAPIGen;

export interface AlarmsCreateManualPath {
  createManual: string;
}

const ManualAlarmsAPIGen = {
  ManualAlarm: {
    /**
     * @description This API manually creates alarms in Ectocloud. * @summary Create Manual alarms.
     */
    alarmsCreateManual: apiEndpoint<CreateAlarmRequest, void, any>(
      Method.POST,
      `/v1/alarms:createManual`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  }
};

export default ManualAlarmsAPIGen;
