import React from 'react';
import { hourDiff } from 'ecto-common/lib/utils/dateUtils';
import T from 'ecto-common/lib/lang/Language';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import { SignalProviderTelemetryValueResponseModel } from 'ecto-common/lib/API/APIGen';
import { Moment } from 'moment';

function _getEffectSummary(
  steerSignalValues: SignalProviderTelemetryValueResponseModel[],
  amplitude: number,
  utcStartDate: Moment
): React.ReactNode {
  let closestDiffHours = Number.MAX_VALUE;
  let closestValue = null;

  // Find closest signal value that occurs before or at utcStartDate
  for (let i = 0; i < steerSignalValues.length; i++) {
    const steerSignalValue = steerSignalValues[i];
    const hours = hourDiff(utcStartDate, steerSignalValue.time);

    if (hours < 0) {
      // Found later date
      break;
    }

    if (hours < closestDiffHours) {
      closestDiffHours = hours;
      closestValue = steerSignalValue.value;
    }
  }

  if (closestDiffHours < 1 && closestValue) {
    if (amplitude < 0) {
      const calculatedEffect = Math.round((-amplitude / 100.0) * closestValue);
      return (
        <span>
          {T.format(
            T.powercontrol.dispatch.schedule.forecasting.decrease,
            <strong key={0}>{calculatedEffect} kW</strong>
          )}
        </span>
      );
    }

    const calculatedEffect = Math.round((amplitude / 100.0) * closestValue);
    return (
      <span>
        {T.format(
          T.powercontrol.dispatch.schedule.forecasting.increase,
          <strong key={0}>{calculatedEffect} kW</strong>
        )}
      </span>
    );
  }

  return T.powercontrol.dispatch.schedule.noforecasting;
}

interface EffectSummaryProps {
  amplitude: number;
  startDate?: Moment;
}

const EffectSummary = ({ amplitude, startDate }: EffectSummaryProps) => {
  const steerSignalValues = useOperatorSelector(
    (state) => state.schedule.steerSignalValues
  );
  const summary = _getEffectSummary(steerSignalValues, amplitude, startDate);
  return <>{summary}</>;
};

export default React.memo(EffectSummary);
