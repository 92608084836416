import { SET_USERSETTINGS } from './actionTypes';
import IdentityServiceAPI from 'ecto-common/lib/utils/IdentityServiceAPI';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import { SignalCollectionActions } from 'js/modules/signalCollections/signalCollections';
import { OperatorDispatch } from 'js/reducers/storeOperator';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

export function updateSettings(
  contextSettings: ApiContextSettings,
  settings: object
) {
  return (dispatch: OperatorDispatch) => {
    dispatch(SignalCollectionActions.setIsSavingCollections(true));
    return IdentityServiceAPI.TenantUser.updateSettings(
      contextSettings,
      JSON.stringify(settings)
    )
      .then(() => {
        const newSettings =
          settings && typeof settings === 'string'
            ? JSON.parse(settings)
            : settings;
        dispatch(setUserSettings(newSettings));
        dispatch(SignalCollectionActions.setIsSavingCollections(false));
      })
      .catch(() => {
        dispatch(SignalCollectionActions.setIsSavingCollections(false));
        toastStore.addErrorToast(T.graphs.failedtosave);
      });
  };
}

export function setUserSettings(settings: object) {
  return {
    type: SET_USERSETTINGS,
    payload: {
      settings:
        settings && typeof settings === 'string'
          ? JSON.parse(settings)
          : settings
    }
  };
}
