import React from 'react';

import T from 'ecto-common/lib/lang/Language';
import { isSameDay } from 'ecto-common/lib/utils/dateUtils';

import {
  formatDate,
  formatDateFull,
  formatTime
} from 'js/components/EMPTools/PowerControl/Dispatch/utils/dateUtils';
import TemperatureImpactSummary from 'js/components/EMPTools/PowerControl/Dispatch/Components/TemperatureImpactSummary';
import EffectSummary from 'js/components/EMPTools/PowerControl/Dispatch/Components/EffectSummary';
import { BuildingTemperatureImpactPreviewResponseModel } from 'ecto-common/lib/API/APIGen';
import { Moment } from 'moment';

interface ScheduleSummaryProps {
  schedulePreviewBuildings?: BuildingTemperatureImpactPreviewResponseModel[];
  isLoading: boolean;
  hasInvalidScheduleStart: boolean;
  isPowerControlTest: boolean;
  currentScheduleStart: object;
  amplitude?: number;
  startDate: Moment;
  endDate: Moment;
}

const ScheduleSummary = ({
  schedulePreviewBuildings,
  isLoading,
  hasInvalidScheduleStart,
  isPowerControlTest,
  currentScheduleStart,
  amplitude,
  startDate,
  endDate
}: ScheduleSummaryProps) => {
  const startDateIsToday = isSameDay(startDate, new Date());
  const sameDay = isSameDay(startDate, endDate);

  return (
    <>
      {hasInvalidScheduleStart && (
        <span>{T.powercontrol.dispatch.schedule.dateerror}</span>
      )}

      {!hasInvalidScheduleStart && (
        <>
          {isPowerControlTest && (
            <span>
              {T.format(
                T.powercontrol.dispatch.schedule.testinfo,
                <strong key={0}>
                  {!startDateIsToday && formatDateFull(currentScheduleStart)}
                </strong>
              )}
              <br />
              <br />
            </span>
          )}

          {!isPowerControlTest && (
            <span>
              {T.format(
                T.powercontrol.dispatch.schedule.startinterval,
                <strong key={0}>
                  {!startDateIsToday && formatDate(startDate)}
                </strong>,
                <strong key={1}>{formatTime(startDate)}</strong>,
                <strong key={2}>{!sameDay && formatDate(endDate)}</strong>,
                <strong key={3}>{formatTime(endDate)}</strong>
              )}
              &nbsp;
              <EffectSummary amplitude={amplitude} startDate={startDate} />
              <br />
              <br />
            </span>
          )}

          <TemperatureImpactSummary
            schedulePreviewBuildings={schedulePreviewBuildings}
            isLoading={isLoading}
          />
        </>
      )}
    </>
  );
};

export default ScheduleSummary;
