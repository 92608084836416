import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EctoplannerResultView from 'js/components/Ectoplanner/EctoplannerResultView';
import styles from './EctoplannerResults.module.css';
import { EctoplannerParams } from 'js/utils/routeConstants';

const EctoplannerResultPage = () => {
  const params = useParams<EctoplannerParams>();

  // Unfortunately have to apply this style to the body element directly in order
  // to enable scrolling that does not break the printing content.
  useEffect(() => {
    document.body.className = styles.printBody;
    return () => {
      document.body.className = null;
    };
  }, []);

  return (
    <EctoplannerResultView
      projectId={params.projectId}
      build={null}
      buildId={params.itemId}
      printMode
    />
  );
};

export default React.memo(EctoplannerResultPage);
