export const parseTimeSeriesFile = (file: string) => {
  if (file == null) {
    return [];
  }

  return file
    .split('\n')
    .filter((x) => x != '')
    .map(parseFloat);
};
