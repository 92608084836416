import React, { Dispatch, SetStateAction, useMemo } from 'react';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import useOnUpdateFormInput from 'ecto-common/lib/ModelForm/useOnUpdateFormInput';
import styles from 'js/components/Ectoplanner/EditEctoplannerProject.module.css';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

interface EditEctoplannerTechnologiesProps {
  projectId?: string;
  form?: object;
  setForm: Dispatch<SetStateAction<EctoplannerForm>>;
  sections?: ModelFormSectionType<EctoplannerForm>[];
}

const EditEctoplannerTechnologies = ({
  form,
  setForm,
  sections,
  projectId
}: EditEctoplannerTechnologiesProps) => {
  const onUpdateInput = useOnUpdateFormInput(setForm);
  const environment = useMemo(() => ({ projectId }), [projectId]);

  return (
    <div className={styles.flexWrapLayout}>
      <ModelForm
        sections={sections}
        onUpdateInput={onUpdateInput}
        input={form}
        environment={environment}
        sectionClassName={styles.section}
        useTooltipHelpTexts
      />
    </div>
  );
};

export default React.memo(EditEctoplannerTechnologies);
