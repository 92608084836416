import React from 'react';
import SignalStrengthIllustration from 'ecto-common/lib/SignalStrengthIllustration/SignalStrengthIllustration';
import getRangesBasedOnAccessTechnology from 'ecto-common/lib/SignalStrengthIllustration/getRangesBasedOnAccessTechnology';

const SignalStrengthIllustrationExample = () => {
  const accessTechnology = '4G/LTE (E-UTRAN)';
  const ranges = getRangesBasedOnAccessTechnology(accessTechnology);

  return (
    <div>
      <div>
        <SignalStrengthIllustration signalStrength={-51} ranges={ranges} />
      </div>

      <div>
        <SignalStrengthIllustration signalStrength={-130} ranges={ranges} />
      </div>

      <div>
        <SignalStrengthIllustration signalStrength={-90} ranges={ranges} />
      </div>
    </div>
  );
};

SignalStrengthIllustrationExample.class = 'SignalStrengthIllustration';

export default SignalStrengthIllustrationExample;
