import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { withSize } from 'react-sizeme';
import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';
import classNames from 'classnames';
import styles from './CollapsingSegmentControlPicker.module.css';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';

export type OptionWithIcon<ValueType = string> =
  GenericSelectOption<ValueType> & {
    icon?: React.ReactNode;
  };

type CollapsingSegmentControlPickerProps<ValueType> = {
  options?: OptionWithIcon<ValueType>[];
  value?: OptionWithIcon<ValueType>;
  onChangeValue?: (value: OptionWithIcon<ValueType>) => void;
  maxWidth?: number;
  size?: {
    width: number;
    height: number;
  };
};

type CollapsingSegmentControlPickerContentProps<ValueType> = Omit<
  CollapsingSegmentControlPickerProps<ValueType>,
  'maxWidth'
> & {
  setWidth: Dispatch<SetStateAction<number>>;
  visible: boolean;
};

/**
 * This complementary component is needed since we need to figure out the dimensions of the segment control.
 * The segment control is always part of the DOM, just not always visible. It is placed as an absolute element
 * so we can get the actual width without the containing environment interfering.
 */

function CollapsingSegmentControlPickerContent<ValueType>({
  size,
  options,
  value,
  setWidth,
  visible,
  onChangeValue
}: CollapsingSegmentControlPickerContentProps<ValueType>) {
  useEffect(() => {
    setWidth(size.width);
  }, [setWidth, size.width]);

  return (
    <div>
      <SegmentControl
        className={classNames(styles.segmentControl, visible && styles.visible)}
      >
        {options.map((option, idx) => (
          <SegmentControlItem
            active={option === value}
            key={idx}
            onClick={() => onChangeValue(option)}
          >
            {option.icon}
            {option.label}
          </SegmentControlItem>
        ))}
      </SegmentControl>
    </div>
  );
}

const ContentWithSize = withSize({ monitorWidth: true })(
  CollapsingSegmentControlPickerContent
);

/**
 * This is a segment control that when being too wide for the current context collapses to a Select component.
 * It has some limitations compared to the regular SegmentedControl as it uses the same options format as the
 * Select component.
 *
 * The current implementation just collapses when using more than 90% of the available parent width.
 */
function CollapsingSegmentControlPicker<ValueType = string>({
  options,
  value,
  size,
  onChangeValue,
  maxWidth = 0.9
}: CollapsingSegmentControlPickerProps<ValueType>) {
  const [showSegmentControl, setShowSegmentControl] = useState(true);
  const [childWidth, setChildWidth] = useState(0);

  useEffect(() => {
    setShowSegmentControl(childWidth < maxWidth * size.width);

    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }, [childWidth, size.width, maxWidth]);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.segmentContainer)}>
        <ContentWithSize
          onChangeValue={onChangeValue}
          options={options}
          value={value}
          setWidth={setChildWidth}
          visible={showSegmentControl}
        />
      </div>
      <Select
        options={options}
        value={value}
        onChange={onChangeValue}
        className={classNames(
          styles.select,
          !showSegmentControl && styles.visible
        )}
      />
    </div>
  );
}

export default typedMemo<typeof CollapsingSegmentControlPicker>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  withSize({ monitorWidth: true })(CollapsingSegmentControlPicker) as any
);
