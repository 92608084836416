import React from 'react';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import ComponentRow from 'ecto-common/lib/ComponentRow/ComponentRow';

const LocalizedButtonsExample = () => (
  <ComponentRow>
    <LocalizedButtons.Add />
    <LocalizedButtons.Save />
    <LocalizedButtons.Cancel />
    <LocalizedButtons.Done />
    <LocalizedButtons.Ok />
  </ComponentRow>
);

LocalizedButtonsExample.description =
  'Use LocalizedButtons utility object to get translated button texts.';
LocalizedButtonsExample.namespace = 'LocalizedButtons.';
LocalizedButtonsExample.class = 'Button';

export default LocalizedButtonsExample;
