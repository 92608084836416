import setup from 'ecto-common/lib/Application/Application';
import MainContainer from 'js/containers/MainContainer';

import store from 'js/reducers/storeOperator';
import {
  getAlarmUrl,
  getAlarmUrlV2,
  getSignalsUrl,
  LocationRoute
} from 'js/utils/routeConstants';

setup(store, MainContainer, {
  getSignalsUrl: getSignalsUrl,
  getAlarmUrl: getAlarmUrl,
  getAlarmUrlV2: getAlarmUrlV2,
  locationRoute: LocationRoute
});
