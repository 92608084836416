import React, {
  ChangeEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from '../AcknowledgeButton.module.css';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import AlarmsAPIGen, {
  AlarmEventModel,
  AlarmModel
} from 'ecto-common/lib/API/AlarmsAPIGen';
import { useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

interface AcknowledgeAlarmTextProps {
  description?: string;
  node?: SingleGridNode;
}

const AcknowledgeAlarmText = ({
  description,
  node
}: AcknowledgeAlarmTextProps) => {
  const _description = description ? (
    <strong key="descriptiontext">{description}</strong>
  ) : null;

  const { name, street } = node || {};

  let location = null;
  if (name) {
    let locationString = name;

    if (street) {
      locationString += ` (${node.street})`;
    }

    location = <strong key="locationtext">{locationString}</strong>;
  }

  return (
    <p className={styles.modalText}>
      {T.format(T.alarms.acknowledgeformat, _description || '', location || '')}
    </p>
  );
};

interface AcknowledgeAlarmModalPropsV2 {
  alarm: AlarmModel | AlarmEventModel;
  node: SingleGridNode;
  isOpen?: boolean;
  onModalClose: () => void;
  onSuccess?(): void;
}

export const AcknowledgeAlarmModalV2 = ({
  alarm,
  node,
  isOpen,
  onModalClose,
  onSuccess
}: AcknowledgeAlarmModalPropsV2) => {
  const [comment, setComment] = useState<string>(null);
  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const acknowledgeMutation = AlarmsAPIGen.Alarms.acknowledgeAlarm.useMutation(
    {
      awaitRequest: true
    },
    {
      onSuccess: () => {
        toastStore.addSuccessToast(T.alarms.acknowledgestatus.success);
        queryClient.invalidateQueries(
          AlarmsAPIGen.Alarms.listAlarms.path(contextSettings)
        );
        queryClient.invalidateQueries(
          AlarmsAPIGen.Alarms.listAlarmEvents.path(contextSettings)
        );
        onSuccess?.();
      },
      onError: () => {
        toastStore.addErrorToast(T.alarms.acknowledgestatus.failure);
      }
    }
  );

  const onCommentChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setComment(event.target.value);
    },
    []
  );

  const onConfirmClick = useCallback(() => {
    acknowledgeMutation.mutate({
      sourceUri: alarm.sourceUri,
      comment: comment
    });
  }, [acknowledgeMutation, alarm.sourceUri, comment]);

  useEffect(() => {
    setComment(null);
  }, [isOpen]);

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      onConfirmClick={onConfirmClick}
      actionText={T.alarms.acknowledgebutton}
      title={T.alarms.acknowledgetitle}
      headerIcon={Icons.Alarm}
      isLoading={acknowledgeMutation.isLoading}
    >
      <AcknowledgeAlarmText
        node={node}
        description={alarm?.properties?.signalDescription}
      />

      <div className={styles.comment}>
        <TextInput
          value={comment}
          placeholder={T.alarms.commentplaceholder}
          onChange={onCommentChange}
          maxLength={500}
        />
      </div>
    </ActionModal>
  );
};
