import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import {
  GraphMinMaxSettings,
  GraphSettingsType
} from 'ecto-common/lib/types/EctoCommonTypes';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { EmptySignalType } from 'ecto-common/lib/utils/constants';
import {
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import { GraphMinMaxSettingsTable } from 'ecto-common/lib/GraphMinMaxSettingsTable/GraphMinMaxSettingsTable';

type GraphMinMaxDialogProps = {
  isOpen: boolean;
  onConfirmSettings: (newSettings: GraphSettingsType) => void;
  onModalClose: () => void;
  selectedSignals: ChartSignal[];
  graphSettings: GraphSettingsType;
  setGraphSettings: Dispatch<SetStateAction<GraphSettingsType>>;
};

const GraphMinMaxDialog = ({
  isOpen,
  onModalClose,
  onConfirmSettings,
  selectedSignals,
  graphSettings,
  setGraphSettings
}: GraphMinMaxDialogProps) => {
  const signalTypesMap: Record<string, SignalTypeResponseModel> =
    useCommonSelector((state) => state.general.signalTypesMap);
  const signalUnitTypesMap: Record<string, UnitResponseModel> =
    useCommonSelector((state) => state.general.signalUnitTypesMap);
  const units = _.uniq(
    selectedSignals.map((signal) => {
      const signalType =
        signalTypesMap[signal.item.signalTypeId] ?? EmptySignalType;
      return signalUnitTypesMap[signalType.unitId];
    })
  );

  const [hasChanges, setHasChanges] = useState(false);

  if (!isOpen && hasChanges) {
    setHasChanges(false);
  }

  const onMinMaxSettingsChanged = useCallback(
    (newSettings: Record<string, GraphMinMaxSettings>) => {
      setGraphSettings({
        ...graphSettings,
        minMaxSettings: newSettings
      });
    },
    [graphSettings, setGraphSettings]
  );

  const onConfirmMinMaxSettings = useCallback(
    (newSettings: Record<string, GraphMinMaxSettings>) => {
      onConfirmSettings({
        ...graphSettings,
        minMaxSettings: newSettings
      });
    },
    [graphSettings, onConfirmSettings]
  );

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      headerIcon={Icons.MinMax}
      title={T.graphs.minmax.title}
      actionText={T.common.done}
      onConfirmClick={() => onConfirmSettings(graphSettings)}
      disableActionButton={!hasChanges}
    >
      <GraphMinMaxSettingsTable
        units={units}
        minMaxSettings={graphSettings.minMaxSettings}
        onMinMaxSettingsChanged={onMinMaxSettingsChanged}
        onConfirmMinMaxSettings={onConfirmMinMaxSettings}
        setHasChanges={setHasChanges}
      />
    </ActionModal>
  );
};

export default React.memo(GraphMinMaxDialog);
