import { AggregationType, SamplingInterval } from 'ecto-common/lib/API/APIGen';
import {
  GraphSettingsType,
  GraphType
} from 'ecto-common/lib/types/EctoCommonTypes';

export const DEFAULT_GRAPH_SETTINGS: GraphSettingsType = {
  type: GraphType.LINE,
  aggregation: AggregationType.None,
  samplingInterval: SamplingInterval.Raw
};
