// Raw data

import rawData from './cop_data.json';
import _ from 'lodash';

const hpData = _.keyBy(Object.values(rawData), 'id');

function getHpData(hpType) {
  if (hpType === 'all') {
    return hpData;
  } else {
    return _.pickBy(hpData, (value) => {
      return value.source === hpType;
    });
  }
}

// Find index of a value in an array
function findIndex(arr, value) {
  /** 
   * Code from Marco, This one contains the overwritting function. 
   * It does not return the location and value from the array, 
   * Instead, it returns the the location and desired value of warm temperature and cold temperature
   * Can only be used in function getProductDataCop
  Examples:  Arr [10,20,30,40,50]
    - value: 5, return {idx: 0, val: 10} to return 
    - value: 60, return {idx: 3, val: 50} to return the second last value
    - value: 25, return {idx: 1, val: 20} 
  */

  // Outside value range
  if (value < arr[0]) {
    return { idx: 0, val: arr[0] };
  }
  if (value > arr[arr.length - 1]) {
    return { idx: arr.length - 2, val: arr[arr.length - 1] }; //revised to return correct value, original arr[arr.length - 1]
  }
  // Inside value range
  for (let i = 0; i < arr.length - 1; i++) {
    if (value >= arr[i] && value <= arr[i + 1]) {
      return { idx: i, val: value }; // revised to return correct value, original val: value
    }
  }
}

function getProductDataCop(warm_temp, cold_temp, heat_pump_model, cop_type) {
  console.assert(cop_type === 'chiller' || cop_type === 'heat_pump');
  const cop_data = hpData;
  // Get index of model
  let idx = '';

  Object.keys(cop_data).forEach((key) => {
    let object = cop_data[key];
    if (object.id === heat_pump_model) {
      idx = key;
    }
  });

  if (idx === '') {
    return null;
  }

  // Get data and convert to float
  let cold_temp_data, warm_temp_data, cop_table;
  if (cop_type === 'heat_pump') {
    cold_temp_data = cop_data[idx]['coldTempHeat'];
    warm_temp_data = cop_data[idx]['warmTempHeat'];
    cop_table = cop_data[idx]['copTableHeat'];
  } else if (cop_type === 'chiller') {
    cold_temp_data = cop_data[idx]['coldTempCool'];
    warm_temp_data = cop_data[idx]['warmTempCool'];
    cop_table = cop_data[idx]['copTableCool'];
  }

  // Find indices
  let res = findIndex(warm_temp_data, warm_temp);
  //console.log(res)
  let warmIndex = res.idx;
  warm_temp = res.val; // overwrite
  res = findIndex(cold_temp_data, cold_temp);
  let coldIndex = res.idx;
  cold_temp = res.val; // overwrite

  //console.log(warmIndex, warm_temp)
  //console.log(coldIndex, cold_temp)

  const x0 = warm_temp_data[warmIndex];
  const x1 = warm_temp_data[warmIndex + 1];
  const y0 = cold_temp_data[coldIndex];
  const y1 = cold_temp_data[coldIndex + 1];

  const f00 = cop_table[warmIndex][coldIndex];
  const f01 = cop_table[warmIndex][coldIndex + 1];
  const f10 = cop_table[warmIndex + 1][coldIndex];
  const f11 = cop_table[warmIndex + 1][coldIndex + 1];
  //console.log(x0, x1, y0, y1)
  //console.log(f00, f01, f10, f11)
  const result =
    (f00 * (x1 - warm_temp) * (y1 - cold_temp) +
      f10 * (warm_temp - x0) * (y1 - cold_temp) +
      f01 * (x1 - warm_temp) * (cold_temp - y0) +
      f11 * (warm_temp - x0) * (cold_temp - y0)) /
    ((x1 - x0) * (y1 - y0));

  return result;
}

function getAllHpData() {
  return hpData;
}

function interAndExtraPolate3D(x, y, xData, yData, zData) {
  /**
   * x: number
   * y: number
   * xData: array [] contains two item, example [10,20]
   * yData: array [] contains two item, example [35,45]
   * zData: 2d array [[], []], contains four item, example: [[4.48, 3.4], [5.87, 4.03]]
   *
   * The xdata, yData, zData should be corresponding: xData[i], yData[j], zData[i][j],
   * example, zData[0][1] (3.4) should be correponding to xData[0] (10) and yData[1](45).
   */

  // The inter- and extra-polationg along the xData. for example, x = 12,
  // To obtain inter- and extra-polationg like (12, 35) => COP0  (12,45) => COP1
  const interCOP = [];
  for (let i = 0; i < 2; i++) {
    //
    interCOP.push(
      (zData[0][i] * (x - xData[1]) - zData[1][i] * (x - xData[0])) /
        (xData[0] - xData[1])
    );
  }

  // Further inter- and extra-polationg along the yData
  return (
    (interCOP[0] * (y - yData[1]) - interCOP[1] * (y - yData[0])) /
    (yData[0] - yData[1])
  );
}

function findValues(value, arr) {
  /** 
  Examples:  Arr [10,20,30,40,50]
    - value: 5, return {idx: [0,1], val: [10,20]} to return 
    - value: 60, return {idx: [3,4], val: [40,50]} 
    - value: 25, return {idx: [1,2], val: [20,30]} 
  */

  // Outside value range
  if (value < arr[0]) {
    return { idx: [0, 1], val: [arr[0], arr[1]] };
  }
  if (value > arr[arr.length - 1]) {
    return {
      idx: [arr.length - 2, arr.length - 1],
      val: [arr[arr.length - 2], arr[arr.length - 1]]
    };
  }
  // Inside value range
  for (let i = 0; i < arr.length - 1; i++) {
    if (value >= arr[i] && value <= arr[i + 1]) {
      return { idx: [i, i + 1], val: [arr[i], arr[i + 1]] };
    }
  }
}

function findClosePointsFromProductData(
  evaporatorTemp,
  condensorTemp,
  evaporatorTempArray,
  condensorTempArray,
  copTable
) {
  /**
   * Return the close four points from the product table based on the condensor T and evaporator T
   */

  // Check the input length of arrays, at least two values
  console.assert(condensorTempArray.length >= 2);
  console.assert(evaporatorTempArray.length >= 2);

  // Check the input array size, the array size should be len(evaporatorTempArray) * len (condensorTempArray)
  console.assert(
    copTable.length === condensorTempArray.length &&
      copTable[0].length == evaporatorTempArray.length
  );

  // To find index and values
  let condensorTempRes = findValues(condensorTemp, condensorTempArray);
  let evaporatorTempRes = findValues(evaporatorTemp, evaporatorTempArray);

  //console.log(condensorTempRes)
  //console.log(evaporatorTempRes)
  //console.log(copTable[condensorTempRes.idx[0]][evaporatorTempRes.idx[0]])

  // COPTable [condensor_index, Evaportatorindex ] >> COP_value at condensorT and evaportatorT
  const v00 = copTable[condensorTempRes.idx[0]][evaporatorTempRes.idx[0]];
  const v01 = copTable[condensorTempRes.idx[1]][evaporatorTempRes.idx[0]];
  const v10 = copTable[condensorTempRes.idx[0]][evaporatorTempRes.idx[1]];
  const v11 = copTable[condensorTempRes.idx[1]][evaporatorTempRes.idx[1]];

  //evaporator[i], condensor[j], copValue[i][j]

  return {
    evaporatorTempValues: evaporatorTempRes.val,
    condensorTempValues: condensorTempRes.val,
    copValues: [
      [v00, v01],
      [v10, v11]
    ]
  };
}

function getCOPFromProductData(
  evaportorTemp,
  condensorTemp,
  heat_pump_model,
  cop_type
) {
  /**
   * The new method to get COP from product data,
   * This method will extrapolate the values if the temperatures are out of range of the product COP table.
   */

  // Find the heat pump product data from the database, retrieve the product data table
  console.assert(cop_type === 'chiller' || cop_type === 'heat_pump');
  const cop_data = hpData;
  // Get index of model
  let idx = '';

  Object.keys(cop_data).forEach((key) => {
    let object = cop_data[key];
    if (object.id === heat_pump_model) {
      idx = key;
    }
  });

  if (idx === '') {
    return null;
  }

  // Get data and convert to float
  let evaporatorTempArray, condensorTempArray, copTable;
  if (cop_type === 'heat_pump') {
    evaporatorTempArray = cop_data[idx]['coldTempHeat'];
    condensorTempArray = cop_data[idx]['warmTempHeat'];
    copTable = cop_data[idx]['copTableHeat'];
  } else if (cop_type === 'chiller') {
    evaporatorTempArray = cop_data[idx]['coldTempCool'];
    condensorTempArray = cop_data[idx]['warmTempCool'];
    copTable = cop_data[idx]['copTableCool'];
  }

  // Get the close four points from the product data table based on the evaporator T and condensor T
  let fourPoints = findClosePointsFromProductData(
    evaportorTemp,
    condensorTemp,
    evaporatorTempArray,
    condensorTempArray,
    copTable
  );
  // console.log(fourPoints)
  // Calculate the COP

  return interAndExtraPolate3D(
    evaportorTemp,
    condensorTemp,
    fourPoints.evaporatorTempValues,
    fourPoints.condensorTempValues,
    fourPoints.copValues
  );
}

const exportedFunctions = {
  getProductDataCop,
  getHpData,
  getAllHpData,
  findIndex,
  interAndExtraPolate3D,
  getCOPFromProductData
};
export default exportedFunctions;
