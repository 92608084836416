/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ListAlarmsResponse {
  alarms?: AlarmModel[] | null;
  nextPageToken?: string | null;
}

export interface AlarmModel {
  /** Gets the projection that can project a AlarmReadItem to a AlarmModel. */
  projection?: ExpressionOfFuncOfAlarmReadItemAndAlarmModel | null;
  sourceUri?: string | null;
  /** @format int32 */
  severity?: number;
  isActive?: boolean;
  canAcknowledge?: boolean;
  /** @format date-time */
  acknowledgedAt?: string | null;
  acknowledgedBy?: string | null;
  acknowledgedComment?: string | null;
  /**
   * Gets how many times the alarm has been active since it was acknowledged the last time.
   * The first active state doesn't count i.e. ACTIVE -> INACTIVE -> ACTIVE = 1.
   * @format int32
   */
  countActiveStatesSinceLastAcknowledgment?: number;
  /**
   * Gets the date when the alarm first became active after it was previously acknowledged or
   * never active.
   * @format date-time
   */
  firstActiveAtSinceLastAcknowledgement?: string | null;
  nodeId?: string | null;
  nodeName?: string | null;
  nodePath?: NodePathItem[] | null;
  properties?: Record<string, string>;
  message?: string | null;
}

export type ExpressionOfFuncOfAlarmReadItemAndAlarmModel = LambdaExpression & {
  typeCore?: string;
  publicType?: string;
};

export type LambdaExpression = Expression & {
  canCompileToIL?: boolean;
  canInterpret?: boolean;
  type?: string;
  typeCore?: string;
  publicType?: string;
  nodeType?: ExpressionType;
  parameters?: ParameterExpression[];
  name?: string | null;
  nameCore?: string | null;
  body?: Expression;
  returnType?: string;
  tailCall?: boolean;
  tailCallCore?: boolean;
  /** @format int32 */
  'system.Linq.Expressions.IParameterProvider.ParameterCount'?: number;
  /** @format int32 */
  parameterCount?: number;
};

export enum ExpressionType {
  Add = 'add',
  AddChecked = 'addChecked',
  And = 'and',
  AndAlso = 'andAlso',
  ArrayLength = 'arrayLength',
  ArrayIndex = 'arrayIndex',
  Call = 'call',
  Coalesce = 'coalesce',
  Conditional = 'conditional',
  Constant = 'constant',
  Convert = 'convert',
  ConvertChecked = 'convertChecked',
  Divide = 'divide',
  Equal = 'equal',
  ExclusiveOr = 'exclusiveOr',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqual = 'greaterThanOrEqual',
  Invoke = 'invoke',
  Lambda = 'lambda',
  LeftShift = 'leftShift',
  LessThan = 'lessThan',
  LessThanOrEqual = 'lessThanOrEqual',
  ListInit = 'listInit',
  MemberAccess = 'memberAccess',
  MemberInit = 'memberInit',
  Modulo = 'modulo',
  Multiply = 'multiply',
  MultiplyChecked = 'multiplyChecked',
  Negate = 'negate',
  UnaryPlus = 'unaryPlus',
  NegateChecked = 'negateChecked',
  New = 'new',
  NewArrayInit = 'newArrayInit',
  NewArrayBounds = 'newArrayBounds',
  Not = 'not',
  NotEqual = 'notEqual',
  Or = 'or',
  OrElse = 'orElse',
  Parameter = 'parameter',
  Power = 'power',
  Quote = 'quote',
  RightShift = 'rightShift',
  Subtract = 'subtract',
  SubtractChecked = 'subtractChecked',
  TypeAs = 'typeAs',
  TypeIs = 'typeIs',
  Assign = 'assign',
  Block = 'block',
  DebugInfo = 'debugInfo',
  Decrement = 'decrement',
  Dynamic = 'dynamic',
  Default = 'default',
  Extension = 'extension',
  Goto = 'goto',
  Increment = 'increment',
  Index = 'index',
  Label = 'label',
  RuntimeVariables = 'runtimeVariables',
  Loop = 'loop',
  Switch = 'switch',
  Throw = 'throw',
  Try = 'try',
  Unbox = 'unbox',
  AddAssign = 'addAssign',
  AndAssign = 'andAssign',
  DivideAssign = 'divideAssign',
  ExclusiveOrAssign = 'exclusiveOrAssign',
  LeftShiftAssign = 'leftShiftAssign',
  ModuloAssign = 'moduloAssign',
  MultiplyAssign = 'multiplyAssign',
  OrAssign = 'orAssign',
  PowerAssign = 'powerAssign',
  RightShiftAssign = 'rightShiftAssign',
  SubtractAssign = 'subtractAssign',
  AddAssignChecked = 'addAssignChecked',
  MultiplyAssignChecked = 'multiplyAssignChecked',
  SubtractAssignChecked = 'subtractAssignChecked',
  PreIncrementAssign = 'preIncrementAssign',
  PreDecrementAssign = 'preDecrementAssign',
  PostIncrementAssign = 'postIncrementAssign',
  PostDecrementAssign = 'postDecrementAssign',
  TypeEqual = 'typeEqual',
  OnesComplement = 'onesComplement',
  IsTrue = 'isTrue',
  IsFalse = 'isFalse'
}

export type ParameterExpression = Expression & {
  type?: string;
  nodeType?: ExpressionType;
  name?: string | null;
  isByRef?: boolean;
};

export interface Expression {
  nodeType?: ExpressionType;
  type?: string;
  canReduce?: boolean;
  debugView?: string;
}

export interface NodePathItem {
  id?: string | null;
  name?: string | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ListAlarmEventsResponse {
  alarmEvents?: AlarmEventModel[] | null;
  nextPageToken?: string | null;
}

export interface AlarmEventModel {
  /** Gets the projection that can project a AlarmEventReadItem to a AlarmEventModel. */
  projection?: ExpressionOfFuncOfAlarmEventReadItemAndAlarmEventModel | null;
  sourceUri?: string | null;
  /** @format int32 */
  severity?: number;
  isActive?: boolean;
  /** @format date-time */
  occurredAt?: string;
  /** @format date-time */
  acknowledgedAt?: string | null;
  acknowledgedBy?: string | null;
  acknowledgedComment?: string | null;
  /**
   * Gets the date when the alarm first became active after it was previously acknowledged or
   * never active.
   * @format date-time
   */
  firstActiveAtSinceLastAcknowledgement?: string | null;
  nodeId?: string | null;
  nodeName?: string | null;
  nodePath?: NodePathItem[] | null;
  properties?: Record<string, string>;
  message?: string | null;
}

export type ExpressionOfFuncOfAlarmEventReadItemAndAlarmEventModel =
  LambdaExpression & {
    typeCore?: string;
    publicType?: string;
  };

export interface AcknowledgeAlarmRequest {
  /** @minLength 1 */
  sourceUri: string;
  comment?: string | null;
}

export interface AcknowledgeAlarmsRequest {
  /** @minLength 1 */
  nodeId: string;
  filter?: string | null;
  comment?: string | null;
}

export interface AlarmsListAlarmsParams {
  /** The token that specifies where to continue fetching results. */
  pageToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface AlarmsListAlarmEventsParams {
  /** The page token to use when continuing the page enumeration. */
  pageToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface AlarmsAcknowledgeAlarmParams {
  /** if set to true then the method will block and await request. */
  awaitRequest?: boolean;
  acknowledge: string;
}

/**
 * @title Alarms API
 * @version v1
 * @baseUrl https://app-ec-alarm-test-weu-001-hfkk.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointUsingQueryParams
} from './APIUtils';

const apiTitle = APIGenType.AlarmsAPIGen;

export interface AlarmsAcknowledgeAlarmPath {
  acknowledge: string;
}

export interface AlarmsAcknowledgeAlarmsPath {
  batchAcknowledge: string;
}

const AlarmsAPIGen = {
  Alarms: {
    /**
     * @summary Gets all the alarms for the supplied node in a paged manner.
     */
    listAlarms: apiEndpointEmptyUsingQueryParams<
      AlarmsListAlarmsParams,
      ListAlarmsResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/alarms`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets all the alarm events for the supplied node in a paged manner.
     */
    listAlarmEvents: apiEndpointEmptyUsingQueryParams<
      AlarmsListAlarmEventsParams,
      ListAlarmEventsResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/alarms/events`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Acknowledges an alarm.
     */
    acknowledgeAlarm: apiEndpointUsingQueryParams<
      AcknowledgeAlarmRequest,
      Omit<AlarmsAcknowledgeAlarmParams, keyof AlarmsAcknowledgeAlarmPath>,
      void,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/alarms:acknowledge`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
 * @summary Acknowledges the alarms that has the supplied node in the node path. If a filter is supplied then only the
alarms matching that filter will be acknowledged.
*/
    acknowledgeAlarms: apiEndpoint<AcknowledgeAlarmsRequest, void, any>(
      Method.POST,
      `/api/v1/alarms:batchAcknowledge`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  }
};

export default AlarmsAPIGen;
