import React, { useMemo } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import classNames from 'classnames';
import styles from '../AlarmView.module.css';
import moment from 'moment';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import AlarmsAPIGen, {
  AlarmEventModel
} from 'ecto-common/lib/API/AlarmsAPIGen';
import {
  TimeFormats,
  getDefaultDateTimeFormat,
  getDefaultTimeFormat
} from 'ecto-common/lib/utils/dateUtils';
import { createAlarmListEventQuery } from './listQuery';

const columns: DataTableColumnProps<AlarmEventModel>[] = [
  {
    label: T.alarms.columns.status,
    dataKey: 'isActive',
    width: 120,
    flexGrow: 1,
    truncateText: true,
    dataFormatter: (isActive: boolean) => (
      <TableColumn
        title={
          <span className={classNames(isActive && styles.active)}>
            {isActive ? T.alarms.active : T.alarms.inactive}
          </span>
        }
      />
    )
  },
  {
    label: T.common.date,
    dataKey: 'occuredAt',
    width: 120,
    flexGrow: 1,
    dataFormatter: (alarmDate: string) => {
      const date = moment.tz(alarmDate, DEFAULT_TIMEZONE);
      return (
        <TableColumn
          title={date.format(getDefaultDateTimeFormat(TimeFormats.NONE))}
          subtitle={T.format(
            T.alarms.datesubtitleformat,
            date.format(getDefaultTimeFormat(TimeFormats.LONG_TIME))
          )}
        />
      );
    }
  },
  {
    label: T.alarms.columns.ackstatus,
    dataKey: 'acknowledgedBy',
    width: 120,
    flexGrow: 1,
    dataFormatter: (acknowledgedBy: string) => (
      <TableColumn
        title={
          acknowledgedBy != null
            ? T.alarms.acknowledged
            : T.alarms.notacknowledgedstatus
        }
        subtitle={
          acknowledgedBy != null
            ? acknowledgedBy
            : T.alarms.notacknowledgedstatus
        }
      />
    )
  },
  {
    label: T.alarms.columns.comment,
    dataKey: 'acknowledgedComment',
    width: 120,
    flexGrow: 1,
    dataFormatter: (alarmCommentMessage: string) => (
      <TableColumn title={alarmCommentMessage} />
    )
  }
];

interface AlarmHistoryModalProps {
  nodeId: string;
  onModalClose: () => void;
  name: string;
  sourceUri?: string;
  occuredAt?: string;
}

const AlarmHistoryModalV2 = ({
  nodeId,
  name,
  onModalClose,
  sourceUri,
  occuredAt
}: AlarmHistoryModalProps) => {
  const title = useMemo(
    () => T.format(T.alarms.historymodal.titleformat, name),
    [name]
  );

  const listQuery = AlarmsAPIGen.Alarms.listAlarmEvents.useInfiniteQuery(
    createAlarmListEventQuery({ nodeId, sourceUri }),
    {
      enabled: sourceUri != null
    },
    'nextPageToken'
  );

  const dataTableData = useMemo(() => {
    return listQuery.data?.pages.flatMap((x) => x.alarmEvents) ?? [];
  }, [listQuery.data?.pages]);

  let selectedIndex = 0;
  if (occuredAt) {
    selectedIndex = dataTableData.findIndex((x) => x.occurredAt === occuredAt);
  }

  return (
    <ActionModal
      isOpen={sourceUri != null}
      onModalClose={onModalClose}
      disableCancel
      className={styles.historyModal}
      headerIcon={Icons.History}
      title={title}
      onConfirmClick={onModalClose}
    >
      <DataTable<AlarmEventModel>
        columns={columns}
        isLoading={listQuery.isLoading}
        className={styles.historyModalTable}
        data={dataTableData}
        hasError={listQuery.isError}
        onUserScrolledToEndOfTable={
          !listQuery.isLoading && listQuery.hasNextPage
            ? listQuery.fetchNextPage
            : null
        }
        selectedIndexStyle={styles.selectedIndex}
        selectedIndex={selectedIndex}
      />
    </ActionModal>
  );
};

export default React.memo(AlarmHistoryModalV2);
