import React, { useCallback, useContext, useEffect, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';

import T from 'ecto-common/lib/lang/Language';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import _ from 'lodash';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import Icons from 'ecto-common/lib/Icons/Icons';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { modelFormIsValid } from 'ecto-common/lib/ModelForm/validateForm';
import UUID from 'uuidjs';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { InitialDashboard } from 'ecto-common/lib/DashboardEditor/DashboardConstants';
import NavLinkFix from 'ecto-common/lib/NavLinkFix/NavLinkFix';
import { getUserDashboardsUrl } from 'js/utils/routeConstants';
import EditDashboardPropertiesModal from 'ecto-common/lib/DashboardEditor/EditDashboardPropertiesModal';
import {
  createCopyPanelToPersonalModels,
  hasFixedTargets,
  patchDashboardFile
} from 'js/components/DashboardPage/personalDashboardUtil';
import { useUpdateModelFormInput } from 'ecto-common/lib/ModelForm/formUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import {
  DashboardCopyPanelToPersonalFormState,
  DashboardPanel
} from 'ecto-common/lib/Dashboard/Panel';
import { DashboardResponseModel } from 'ecto-common/lib/API/APIGen';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import IdentityServiceAPIGenV2 from 'ecto-common/lib/API/IdentityServiceAPIGenV2';

const InitialInput: DashboardCopyPanelToPersonalFormState = {
  title: null,
  dashboard: null,
  useFixedLocation: false
};

interface DashboardCopyPanelToPersonalModalProps {
  panel?: DashboardPanel;
  isOpen?: boolean;
  onModalClose: () => void;
}

const DashboardCopyPanelToPersonalModal = ({
  panel,
  isOpen,
  onModalClose
}: DashboardCopyPanelToPersonalModalProps) => {
  const { nodeId: currentNodeId } = useContext(DashboardDataContext);
  const nodeMap = useOperatorSelector((state) => state.general.nodeMap);
  const node = getNodeFromMap(nodeMap, currentNodeId);

  const { userId } = useContext(UserContext);
  const [models, setModels] = useState(
    createCopyPanelToPersonalModels([], panel)
  );
  const [dashboards, setDashboards] = useState<DashboardResponseModel[]>([]);
  const [input, setInput] = useState<DashboardCopyPanelToPersonalFormState>({
    ...InitialInput
  });
  const [newDashboard, setNewDashboard] =
    useState<DashboardResponseModel>(null);
  const [isShowingAddNewDialog, showAddNewDialog, hideAddNewDialog] =
    useDialogState(false);
  const { tenantId } = useContext(TenantContext);

  const panelType = panel
    ? T.admin.dashboards.panels[
        panel.type.toLowerCase() as keyof typeof T.admin.dashboards.panels
      ]
    : '';

  const [isLoading, getUserDashboards] = usePromiseCall({
    promise: IdentityServiceAPIGenV2.User.getUserDashboards.promise,
    onSuccess: (dashboardsResponse) => {
      // TODO: Update to new model once the other places are fixed
      const newDashboards: DashboardResponseModel[] = _.map(
        dashboardsResponse.dashboards,
        (dashboard) => {
          return {
            ...dashboard,
            dashboardId: dashboard.id
          };
        }
      );

      setModels(createCopyPanelToPersonalModels(newDashboards, panel));
      setDashboards(newDashboards);
      setInput({
        ...InitialInput,
        dashboard: _.head(newDashboards),
        title: panel.title
      });
    },
    onError: () => {
      toastStore.addErrorToast(T.common.unknownerror);
    }
  });

  const [patchUserDashboardIsLoading, patchUserDashboard] = usePromiseCall({
    promise: patchDashboardFile,
    onSuccess: () => {
      onModalClose();
      const link = (
        <NavLinkFix
          key="dashboardUrl"
          to={getUserDashboardsUrl(
            tenantId,
            currentNodeId,
            input.dashboard.dashboardId
          )}
        >
          {input.dashboard.name}
        </NavLinkFix>
      );
      const body = T.format(T.dashboard.addpanel.successformat, link);
      toastStore.addSuccessToast(body);
    },
    onError: () => {
      toastStore.addErrorToast(T.dashboard.addpanel.error);
    }
  });

  const updateInputSetter = useUpdateModelFormInput(setInput);

  const onUpdateInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (name: string[], value: any) => {
      if (name[0] === 'dashboard' && _.isString(value)) {
        setNewDashboard({
          ...InitialDashboard,
          dashboardId: UUID.generate(),
          userId,
          name: isNullOrWhitespace(value) ? InitialDashboard.name : value
        });
        showAddNewDialog();
      } else {
        updateInputSetter(name, value);
      }
    },
    [userId, showAddNewDialog, setNewDashboard, updateInputSetter]
  );

  const onAddNewDashboard = useCallback(
    (createdDashboard: DashboardResponseModel) => {
      setModels(
        createCopyPanelToPersonalModels(
          _.sortBy(dashboards.concat(createdDashboard), 'name'),
          panel
        )
      );
      setInput((oldInput) => ({ ...oldInput, dashboard: createdDashboard }));
      hideAddNewDialog();
    },
    [hideAddNewDialog, dashboards, panel]
  );

  const onConfirm = useCallback(() => {
    patchUserDashboard(
      input.dashboard.dashboardId,
      panel,
      input.useFixedLocation,
      currentNodeId,
      input.title
    );
  }, [patchUserDashboard, currentNodeId, panel, input]);

  useEffect(() => {
    if (isOpen) {
      setInput({ ...InitialInput, title: panel.title });
      setModels(createCopyPanelToPersonalModels([], panel));
      getUserDashboards(null);
    }
  }, [isOpen, getUserDashboards, panel]);

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        onModalClose={onModalClose}
        onConfirmClick={onConfirm}
        title={T.format(T.dashboard.addpaneltopersonaltitleformat, panelType)}
        isLoading={isLoading || patchUserDashboardIsLoading}
        headerIcon={Icons.Add}
        disableActionButton={!modelFormIsValid(models, input)}
      >
        <ModelForm
          models={models}
          input={input}
          onUpdateInput={onUpdateInput}
        />
        {!hasFixedTargets(panel) && (
          <span>
            {T.format(
              T.dashboard.usefixedlocationhelpformat,
              <strong key="location-name">{node?.name ?? ''}</strong>
            )}
          </span>
        )}
      </ActionModal>
      <EditDashboardPropertiesModal
        isOpen={isShowingAddNewDialog}
        onCloseModal={hideAddNewDialog}
        isNew
        dashboard={newDashboard}
        onDashboardUpdated={onAddNewDashboard}
      />
    </>
  );
};

export default DashboardCopyPanelToPersonalModal;
