type ListQuery = {
  nodeId: string;
  orderBy?: string;
  sortDirection?: 'asc' | 'desc';
  searchFilter?: string;
};

type ListQueryResult = {
  $filter: string;
  $orderby: string;
  $top: number;
};

export const createAlarmListQuery = ({
  nodeId,
  orderBy,
  sortDirection,
  searchFilter
}: ListQuery): ListQueryResult => {
  const baseFilter = `nodePath/any(d:contains(d/id,'${nodeId}'))`;
  const filter = searchFilter
    ? `${baseFilter} and properties/any(pair: pair/key eq 'signalName' and contains(tolower(pair/value),tolower('${searchFilter}')) or pair/key eq 'signalDescription' and contains(tolower(pair/value),tolower('${searchFilter}')))`
    : baseFilter;
  return {
    $filter: filter,
    $orderby:
      orderBy != null && sortDirection != null
        ? orderBy + ' ' + sortDirection
        : undefined,
    $top: 20
  };
};

type EventListQuery = ListQuery & {
  sourceUri: string;
};

export const createAlarmListEventQuery = ({
  nodeId,
  sourceUri,
  orderBy,
  sortDirection,
  searchFilter
}: EventListQuery): ListQueryResult => {
  const query = createAlarmListQuery({
    nodeId,
    orderBy,
    sortDirection,
    searchFilter
  });
  const filter = sourceUri
    ? `${query.$filter} and sourceUri eq '${sourceUri}'`
    : query.$filter;

  return {
    ...query,
    $filter: filter
  };
};
