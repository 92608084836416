import { useContext, useMemo } from 'react';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { AuthenticationContext } from 'ecto-common/lib/hooks/useAuthentication';
import { useSimpleEventHubConnection } from 'ecto-common/lib/EventHubConnection/useSimpleEventHubConnection';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

export type BuildEvent = {
  buildId: string;
  status: string;
};

export const useBuildUpdates = (
  onUpdate: (event: BuildEvent) => void,
  onError: (error: unknown) => void
) => {
  const { scopes, msalConfiguration, currentAccount } = useContext(
    AuthenticationContext
  );
  const methods = useMemo(
    () => [
      {
        method: 'buildStatusUpdate',
        handler: onUpdate
      }
    ],
    [onUpdate]
  );

  const plannerScopes = useMemo(() => [scopes.ectoplanner], [scopes]);
  const url = useMemo(
    () => getApiEnvironment().urls.ectoplannerHubsUrl + '/status',
    []
  );
  const { contextSettings } = useContext(TenantContext);

  useSimpleEventHubConnection(
    contextSettings,
    onError,
    url,
    plannerScopes,
    msalConfiguration,
    currentAccount,
    methods
  );
};
