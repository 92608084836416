import React, { useContext, useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { SignalCollectionActions } from 'js/modules/signalCollections/signalCollections';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import {
  useOperatorSelector,
  useOperatorDispatch
} from 'js/reducers/storeOperator';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const GraphConfirmDeleteDialog = () => {
  const dispatch = useOperatorDispatch();
  const signalCollectionActions = useMemo(
    () => bindActionCreators(SignalCollectionActions, dispatch),
    [dispatch]
  );
  const confirmDeleteId = useOperatorSelector(
    (state) => state.signalCollections.confirmDeleteId
  );
  const collections = useOperatorSelector(
    (state) => state.signalCollections.signalCollections
  );
  const deleteCollection = _.find(collections, { id: confirmDeleteId });
  const { contextSettings } = useContext(TenantContext);

  return (
    <ActionModal
      compact
      onModalClose={() =>
        signalCollectionActions.confirmDeleteSignalCollection(
          contextSettings,
          null
        )
      }
      isOpen={Boolean(confirmDeleteId)}
      headerIcon={Icons.Delete}
      title={T.graphs.confirmdelete.title}
      actionText={T.common.delete}
      onConfirmClick={() =>
        signalCollectionActions.confirmDeleteSignalCollection(
          contextSettings,
          confirmDeleteId
        )
      }
    >
      {T.format(T.graphs.confirmdelete.message, deleteCollection?.name)}
    </ActionModal>
  );
};

export default GraphConfirmDeleteDialog;
