import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';
import styles from './File.module.css';

const Fuse = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 42 42" {...props}>
    <g>
      <path
        className={styles.cls1}
        d="M32,13.77V35.44a.56.56,0,0,1-.56.56H11.56a.56.56,0,0,1-.56-.56V6.56A.56.56,0,0,1,11.56,6h12.7a.56.56,0,0,1,.4.17l7.17,7.21A.56.56,0,0,1,32,13.77Z"
      />
      <path
        className={styles.cls1}
        d="M22,6h1.21A1.79,1.79,0,0,1,25,7.79v4.65a.56.56,0,0,0,.56.56h4.65A1.79,1.79,0,0,1,32,14.79V17"
      />
    </g>
  </SvgIcon>
);

export default Fuse;
