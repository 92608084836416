import React from 'react';
import _ from 'lodash';
import { useParams } from 'react-router';

import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';

import OperatorPage from 'js/components/OperatorPage/OperatorPage';

import locationSections from './locationSections';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';

const Location = () => {
  const nodeMap = useOperatorSelector((state) => state.general.nodeMap);
  const params = useParams<NodeParams>();
  const { nodeId, page } = params;
  const selectedNode = nodeId && getNodeFromMap(nodeMap, nodeId);
  const item = _.find(locationSections, { route: page });
  const noLocations = selectedNode === undefined;

  return (
    <OperatorPage
      content={
        <>
          {selectedNode && item && item.renderContent(page, selectedNode)}

          {!selectedNode && (
            <ToolbarContentPage
              title={T.location.error.find}
              wrapContent={noLocations}
            >
              {noLocations && T.location.error.nolocations}
            </ToolbarContentPage>
          )}
        </>
      }
    />
  );
};

export default Location;
