import React, { useContext, useMemo } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import HelpPaths from 'ecto-common/help/tocKeys';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants';

import PowerControl from 'js/components/EMPTools/PowerControl/PowerControl';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { SignalGroupLiteResponseModel } from 'ecto-common/lib/API/APIGen';

const collectPowerControls = (
  selectedNode: SingleGridNode
): SignalGroupLiteResponseModel[] => {
  return _.map(selectedNode.powerControls || [], (control) => ({
    ...control,
    parentName: selectedNode.name
  }));
};

interface PowerControlPageProps {
  selectedNode: SingleGridNode;
}

const PowerControlPage = ({ selectedNode }: PowerControlPageProps) => {
  const controls = useMemo(() => {
    return collectPowerControls(selectedNode).filter(
      (control) => control.name === 'Power Control Heating'
    );
    // TODO: We just want HEATING, need improved filtering of PowerControl Heating.
  }, [selectedNode]);
  const { tenantResources } = useContext(TenantContext);

  if (!hasAccessToResource(ResourceType.POWER_CONTROL, tenantResources)) {
    return (
      <ToolbarContentPage
        title={T.location.tabs.powercontrol}
        showLocationPicker={false}
      >
        {T.powercontrol.unauthorized.text}
      </ToolbarContentPage>
    );
  }

  return (
    <ToolbarContentPage
      title={T.location.tabs.powercontrol}
      padContent={false}
      helpPath={HelpPaths.docs.operator.powercontrol}
    >
      <PowerControl controls={controls} selectedNode={selectedNode} />
    </ToolbarContentPage>
  );
};

export default PowerControlPage;
