export enum FileFormatOptions {
  CSV = 'CSV',
  Excel = 'Excel',
  SVG = 'SVG',
  PNG = 'PNG',
  JPEG = 'JPEG',
  PDF = 'PDF'
}

export const FileFormatTypeStrings: Record<string, string> = {
  [FileFormatOptions.PNG]: 'image/png',
  [FileFormatOptions.JPEG]: 'image/jpeg',
  [FileFormatOptions.PDF]: 'application/pdf',
  [FileFormatOptions.SVG]: 'image/svg+xml'
};
