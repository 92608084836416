import { DashboardCollectionNodeRelationsResponseModel } from 'ecto-common/lib/API/APIGen';
import { SET_NODE_DASHBOARD_COLLECTION_RELATIONS } from 'js/actions/actionTypes';

export function setNodeDashboardCollectionRelations(
  relations: DashboardCollectionNodeRelationsResponseModel
) {
  return {
    type: SET_NODE_DASHBOARD_COLLECTION_RELATIONS,
    payload: relations.nodeToDashboardCollectionRelations
  };
}
