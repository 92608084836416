import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import API from 'ecto-common/lib/API/API';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';

import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import {
  commaSeparateCollectionWithAnd,
  isNullOrWhitespace
} from 'ecto-common/lib/utils/stringUtils';
import {
  getNodeFromMap,
  isRootNodeId
} from 'ecto-common/lib/utils/locationUtils';
import {
  BuildingStatusDefault,
  BuildingStatusText
} from 'ecto-common/lib/utils/buildingStatusUtil';

import styles from './AlarmConfirmAllModal.module.css';
import { BuildingStatus } from 'ecto-common/lib/API/APIGen';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';

interface AlarmConfirmAllModalProps {
  nodeId: string;
  isOpen?: boolean;
  onModalClose: () => void;
  buildingStatuses?: BuildingStatus[];
  searchPhrase?: string;
}

const AlarmConfirmAllModal = ({
  nodeId,
  isOpen,
  onModalClose,
  buildingStatuses,
  searchPhrase
}: AlarmConfirmAllModalProps) => {
  const [acknowledgeAllComment, setAcknowledgeAllComment] = useState('');
  const showBuildingStatusText = useMemo(
    () =>
      _.difference(BuildingStatusDefault, buildingStatuses).length !== 0 &&
      !_.isEmpty(buildingStatuses),
    [buildingStatuses]
  );
  const buildingStatusText = useMemo(() => {
    if (!_.isEmpty(buildingStatuses)) {
      const _buildingStatusText = _.values(
        _.pick(BuildingStatusText, buildingStatuses)
      );

      return commaSeparateCollectionWithAnd(_buildingStatusText);
    }

    return null;
  }, [buildingStatuses]);

  useEffect(() => {
    if (isOpen) {
      setAcknowledgeAllComment('');
    }
  }, [isOpen]);

  const [isLoading, confirmAllAlarms] = usePromiseCall({
    promise: API.Alarms.acknowledgeAlarmsAll,
    onSuccess: () => {
      toastStore.addSuccessToast(T.alarms.acknowledgeallstatus.success);
      onModalClose();
    },
    onError: () => {
      toastStore.addErrorToast(T.alarms.acknowledgeallstatus.failure);
    }
  });

  const nodeMap = useCommonSelector((state) => state.general.nodeMap);

  const onConfirmAllAlarms = useCallback(() => {
    const _nodeId = isRootNodeId(nodeId) ? null : nodeId;
    const node = getNodeFromMap(nodeMap, nodeId);
    confirmAllAlarms(
      _nodeId,
      node.grid,
      acknowledgeAllComment,
      buildingStatuses,
      searchPhrase
    );
  }, [
    acknowledgeAllComment,
    buildingStatuses,
    confirmAllAlarms,
    nodeId,
    nodeMap,
    searchPhrase
  ]);

  const disableActionButton = isNullOrWhitespace(acknowledgeAllComment);

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setAcknowledgeAllComment(event.target.value);
    },
    []
  );

  return (
    <ActionModal
      messageBodyClassName={styles.confirmBody}
      className={styles.confirmAllDialog}
      onModalClose={onModalClose}
      isOpen={isOpen}
      isLoading={isLoading}
      title={T.alarms.page.heading.acknowledgeallalarms}
      headerIcon={Icons.Alarm}
      actionText={T.alarms.acknowledge.action}
      disableActionButton={disableActionButton}
      onConfirmClick={onConfirmAllAlarms}
    >
      <>
        {showBuildingStatusText
          ? T.format(
              T.alarms.acknowledge.all.promptwithbuildingstatuses,
              buildingStatusText
            )
          : T.alarms.acknowledge.all.prompt}
      </>

      <TextInput
        autoFocus
        wrapperClassName={styles.acknowledgeAllCommentInput}
        value={acknowledgeAllComment}
        onChange={onChange}
        placeholder={T.alarms.acknowledge.comment}
      />
    </ActionModal>
  );
};

export default AlarmConfirmAllModal;
