import ArrayUtils from './arrayUtils';
import calcDiversity from './calcDiversity';
import calcNetworkLoss from './calcNetworkLoss';
import calcBes from './calcBes';
import getState from '../init_states/getState.js';
import createInputDict from './createInputDict';
import { round2Decimal } from './mathUtils';

// Add building from state to building list, including calculation of building energy system
function addBuilding(state) {
  let newBuilding = {};

  // Write attributes, which are later not saved on server to new state
  const attrList = [
    'spaceHeatInputs',
    'dhwInputs',
    'spaceCoolInputs',
    'processCoolInputs',
    'plugLoadsInputs',
    'emobInputs',
    'spaceHeatProfile',
    'dhwProfile',
    'spaceCoolProfile',
    'processCoolProfile',
    'plugLoadsProfile',
    'emobProfile'
  ];
  attrList.forEach((item) => {
    newBuilding[item] = state[item];
  });

  // Write building attributes, which are later saved on/loaded from the server in the exact same form
  const bldgParams = getState.getState('sBldg');
  bldgParams.forEach((item) => {
    newBuilding[item] = state[item];
  });

  // Filter main attributes, which are later saved on/loaded from the server in the exact same form
  const mainParams = getMainParams(state);

  // Calculate building energy system
  newBuilding = calcBes.calcBes(newBuilding, mainParams);

  // Add to building list (under first non-existing id)
  let i = 0;
  while (i < 999) {
    if (!(i in state.buildingList)) {
      state.buildingList[i] = newBuilding;
      break;
    }
    i++;
  }

  // Calculate sum and max of cumulated profiles of all buildings
  const allBldgsImportProfile = calcImportsAllBldgs(state.buildingList);

  // Update load profiles at energy hub
  const ehLoadProfiles = calcEhLoadProfiles(allBldgsImportProfile, mainParams);

  state.allBldgsImportProfile = allBldgsImportProfile;
  state.ehLoadProfiles = ehLoadProfiles;

  return state;
}

// Calculate load at energy hub
function calcEhLoadProfiles(allBldgsImportProfile, mainParams) {
  // Set diversity factors
  let divFactorHeat = 1;
  let divFactorCool = 1;
  if (mainParams.networkOption === 'separate') {
    divFactorHeat = Number(mainParams.diversityFactorHeat);
    divFactorCool = Number(mainParams.diversityFactorCold);
  } else if (mainParams.networkOption === 'lowEx') {
    divFactorHeat = Number(mainParams.diversityFactorLowEx);
    divFactorCool = Number(mainParams.diversityFactorLowEx);
  }
  // Calculate diversity
  const smoothedHeatProfile = calcDiversity.calcDiversityProfile(
    allBldgsImportProfile.heat,
    divFactorHeat
  );
  const smoothedCoolProfile = calcDiversity.calcDiversityProfile(
    allBldgsImportProfile.cool,
    divFactorCool
  );
  let profileEhHeat = [];
  let profileEhCool = [];
  let heatLossesLowEx = ArrayUtils.zeros(8760);

  if (mainParams.networkOption === 'separate') {
    // Heating network
    const lossFactorHeat = 1 / (1 / (mainParams.heatLossesRel / 100) - 1);
    let heatLosses =
      mainParams.heatLossesAbs === 0
        ? (ArrayUtils.sum(smoothedHeatProfile) * lossFactorHeat) / 8760
        : Number(mainParams.heatLossesAbs);
    const heatNetwLosses = ArrayUtils.createConstantArray(8760, heatLosses);
    profileEhHeat = ArrayUtils.arraySum(smoothedHeatProfile, heatNetwLosses);

    // Cooling network
    const lossFactorCool = 1 / (1 / (mainParams.coldLossesRel / 100) - 1);
    let coldLosses =
      mainParams.coldLossesAbs === 0
        ? (ArrayUtils.sum(smoothedCoolProfile) * lossFactorCool) / 8760
        : Number(mainParams.coldLossesAbs);
    const coldNetwLosses = ArrayUtils.createConstantArray(8760, coldLosses);
    profileEhCool = ArrayUtils.arraySum(smoothedCoolProfile, coldNetwLosses);
  } else if (mainParams.networkOption === 'lowEx') {
    if (mainParams.netwInsulation === 'uninsulated') {
      let groundTempProfile;
      if (mainParams.groundTempOption === 'variable') {
        // Calculate ground temperature
        groundTempProfile = calcNetworkLoss.calcGroundTemp(
          mainParams.weatherAirTemp,
          mainParams.installationDepth
        );
      } else if (mainParams.groundTempOption === 'const') {
        // Get constant ground temperature
        groundTempProfile = ArrayUtils.createConstantArray(
          8760,
          Number(mainParams.tempGroundConst)
        );
      }
      // Calculate heat losses
      heatLossesLowEx = calcNetworkLoss.calcLowExHeatLosses(
        mainParams,
        groundTempProfile
      );
    }
    // Calculate balancing in low-ex network
    let resDem = 0;
    for (let t = 0; t < 8760; t++) {
      resDem =
        smoothedHeatProfile[t] - smoothedCoolProfile[t] + heatLossesLowEx[t];
      if (resDem >= 0) {
        profileEhHeat.push(resDem);
        profileEhCool.push(0);
      } else {
        profileEhHeat.push(0);
        profileEhCool.push(-1 * resDem);
      }
    }
  }

  // Electricity network
  let pumpWorkHeatFac = 0;
  let pumpWorkColdFac = 0;
  if (mainParams.networkOption === 'separate') {
    pumpWorkHeatFac = mainParams.heatPumpWork;
    pumpWorkColdFac = mainParams.coldPumpWork;
  } else if (mainParams.networkOption === 'lowEx') {
    pumpWorkHeatFac = mainParams.lowExPumpWork;
    pumpWorkColdFac = mainParams.lowExPumpWork;
  }
  // TODO: Now a temporary solution to make pump work zero
  // Requested by Gustav on 2024-03-33
  pumpWorkHeatFac = 0;
  pumpWorkColdFac = 0;
  const pumpWorkHeat = ArrayUtils.scaleLin(
    profileEhHeat,
    pumpWorkHeatFac / 100
  );
  const pumpWorkCool = ArrayUtils.scaleLin(
    profileEhCool,
    pumpWorkColdFac / 100
  );
  const pumpWorkSum = ArrayUtils.arraySum(pumpWorkHeat, pumpWorkCool);

  const profileEhEl = ArrayUtils.arraySum(
    allBldgsImportProfile.elec,
    pumpWorkSum
  );

  const ehLoadProfiles = {
    heat: profileEhHeat,
    cool: profileEhCool,
    elec: profileEhEl
  };

  return ehLoadProfiles;
}

// Calculate sum of import profiles of all buildings
function calcImportsAllBldgs(buildingList) {
  const listDems = [
    'heatImportProfile',
    'coolImportProfile',
    'elImportProfile'
  ];

  let summedProfiles = {};
  listDems.forEach((dem) => {
    summedProfiles[dem] = ArrayUtils.zeros(8760);
  });

  Object.keys(buildingList).forEach((item) => {
    listDems.forEach((dem) => {
      if (buildingList[item][dem] == null) {
        console.error('Missing property', dem);
      } else if (buildingList[item][dem].length > 0) {
        summedProfiles[dem] = ArrayUtils.arraySum(
          summedProfiles[dem],
          buildingList[item][dem]
        );
      }
    });
  });

  const allBldgsImportProfile = {
    heat: summedProfiles.heatImportProfile,
    cool: summedProfiles.coolImportProfile,
    elec: summedProfiles.elImportProfile
  };

  return allBldgsImportProfile;
}

// calculate the sum of all demand profiles of all buildings
function calcDemandsAllBldgs(buildingList) {
  const listDems = [
    'spaceHeatProfile',
    'dhwProfile',
    'spaceCoolProfile',
    'processCoolProfile',
    'plugLoadsProfile',
    'emobProfile'
    //'heatImportProfile',
    //'coolImportProfile',
    //'elImportProfile'
  ];

  let summedProfilesAllBldgs = {};
  listDems.forEach((dem) => {
    summedProfilesAllBldgs[dem] = ArrayUtils.zeros(8760);
  });

  Object.keys(buildingList).forEach((item) => {
    listDems.forEach((dem) => {
      if (buildingList[item][dem].length > 0) {
        summedProfilesAllBldgs[dem] = ArrayUtils.arraySum(
          summedProfilesAllBldgs[dem],
          buildingList[item][dem]
        );
      }
    });
  });

  const heatSumProfile = ArrayUtils.arraySum(
    summedProfilesAllBldgs.spaceHeatProfile,
    summedProfilesAllBldgs.dhwProfile
  );

  const coolSumProfile = ArrayUtils.arraySum(
    summedProfilesAllBldgs.spaceCoolProfile,
    summedProfilesAllBldgs.processCoolProfile
  );

  const elecSumProfile = ArrayUtils.arraySum(
    summedProfilesAllBldgs.plugLoadsProfile,
    summedProfilesAllBldgs.emobProfile
  );

  const allBldgDemandsProfile = {};
  listDems.forEach((dem) => {
    allBldgDemandsProfile[dem] = summedProfilesAllBldgs[dem];
  });

  allBldgDemandsProfile['heat'] = heatSumProfile;
  allBldgDemandsProfile['cool'] = coolSumProfile;
  allBldgDemandsProfile['elec'] = elecSumProfile;

  return allBldgDemandsProfile;
}

// Return the heat / cool / el import profiles by the building energy type: transversal or traditional
function calcImportsAllBldgsByEnergySystemType(buildingList) {
  const energySystem = ['Transversal', 'Traditional'];

  const listDems = [
    'heatImportProfile',
    'coolImportProfile',
    'elImportProfile'
  ];

  let summedProfilesTraditional = {};
  let summedProfilesTransversal = {};

  listDems.forEach((dem) => {
    summedProfilesTraditional[dem] = ArrayUtils.zeros(8760);
  });

  listDems.forEach((dem) => {
    summedProfilesTransversal[dem] = ArrayUtils.zeros(8760);
  });

  Object.keys(buildingList).forEach((item) => {
    if (buildingList[item]['useTransversal']) {
      listDems.forEach((dem) => {
        if (buildingList[item][dem] == null) {
          console.error('Missing property', dem);
        } else if (buildingList[item][dem].length > 0) {
          summedProfilesTransversal[dem] = ArrayUtils.arraySum(
            summedProfilesTransversal[dem],
            buildingList[item][dem]
          );
        }
      });
    } else {
      listDems.forEach((dem) => {
        if (buildingList[item][dem] == null) {
          console.error('Missing property', dem);
        } else if (buildingList[item][dem].length > 0) {
          summedProfilesTraditional[dem] = ArrayUtils.arraySum(
            summedProfilesTraditional[dem],
            buildingList[item][dem]
          );
        }
      });
    }
  });

  const summedProfilesTraditionalExport = {
    heat: summedProfilesTraditional.heatImportProfile,
    cool: summedProfilesTraditional.coolImportProfile,
    elec: summedProfilesTraditional.elImportProfile
  };

  const summedProfilesTransveralExport = {
    heat: summedProfilesTransversal.heatImportProfile,
    cool: summedProfilesTransversal.coolImportProfile,
    elec: summedProfilesTransversal.elImportProfile
  };

  return {
    transversalEnergySystemsSummary: summedProfilesTransveralExport,
    traditionalEnergySystemsSummary: summedProfilesTraditionalExport
  };
}

// Remove building from building list
function deleteBuilding(state, id) {
  let newBuildingList = state.buildingList;

  // Delete building and rewrite ids of building list
  delete newBuildingList[id];
  newBuildingList = updateIds(newBuildingList);

  // Calculate sum and max of cumulated profiles of all buildings
  const allBldgsImportProfile = calcImportsAllBldgs(state.buildingList);

  // Update load profiles at energy hub
  const mainParams = getMainParams(state);
  const newEhLoadProfiles = calcEhLoadProfiles(
    allBldgsImportProfile,
    mainParams
  );

  let newState = {
    buildingList: newBuildingList,
    allBldgsImportProfile: allBldgsImportProfile,
    ehLoadProfiles: newEhLoadProfiles
  };

  return newState;
}

// Write building paramters into input form
function editBuilding(state, id) {
  let stateUpdate = {};
  const buildingSelected = state.buildingList[id];

  // Write building attributes, which saved on/loaded from the server
  const bldgParams = getState.getState('sBldg');
  bldgParams.forEach((item) => {
    stateUpdate[item] = buildingSelected[item];
  });

  const demList = [
    'spaceHeat',
    'dhw',
    'spaceCool',
    'processCool',
    'plugLoads',
    'emob'
  ];
  let allAttr = [];
  demList.forEach((demType) => {
    allAttr = createInputDict.getAttr(demType);
    allAttr.forEach((attr) => {
      stateUpdate[attr] = buildingSelected[demType + 'Inputs'][attr];
    });
    stateUpdate[demType + 'ProfileUploaded'] =
      buildingSelected[demType + 'Inputs'][demType + 'ProfileUploaded'];
    stateUpdate[demType + 'Profile'] =
      buildingSelected[demType + 'Inputs'][demType + 'Profile'];
  });

  // General attributes for all demand types
  stateUpdate['buildingType'] = buildingSelected.spaceHeatInputs.buildingType; // spaceHeatInputs used here, but could be all other demand types as well
  stateUpdate['buildingSubtype'] =
    buildingSelected.spaceHeatInputs.buildingSubtype;

  // Re-calculate all profiles
  stateUpdate['heatParamsChanged'] = true;
  stateUpdate['coolParamsChanged'] = true;
  stateUpdate['elecParamsChanged'] = true;

  // Switch to an input demand tab that actually exists for the building that is edited
  if (stateUpdate.checkCalcSpaceHeat | stateUpdate.checkCalcDhw) {
    stateUpdate['activeTab'] = 'heat';
  } else if (
    stateUpdate.checkCalcSpaceCool | stateUpdate.checkCalcProcessCool
  ) {
    stateUpdate['activeTab'] = 'cold';
  } else if (stateUpdate.checkCalcPlugLoads | stateUpdate.checkCalcEmob) {
    stateUpdate['activeTab'] = 'electricity';
  }

  // Scroll to building definition
  const element = document.getElementById('headerDefineNewBuilding');
  element.scrollIntoView();

  return stateUpdate;
}

// Shift building one up
function shiftUpBuilding(state, id) {
  let newBuildingList = {};
  const oldId = Number(id);
  let currId = 0;
  Object.keys(state.buildingList).forEach((item) => {
    if (currId === oldId - 1) {
      newBuildingList[currId] = state.buildingList[oldId];
    } else if (currId === oldId) {
      newBuildingList[currId] = state.buildingList[oldId - 1];
    } else {
      newBuildingList[currId] = state.buildingList[currId];
    }
    currId += 1;
  });
  return newBuildingList;
}

// Filter main attributes, which are later saved on/loaded from the server in the exact same form
function getMainParams(state) {
  const mainParamsList = getState.getState('sMain');
  let mainParams = {};
  mainParamsList.forEach((item) => {
    mainParams[item] = state[item];
  });
  return mainParams;
}

// Ensure that Id in building list goes from 0 to n without gaps
function updateIds(buildingList) {
  let newBuildingList = {};
  let newId = 0;
  Object.keys(buildingList).forEach((item) => {
    newBuildingList[newId] = buildingList[item];
    newId += 1;
  });
  return newBuildingList;
}

// Check if building has only uploaded time series (no calculation), then do not display building type
function onlyUploads(buildingSelected) {
  const demList = [
    'spaceHeat',
    'dhw',
    'spaceCool',
    'processCool',
    'plugLoads',
    'emob'
  ];
  let demStrCap = '';
  let onlyUploads = true;
  demList.forEach((dem) => {
    demStrCap = dem[0].toUpperCase() + dem.substring(1);
    if (
      !buildingSelected[dem + 'Inputs'][dem + 'ProfileUploaded'] &
      buildingSelected['checkCalc' + demStrCap]
    ) {
      onlyUploads = false;
    }
  });
  return onlyUploads;
}

// Recalculate energy hub demands when network temperatures/parameters are changed in network modal
function recalculateNetw(state, netwTempChanged) {
  let stateUpdate = {};
  // If network temperature was changed, recalculate building energy systems
  if (netwTempChanged) {
    let newBuildingList = {};
    let idx = 0;
    Object.keys(state.buildingList).forEach((bldg) => {
      let building = state.buildingList[bldg];

      // Re-calculate building energy system
      building = calcBes.calcBes(building, state);

      // Save building data in new state
      newBuildingList[idx] = building;
      idx += 1;
    });

    // Add building list to new state
    stateUpdate['buildingList'] = newBuildingList;
  } else {
    // If network temp. was not changed, building list is not updated
    stateUpdate['buildingList'] = state.buildingList;
  }

  // Re-calculate sum and max of cumulated profiles of all buildings
  stateUpdate['allBldgsImportProfile'] = calcImportsAllBldgs(
    stateUpdate['buildingList']
  );

  // Re-calculate load profiles at energy hub
  stateUpdate['ehLoadProfiles'] = calcEhLoadProfiles(
    stateUpdate['allBldgsImportProfile'],
    state
  );

  return stateUpdate;
}

const exportedFunctions = {
  deleteBuilding,
  editBuilding,
  shiftUpBuilding,
  addBuilding,
  calcEhLoadProfiles,
  calcImportsAllBldgs,
  calcDemandsAllBldgs,
  onlyUploads,
  recalculateNetw,
  calcImportsAllBldgsByEnergySystemType
};
export default exportedFunctions;
