import React, { useState, useCallback, MouseEventHandler } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import ProgressBar from 'ecto-common/lib/ProgressBar/ProgressBar';
import { ScheduleStatusText } from 'ecto-common/lib/constants';
import { AbortIcon } from 'ecto-common/lib/Icon';

import ScheduleTools from 'js/components/EMPTools/PowerControl/Dispatch/ScheduleTools';
import ScheduleSignals from 'js/components/EMPTools/PowerControl/Dispatch/ScheduleSignals';
import { formatScheduleDate } from 'js/components/EMPTools/PowerControl/Dispatch/utils/dateUtils';
import styles from 'js/components/EMPTools/PowerControl/Dispatch/Schedule.module.css';
import { ScheduleSourceTypeIds } from 'js/components/EMPTools/PowerControl/Dispatch/utils/scheduleConstants';
import { formatNumber } from 'ecto-common/lib/utils/stringUtils';
import { ScheduleResponseModel } from 'ecto-common/lib/API/APIGen';

export function formatScheduleStatus(status: string) {
  return ScheduleStatusText[status];
}

type ScheduleProps = {
  onEditClick: () => void;
  onDeleteClick: () => void;
  onAbortClick: () => void;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  schedule: ScheduleResponseModel;
  showShortcut: boolean;
  locationPath: string;
  className: string;
};

const Schedule = ({
  onAbortClick,
  onDeleteClick,
  onEditClick,
  schedule,
  showShortcut,
  locationPath,
  onClick,
  className
}: ScheduleProps) => {
  const [actionText, setActionText] = useState<string>(null);
  const [showActionModal, setShowActionModal] = useState(false);
  const [shouldDelete, setShouldDelete] = useState(false);
  const {
    startDate,
    endDate,
    status,
    signals,
    totalNumberOfBuildings,
    numberOfSucceededBuildings,
    totalPower,
    actualPower
  } = schedule;
  const transferPercentage =
    100 *
    (totalNumberOfBuildings !== 0
      ? numberOfSucceededBuildings / totalNumberOfBuildings
      : 0);

  const _onAbortClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setActionText(T.powercontrol.dispatch.schedule.state.abort);
      setShowActionModal(true);
      setShouldDelete(false);
    },
    []
  );

  const _onDeleteClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();
      setActionText(T.powercontrol.dispatch.schedule.state.delete);
      setShowActionModal(true);
      setShouldDelete(true);
    },
    []
  );

  const _onEditClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();

      onEditClick();
    },
    [onEditClick]
  );

  const onActionModalClose = useCallback(() => {
    setActionText(null);
    setShowActionModal(false);
    setShouldDelete(false);
  }, []);

  const onConfirmActionClick = useCallback(() => {
    if (shouldDelete) {
      onDeleteClick();
    } else {
      onAbortClick();
    }
    onActionModalClose();
  }, [shouldDelete, onDeleteClick, onAbortClick, onActionModalClose]);

  const formattedTotalPower = formatNumber(_.floor(totalPower / 1000, 2));
  const formattedActualPower = formatNumber(_.floor(actualPower / 1000, 2));
  const hasAvailablePower = _.isFinite(totalPower) && _.isFinite(actualPower);

  const isStopSchedule = schedule.sourceTypeId === ScheduleSourceTypeIds.STOP;
  const scheduleName = isStopSchedule
    ? T.powercontrol.dispatch.scheduletype.stop
    : T.powercontrol.dispatch.schedule.schedule;

  return (
    <>
      <div onClick={onClick} className={className}>
        <div className={styles.schedule}>
          <div className={styles.infoArea}>
            <strong
              className={classNames(isStopSchedule && styles.stopschedule)}
            >
              {scheduleName}
            </strong>

            <span className={styles.dates}>
              {formatScheduleDate(startDate)}

              <span className={styles.arrow}>&rarr;</span>

              {formatScheduleDate(endDate)}
            </span>

            <strong className={classNames(styles[status], styles.status)}>
              {formatScheduleStatus(status)}
            </strong>

            <div className={styles.progressArea}>
              {totalNumberOfBuildings > 0 && (
                <>
                  <ProgressBar percentage={transferPercentage} />

                  <label>
                    {T.format(
                      T.powercontrol.dispatch.schedule.affectedbuildings,
                      numberOfSucceededBuildings,
                      totalNumberOfBuildings
                    )}
                  </label>
                </>
              )}

              {hasAvailablePower && (
                <span>
                  {T.format(
                    T.powercontrol.dispatch.schedule.power,
                    formattedActualPower,
                    formattedTotalPower
                  )}
                </span>
              )}
            </div>
          </div>

          <ScheduleTools
            schedule={schedule}
            onDeleteClick={_onDeleteClick}
            onEditClick={_onEditClick}
            onAbortClick={_onAbortClick}
            showShortcut={showShortcut}
          />
        </div>

        <div className={styles.bottomInfo}>
          <span className={styles.location}>{locationPath}</span>

          <ScheduleSignals signals={signals} />
        </div>
      </div>

      <ActionModal
        compact
        onModalClose={onActionModalClose}
        actionText={T.powercontrol.dispatch.schedule.state.confirm}
        isOpen={showActionModal}
        title={T.powercontrol.dispatch.schedule.doaction.title}
        onConfirmClick={onConfirmActionClick}
        headerIcon={AbortIcon}
      >
        {T.format(
          T.powercontrol.dispatch.schedule.doaction.question,
          actionText
        )}
      </ActionModal>
    </>
  );
};

export default Schedule;
