import React, { ChangeEventHandler, Fragment, useCallback } from 'react';
import Heading from 'ecto-common/lib/Heading/Heading';
import T from 'ecto-common/lib/lang/Language';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { Moment } from 'moment';

import styles from '../ScheduleModal.module.css';
import _ from 'lodash';
import { formatHoursUTC } from '../utils/dateUtils';
import { startOfDay } from 'ecto-common/lib/utils/dateUtils';
import moment from 'moment';
import DateInput from 'ecto-common/lib/DateInput/DateInput';

export const TIME_TYPE_CALENDAR = 'TIME_TYPE_CALENDAR';
export const TIME_TYPE_OFFSET = 'TIME_TYPE_OFFSET';

const offsets = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const offsetOptions: GenericSelectOption<number>[] = [
  {
    value: 0,
    label: T.powercontrol.dispatch.schedule.immediately
  }
].concat(
  offsets.map((offset) => ({
    value: offset,
    label:
      formatHoursUTC(offset) +
      ' ' +
      T.powercontrol.dispatch.schedule.option.hours
  }))
);

const isValidDate = (date: Moment) => {
  const startOfToday = startOfDay(new Date());
  return date.isSameOrAfter(startOfToday);
};

interface StartTimeControlProps {
  onTimeTypeChanged(newTimeType: string): void;
  onStartOffsetChange(offset: number): void;
  onDateChanged(newDate: Moment): void;
  onDateFormatSetValid(isValid: boolean): void;
  timeType: string;
  startOffset: number;
  startDate: Moment;
  startTimeError: boolean;
  disableOffsetChange: boolean;
  timeFormat: boolean;
}

const StartTimeControl = ({
  onTimeTypeChanged,
  onStartOffsetChange,
  onDateChanged,
  onDateFormatSetValid,
  timeType,
  startOffset,
  startDate,
  startTimeError,
  disableOffsetChange,
  timeFormat
}: StartTimeControlProps) => {
  const startOffsetOption = _.find(offsetOptions, { value: startOffset });

  const _onDateChanged = useCallback(
    (date: Moment) => {
      onDateFormatSetValid(true);
      onDateChanged(moment(date));
    },
    [onDateChanged, onDateFormatSetValid]
  );

  const _onTimeTypeChanged: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => onTimeTypeChanged(e.target.value),
    [onTimeTypeChanged]
  );

  return (
    <Fragment>
      <Heading level={5}>
        {T.powercontrol.dispatch.schedule.header.start}
      </Heading>
      <div className={styles.flexboxContainer}>
        <input
          type="radio"
          checked={timeType === TIME_TYPE_OFFSET}
          disabled={disableOffsetChange}
          name="timeOffset"
          id={TIME_TYPE_OFFSET}
          onChange={_onTimeTypeChanged}
          value={TIME_TYPE_OFFSET}
        />
        <label htmlFor={TIME_TYPE_OFFSET}>
          {T.powercontrol.dispatch.schedule.option.startoffset}
        </label>
        <div className={styles.select}>
          <Select<GenericSelectOption<number>, false>
            isSearchable={false}
            name="form-field-name"
            maxMenuHeight={200}
            value={startOffsetOption}
            menuPlacement="auto"
            disabled={disableOffsetChange}
            onChange={(e) => onStartOffsetChange(e.value)}
            options={offsetOptions}
          />
        </div>
      </div>
      <div className={styles.flexboxContainer}>
        <input
          type="radio"
          checked={timeType === TIME_TYPE_CALENDAR}
          name="timeCalender"
          id={TIME_TYPE_CALENDAR}
          onChange={_onTimeTypeChanged}
          value={TIME_TYPE_CALENDAR}
        />
        <label htmlFor={TIME_TYPE_CALENDAR}>
          {T.powercontrol.dispatch.schedule.option.starttime}
        </label>
        <div className={styles.calendarInput}>
          <DateInput
            enabled={timeType === TIME_TYPE_CALENDAR}
            wrapContent
            compact={false}
            expandingWidth
            hasError={startTimeError}
            onChange={_onDateChanged}
            isValidDate={isValidDate}
            timeFormat={timeFormat}
            value={startDate}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(StartTimeControl);
