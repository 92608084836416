import demCalc from './demCalc';

function getAttr(type) {
  if (type === 'spaceHeat') {
    return [
      'annDemTotHeat',
      'checkPeakKnownHeat',
      'peakDemTotHeat',
      'checkHeatingPeriod',
      'heatingPeriodBegin',
      'heatingPeriodEnd', // Needed for calculation
      'annDemSpecHeat',
      'peakDemSpecHeat',
      'fullLoadHrsHeat' // For "edit building" function
    ];
  } else if (type === 'dhw') {
    return [
      'annDemTotDhw', // Needed for calculation
      'annDemSpecDhw',
      'shareDhw' // For "edit building" function
    ];
  } else if (type === 'spaceCool') {
    return [
      'annDemTotCool',
      'checkPeakKnownCool',
      'peakDemTotCool',
      'checkCoolingPeriod',
      'coolingPeriodBegin',
      'coolingPeriodEnd', // Needed for calculation
      'annDemSpecCool',
      'peakDemSpecCool',
      'fullLoadHrsCool' // For "edit building" function
    ];
  } else if (type === 'processCool') {
    return [
      'annDemTotProcessCool', // Needed for calculation
      'annDemSpecProcessCool' // For "edit building" function
    ];
  } else if (type === 'plugLoads') {
    return [
      'annDemTotPlugLoads', // Needed for calculation
      'annDemSpecPlugLoads' // For "edit building" function
    ];
  } else if (type === 'emob') {
    return [
      'annDemTotEmob', // Needed for calculation
      'distanceEmob',
      'consumptionEmob' // For "edit building" function
    ];
  }
}

function createInputDict(state, demStr) {
  const demStrCap = demStr[0].toUpperCase() + demStr.substring(1);

  let input = {};
  input[demStr + 'ProfileUploaded'] = state[demStr + 'ProfileUploaded'];
  input['checkCalc' + demStrCap] = state['checkCalc' + demStrCap];

  const allAttr = getAttr(demStr);
  allAttr.forEach((attr) => {
    input[attr] = state[attr];
  });

  // General attributes for all demand types
  input['buildingType'] = state.buildingType;
  input['buildingSubtype'] = state.buildingSubtype;

  input['considerTimeChange'] = state.considerTimeChange;
  input['considerHolidays'] = state.considerHolidays;
  input['firstDayOfYear'] = state.firstDayOfYear;

  input['doChange'] = state.countryData.timeChange.doChange;
  input['forwardDay'] = state.countryData.timeChange.forwardDay;
  input['backwardDay'] = state.countryData.timeChange.backwardDay;

  input['holidaysList'] = demCalc.convertHolidays(
    state.countryData.holidaysFixDate,
    state.countryData.holidaysVarDate,
    state.firstDayOfYear
  );
  input['schoolHolidaysList'] = demCalc.convertSchoolHolidays(
    state.countryData.schoolHolidays,
    state.firstDayOfYear
  );

  // Save existing profile if it was uploaded
  if (state[demStr + 'ProfileUploaded']) {
    input[demStr + 'Profile'] = state[demStr + 'Profile'];
  } else {
    // console.log("No profile uploaded.")
    input[demStr + 'Profile'] = [];
  }
  return input;
}

const exportedFunctions = {
  createInputDict,
  getAttr
};
export default exportedFunctions;
