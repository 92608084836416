import React from 'react';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';

const PlainBoxExample = () => (
  <PlainBox header="Example header">
    This is some content inside the Plainbox
  </PlainBox>
);

PlainBoxExample.description =
  'Note that we have to set a gray background color since the PlainBox background is white.';
PlainBoxExample.class = 'PlainBox';

export default PlainBoxExample;
