import { CustomModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import React, { useMemo } from 'react';
import EctoplannerArrayEditor, {
  EctoplannerArrayEditorField
} from 'js/components/Ectoplanner/EctoplannerArrayEditor';
import { EctoplannerFormEnvironment } from 'js/components/Ectoplanner/EctoplannerTypes';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

type EctoplannerNumericalArrayEditorProps = Omit<
  CustomModelEditorProps<EctoplannerForm, EctoplannerFormEnvironment>,
  'rawValue'
> & {
  rawValue: number[];
  label: string;
};

type WrappedNumber = {
  numberValue: number;
};

const defaultValue: WrappedNumber = {
  numberValue: 0
};

const EctoplannerNumericalArrayEditor = ({
  model,
  updateItem,
  rawValue,
  label,
  ...props
}: EctoplannerNumericalArrayEditorProps) => {
  const fields: EctoplannerArrayEditorField<WrappedNumber>[] = [
    {
      label,
      dataKey: 'numberValue',
      type: 'number'
    }
  ];

  // DataTable only supports objects, so we need to wrap the numbers in objects
  const wrappedNumbers = useMemo(() => {
    return (rawValue ?? []).map((value) => {
      return {
        numberValue: value
      };
    });
  }, [rawValue]);

  const _updateItem = (newValue: WrappedNumber[]) => {
    const newRawValue = newValue.map((value) => {
      return value.numberValue;
    });
    updateItem(newRawValue);
  };

  return (
    <EctoplannerArrayEditor<
      EctoplannerForm,
      EctoplannerFormEnvironment,
      WrappedNumber
    >
      fields={fields}
      updateItem={_updateItem}
      rawValue={wrappedNumbers}
      {...props}
      defaultItem={defaultValue}
      model={model}
    />
  );
};

export default React.memo(EctoplannerNumericalArrayEditor);
