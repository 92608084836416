import React, {
  Fragment,
  MouseEventHandler,
  useCallback,
  useState
} from 'react';
import { NavLink } from 'react-router-dom';

import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import Icons from 'ecto-common/lib/Icons/Icons';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import T from 'ecto-common/lib/lang/Language';

import { isImpactSignalOutsideRange } from 'js/components/EMPTools/PowerControl/Dispatch/utils/signals';
import styles from 'js/components/EMPTools/PowerControl/Dispatch/Components/TemperatureImpactSummary.module.css';
import TemperatureImpactBuildingsTable from 'js/components/EMPTools/PowerControl/Dispatch/Components/TemperatureImpactBuildingsTable';
import { BuildingTemperatureImpactPreviewResponseModel } from 'ecto-common/lib/API/APIGen';

interface TemperatureImpactSummaryProps {
  schedulePreviewBuildings?: BuildingTemperatureImpactPreviewResponseModel[];
  isLoading: boolean;
}

const TemperatureImpactSummary = ({
  schedulePreviewBuildings,
  isLoading
}: TemperatureImpactSummaryProps) => {
  const [isBuildingDialogVisible, setBuildingDialogVisible] = useState(false);
  const showBuildingsDialog: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.stopPropagation();
      setBuildingDialogVisible(true);
    },
    []
  );
  const hideBuildingDialog = useCallback(
    () => setBuildingDialogVisible(false),
    []
  );

  return (
    <Fragment>
      {isImpactSignalOutsideRange(schedulePreviewBuildings) && (
        <div className={styles.warning}>
          {T.powercontrol.dispatch.schedule.temperaturerangewarning}
        </div>
      )}
      <div className={styles.buildingInfo}>
        {!isLoading ? (
          <NavLink
            className={styles.buildingsLink}
            to="#"
            onClick={showBuildingsDialog}
            key="showBuildings"
          >
            {schedulePreviewBuildings?.length === 1 ? (
              <>{T.powercontrol.dispatch.schedule.showmostaffectedbuilding}</>
            ) : (
              <>
                {T.format(
                  T.powercontrol.dispatch.schedule.showmostaffectedbuildings,
                  schedulePreviewBuildings?.length
                )}
              </>
            )}
          </NavLink>
        ) : (
          <Spinner size={SpinnerSize.SMALL} />
        )}
      </div>
      <Modal
        className={styles.modal}
        onModalClose={hideBuildingDialog}
        isOpen={isBuildingDialogVisible}
      >
        <ModalHeader titleIcon={Icons.Building}>
          {
            T.powercontrol.dispatch.schedule.temperatureimpact.affectedbuildings
              .title
          }
        </ModalHeader>
        <ModalBody className={styles.modalBody} loading={isLoading}>
          <div className={styles.modalContent}>
            <TemperatureImpactBuildingsTable
              disabled={isLoading}
              buildings={schedulePreviewBuildings ?? []}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <LocalizedButtons.Ok onClick={hideBuildingDialog} />
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default React.memo(TemperatureImpactSummary);
