import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import styles from './AlarmViewFilterBox.module.css';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import T from 'ecto-common/lib/lang/Language';
import { AlarmSeverity, AlarmSeverityText } from 'ecto-common/lib/constants';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import Button from 'ecto-common/lib/Button/Button';
import _ from 'lodash';
import Options from 'ecto-common/lib/Options/Options';
import EventBox from 'ecto-common/lib/EventBox/EventBox';
import DateInput from 'ecto-common/lib/DateInput/DateInput';
import {
  BuildingStatusDefault,
  BuildingStatusOptions
} from 'ecto-common/lib/utils/buildingStatusUtil';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { BuildingStatus } from 'ecto-common/lib/API/APIGen';
import { Moment } from 'moment';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';

type AlarmViewFilterParams = {
  fromDate?: Moment;
  toDate?: Moment;
  severities: number[];
  isActive?: boolean;
  isAcknowledged?: boolean;
  buildingStatuses: BuildingStatus[];
};

export const INITIAL_FILTER_PARAMS: AlarmViewFilterParams = {
  fromDate: null,
  toDate: null,
  severities: [],
  isActive: null,
  isAcknowledged: null,
  buildingStatuses: BuildingStatusDefault
};

interface AlarmViewToggleListProps<ValueType> {
  title?: React.ReactNode;
  options?: GenericSelectOption<ValueType>[];
  values?: ValueType[];
  setValues?: React.Dispatch<React.SetStateAction<AlarmViewFilterParams>>;
  valueKey?: string;
}

const AlarmViewToggleList = <ValueType,>({
  title,
  options,
  values,
  setValues,
  valueKey
}: AlarmViewToggleListProps<ValueType>) => {
  const _onChange = useCallback(
    (newValues: GenericSelectOption<ValueType>[]) => {
      setValues((original) => ({
        ...original,
        [valueKey]: _.map(newValues, 'value')
      }));
    },
    [setValues, valueKey]
  );

  const selectedValues = _.map(values, (value) => {
    const foundOption = _.find(options, (option) => option.value === value);
    return {
      label: foundOption.label,
      value
    };
  });

  return (
    <KeyValueLine>
      <KeyValueGeneric keyText={title}>
        <Options
          isMulti
          options={options}
          value={selectedValues}
          onChange={_onChange}
        />
      </KeyValueGeneric>
    </KeyValueLine>
  );
};

interface AlarmViewBoolOptionProps {
  title?: React.ReactNode;
  activeOptionLabel?: React.ReactNode;
  inactiveOptionLabel?: React.ReactNode;
  value?: boolean;
  setValue?: React.Dispatch<React.SetStateAction<AlarmViewFilterParams>>;
  valueKey?: string;
}

const AlarmViewBoolOption = ({
  title,
  activeOptionLabel,
  inactiveOptionLabel,
  value,
  setValue,
  valueKey
}: AlarmViewBoolOptionProps) => {
  const options: GenericSelectOption<boolean>[] = [
    { label: activeOptionLabel, value: true },
    { label: inactiveOptionLabel, value: false }
  ];
  const selectedValue = _.find(options, { value });

  const onChange = useCallback(
    (newValue: GenericSelectOption<boolean>) => {
      setValue((original) => ({ ...original, [valueKey]: newValue?.value }));
    },
    [setValue, valueKey]
  );

  return (
    <KeyValueGeneric keyText={title}>
      <Options<boolean, false>
        options={options}
        value={selectedValue}
        onChange={onChange}
      />
    </KeyValueGeneric>
  );
};

interface AlarmViewFilterBoxProps {
  params: AlarmViewFilterParams;
  setParams: Dispatch<SetStateAction<AlarmViewFilterParams>>;
  updateResults: () => void;
  showEventFiltering?: boolean;
}

const AlarmViewFilterBox = ({
  params,
  setParams,
  updateResults,
  showEventFiltering
}: AlarmViewFilterBoxProps) => {
  const severityOptions = useMemo(() => {
    return [
      {
        label: AlarmSeverityText[AlarmSeverity.HIGH],
        value: AlarmSeverity.HIGH
      },
      {
        label: AlarmSeverityText[AlarmSeverity.MEDIUM],
        value: AlarmSeverity.MEDIUM
      },
      { label: AlarmSeverityText[AlarmSeverity.LOW], value: AlarmSeverity.LOW }
    ];
  }, []);

  return (
    <EventBox className={styles.eventFilterBox}>
      {showEventFiltering && (
        <>
          <AlarmViewToggleList
            title={T.alarms.columns.severity}
            options={severityOptions}
            setValues={setParams}
            values={params.severities}
            valueKey="severities"
          />

          <hr />

          <AlarmViewBoolOption
            title={T.alarms.columns.status}
            activeOptionLabel={T.alarms.active}
            inactiveOptionLabel={T.alarms.inactive}
            setValue={setParams}
            value={params.isActive}
            valueKey="isActive"
          />

          <AlarmViewBoolOption
            title={T.alarms.columns.ackstatus}
            activeOptionLabel={T.alarms.acknowledged}
            inactiveOptionLabel={T.alarms.notacknowledged}
            setValue={setParams}
            value={params.isAcknowledged}
            valueKey="isAcknowledged"
          />

          <hr />
        </>
      )}

      <AlarmViewToggleList
        title={T.alarms.filterparams.buildingstatus}
        options={BuildingStatusOptions}
        values={params.buildingStatuses}
        setValues={setParams}
        valueKey="buildingStatuses"
      />

      {showEventFiltering && (
        <>
          <hr />
          <KeyValueGeneric keyText={T.common.date}>
            <div className={styles.fromToContainer}>
              <DateInput
                onChange={(fromDate: Moment) => {
                  setParams((original) => ({ ...original, fromDate }));
                }}
                value={params.fromDate}
                label={T.common.dateinput.from}
                timeFormat
                wrapContent
                expandingWidth
                clearButton
                allowEmptyValue
              />

              <DateInput
                onChange={(toDate) => {
                  setParams((original) => ({ ...original, toDate }));
                }}
                value={params.toDate}
                label={T.common.dateinput.to}
                timeFormat
                wrapContent
                expandingWidth
                clearButton
                allowEmptyValue
              />
            </div>
          </KeyValueGeneric>
        </>
      )}

      <div className={styles.footer}>
        {showEventFiltering && (
          <GreyButton
            onClick={() => {
              setParams(INITIAL_FILTER_PARAMS);
            }}
          >
            {T.alarms.clearall}
          </GreyButton>
        )}

        <Button onClick={updateResults}>
          {showEventFiltering ? (
            <>
              {_.isEqual(params, INITIAL_FILTER_PARAMS)
                ? T.common.done
                : T.alarms.filter}
            </>
          ) : (
            <>{T.alarms.filter}</>
          )}
        </Button>
      </div>
    </EventBox>
  );
};

export default AlarmViewFilterBox;
