import React, { useMemo, useContext } from 'react';
import moment from 'moment';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import {
  getNodeFromMap,
  getFullPathFromMap,
  getEquipmentNode
} from 'ecto-common/lib/utils/locationUtils';
import T from 'ecto-common/lib/lang/Language';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';

import APIGen, {
  ActivityLogResponseModel,
  ActivityLogType,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';

import PagingFooter from 'ecto-common/lib/PagingFooter/PagingFooter';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { SortDirectionType } from 'ecto-common/lib/DataTable/SortDirection';
import { ActivityLogTypeTranslations } from 'js/containers/ActivityLogTypeTranslations';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import Icons from 'ecto-common/lib/Icons/Icons';
import Flex, { FlexItem } from 'ecto-common/lib/Layout/Flex';
import {
  TimeFormats,
  getDefaultDateTimeFormat
} from 'ecto-common/lib/utils/dateUtils';
import { getSignalsUrl } from 'js/utils/routeConstants';

interface LogViewProps {
  className?: string;
  nodeId: string;
  tableClassName?: string;
  activityLogTypes?: ActivityLogType[];
  onSortChange?: (orderBy: string, sortDirection: string) => void;
  currentPage: number;
  orderBy: string;
  sortDirection: SortDirectionType;
  searchString?: string;
  selectedSignalIds?: string[];
  onPageChange?: (newPage: number) => void;
}

const formatValue = (
  oldValue: number,
  row: ActivityLogResponseModel,
  units: Record<string, UnitResponseModel>
) => {
  if (oldValue == null || row.newValue == null) {
    return '';
  }

  const unit = units[row.unitId]?.unit;
  const oldValueString = formatNumberUnit(oldValue, unit);
  const newValueString = formatNumberUnit(row.newValue, unit);
  // Force width on the icon so the spacing is correct
  return (
    <Flex>
      <FlexItem>{oldValueString}</FlexItem>
      <FlexItem style={{ width: '14px' }}>
        <Icons.ArrowRight />
      </FlexItem>
      <FlexItem>{newValueString}</FlexItem>
    </Flex>
  );
};

type NodeColumnProps = {
  nodeId: string;
};

const NodeColumn = ({ nodeId }: NodeColumnProps) => {
  const { tenantId } = useContext(TenantContext);
  const nodeMap = useOperatorSelector((state) => state.general.nodeMap);
  const equipmentMap = useOperatorSelector(
    (state) => state.general.equipmentMap
  );

  const node = getNodeFromMap(nodeMap, nodeId);
  const equipmentNode =
    node == null ? getEquipmentNode(nodeId, nodeMap, equipmentMap) : null;

  let titleLink = getSignalsUrl(
    tenantId,
    node?.nodeId ?? equipmentNode?.nodeId,
    null
  );
  let title = node?.name;
  const subtitleLink = titleLink;

  if (equipmentNode != null) {
    const equipment = equipmentMap[nodeId];
    title = equipment?.name ?? equipmentNode?.name;
    titleLink = getSignalsUrl(
      tenantId,
      equipmentNode.nodeId,
      equipment.equipmentId
    );
  }

  const visibleNode = node ?? equipmentNode;
  const fullPath = getFullPathFromMap(
    nodeMap,
    visibleNode?.nodeId,
    nodeId,
    null
  );
  return (
    <TableColumn
      title={title}
      subtitle={fullPath}
      titleLink={titleLink}
      subtitleLink={subtitleLink}
    />
  );
};

const LogView = ({
  className,
  nodeId,
  tableClassName,
  activityLogTypes,
  onSortChange,
  currentPage = 0,
  orderBy,
  sortDirection,
  searchString,
  selectedSignalIds,
  onPageChange
}: LogViewProps) => {
  const nodeMap = useOperatorSelector((state) => state.general.nodeMap);

  const signalUnitTypesMap = useOperatorSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const columns: DataTableColumnProps<ActivityLogResponseModel>[] = useMemo(
    () => [
      {
        label: T.common.date,
        dataKey: 'created',
        flexGrow: 0,
        minWidth: 180,
        dataFormatter: (created, row) => {
          return (
            <TableColumn
              title={moment
                .utc(created)
                .local()
                .format(getDefaultDateTimeFormat(TimeFormats.LONG_TIME))}
              subtitle={row.username || <em>{T.logview.user.unknown}</em>}
            />
          );
        }
      },
      {
        label: T.logview.columns.location,
        dataKey: 'locationId',
        flexGrow: 1,
        minWidth: 180,
        dataFormatter: (locationId: string) => {
          return <NodeColumn nodeId={locationId} />;
        }
      },
      {
        label: T.logview.columns.message,
        dataKey: 'message',
        minWidth: 100
      },
      {
        label: T.logview.columns.logtype,
        dataKey: 'activityLogType',
        minWidth: 50,
        dataFormatter: (value) =>
          ActivityLogTypeTranslations[value as string] ?? value
      },
      {
        label: T.alarms.columns.signal,
        dataKey: 'signalName',
        minWidth: 30,
        dataFormatter: (name: string, row: ActivityLogResponseModel) => {
          return <TableColumn title={name} subtitle={row.signalDescription} />;
        }
      },
      {
        label: T.logview.columns.valuechange,
        dataKey: 'oldValue',
        minWidth: 30,
        dataFormatter: (oldValue: number, row: ActivityLogResponseModel) =>
          formatValue(oldValue, row, signalUnitTypesMap)
      }
    ],
    [signalUnitTypesMap]
  );

  const node = getNodeFromMap(nodeMap, nodeId);

  const additionalParams = useMemo(
    () => ({ SignalIds: selectedSignalIds }),
    [selectedSignalIds]
  );
  const api = useMemo(
    () =>
      selectedSignalIds
        ? APIGen.ActivityLog.get
        : APIGen.ActivityLog.getRecursive,
    [selectedSignalIds]
  );

  const { isLoadingPaginatedData, error, data } = api.useQuery(
    {
      LocationId: nodeId.startsWith(ROOT_NODE_ID) ? undefined : nodeId,
      ActivityLogTypes: activityLogTypes,
      Page: currentPage + 1,
      SortColumn: orderBy,
      SortOrder: sortDirection,
      SearchPhrase: searchString,
      Grid: node.grid,
      PageSize: 15,
      ...additionalParams
    },
    {
      keepPreviousData: true
    }
  );

  return (
    <div className={className}>
      <DataTable<ActivityLogResponseModel>
        data={data?.activityLogs}
        columns={columns}
        isLoading={isLoadingPaginatedData}
        hasError={error != null}
        className={tableClassName}
        onSortChange={onSortChange}
        sortBy={orderBy}
        sortDirection={sortDirection}
        noDataText={T.logview.nodatatext}
        useAllAvailableHeight
      />
      <PagingFooter
        totalPages={data?.totalPages ?? 0}
        page={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default typedMemo(LogView);
