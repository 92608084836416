import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback
} from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import ProcessMap from 'ecto-common/lib/ProcessMap/ProcessMap';
import useProcessMapDropdown from 'ecto-common/lib/ProcessMap/useProcessMapDropdown';
import { SignalsActions } from 'ecto-common/lib/modules/signals/signals';
import RemoteOptimisationSettings from 'js/components/EMPTools/RemoteOptimisation/RemoteOptimisationSettings';

import T from 'ecto-common/lib/lang/Language';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import {
  REQ_STATE_ERROR,
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS
} from 'ecto-common/lib/utils/requestStatus';

import styles from './SignalProvidersPage.module.css';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Notice from 'ecto-common/lib/Notice/Notice';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import HelpPaths from 'ecto-common/help/tocKeys';
import SignalsToolbar from 'js/components/SignalProvidersPage/SignalsToolbar';
import {
  useOperatorSelector,
  useOperatorDispatch
} from 'js/reducers/storeOperator';
import { FullSignalProviderResponseModel } from 'ecto-common/lib/API/APIGen';
import { getSignalsUrl } from 'js/utils/routeConstants';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { Moment } from 'moment';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import useEquipmentLinkAction from 'ecto-common/lib/utils/useEquipmentLinkAction';

const urlBuilder = (
  tenantId: string,
  newNodeId: string,
  equipmentId: string
) => {
  return getSignalsUrl(tenantId, newNodeId, equipmentId);
};

interface SignalProvidersPageProps {
  selectedNode?: SingleGridNode;
}

const SignalProvidersPage = ({ selectedNode }: SignalProvidersPageProps) => {
  const params = useParams<NodeParams>();
  const dispatch = useOperatorDispatch();
  const providersRequest = useOperatorSelector(
    (state) => state.signals.providersReqState
  );

  const isLoading = providersRequest.state === REQ_STATE_PENDING;
  const hasError = providersRequest.state === REQ_STATE_ERROR;
  const [selectedNodeId, setSelectedNodeId] = useState<string>(null);
  const { contextSettings } = useContext(TenantContext);

  const { nodeId } = params;

  useEffect(() => {
    setSelectedNodeId(params.subPage ?? nodeId);
  }, [selectedNode, params.subPage, nodeId]);

  const signalProviders: FullSignalProviderResponseModel[] = useMemo(() => {
    const currentNodeId = selectedNodeId;

    if (
      providersRequest.state === REQ_STATE_SUCCESS &&
      providersRequest.payload
    ) {
      return _(providersRequest.payload)
        .filter((provider) => provider.nodeIds.includes(currentNodeId))
        .uniqBy('signalProviderId')
        .value();
    }
    return [];
  }, [providersRequest.state, providersRequest.payload, selectedNodeId]);

  const {
    ProcessMapDropdown: EquipmentDropdown,
    showMenu: showEquipmentMenu,
    hideMenu: hideEquipmentMenu
  } = useProcessMapDropdown();

  useEffect(() => {
    if (selectedNode) {
      if (selectedNode.nodeId.startsWith(ROOT_NODE_ID)) {
        dispatch(SignalsActions.cancelRequests());
        dispatch(SignalsActions.resetRequests());
      } else {
        dispatch(SignalsActions.getProviders(contextSettings, selectedNode));
      }
    } else {
      dispatch(SignalsActions.cancelRequests());
    }

    return () => dispatch(SignalsActions.cancelRequests());
  }, [contextSettings, dispatch, selectedNode]);

  const selectedOptimisation = useMemo(() => {
    return _.head(
      _.find(selectedNode.equipments, { equipmentId: selectedNodeId })
        ?.linearOptimisations
    );
  }, [selectedNode, selectedNodeId]);

  const [signalsSearchFilter, setSignalsSearchFilter] = useState('');
  const [selectedSignalIds, setSelectedSignalIds] = useState<
    Record<string, ChartSignal>
  >({});
  const [fromDate, setFromDate] = useState<Moment>(null);

  const onOpenEquipmentType = useEquipmentLinkAction({
    node: selectedNode,
    showMenu: showEquipmentMenu,
    hideMenu: hideEquipmentMenu
  });

  const onMouseUpContainer = useCallback(() => {
    hideEquipmentMenu();
  }, [hideEquipmentMenu]);

  if (!selectedNode) {
    return undefined;
  }

  const isEmpty = signalProviders.length === 0;
  const isRootNode = selectedNode.nodeId.startsWith(ROOT_NODE_ID);
  return (
    <ToolbarContentPage
      selectEquipment
      title={T.location.tabs.signalproviders}
      padContent={false}
      wrapContent={false}
      urlBuilder={urlBuilder}
      helpPath={HelpPaths.docs.operator.signals}
      dockToolbar
      toolbarItems={
        <SignalsToolbar
          nodeId={nodeId}
          fromDate={fromDate}
          setFromDate={setFromDate}
          searchFilter={signalsSearchFilter}
          setSearchFilter={setSignalsSearchFilter}
          selectedSignalIds={selectedSignalIds}
          setSelectedSignalIds={setSelectedSignalIds}
        />
      }
    >
      {isRootNode && isEmpty && !isLoading && (
        <PlainBox className={styles.infoBox}>
          <Notice className={styles.emptyNotice} showHeader>
            {T.signalproviders.emptylist}
          </Notice>
        </PlainBox>
      )}
      <div onMouseUp={onMouseUpContainer}>
        {selectedOptimisation && (
          <PlainBox className={styles.optimisationBox}>
            <RemoteOptimisationSettings
              selectedOptimisation={selectedOptimisation}
            />
          </PlainBox>
        )}

        {!isRootNode && (
          <ProcessMap
            isLoading={isLoading}
            selectedSignalProviders={signalProviders}
            selectedNodeId={params.subPage == null ? params.nodeId : null}
            selectedEquipmentId={params.subPage}
            key={selectedNodeId ?? '-'}
            setSelectedSignalIds={setSelectedSignalIds}
            searchFilter={signalsSearchFilter}
            selectedSignalIds={selectedSignalIds}
            hasError={hasError}
            fromDate={fromDate}
            onOpenEquipmentType={onOpenEquipmentType}
          />
        )}
        <EquipmentDropdown />
      </div>
    </ToolbarContentPage>
  );
};

export default SignalProvidersPage;
