import ArrayUtils from './arrayUtils';
import changeBuildingList from './changeBuildingList';

// Sums of import profiles of all selected buildings
function calcResidualHeatFlows(state, pipeSizingModalState) {
  let allBldgsImportProfile = {
    heat: ArrayUtils.createConstantArray(8760, 0),
    cool: ArrayUtils.createConstantArray(8760, 0),
    elec: ArrayUtils.createConstantArray(8760, 0) // not needed
  };
  Object.keys(state.buildingList).forEach((bldg) => {
    if (pipeSizingModalState['bldg-' + bldg]) {
      allBldgsImportProfile['heat'] = ArrayUtils.arraySum(
        state.buildingList[bldg]['heatImportProfile'],
        allBldgsImportProfile['heat']
      );
      allBldgsImportProfile['cool'] = ArrayUtils.arraySum(
        state.buildingList[bldg]['coolImportProfile'],
        allBldgsImportProfile['cool']
      );
    }
  });

  // Parameters for function 'calcEhLoadProfiles'
  const params = {
    networkOption: state.networkOption,
    diversityFactorHeat: Number(state.diversityFactorHeat),
    diversityFactorCold: Number(state.diversityFactorCold),
    diversityFactorLowEx: Number(state.diversityFactorLowEx),
    heatLossesRel: 0,
    heatLossesAbs: 0,
    coldLossesRel: 0,
    coldLossesAbs: 0,
    heatPumpWork: 0,
    coldPumpWork: 0,
    lowExPumpWork: 0
  };

  // Calculate sum at pipe that supplies all downstream buildings with 'energy hub'-function
  const pipeLoadProfiles = changeBuildingList.calcEhLoadProfiles(
    allBldgsImportProfile,
    params
  );

  // Return the correct heat flow profile
  let pipeLoadProfile = [];
  if (state.networkOption === 'separate') {
    if (state.sizingSeparateNetwType === 'heat') {
      pipeLoadProfile = pipeLoadProfiles.heat;
    } else if (state.sizingSeparateNetwType === 'cool') {
      pipeLoadProfile = pipeLoadProfiles.cool;
    }
  } else if (state.networkOption === 'lowEx') {
    pipeLoadProfile = ArrayUtils.arraySum(
      pipeLoadProfiles.heat,
      pipeLoadProfiles.cool
    );
  }
  return pipeLoadProfile; // kW
}

// Calculates hourly velocity profile
function calcVelocityProfile(heatFlowProfile, diameter, state) {
  const waterProps = getPhysicalPropertiesWater(state);
  const crossSectionalArea = ((diameter * diameter) / 4) * Math.PI;
  let velocityFlowProfile = [];
  for (let t = 0; t < 8760; t++) {
    velocityFlowProfile.push(
      (heatFlowProfile[t] * 1000) /
        waterProps.heatCapacity /
        state.deltaTPipeSizing /
        waterProps.density /
        crossSectionalArea
    );
  }
  return velocityFlowProfile;
}

// Calculates hourly profile of specific pressure loss
function calcSpecPressureLossProfile(velocityProfile, diameter, state) {
  const waterProps = getPhysicalPropertiesWater(state);
  let specPressureLossProfile = [];
  let specPressureLoss = null;
  for (let t = 0; t < 8760; t++) {
    specPressureLoss = calcSpecPressureLoss(
      velocityProfile[t],
      diameter,
      waterProps,
      state
    );
    specPressureLossProfile.push(specPressureLoss);
  }
  return specPressureLossProfile;
}

// Calculate pressure loss
function calcSpecPressureLoss(velocity, diameter, waterProps, state) {
  const eps = state.pipeRoughnessPipeSizing / 1000;

  const Re = reynoldsNumber(
    diameter,
    velocity,
    waterProps['density'],
    waterProps['viscosity']
  );

  let lambda = calcLambdaTurbFlow(diameter, eps);

  lambda = solveColebrookEquation(eps, diameter, Re, lambda);

  const specPressureLoss = calcPressureLoss(
    waterProps['density'],
    lambda,
    diameter,
    velocity
  );

  return specPressureLoss;
}

// Calculate volume flow rate from heat flow
function calcFlowRatesFromHeatFlow(heatFlow, state) {
  const waterProps = getPhysicalPropertiesWater(state);
  const massFlowRate_kg_s =
    (heatFlow * 1000) / state.deltaTPipeSizing / waterProps.heatCapacity;
  const volFlowRate_m3_s = massFlowRate_kg_s / waterProps.density;
  return {
    volFlowRate_m3_s: volFlowRate_m3_s.toFixed(1),
    volFlowRate_m3_h: (volFlowRate_m3_s * 3600).toFixed(1),
    volFlowRate_ltr_s: (volFlowRate_m3_s * 1000).toFixed(1),
    massFlowRate_kg_s: massFlowRate_kg_s.toFixed(1),
    massFlowRate_t_h: ((massFlowRate_kg_s / 1000) * 3600).toFixed(1)
  };
}

// Pressure loss formula
function calcPressureLoss(density, lambda, diameter, velocity) {
  if (velocity === 0) {
    return 0;
  } else {
    return (((density * lambda) / diameter) * Math.pow(velocity, 2)) / 2;
  }
}

// Colebrook equation
function solveColebrookEquation(eps, diameter, Re, lambda) {
  const maxNumIter = 4;
  let iterNum = 1;
  let oldLambda = 100000;
  while ((Math.abs(oldLambda - lambda) > 0.01) & (iterNum < maxNumIter)) {
    oldLambda = lambda;
    lambda = colebrookEquation(eps, diameter, Re, lambda);
    iterNum += 1;
  }
  return lambda;
}

// Lambda for completely turbulent flow
function calcLambdaTurbFlow(diameter, eps) {
  const lambda = Math.pow(1.14 + 2 * Math.log10(diameter / eps), -2);
  return lambda;
}

// Reynolds number
function reynoldsNumber(diameter, velocity, density, viscosity) {
  return (diameter * velocity * density) / viscosity;
}

// RHS of Colebrook equation
function colebrookEquation(eps, diameter, Re, lambda) {
  const innerTerm = eps / diameter / 3.72 + 2.51 / (Re * Math.pow(lambda, 0.5));
  const newLambda = Math.pow(-2 * Math.log10(innerTerm), -2);
  return newLambda;
}

// Calculate specific annual pump work
function calcAnnualPumpWork(velocityProfile, diameter, pressureLossProfile) {
  const crossSectionalArea = ((diameter * diameter) / 4) * Math.PI;
  let annualPumpWork = 0;
  for (let t = 0; t < 8760; t++) {
    annualPumpWork +=
      crossSectionalArea * velocityProfile[t] * pressureLossProfile[t];
  }
  return (annualPumpWork * 3600) / 1000 / 3600; // J/m -> kWh/m
}

// Get relative deviation
function calcRelDeviationToOptimum(maxVelocity, diameter) {
  const optimumVelocity = {
    25: 0.9, // DN: velocity in m/s
    32: 0.93,
    40: 0.98,
    50: 1,
    60: 1.08,
    65: 1.12,
    80: 1.23,
    100: 1.45,
    125: 1.65,
    150: 1.85,
    200: 2.25,
    250: 2.4,
    300: 2.53,
    350: 2.58,
    400: 2.7,
    450: 2.8,
    500: 2.9,
    600: 2.95,
    700: 3.1,
    800: 3.25
  };
  return (
    Math.abs(maxVelocity - optimumVelocity[diameter]) /
    optimumVelocity[diameter]
  );
}

// Get three best diameters
function getBest3Diameters(recommendedDiam) {
  let diamList = [];
  diamList.push(recommendedDiam);
  const normDiameters = this.getNormDiameters();
  const index = normDiameters.indexOf(recommendedDiam);
  if (index > 0) {
    diamList.push(normDiameters[index - 1]);
  }
  if (index < normDiameters.length - 1) {
    diamList.push(normDiameters[index + 1]);
  }
  return diamList;
}

// Physical properties
function getPhysicalPropertiesWater(state) {
  if (state.networkOption === 'separate') {
    if (state.sizingSeparateNetwType === 'heat') {
      return {
        density: 983,
        viscosity: 0.0004655,
        heatCapacity: 4185
      };
    } else if (state.sizingSeparateNetwType === 'cool') {
      return {
        density: 1000,
        viscosity: 0.001308,
        heatCapacity: 4200
      };
    }
  } else if (state.networkOption === 'lowEx') {
    return {
      density: 987,
      viscosity: 0.0008913,
      heatCapacity: 4180
    };
  }
}

// Norm diameters
function getNormDiameters() {
  return [
    25, 32, 40, 50, 60, 65, 80, 100, 125, 150, 200, 250, 300, 350, 400, 450,
    500, 600, 700, 800
  ];
}

const exportedFunctions = {
  calcSpecPressureLoss,
  getNormDiameters,
  calcResidualHeatFlows,
  calcVelocityProfile,
  calcSpecPressureLossProfile,
  calcFlowRatesFromHeatFlow,
  calcRelDeviationToOptimum,
  getBest3Diameters,
  calcAnnualPumpWork
};
export default exportedFunctions;
