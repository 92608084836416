import React from 'react';
import styles from './ProcessMapHeaderColumn.module.css';
import classNames from 'classnames';

interface ProcessMapHeaderColumnProps {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}

const ProcessMapHeaderColumn = ({
  title,
  subtitle
}: ProcessMapHeaderColumnProps) => {
  return (
    <div className={styles.column}>
      <div
        className={classNames(
          styles.title,
          subtitle == null && styles.singleTitle
        )}
      >
        {title}
      </div>

      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  );
};

export default ProcessMapHeaderColumn;
