import React, { useMemo } from 'react';
import _ from 'lodash';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import {
  PlotType,
  PlotTypes
} from 'js/components/Ectoplanner/EctoplannerTypes';
import T from 'ecto-common/lib/lang/Language';
import { HOURS_IN_YEAR } from './EctoplannerTypes';
import {
  ectoplannerDayNames,
  ectoplannerMonthBins,
  ectoplannerMonthNames
} from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerGraphBrowserTypes';

const hourNames: string[] = new Array(HOURS_IN_YEAR)
  .fill(0)
  .map((_unused, index) => (index + 1).toString());

const averageTimeSeriesToDays = (timeSeries: number[]) => {
  let binned = binTimeSeriesToDays(timeSeries);

  for (let i = 0; i < binned.length; i++) {
    binned[i] /= 24.0;
  }

  return binned;
};

export const binTimeSeriesToDays = (timeSeries: number[]) => {
  let output = new Array(365).fill(0);
  let index = 0;

  for (let day = 0; day < output.length; day++) {
    for (let hour = 0; hour < 24; hour++, index++) {
      output[day] += timeSeries[index];
    }
  }

  return output;
};

const averageTimeSeriesToMonthsMWH = (timeSeries: number[]) => {
  let startOfMonth = 0;
  return ectoplannerMonthBins.map((daysInMonth) => {
    const hoursInMonth = daysInMonth * 24;
    const endOfCurrentMonthInHours = startOfMonth + hoursInMonth;
    let sum = 0;
    for (let hour = startOfMonth; hour < endOfCurrentMonthInHours; hour++) {
      sum += timeSeries[hour];
    }
    startOfMonth = endOfCurrentMonthInHours;
    return sum / hoursInMonth;
  });
};

const binTimeSeriesToMonthsMWH = (timeSeries: number[]) => {
  let curIndex = 0;

  let output = new Array(12).fill(0);

  for (let curMonth = 0; curMonth < ectoplannerMonthBins.length; curMonth++) {
    let startIndex = curIndex;
    let endIndex = curIndex + ectoplannerMonthBins[curMonth] * 24;
    for (let i = startIndex; i < endIndex; i++) {
      output[curMonth] += timeSeries[i] / 1000.0;
    }

    curIndex = endIndex;
  }

  return output;
};

type DemandGraphsModelEditorProps = {
  primaryGraph: number[];
  secondaryGraph?: number[];
  primaryColor: string;
  secondaryColor?: string;
  primaryName: string;
  secondaryName?: string;
  primaryGraphZones?: Highcharts.SeriesZonesOptionsObject[];
  stack?: boolean;
  disableLegend?: boolean;
  plotType?: PlotType;
};

export const EctoplannerDemandGraphs = ({
  primaryGraph,
  secondaryGraph,
  primaryColor,
  secondaryColor,
  primaryName,
  primaryGraphZones,
  secondaryName,
  plotType = PlotTypes.Annual,
  disableLegend = false,
  stack = false
}: DemandGraphsModelEditorProps) => {
  const chartType =
    plotType === PlotTypes.Monthly || plotType === PlotTypes.MonthlyCOP
      ? 'column'
      : 'line';

  const seriesTransformer: (timeSeries: number[]) => number[] = useMemo(() => {
    switch (plotType) {
      case PlotTypes.AnnualTemperature:
      case PlotTypes.AnnualPrice:
      case PlotTypes.AnnualCOP:
        return averageTimeSeriesToDays;
      case PlotTypes.MonthlyCOP:
        return averageTimeSeriesToMonthsMWH;
      case PlotTypes.Annual:
        return binTimeSeriesToDays;
      case PlotTypes.Monthly:
        return binTimeSeriesToMonthsMWH;
      case PlotTypes.DurationCurveCOP:
      case PlotTypes.DurationCurve:
        return (timeSeries: number[]) => [...timeSeries].sort((a, b) => b - a);
      default:
        return binTimeSeriesToDays;
    }
  }, [plotType]);

  let unit = T.ectoplanner.units.kwh;

  if (plotType === PlotTypes.Annual) {
    unit = T.ectoplanner.units.kwh;
  } else if (plotType === PlotTypes.Monthly) {
    unit = T.ectoplanner.units.mwh;
  } else if (
    plotType === PlotTypes.MonthlyCOP ||
    plotType === PlotTypes.DurationCurveCOP ||
    plotType === PlotTypes.AnnualCOP
  ) {
    unit = '';
  } else if (plotType === PlotTypes.DurationCurve) {
    unit = T.ectoplanner.units.kw;
  } else if (plotType === PlotTypes.AnnualPrice) {
    unit = T.ectoplanner.units.eurkwh;
  } else if (plotType === PlotTypes.AnnualTemperature) {
    unit = T.ectoplanner.units.degc;
  }

  const showDecimals =
    plotType === PlotTypes.DurationCurve ||
    plotType === PlotTypes.AnnualPrice ||
    plotType === PlotTypes.AnnualTemperature ||
    plotType === PlotTypes.MonthlyCOP ||
    plotType === PlotTypes.AnnualCOP ||
    plotType === PlotTypes.DurationCurveCOP;

  const config: Highcharts.Options = useMemo(() => {
    let categories: string[] = [];

    switch (plotType) {
      case PlotTypes.MonthlyCOP:
      case PlotTypes.Monthly:
        categories = ectoplannerMonthNames;
        break;
      case PlotTypes.AnnualTemperature:
      case PlotTypes.AnnualPrice:
      case PlotTypes.AnnualCOP:
      case PlotTypes.Annual:
        categories = ectoplannerDayNames;
        break;
      case PlotTypes.DurationCurveCOP:
      case PlotTypes.DurationCurve:
        categories = hourNames;
        break;
      default:
        break;
    }

    return {
      rangeSelector: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      plotOptions: {
        column: {
          animation: true,
          stacking: stack ? 'normal' : null
        }
      },
      credits: {
        enabled: false
      },
      scrollbar: {
        liveRedraw: false,
        enabled: false
      },
      chart: {
        type: chartType,
        zoomType: null,
        backgroundColor: null
      },
      legend: {
        verticalAlign: 'top',
        enabled: !disableLegend
      },
      xAxis: {
        categories,
        crosshair: true,
        type: 'category'
      },
      yAxis: {
        crosshair: false,
        opposite: false,
        labels: {
          format: showDecimals ? '{value:.1f}' : '{value:.0f}'
        }
      },
      tooltip: {
        valueDecimals: showDecimals ? 1 : 0,
        valueSuffix: ' ' + unit
      },
      series: _.compact([
        primaryGraph && {
          type: chartType,
          name: primaryName,
          data: seriesTransformer(primaryGraph),
          color: primaryColor,
          zones: primaryGraphZones,
          zoneAxis: 'x'
        },
        secondaryGraph && {
          type: chartType,
          name: secondaryName,
          data: seriesTransformer(secondaryGraph),
          color: secondaryColor
        }
      ])
    };
  }, [
    plotType,
    stack,
    chartType,
    disableLegend,
    showDecimals,
    unit,
    primaryGraph,
    primaryName,
    seriesTransformer,
    primaryColor,
    secondaryGraph,
    secondaryName,
    secondaryColor,
    primaryGraphZones
  ]);

  return (
    <>
      <div style={{ width: '100%', height: 300 }}>
        <StockChart enableAnimation config={config} />
      </div>
    </>
  );
};
