import { createStore } from 'zustand';
import {
  BuildGraphResponse,
  BuildGraphType
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import UUID from 'uuidjs';
import { Draft, produce } from 'immer';
import _ from 'lodash';
import { temporal } from 'zundo';
import { createContext } from 'react';
import T from 'ecto-common/lib/lang/Language';

type EctoplannerGraphStore = {
  loading: boolean;
  buildId: string;
  currentCollectionId: string;
  graphs: Record<string, BuildGraphResponse>;
  backendGraphs: Record<string, BuildGraphResponse>;
  setCurrentCollectionId: (collectionId: string) => void;
  updateGraph: (graph: BuildGraphResponse) => void;
  markGraphAsSaved: (collectionId: string) => void;
  createNewCollection: () => void;
  duplicateCollection: () => void;
  removeGraph: (collectionId: string) => void;
};

const createNewCollectionHelper = (draft: Draft<EctoplannerGraphStore>) => {
  let newId = UUID.generate();
  let numOthers = _.keys(draft.graphs).length;
  let suffix = '';
  if (numOthers > 0) {
    suffix = ' ' + (numOthers + 1);
  }

  let newCollection: BuildGraphResponse = {
    series: [],
    graphType: BuildGraphType.Line,
    name: T.ectoplanner.graphs.newgraph + suffix,
    id: newId,
    buildId: draft.buildId
  };

  draft.graphs[newCollection.id] = newCollection;
  draft.currentCollectionId = newCollection.id;
};

const createEctoplannerGraphStore = (
  initialGraphs: BuildGraphResponse[],
  buildId: string,
  loading: boolean
) => {
  let graphs = [...initialGraphs];
  let unsavedCollections: Record<string, boolean> = {};

  if (_.isEmpty(graphs)) {
    let newId = UUID.generate();
    let newCollection: BuildGraphResponse = {
      series: [],
      graphType: BuildGraphType.Line,
      name: T.ectoplanner.graphs.newgraph,
      id: newId,
      buildId
    };
    unsavedCollections[newCollection.id] = true;

    graphs.push(newCollection);
  }

  return createStore<EctoplannerGraphStore>()(
    temporal(
      (set) => ({
        loading,
        graphs: _.keyBy(graphs, (graph) => graph.id),
        backendGraphs: _.keyBy(_.cloneDeep(graphs), (graph) => graph.id),
        buildId,
        currentCollectionId: _.head(graphs).id,
        unsavedCollections,
        createNewCollection: () => {
          set((state) =>
            produce(state, (draft) => {
              createNewCollectionHelper(draft);
            })
          );
        },
        duplicateCollection: () => {
          set((state) =>
            produce(state, (draft) => {
              const currentCollection = draft.graphs[draft.currentCollectionId];
              let newId = UUID.generate();
              let otherCollections = _.filter(_.values(draft.graphs), (graph) =>
                graph.name.startsWith(currentCollection.name)
              );

              let numOthers = otherCollections.length;
              let suffix = ' ' + (numOthers + 1);

              let newCollection: BuildGraphResponse = {
                series: [...currentCollection.series],
                graphType: currentCollection.graphType,
                name: currentCollection.name + suffix,
                id: newId,
                buildId: draft.buildId
              };

              draft.graphs[newCollection.id] = newCollection;
              draft.currentCollectionId = newCollection.id;
            })
          );
        },
        removeGraph: (collectionId: string) => {
          set((state) =>
            produce(state, (draft) => {
              delete draft.graphs[collectionId];
              delete draft.backendGraphs[collectionId];

              draft.currentCollectionId = _.first(_.keys(draft.graphs)) ?? '';
              if (_.isEmpty(draft.graphs)) {
                createNewCollectionHelper(draft);
              }
            })
          );
        },
        updateGraph: (graph: BuildGraphResponse) => {
          set((state) =>
            produce(state, (draft) => {
              draft.graphs[graph.id] = graph;
            })
          );
        },
        setCurrentCollectionId: (collectionId: string) => {
          set((state) =>
            produce(state, (draft) => {
              draft.currentCollectionId = collectionId;
            })
          );
        },
        markGraphAsSaved: (collectionId: string) => {
          set((state) =>
            produce(state, (draft) => {
              draft.backendGraphs[collectionId] = _.cloneDeep(
                draft.graphs[collectionId]
              );
            })
          );
        }
      }),
      {
        partialize: (state) => {
          return _.omit(state, ['backendGraphs', 'markGraphAsSaved']);
        },
        // Use this so that markGraphAsSaved doesn't store an undo entry.
        equality: (pastState, currentState) =>
          _.isEqual(pastState, currentState),
        handleSet: (handleSet) =>
          _.throttle((state) => {
            handleSet(state);
          }, 600)
      }
    )
  );
};

export const emptyEctoplannerGraphStore = createEctoplannerGraphStore(
  [],
  '',
  true
);

export default createEctoplannerGraphStore;
export type EctoplannerGraphStoreType = ReturnType<
  typeof createEctoplannerGraphStore
>;
export const EctoplannerGraphStoreContext =
  createContext<EctoplannerGraphStoreType>(null);
