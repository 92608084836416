import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { useLocation, useHistory, useParams } from 'react-router';
import queryString from 'query-string';

import { useAlarmEventHubSubscription } from 'ecto-common/lib/EventHubConnection/EventHubConnectionHooks';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import Button from 'ecto-common/lib/Button/Button';
import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';

import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import HelpPaths from 'ecto-common/help/tocKeys';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import { matchPath } from 'react-router';

import AlarmConfirmAllModal from './AlarmConfirmAllModal';
import AlarmTableView from './AlarmTableView';
import styles from './AlarmView.module.css';
import AlarmViewFilterBox, {
  INITIAL_FILTER_PARAMS
} from './AlarmViewFilterBox';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import UrlContext from 'ecto-common/lib/hooks/UrlContext';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';

const subPages = Object.freeze({
  eventList: 'eventList',
  signalClusters: 'signalClusters'
});

interface AlarmViewProps {
  nodeId: string;
}

const AlarmView = ({ nodeId }: AlarmViewProps) => {
  const [showFiltering, setShowFiltering] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const params = useParams<NodeParams>();
  const [filterParams, setFilterParams] = useState(INITIAL_FILTER_PARAMS);
  const [committedFilterParams, setCommittedFilterParams] = useState(
    INITIAL_FILTER_PARAMS
  );
  const { tenantId } = useContext(TenantContext);

  const queryParams = queryString.parse(location.search);
  const search = queryParams.search as string;

  const [showConfirmAllDialog, onShowConfirmAllDialog, onHideConfirmAllDialog] =
    useDialogState(false);
  const [reloadTrigger, triggerReload] = useReloadTrigger();

  const { getAlarmUrl, locationRoute } = useContext(UrlContext);

  const updateSubpage = useCallback(
    (subPage: string) => {
      history.push(getAlarmUrl(tenantId, nodeId, subPage));
    },
    [nodeId, history, tenantId, getAlarmUrl]
  );

  const buildingStatuses = filterParams?.buildingStatuses;
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const node = getNodeFromMap(nodeMap, nodeId);
  useAlarmEventHubSubscription(
    node.grid,
    nodeId,
    triggerReload,
    buildingStatuses
  );

  const onUpdateSearchPhrase = useCallback(
    (newSearch: string) => {
      if (newSearch !== '') {
        history.replace({
          search: '?' + queryString.stringify({ search: newSearch })
        });
      } else {
        history.replace({ search: null });
      }
    },
    [history]
  );

  const showEventList = params.subPage === subPages.eventList;
  const toggleEventFiltering = useCallback(() => {
    setShowFiltering((s) => !s);
  }, [setShowFiltering]);

  useEffect(() => {
    setFilterParams(INITIAL_FILTER_PARAMS);
    setCommittedFilterParams(INITIAL_FILTER_PARAMS);
    setShowFiltering(false);
  }, [
    params.subPage,
    setFilterParams,
    setCommittedFilterParams,
    setShowFiltering
  ]);

  const updateResults = useCallback(() => {
    setShowFiltering(false);
    setCommittedFilterParams({ ...filterParams });

    if (queryParams.page && queryParams.page !== '0') {
      let newSearch = {
        ...queryString.parse(window.location.search),
        page: '0'
      };

      history.replace({
        search: '?' + queryString.stringify(newSearch)
      });
    }
  }, [filterParams, setCommittedFilterParams, history, queryParams.page]);

  useEffect(() => {
    if (params.subPage == null) {
      history.replace(
        getAlarmUrl(params.tenantId, params.nodeId, subPages.signalClusters)
      );
    }
  }, [params, history, getAlarmUrl]);

  const toolbarItems = (
    <>
      <ToolbarSearch onChange={onUpdateSearchPhrase} value={search} />

      <ToolbarItem>
        {showFiltering && (
          <AlarmViewFilterBox
            params={filterParams}
            setParams={setFilterParams}
            updateResults={updateResults}
            showEventFiltering={showEventList}
          />
        )}
      </ToolbarItem>

      <ToolbarItem>
        <Button onClick={toggleEventFiltering}>
          <Icons.Filter />

          {T.alarms.filter}

          <span
            className={classNames(
              styles.filterAsterisk,
              !_.isEqual(committedFilterParams, INITIAL_FILTER_PARAMS) &&
                styles.visible
            )}
          >
            *
          </span>
        </Button>
      </ToolbarItem>

      <ToolbarFlexibleSpace />

      <ToolbarItem>
        <SegmentControl>
          <SegmentControlItem
            active={!showEventList}
            onClick={() => updateSubpage(subPages.signalClusters)}
          >
            <Icons.AlarmSignalClusters />
            {T.alarms.section.signalclusters}
          </SegmentControlItem>

          <SegmentControlItem
            active={showEventList}
            onClick={() => updateSubpage(subPages.eventList)}
          >
            <Icons.AlarmEventList />
            {T.alarms.section.eventlist}
          </SegmentControlItem>
        </SegmentControl>
      </ToolbarItem>

      <ToolbarFlexibleSpace />

      {!showEventList && (
        <ToolbarItem>
          <Button onClick={onShowConfirmAllDialog}>
            <Icons.Checkmark />
            {T.alarms.page.heading.acknowledgeallalarms}
          </Button>
        </ToolbarItem>
      )}
    </>
  );

  const urlBuilder = (_tenantId: string, newNodeId: string) => {
    const newParams = matchPath<NodeParams>(
      window.location.pathname,
      locationRoute
    ).params;

    return `/${_tenantId}/home/${newNodeId}/${newParams.page}/${newParams.subPage}`;
  };

  return (
    <ToolbarContentPage
      title={T.alarms.title}
      toolbarItems={toolbarItems}
      helpPath={HelpPaths.docs.operator.alarms}
      urlBuilder={urlBuilder}
    >
      <AlarmTableView
        nodeId={nodeId}
        search={search}
        reloadTrigger={reloadTrigger}
        listHistory={showEventList}
        filterParams={committedFilterParams}
      />

      <AlarmConfirmAllModal
        nodeId={nodeId}
        isOpen={showConfirmAllDialog}
        onModalClose={onHideConfirmAllDialog}
        buildingStatuses={filterParams?.buildingStatuses}
      />
    </ToolbarContentPage>
  );
};

export default AlarmView;
