import React, { useCallback, useContext, useMemo, useState } from 'react';
import pageTreeStyles from 'ecto-common/lib/Page/PageTreeView.module.css';
import classNames from 'classnames';
import { NodeType, GridType } from 'ecto-common/lib/API/APIGen';
import {
  createFlatNodeTree,
  createNodeMap
} from 'ecto-common/lib/utils/locationUtils';
import { createEquipmentMap } from 'ecto-common/lib/utils/locationUtils';
import LocationTreeView from 'ecto-common/lib/LocationTreeView/LocationTreeView';
import {
  BuildResponse,
  ProjectResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import { useParams, useHistory } from 'react-router-dom';
import {
  EctoplannerParams,
  ectoplannerMasterBuildId,
  getEctoplannerUrl
} from 'js/utils/routeConstants';
import _ from 'lodash';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { LocationTreeEquipmentOrNode } from 'ecto-common/lib/LocationTreeView/LocationTreeViewRow';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import SearchInput from 'ecto-common/lib/SearchInput/SearchInput';
import T from 'ecto-common/lib/lang/Language';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import styles from './EctoplannerNavigationControls.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';

export const EctoplannerNavigationTree = ({
  builds,
  allProjects,
  isLoadingBuildsForProject,
  changedForms
}: {
  builds: BuildResponse[];
  allProjects: ProjectResponse[];
  isLoadingBuildsForProject: boolean;
  changedForms: Record<string, boolean>;
}) => {
  const params = useParams<EctoplannerParams>();
  const [searchTerm, setSearchTerm] = useState('');
  const { tenantId } = useContext(TenantContext);
  const [nodeTree, nodeMap, equipmentMap] = useMemo(() => {
    const validProjectIds = allProjects.map((project) => project.id);
    const filteredBuilds = builds.filter((x) =>
      validProjectIds.includes(x.projectId)
    );

    const nodeList = [
      ...sortByLocaleCompare(allProjects, 'name').map((project) => ({
        name: project.name,
        nodeId: project.id,
        nodeType: NodeType.Site,
        grids: [GridType.Heating],
        parentIds: ['root-heating'],
        numberOfActiveAlarms: 0
      })),
      ..._.filter(filteredBuilds, (build) => !build.isMasterBuild).map(
        (build) => ({
          name: build.description,
          nodeId: build.id,
          parentIds: [build.projectId],
          nodeType: NodeType.Building,
          numberOfActiveAlarms: 0,
          grids: [GridType.Heating]
        })
      )
    ];
    const nodeTreeValue = createFlatNodeTree(
      [GridType.Heating],
      nodeList
    ).nodeTree;

    return [
      nodeTreeValue,
      createNodeMap(nodeTreeValue),
      createEquipmentMap(nodeTreeValue)
    ];
  }, [allProjects, builds]);

  const history = useHistory();

  const onNodeChanged = useCallback(
    (nodeId: string) => {
      const newProject = allProjects.find((project) => project.id === nodeId);
      const newBuild = builds.find((build) => build.id === nodeId);

      if (newProject != null) {
        history.push(
          getEctoplannerUrl(
            tenantId,
            newProject.id,
            ectoplannerMasterBuildId,
            params.section,
            params.itemId
          )
        );
      } else if (newBuild != null) {
        history.push(
          getEctoplannerUrl(
            tenantId,
            newBuild.projectId,
            newBuild.id,
            params.section,
            params.itemId
          )
        );
      }
    },
    [allProjects, builds, history, params.itemId, params.section, tenantId]
  );
  const currentBuildId =
    params.buildId != null && params.buildId !== ectoplannerMasterBuildId
      ? params.buildId
      : params.projectId;

  const selectedIds = useMemo(() => {
    return [currentBuildId];
  }, [currentBuildId]);

  const currentBuildMissingAmongBuildsWhileLoading =
    isLoadingBuildsForProject &&
    params.buildId != null &&
    params.buildId !== ectoplannerMasterBuildId &&
    !builds.some((build) => build.id === params.buildId);

  const renderRowIcons = useCallback(
    (node: LocationTreeEquipmentOrNode) => {
      const isLoading =
        isLoadingBuildsForProject && node.nodeId === params.projectId;
      const formHasChanges = changedForms[node.nodeId];

      return (
        (isLoading || formHasChanges) && (
          <>
            <div style={{ width: 1, flexGrow: 1 }} />
            {formHasChanges && <span>*</span>}
            {isLoading && (
              <Spinner size={SpinnerSize.TREE_VIEW} color={'white'} />
            )}
          </>
        )
      );
    },
    [changedForms, isLoadingBuildsForProject, params.projectId]
  );

  const searchFilter = useMemo(() => {
    if (isNullOrWhitespace(searchTerm)) {
      return undefined;
    }

    return {
      searchTerm
    };
  }, [searchTerm]);

  return (
    <div
      className={classNames(pageTreeStyles.locationTree, pageTreeStyles.open)}
    >
      <SearchInput
        onChange={setSearchTerm}
        wrapperClassName={classNames(
          pageTreeStyles.searchFieldContainer,
          styles.searchMargin
        )}
        placeholder={T.common.search.placeholder}
      />

      {nodeTree.length > 0 &&
        allProjects.length > 0 &&
        !currentBuildMissingAmongBuildsWhileLoading && (
          <LocationTreeView
            className={pageTreeStyles.locationTreeView}
            selectEquipment
            onChangeSelectedState={onNodeChanged}
            nodeTree={nodeTree}
            nodeMap={nodeMap}
            equipmentMap={equipmentMap}
            selectedIds={selectedIds}
            focusedId={currentBuildId}
            sidePadding={0}
            renderRowIcons={renderRowIcons}
            searchFilter={searchFilter}
            searchResultIcon={<Icons.File />}
          />
        )}
    </div>
  );
};
