import ArrayUtils from './arrayUtils';

// Calculate profile including diversity
function calcDiversityProfile(profile, divFactor) {
  // If zero array
  if (ArrayUtils.sum(profile) === 0) {
    return ArrayUtils.zeros(8760);
  }
  // If constant array
  if (ArrayUtils.max(profile) === ArrayUtils.min(profile)) {
    return profile;
  }
  // If diversity factor is 1
  if (divFactor === 1) {
    return profile;
  }

  let intervalSize = 5;
  const maxIntervalSize = 14 * 24;
  let finalProfile = [];

  // Find peak in profile
  const maxValue = ArrayUtils.max(profile);

  // Interval size is increased step-wise from 3, to 5, to 7 ...
  let intervalSizeFound = false;
  while (!intervalSizeFound && intervalSize < maxIntervalSize) {
    // Get data window with n (= 3, 5, 7, 9, ...) entries
    // console.log("Interval size: " + intervalSize)

    // Smooth profile (with equal weights, i.e. mean value of intervals)
    let smoothedInterval = smoothProfileEqualWeights(profile, intervalSize);

    // Calculate new peak
    const maxValueSmoothedInterval = ArrayUtils.max(smoothedInterval);
    // console.log("Reduction after simple smoothing: " + maxValueSmoothedInterval/maxValue)

    if (maxValueSmoothedInterval <= divFactor * maxValue) {
      // If reduction is larger or equal to diversity factor, end loop
      intervalSizeFound = true;
      // console.log("Interval size found: " + intervalSize)
      // console.log("Reduction via interval sizing:")
      // console.log(maxValueSmoothedInterval/maxValue)

      // Get t with maxValue
      let tInterval = getTMaxValue(profile);
      // console.log("tInterval: " + tInterval)

      let epsilonReductionFinished = false;
      let loopCount = 0;
      const maxLoopCount = 5;
      let divFactorAtCurrentT = divFactor;
      while (!epsilonReductionFinished & (loopCount < maxLoopCount)) {
        // Get interval around peak value
        let intervalValues = getIntervalAroundT(
          profile,
          intervalSize,
          tInterval
        );
        // console.log(intervalValues)
        // Determine element weighting factor
        const epsilon = getEpsilon(intervalValues, divFactorAtCurrentT);
        const coeffWeights = getCoeffWeights(epsilon, intervalValues);
        // console.log("eps / coeffWeights:")
        // console.log(coeffWeights)
        // console.log(epsilon)

        // finalProfile = smoothedInterval
        finalProfile = smoothProfileIndividualWeights(
          profile,
          intervalSize,
          coeffWeights
        );

        const newMaxValue = ArrayUtils.max(finalProfile);
        // console.log("Max value: " + maxValue)
        // console.log("New max value: " + newMaxValue)
        // console.log(newMaxValue/maxValue)
        if (newMaxValue / maxValue > 1.02 * divFactor) {
          // At a different position of the profile the reduction was less than the aimed diversity factors
          // Find point of the profile, where reduction was the least
          tInterval = getTMaxValue(finalProfile);
          // console.log("tInterval: " + tInterval)
          divFactorAtCurrentT = (divFactor * maxValue) / profile[tInterval];
          // console.log("divFactorAtCurrentT: " + divFactorAtCurrentT)
          loopCount += 1;
        } else {
          // console.log("ALL LOOPS FINISHED!!!")
          // console.log(newMaxValue/maxValue)
          // console.log(maxValue)
          // console.log(newMaxValue)
          // console.log("loopCount: " + loopCount)
          epsilonReductionFinished = true;
        }
      }
    } else {
      // Loop again with increased window
      intervalSize += 2;
    }
  }

  return finalProfile;
}

function getIntervalAroundT(profile, intervalSize, index) {
  // console.log("intervalSize" + intervalSize)
  // console.log("index" + index)
  const intervalWidth = (intervalSize - 1) / 2;
  let intervalValues = ArrayUtils.slicePlus(
    profile,
    index - intervalWidth,
    index + intervalWidth
  );
  // console.log("intervalValues" + intervalValues)
  return intervalValues;
}

function getTMaxValue(profile) {
  const maxValue = ArrayUtils.max(profile);
  let tMaxValue = 0;
  for (let t = 0; t < profile.length; t++) {
    if (profile[t] >= 0.999999 * maxValue) {
      tMaxValue = t;
    }
  }
  return tMaxValue;
}

// Smooth profile with calculated weights
function smoothProfileIndividualWeights(profile, intervalSize, coeffWeights) {
  let smoothedProfile = [];
  const intervalWidth = (intervalSize - 1) / 2;
  for (let t = 0; t < profile.length; t++) {
    let interval = ArrayUtils.slicePlus(
      profile,
      t - intervalWidth,
      t + intervalWidth
    );
    smoothedProfile.push(getWeightedMean(interval, coeffWeights));
  }
  return smoothedProfile;
}

// Smooth profile with simple average
function smoothProfileEqualWeights(profile, intervalSize) {
  let smoothedProfile = [];
  const intervalWidth = (intervalSize - 1) / 2;
  for (let t = 0; t < profile.length; t++) {
    let interval = ArrayUtils.slicePlus(
      profile,
      t - intervalWidth,
      t + intervalWidth
    );
    smoothedProfile.push(ArrayUtils.mean(interval));
  }
  return smoothedProfile;
}

// Calculate factor between weights
function getEpsilon(interval, divFactor) {
  const arrLen = interval.length;

  // S-values
  const sValues = getSValues(interval);
  const middleValue = interval[Math.round((arrLen - 1) / 2)];

  // See calculation formula in Excel sheet
  const counter = getEpsilonCounter(sValues, middleValue, divFactor, arrLen);
  const denominator = getEpsilonDenominator(sValues, middleValue, arrLen);
  const epsilon = counter / denominator;

  return epsilon;
}

// Multiplication of two arrays
function getWeightedMean(interval, coeffWeights) {
  var sum = 0;
  for (var i = 0; i < interval.length; i++) {
    sum += interval[i] * coeffWeights[i];
  }
  return sum;
}

// Calculate weights for weighted mean calculation
function getCoeffWeights(epsilon, interval) {
  const arrLen = interval.length;
  // Calculate weight for middle value
  const firstSummand = 1 / arrLen;
  let idx = 1;
  let otherSummands = 0;
  while (idx <= Math.round((arrLen - 1) / 2)) {
    otherSummands += (2 * idx * epsilon) / arrLen;
    idx += 1;
  }
  const weightMiddleValue = firstSummand + otherSummands;

  let coeffWeights = [weightMiddleValue];
  idx = 1;
  while (idx <= Math.round((arrLen - 1) / 2)) {
    coeffWeights.push(weightMiddleValue - idx * epsilon);
    coeffWeights.unshift(weightMiddleValue - idx * epsilon);
    idx += 1;
  }
  return coeffWeights;
}

// Calculate denominator of epsilon formula
function getEpsilonDenominator(sValues, middleValue, arrLen) {
  // Calculate prefactor
  let preFactor = 0;
  let idx = 1;
  // console.log(arrLen)
  while (idx <= sValues.length) {
    preFactor += (2 * idx) / arrLen;
    idx += 1;
  }
  // console.log(preFactor)
  let k = 1;
  let tempSum = middleValue * preFactor;
  // console.log(tempSum)
  while (k <= sValues.length) {
    // console.log((preFactor - k) * sValues[k-1])
    tempSum += (preFactor - k) * sValues[k - 1];
    k += 1;
  }
  // console.log(tempSum)
  return tempSum;
}

// Calculate counter of epsilon formula
function getEpsilonCounter(sValues, middleValue, divFactor, arrLen) {
  let k = 0;
  let tempSum = 0;
  while (k < sValues.length) {
    tempSum += sValues[k] / arrLen;
    k += 1;
  }
  // console.log(tempSum)
  const firstTerm = middleValue * (divFactor - 1 / arrLen);
  // console.log(firstTerm)
  return firstTerm - tempSum;
}

// Get S-values of interval
function getSValues(interval) {
  const arrLen = interval.length;
  const middleIdx = (arrLen + 1) / 2;

  let sValues = [];
  let sIdx = 1;
  while (sIdx <= (arrLen - 1) / 2) {
    sValues.push(
      interval[middleIdx - sIdx - 1] + interval[middleIdx + sIdx - 1]
    );
    sIdx += 1;
  }
  return sValues;
}

const exportedFunctions = { calcDiversityProfile, getEpsilon };
export default exportedFunctions;
