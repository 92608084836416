import React from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import styles from './GraphModal.module.css';
import GraphEditor from 'js/containers/GraphEditor';
import Icons from 'ecto-common/lib/Icons/Icons';
import { TelemetryZoomRange } from 'js/modules/signalCollections/signalCollections';
import { SeriesInterval } from 'ecto-common/lib/types/EctoCommonTypes';

interface GraphModalProps {
  isOpen?: boolean;
  onModalClose: () => void;
  initialZoomRange?: TelemetryZoomRange;
  initialSeriesInterval?: SeriesInterval;
}

const GraphModal = ({
  isOpen,
  onModalClose,
  initialZoomRange = null,
  initialSeriesInterval = null
}: GraphModalProps) => {
  return (
    <ActionModal
      headerIcon={Icons.Graph}
      title={T.location.tabs.graphs}
      isOpen={isOpen}
      onModalClose={onModalClose}
      onConfirmClick={onModalClose}
      disableCancel
      actionText={T.common.done}
      className={styles.modal}
      messageBodyClassName={styles.messageBody}
    >
      <div className={styles.flexContent}>
        <GraphEditor
          initialCustomRange={initialZoomRange}
          initialSeriesInterval={initialSeriesInterval}
        />
      </div>
    </ActionModal>
  );
};

export default GraphModal;
