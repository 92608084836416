import { SET_USERSETTINGS } from 'js/actions/actionTypes';
import { GenericActionType } from 'ecto-common/lib/types/EctoCommonTypes';

const initialState = {};

function settings(state = initialState, action: GenericActionType = {}) {
  switch (action.type) {
    case SET_USERSETTINGS: {
      const { settings: settingsPayload } = action.payload;
      return Object.assign({}, state, {
        ...settingsPayload
      });
    }
    default:
      return state;
  }
}

export default settings;
