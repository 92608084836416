import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  EctoplannerResultType,
  EctoplannerTimeSeries
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

import _ from 'lodash';
import EctoplannerEditGraphDialog from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerEditGraphDialog';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';
import Select, {
  GenericSelectOption,
  useLabelSelectOptions as getLabeledSelectProps
} from 'ecto-common/lib/Select/Select';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import Icons from 'ecto-common/lib/Icons/Icons';
import HighchartsReact from 'highcharts-react-official';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import T from 'ecto-common/lib/lang/Language';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import EctoplannerAPIGen, {
  BuildGraphAggregation,
  BuildGraphResponse,
  BuildGraphSeries,
  BuildGraphType,
  BuildResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { parseTimeSeriesFile } from 'js/components/Ectoplanner/Calculation/EctoplannerCalculationUtil';
import colors from 'ecto-common/lib/styles/variables/colors';
import { shadeColor } from 'ecto-common/lib/SignalSelector/StockChart.config';
import styles from './EctoplannerGraphBrowser.module.css';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import {
  EctoplannerAggregatorFunctions,
  EctoplannerSamplingIntervals,
  EctoplannerZoomLevel,
  EctoplannerZoomLevels,
  EctoplannerZoomToSamplingInterval,
  ectoplannerLongMonthNames,
  ectoplannerMonthBins,
  getEctoplannerHighchartsCategories
} from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerGraphBrowserTypes';
import Notice from 'ecto-common/lib/Notice/Notice';
import ToolbarMenuDivider from 'ecto-common/lib/Toolbar/ToolbarMenuDivider';
import { EctoplannerGraphStoreContext } from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerGraphStore';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import { useStore } from 'zustand';
import useUndoShortcuts from 'ecto-common/lib/hooks/useUndoShortcuts';
import { EctoplannerUnitMwh } from './EctoplannerGraphBrowserTypes';
import { downloadBlobTextWithoutUTF8BOM } from 'ecto-common/lib/utils/downloadBlob';
import Spinner from 'ecto-common/lib/Spinner/Spinner';

// Add draggable points module
/* eslint-disable @typescript-eslint/no-var-requires */
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/draggable-points')(Highcharts);

const shouldConvertKwhToMwh = (unit: string, zoom: EctoplannerZoomLevel) => {
  return (
    unit === 'kWh' &&
    (zoom === EctoplannerZoomLevels.MONTH ||
      zoom === EctoplannerZoomLevels.YEAR)
  );
};

const domProps = {
  style: {
    height: '100%'
  }
};

const transformSeriesData = (
  values: number[],
  zoom: EctoplannerZoomLevel,
  aggregation: BuildGraphAggregation,
  scaling: number
) => {
  let samplingInterval = EctoplannerZoomToSamplingInterval[zoom];
  let aggregator = EctoplannerAggregatorFunctions[aggregation];
  let newValues: number[] = [];

  switch (samplingInterval) {
    case EctoplannerSamplingIntervals.MONTH:
      {
        let curIndex = 0;

        for (
          let curMonth = 0;
          curMonth < ectoplannerMonthBins.length;
          curMonth++
        ) {
          const startIndex = curIndex;
          const endIndex = curIndex + ectoplannerMonthBins[curMonth] * 24;
          // TODO: End inclusive?
          newValues.push(
            aggregator(values.slice(startIndex, endIndex)) / scaling
          );
          curIndex = endIndex;
        }
      }
      break;
    case EctoplannerSamplingIntervals.DAY:
      for (let day = 0; day < values.length; day += 24) {
        newValues.push(aggregator(values.slice(day, day + 24)) / scaling);
      }
      break;
    case EctoplannerSamplingIntervals.HOUR:
      // No need to do anything except scaling, samples are already per hour. Can't aggregate with just one value either.
      newValues = values.map((value) => value / scaling);

      for (let i = 0; i < newValues.length; i++) {
        newValues[i] /= scaling;
      }

      break;
    default:
      break;
  }

  return newValues;
};

type ZoomSettings = {
  zoom: EctoplannerZoomLevel;
  range: [number, number];
  title: string;
};

const getHighchartOptions = (
  collectionSeries: BuildGraphSeries[],
  seriesById: Record<string, EctoplannerTimeSeries>,
  seriesDataById: Record<string, string>,
  zoomSettings: ZoomSettings,
  graphType: BuildGraphType,
  onClickIndex: (index: number) => void
): Highcharts.Options => {
  const allSeries = _.compact(
    collectionSeries.map((series) => {
      if (seriesById[series.id] == null) {
        return null;
      }
      let unit = seriesById[series.id].unit;
      let scaling = 1;
      if (shouldConvertKwhToMwh(unit, zoomSettings.zoom)) {
        unit = EctoplannerUnitMwh;
        scaling = 1000;
      }

      return {
        ...seriesById[series.id],
        unit,
        scaling,
        aggregation: series.aggregation
      };
    })
  );

  let currentColors: Record<string, string> = {
    Heating: colors.heatingColor,
    Cooling: colors.coolingColor,
    Electricity: colors.electricityColor,
    Gas: colors.gasColor,
    AirTemp: colors.airTempColor,
    SolarIrradiance: colors.solarIrradianceColor
  };

  const yAxis = _.uniq(
    allSeries.map((series) => {
      return series?.unit ?? '';
    })
  ).map((unit, idx) => {
    return {
      id: unit,
      title: {
        text: unit
      },
      showEmpty: true,
      opposite: idx % 2 === 1
    };
  });

  const type = graphType === BuildGraphType.Column ? 'column' : 'line';
  const categories = getEctoplannerHighchartsCategories(zoomSettings.zoom);
  return {
    yAxis,
    chart: {
      type,
      animation: false,
      resetZoomButton: {
        theme: {
          style: {
            display: 'none'
          }
        }
      }
    },
    navigator: {
      enabled: false
    },
    legend: {
      enabled: true
    },
    noData: {
      style: {
        display: 'none'
      }
    },
    credits: {
      enabled: false
    },
    title: {
      text: zoomSettings.title
    },
    tooltip: {
      valueDecimals: 2
    },
    xAxis: {
      categories,
      min: zoomSettings.range[0],
      max: zoomSettings.range[1] - 1
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        // @ts-ignore-next-line
        groupPadding: 0.1,
        pointPadding: 0,
        events: {
          click: (e) => {
            onClickIndex(e.point.index);
          }
        }
      }
    },
    series: allSeries.map((series) => {
      let seriesData = transformSeriesData(
        parseTimeSeriesFile(seriesDataById[series.id]),
        zoomSettings.zoom,
        series.aggregation,
        series.scaling
      );
      let color = currentColors[series.type] ?? '#000000';
      currentColors[series.type] = shadeColor(color, 2);
      const subcategoryText =
        series.subcategory != null ? series.subcategory + ' - ' : '';
      return {
        type,
        yAxis: series.unit,
        name:
          series.category +
          ' - ' +
          subcategoryText +
          series.name +
          ' (' +
          series.unit +
          ')',
        data: seriesData,
        unit: series.unit,
        color
      };
    })
  };
};

const EctoplannerGraphBrowser = ({
  build,
  results
}: {
  results: EctoplannerResultType;
  build?: BuildResponse;
  isRunningCalculation: boolean;
  formIsUpToDate: boolean;
}) => {
  const buildId = build?.id;

  const [isShowingDialog, showDialog, hideDialog] = useDialogState(false);
  const [zoomSettings, setZoomSettings] = useState<ZoomSettings>({
    zoom: EctoplannerZoomLevels.YEAR,
    range: [0, 12],
    title: T.ectoplanner.graphs.referenceyear
  });

  const chartComponentRef = useRef(null);

  const { contextSettings } = useContext(TenantContext);
  const seriesById = useMemo(() => {
    return _.keyBy(results?.timeseries, 'id');
  }, [results?.timeseries]);

  const graphStore = useContext(EctoplannerGraphStoreContext);

  const { undo, redo, pastStates, futureStates } =
    graphStore.temporal.getState();

  const markGraphAsSaved = useStore(
    graphStore,
    (store) => store.markGraphAsSaved
  );

  const isLoading = useStore(graphStore, (store) => store.loading);

  const currentCollectionId = useStore(
    graphStore,
    (store) => store.currentCollectionId
  );

  useUndoShortcuts(undo, redo);

  const graphs = useStore(graphStore, (store) => store.graphs);
  const backendGraphs = useStore(graphStore, (store) => store.backendGraphs);
  const updateGraph = useStore(graphStore, (store) => store.updateGraph);

  const setCurrentCollectionId = useStore(
    graphStore,
    (store) => store.setCurrentCollectionId
  );
  const createNewCollection = useStore(
    graphStore,
    (store) => store.createNewCollection
  );

  const duplicateCollection = useStore(
    graphStore,
    (store) => store.duplicateCollection
  );

  const removeGraph = useStore(graphStore, (store) => store.removeGraph);

  const graphOptions: GenericSelectOption<string>[] = useMemo(() => {
    return Object.values(graphs).map((collection) => {
      const isSaved = _.isEqual(collection, backendGraphs[collection.id] ?? {});
      const unsavedSuffix = !isSaved ? T.ectoplanner.graphs.unsavedsuffix : '';
      return {
        label: collection.name + unsavedSuffix,
        value: collection.id
      };
    });
  }, [backendGraphs, graphs]);

  const currentCollection = graphs[currentCollectionId];

  const signalDataQueries = useQueries({
    queries: _.compact(
      currentCollection.series.map((seriesReference) => {
        const series = seriesById[seriesReference.id];

        if (series == null) {
          return null;
        }

        return {
          queryKey: [
            'ectoplanner',
            'graphsignaldata',
            buildId,
            seriesReference.id
          ],
          queryFn: ({ signal }: { signal?: AbortSignal }) => {
            return EctoplannerAPIGen.EctoGridBuilds.timeseriesDetail.promise(
              contextSettings,
              {
                buildId,
                filename: series.id
              },
              signal
            );
          }
        };
      })
    )
  });

  const seriesDataById = useMemo(() => {
    const allData = signalDataQueries.map((query) => query.data);
    let ret: Record<string, string> = {};

    for (let i = 0; i < allData.length; i++) {
      ret[currentCollection.series[i].id] = allData[i];
    }

    return ret;
  }, [signalDataQueries, currentCollection.series]);

  const exportCollection: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      let lines: string[] = [];
      lines.push(
        currentCollection.series
          .map((series) => {
            const seriesInfo = seriesById[series.id];
            if (seriesInfo == null) {
              return '';
            }

            const subCategorySuffix =
              seriesInfo.subcategory != null
                ? ' - ' + seriesInfo.subcategory
                : '';

            return (
              seriesInfo.category +
              ' - ' +
              seriesInfo.name +
              subCategorySuffix +
              ' (' +
              seriesInfo.unit +
              ')'
            );
          })
          .join(',')
      );
      let dataArrays = currentCollection.series.map(
        (series) => seriesDataById[series.id]
      );
      let parsedData = dataArrays.map((array) => parseTimeSeriesFile(array));

      for (let i = 0; i < parsedData[0].length; i++) {
        let vals = parsedData.map((data) => (data[i] == null ? '' : data[i]));
        lines.push(vals.join(','));
      }

      _.defer(() => {
        downloadBlobTextWithoutUTF8BOM(
          lines.join('\n'),
          currentCollection.name + '_export.csv'
        );
      });
    }, [currentCollection, seriesById, seriesDataById]);

  const hasDataForAllSeries = _.every(currentCollection.series, (series) => {
    return seriesDataById[series.id] != null;
  });

  const setCurrentCollection = useCallback(
    (
      collection:
        | BuildGraphResponse
        | ((oldCollection: BuildGraphResponse) => BuildGraphResponse)
    ) => {
      // updateGraph
      const newCollection = _.isFunction(collection)
        ? collection(currentCollection)
        : collection;

      updateGraph(newCollection);
    },
    [currentCollection, updateGraph]
  );

  const zoomOut = useCallback(() => {
    switch (zoomSettings.zoom) {
      case EctoplannerZoomLevels.YEAR: {
        // Can't zoom out of year
        break;
      }
      case EctoplannerZoomLevels.MONTH:
        setZoomSettings({
          zoom: EctoplannerZoomLevels.YEAR,
          range: [0, 12],
          title: T.ectoplanner.graphs.referenceyear
        });
        break;
      default:
      case EctoplannerZoomLevels.DAY:
        {
          // Zoom out to month
          let dayIndex = 0;
          for (
            let monthIndex = 0;
            monthIndex < ectoplannerMonthBins.length;
            monthIndex++
          ) {
            dayIndex += ectoplannerMonthBins[monthIndex];
            if (dayIndex * 24 >= zoomSettings.range[0]) {
              setZoomSettings({
                zoom: EctoplannerZoomLevels.MONTH,
                range: [dayIndex - ectoplannerMonthBins[monthIndex], dayIndex],
                title: ectoplannerLongMonthNames[monthIndex]
              });

              break;
            }
          }
        }
        break;
    }
  }, [zoomSettings]);

  const [
    showingConfirmDeleteDialog,
    showConfirmDeleteDialog,
    hideConfirmDeleteDialog
  ] = useDialogState(false);

  const onClickIndex = useCallback(
    (index: number) => {
      switch (zoomSettings.zoom) {
        case EctoplannerZoomLevels.YEAR: {
          // User has clicked on a specific month. Zoom to that month.

          let dayIndex = 0;
          for (let curIndex = 0; curIndex < index; curIndex++) {
            dayIndex += ectoplannerMonthBins[curIndex];
          }
          setZoomSettings({
            zoom: EctoplannerZoomLevels.MONTH,
            range: [dayIndex, dayIndex + ectoplannerMonthBins[index]],
            title: ectoplannerLongMonthNames[index]
          });

          break;
        }
        case EctoplannerZoomLevels.MONTH:
          setZoomSettings({
            zoom: EctoplannerZoomLevels.DAY,
            range: [index * 24, index * 24 + 24],
            title:
              zoomSettings.title + ' ' + (index - zoomSettings.range[0] + 1)
          });
          break;
        default:
        case EctoplannerZoomLevels.DAY:
          break;
      }
    },
    [zoomSettings]
  );

  const highchartOptions = useMemo(() => {
    return getHighchartOptions(
      currentCollection?.series,
      seriesById,
      seriesDataById,
      zoomSettings,
      currentCollection.graphType,
      onClickIndex
    );
  }, [
    currentCollection.series,
    currentCollection.graphType,
    seriesById,
    seriesDataById,
    zoomSettings,
    onClickIndex
  ]);

  const queryClient = useQueryClient();

  const deleteCollectionMutation =
    EctoplannerAPIGen.EctoGridBuilds.graphsDelete.useMutation(
      {
        buildId,
        graphId: currentCollectionId
      },
      {
        onSuccess: () => {
          removeGraph(currentCollectionId);
          queryClient.invalidateQueries(
            EctoplannerAPIGen.EctoGridBuilds.graphsDetail.path(
              contextSettings,
              { buildId }
            )
          );
          hideConfirmDeleteDialog();
        }
      }
    );

  const saveCollectionMutation =
    EctoplannerAPIGen.EctoGridBuilds.graphsUpdate.useMutation(
      {
        buildId,
        graphId: currentCollection.id
      },
      {
        onSuccess: () => {
          markGraphAsSaved(currentCollection.id);
          queryClient.invalidateQueries(
            EctoplannerAPIGen.EctoGridBuilds.graphsDetail.path(
              contextSettings,
              { buildId }
            )
          );
        }
      }
    );

  const saveGraph = useCallback(() => {
    saveCollectionMutation.mutate(currentCollection);
  }, [currentCollection, saveCollectionMutation]);

  const currentGraphOption = graphOptions.find(
    (option) => option.value === currentCollectionId
  );

  const collectionSelectProps = getLabeledSelectProps(
    T.ectoplanner.graphs.collection,
    currentGraphOption
  );

  let currentCollectionIsSaved = _.isEqual(
    backendGraphs[currentCollectionId],
    currentCollection
  );

  return (
    <div className={styles.container}>
      <Toolbar className={styles.toolbar}>
        <ToolbarItem>
          <Select
            className={styles.graphSelector}
            options={graphOptions}
            value={currentGraphOption}
            onChange={(option) => {
              setCurrentCollectionId(option.value);
            }}
            isLoading={
              saveCollectionMutation.isLoading ||
              deleteCollectionMutation.isLoading
            }
            isClearable={false}
            {...collectionSelectProps}
          />
        </ToolbarItem>
        <ToolbarItem>
          <ToolbarMenu>
            <ToolbarMenuButton
              tooltipText={T.common.edit}
              icon={<Icons.Edit />}
              onClick={showDialog}
            />
            <ToolbarMenuDivider />
            <ToolbarMenuButton
              tooltipText={T.common.save}
              icon={<Icons.Save />}
              onClick={saveGraph}
              disabled={currentCollectionIsSaved}
            />
            <ToolbarMenuButton
              tooltipText={T.common.undo}
              icon={<Icons.Undo />}
              onClick={() => undo()}
              disabled={pastStates.length === 0}
            />
            <ToolbarMenuButton
              tooltipText={T.common.redo}
              icon={<Icons.Redo />}
              onClick={() => redo()}
              disabled={futureStates.length === 0}
            />
            <ToolbarMenuButton
              tooltipText={T.graphs.deletebutton}
              icon={<Icons.Delete />}
              onClick={showConfirmDeleteDialog}
            />
            <ToolbarMenuDivider />
            <ToolbarMenuButton
              tooltipText={T.graphs.editmodal.addtitle}
              icon={<Icons.NewDocument />}
              onClick={() => {
                createNewCollection();
              }}
            />
            <ToolbarMenuButton
              tooltipText={T.graphs.duplicatebutton}
              icon={<Icons.Copy />}
              onClick={duplicateCollection}
            />
            <ToolbarMenuDivider />
            <ToolbarMenuButton
              tooltipText={T.ectoplanner.exportcsv}
              icon={<Icons.Download />}
              onClick={exportCollection}
              disabled={
                currentCollection.series.length === 0 || !hasDataForAllSeries
              }
            />
          </ToolbarMenu>
        </ToolbarItem>
        <ToolbarFlexibleSpace />
        <ToolbarItem>
          <GreyButton
            onClick={zoomOut}
            disabled={zoomSettings.zoom === EctoplannerZoomLevels.YEAR}
          >
            <Icons.ZoomOut />
            {T.ectoplanner.graphs.zoomout}
          </GreyButton>
        </ToolbarItem>
      </Toolbar>

      <div className={styles.graphContainer}>
        {currentCollection.series.length > 0 && (
          <HighchartsReact
            options={highchartOptions}
            highcharts={Highcharts}
            containerProps={domProps}
            ref={chartComponentRef}
          />
        )}
        {currentCollection.series.length === 0 && !isLoading && (
          <Notice className={styles.paddedArea}>
            {T.ectoplanner.graphs.nosignalsselected}
          </Notice>
        )}
        {isLoading && (
          <div className={styles.paddedArea}>
            <Spinner />
          </div>
        )}
      </div>
      <EctoplannerEditGraphDialog
        results={results}
        isOpen={isShowingDialog}
        onModalClose={hideDialog}
        currentCollection={currentCollection}
        setCurrentCollection={setCurrentCollection}
        seriesById={seriesById}
      />
      <ConfirmDeleteDialog
        isOpen={showingConfirmDeleteDialog}
        onModalClose={hideConfirmDeleteDialog}
        itemName={currentCollection.name}
        isLoading={deleteCollectionMutation.isLoading}
        onDelete={deleteCollectionMutation.mutate}
      />
    </div>
  );
};

export default React.memo(EctoplannerGraphBrowser);
