import React, { useState, useCallback } from 'react';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';

const SelectExample = () => {
  const [selectedValue, setSelectedValue] = useState<number>(null);

  const options = [
    {
      label: 'First option',
      value: 1
    },
    {
      label: 'Second option',
      value: 2
    }
  ];

  const updateValue = useCallback(
    (e: GenericSelectOption<number>) => setSelectedValue(e.value),
    []
  );

  return (
    <div style={{ width: '200px' }}>
      <Select
        options={options}
        value={options.find((x) => x.value === selectedValue)}
        onChange={updateValue}
      />
    </div>
  );
};

SelectExample.description =
  'We need to set div width explicitly as CreatableSelect has no minimum width (only expands to container width). Should be fixed.';
SelectExample.class = 'Select';

export default SelectExample;
