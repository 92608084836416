import React, { useState } from 'react';

import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import {
  valueOfDate,
  DEFAULT_DATE_RANGE,
  DateRangeType
} from 'ecto-common/lib/utils/dateUtils';
import ToolbarDateTimeRangePicker from 'ecto-common/lib/ToolbarDateTimePicker/ToolbarDateTimeRangePicker';
import { Moment } from 'moment';

interface DateRangeSelectorProps {
  // Called when range has changed. Argument: current range
  onRangeChanged(newRange: DateRangeType): void;
  defaultRange?: DateRangeType;
  expandingItemWidth?: boolean;
}

const DateRangeSelector = ({
  expandingItemWidth = false,
  defaultRange = DEFAULT_DATE_RANGE,
  onRangeChanged
}: DateRangeSelectorProps) => {
  const [range, setRange] = useState<DateRangeType>({
    dateFrom: defaultRange.dateFrom,
    dateTo: defaultRange.dateTo
  });

  const onChangeFrom = (dateFrom: Moment) => {
    const newRange = { ...range, dateFrom: valueOfDate(dateFrom) };
    setRange(newRange);
    onRangeChanged(newRange);
  };

  const onChangeTo = (dateTo: Moment) => {
    const newRange = { ...range, dateTo: valueOfDate(dateTo) };
    setRange(newRange);
    onRangeChanged(newRange);
  };

  return (
    <ToolbarMenu>
      <ToolbarDateTimeRangePicker
        expandingItemWidth={expandingItemWidth}
        dateFrom={range.dateFrom}
        dateTo={range.dateTo}
        onChangeFrom={onChangeFrom}
        onChangeTo={onChangeTo}
      />
    </ToolbarMenu>
  );
};

export default React.memo(DateRangeSelector);
