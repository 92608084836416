import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import React, { useMemo } from 'react';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import AddButton from 'ecto-common/lib/Button/AddButton';
import T from 'ecto-common/lib/lang/Language';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import { parseStringToNumber } from 'ecto-common/lib/utils/stringUtils';
import _ from 'lodash';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
import { CustomModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

export type EctoplannerArrayEditorField<T extends object> = {
  dataKey: Extract<keyof T, string>;
  type: 'number';
  label: string;
};

type EctoplannerArrayEditorProps<
  ObjectType extends object,
  EnvironmentType extends object,
  T extends object
> = ModelEditorProps<EnvironmentType> & {
  rawValue: T[];
  fields: EctoplannerArrayEditorField<T>[];
  defaultItem: T;
  model: CustomModelDefinition<ObjectType, EnvironmentType, unknown>;
};

function EctoplannerArrayEditor<
  ObjectType extends object,
  EnvironmentType extends object,
  T extends object
>({
  defaultItem,
  model,
  rawValue,
  updateItem,
  fields
}: EctoplannerArrayEditorProps<ObjectType, EnvironmentType, T>) {
  const addItem = () => {
    const newValue = [
      ...rawValue,
      {
        ...defaultItem
      }
    ];
    updateItem(newValue);
  };

  const columns: DataTableColumnProps<T>[] = useMemo(() => {
    const updateNumber = (numberText: string, index: number, key: string) => {
      const newValue = [...rawValue];
      _.set(newValue, `${index}.${key}`, parseStringToNumber(numberText));
      updateItem(newValue);
    };

    const inputField = (value: number, index: number, key: string) => (
      <TextInput
        type="number"
        error={value == null}
        value={value?.toString() ?? ''}
        onChange={(e) => updateNumber(e.target.value, index, key)}
      />
    );

    return [
      ...fields.map((field) => ({
        label: field.label,
        dataKey: field.dataKey,
        dataFormatter: (value: number, _object: unknown, index: number) =>
          inputField(value, index, field.dataKey)
      })),
      ...standardColumns({
        onDelete: (_item, index) => {
          let newValue = [...rawValue];
          newValue.splice(index, 1);
          updateItem(newValue);
        },
        shouldDisableDelete: () => rawValue.length === 1
      })
    ];
  }, [fields, rawValue, updateItem]);

  return (
    <KeyValueGeneric keyText={model.label}>
      <DataTable<T> columns={columns} data={rawValue} />
      <DataTableFooter alignRight>
        <AddButton onClick={addItem}>{T.common.add} </AddButton>
      </DataTableFooter>
    </KeyValueGeneric>
  );
}

export default typedMemo(EctoplannerArrayEditor);
