// These are all settings that are used by the calculation, but do not have
// any input fields in the UI. They are set to default values here. We should investigate
// if some of these should be set through the UI instead.
export const mainState = {
  networkOption: 'lowEx',
  considerHolidays: true,
  considerTimeChange: false,
  firstDayOfYear: 'monday', // Should probably be set depending on country
  meanNetwTemp: 16,
  heatLossesAbs: 0,
  heatLossesRel: 10,
  heatPumpWork: 1,
  diversityFactorHeat: 1,
  coldLossesAbs: 0,
  coldLossesRel: 10,
  coldPumpWork: 1,
  diversityFactorCold: 1,
  lowExPumpWork: 1
};
