import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import AddButton from 'ecto-common/lib/Button/AddButton';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import {
  ectoplannerCityDataKeys,
  getBuildingNproSections
} from 'js/components/Ectoplanner/EctoplannerModels';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import _ from 'lodash';
import EditEctoplannerLocation from 'js/components/Ectoplanner/EditEctoplannerLocation';
import useOnUpdateFormInput from 'ecto-common/lib/ModelForm/useOnUpdateFormInput';
import styles from 'js/components/Ectoplanner/EditEctoplannerProject.module.css';
import defaultFormBuilding from './assets/defaultFormBuilding.json';
import T from 'ecto-common/lib/lang/Language';

import { WeatherCountryResponse } from 'ecto-common/lib/API/EctoplannerAPIGen';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import {
  EctoplannerBuildingOverviewTable,
  EctoplannerNetworkOverviewDialog
} from 'js/components/Ectoplanner/EctoplannerNetworkOverview';
import Button from 'ecto-common/lib/Button/Button';
import { EctoplannerFormError } from 'js/components/Ectoplanner/EctoplannerTypes';
import Icons from 'ecto-common/lib/Icons/Icons';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { useHistory, useParams } from 'react-router';
import { EctoplannerParams, getEctoplannerUrl } from 'js/utils/routeConstants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import EctoplannerPipeDialog from 'js/components/Ectoplanner/EctoplannerPipeDialog';
import { EctoplannerBuildingSummary } from 'js/components/Ectoplanner/EctoplannerBuildingSummary';
import {
  EctoplannerForm,
  EctoplannerFormBuilding,
  EctoplannerFormBuildingParams
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
const numberAtEndRegex = /([0-9]+)$/m;

export const updateTotalDemandForBuilding = (
  building: EctoplannerFormBuildingParams
) => {
  building.annDemTotHeat = Math.round(
    (building.floorArea * building.annDemSpecHeat) / 1000.0
  );
  building.annDemTotCool = Math.round(
    (building.floorArea * building.annDemSpecCool) / 1000.0
  );
  building.annDemTotProcessCool = Math.round(
    (building.floorArea * building.annDemSpecProcessCool) / 1000.0
  );
  building.annDemTotPlugLoads = Math.round(
    (building.floorArea * building.annDemSpecPlugLoads) / 1000.0
  );
  building.annDemTotDhw = Math.round(
    (building.floorArea * building.annDemSpecDhw) / 1000.0
  );
};

interface EditEctoplannerBuildingsProps {
  form?: EctoplannerForm;
  setFormFromUserInput: Dispatch<SetStateAction<EctoplannerForm>>;
  projectId?: string;
  weatherCountries: WeatherCountryResponse[];
  cityData: object;
  formErrors: EctoplannerFormError[];
}

const EditEctoplannerBuildings = ({
  form,
  setFormFromUserInput,
  projectId,
  weatherCountries,
  cityData,
  formErrors
}: EditEctoplannerBuildingsProps) => {
  const [editBuildingIndex, setEditBuildingIndex] = useState(-1);
  const [showingSummaryDialog, showSummaryDialog, hideSummaryDialog] =
    useDialogState(false);
  const [deleteBuilding, setDeleteBuilding] =
    useState<EctoplannerFormBuilding>(null);
  const clearDeleteBuilding = useCallback(() => setDeleteBuilding(null), []);
  const params = useParams<EctoplannerParams>();
  const history = useHistory();
  const { tenantId } = useContext(TenantContext);

  const disablePipeDialog = params.section !== 'buildings';

  const confirmDeleteBuilding = useCallback(() => {
    setFormFromUserInput((oldForm) => {
      return {
        ...oldForm,
        buildings: _.without(oldForm.buildings, deleteBuilding)
      };
    });

    setDeleteBuilding(null);
  }, [deleteBuilding, setFormFromUserInput]);

  const addBuilding = useCallback(() => {
    const buildingType = 'residential';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const buildingSubtype = Object.keys((cityData as any)[buildingType])[0];

    setFormFromUserInput((oldForm: EctoplannerForm) => {
      const defaultFormBuildingTyped =
        defaultFormBuilding as EctoplannerFormBuilding;

      let newBuilding: EctoplannerFormBuilding = {
        ...defaultFormBuildingTyped,
        params: {
          ...defaultFormBuildingTyped.params,
          buildingType,
          buildingSubtype
        },
        name: T.ectoplanner.form.shared.building + (form.buildings.length + 1)
      };

      for (let key of ectoplannerCityDataKeys) {
        _.set(
          newBuilding.params,
          key,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (cityData as any)[newBuilding.params.buildingType][
            newBuilding.params.buildingSubtype
          ][key]
        );
      }
      updateTotalDemandForBuilding(newBuilding.params);

      return {
        ...oldForm,
        buildings: [...oldForm.buildings, newBuilding]
      };
    });
    setEditBuildingIndex(form.buildings.length);
  }, [cityData, form.buildings.length, setFormFromUserInput]);

  const onDuplicateBuildingIndex = useCallback(
    (buildingIndex: number) => {
      const building = form.buildings[buildingIndex];
      setFormFromUserInput((oldForm) => {
        return {
          ...oldForm,
          buildings: [
            ...oldForm.buildings,
            {
              ..._.cloneDeep(building),
              name: building.name.replace(
                numberAtEndRegex,
                oldForm.buildings.length + 1 + ''
              )
            }
          ]
        };
      });
    },
    [form.buildings, setFormFromUserInput]
  );

  const onDeleteBuildingIndex = useCallback(
    (buildingIndex: number) => {
      setDeleteBuilding(form.buildings[buildingIndex]);
    },
    [form]
  );

  const environment = useMemo(() => ({ projectId }), [projectId]);
  const onUpdateForm = useOnUpdateFormInput(setFormFromUserInput);

  const [isShowingNetworkOverview, showNetworkOverview, hideNetworkOverview] =
    useDialogState(false);

  const buildingSections = useMemo(() => {
    return getBuildingNproSections(form.buildings, cityData);
  }, [form.buildings, cityData]);

  const editSections = useMemo(() => {
    return editBuildingIndex === -1
      ? []
      : [buildingSections[editBuildingIndex]];
  }, [buildingSections, editBuildingIndex]);

  const showPipeDialog = useCallback(
    () =>
      history.push(
        getEctoplannerUrl(
          tenantId,
          projectId,
          params.buildId,
          'buildings',
          'pipes'
        )
      ),
    [history, params.buildId, projectId, tenantId]
  );
  const closePipeDialog = useCallback(
    () =>
      history.push(
        getEctoplannerUrl(tenantId, projectId, params.buildId, 'buildings')
      ),
    [history, params.buildId, projectId, tenantId]
  );

  return (
    <>
      <div className={styles.buildingSection}>
        <EditEctoplannerLocation
          setFormFromUserInput={setFormFromUserInput}
          key={params.projectId + '-' + params.buildId}
          form={form}
          weatherCountries={weatherCountries}
        />
      </div>
      <EctoplannerBuildingOverviewTable
        form={form}
        formErrors={formErrors}
        onCopyBuilding={onDuplicateBuildingIndex}
        onDeleteBuilding={onDeleteBuildingIndex}
        onEditBuilding={setEditBuildingIndex}
      />
      <EctoplannerPipeDialog
        isOpen={params.itemId === 'pipes'}
        onModalClose={closePipeDialog}
        form={form}
      />

      <ActionModal
        className={styles.editBuildingModal}
        isOpen={editBuildingIndex !== -1}
        onModalClose={() => setEditBuildingIndex(-1)}
        title={
          form.buildings[editBuildingIndex]?.name ??
          T.ectoplanner.buildings.edit
        }
        actionText={T.common.done}
        disableCancel
        headerIcon={Icons.Edit}
        onConfirmClick={() => setEditBuildingIndex(-1)}
        leftSideButton={
          <Button onClick={showSummaryDialog}>
            <Icons.Graph />
            {T.ectoplanner.form.building.showsummary}
          </Button>
        }
      >
        <ModelForm
          useTooltipHelpTexts
          sections={editSections}
          onUpdateInput={onUpdateForm}
          input={form}
          environment={environment}
        />

        <ActionModal
          isOpen={showingSummaryDialog}
          onModalClose={hideSummaryDialog}
          onConfirmClick={hideSummaryDialog}
          disableCancel
          className={styles.summaryModal}
          headerIcon={Icons.Graph}
          title={
            form.buildings[editBuildingIndex]?.name ??
            T.ectoplanner.form.building.params.sections.summary
          }
          actionText={T.common.done}
        >
          {editBuildingIndex !== -1 && (
            <EctoplannerBuildingSummary
              building={form.buildings[editBuildingIndex]}
            />
          )}
        </ActionModal>
      </ActionModal>

      <DataTableFooter alignRight>
        {!disablePipeDialog && (
          <Button
            disabled={form.calculations == null || form?.buildings.length === 0}
            onClick={showPipeDialog}
          >
            <Icons.Network /> {T.ectoplanner.pipes.button}
          </Button>
        )}
        <Button
          disabled={form.calculations == null || form?.buildings.length === 0}
          onClick={showNetworkOverview}
        >
          <Icons.Building /> {T.ectoplanner.buildingsummary.button}
        </Button>
        <AddButton disabled={cityData == null} onClick={addBuilding}>
          {' '}
          {T.ectoplanner.buildings.add}{' '}
        </AddButton>
      </DataTableFooter>

      <ConfirmDeleteDialog
        onModalClose={clearDeleteBuilding}
        isOpen={deleteBuilding != null}
        itemName={deleteBuilding?.name}
        onDelete={confirmDeleteBuilding}
      />

      {form.calculations && (
        <EctoplannerNetworkOverviewDialog
          form={form}
          isOpen={isShowingNetworkOverview}
          onModalClose={hideNetworkOverview}
        />
      )}
    </>
  );
};

export default React.memo(EditEctoplannerBuildings);
