const noiseAbs = [
  0.03, 0.04, 0.04, 0.04, 0.03, 0.08, 0.02, 0.05, 0.04, 0.03, 0.06, 0.02, 0.07,
  0.03, 0.06, 0.05, 0.03, 0.07, 0.02, 0.03, 0.03, 0.02, 0.06, 0.0, 0.05, 0.06,
  0.03, 0.04, 0.03, 0.04, 0.02, 0.01, 0.03, 0.02, 0.04, 0.12, 0.03, 0.05, 0.04,
  0.05, 0.04, 0.07, 0.07, 0.03, 0.04, 0.03, 0.04, 0.03, 0.05, 0.08, 0.12, 0.08,
  0.02, 0.05, 0.06, 0.03, 0.04, 0.0, 0.01, 0.01, 0.01, 0.08, 0.03, 0.02, 0.04,
  0.09, 0.05, 0.03, 0.12, 0.02, 0.05, 0.08, 0.06, 0.09, 0.1, 0.04, 0.03, 0.01,
  0.06, 0.07, 0.03, 0.09, 0.04, 0.03, 0.01, 0.02, 0.04, 0.04, 0.02, 0.01, 0.06,
  0.0, 0.07, 0.04, 0.02, 0.01, 0.02, 0.07, 0.04, 0.07, 0.02, 0.12, 0.03, 0.02,
  0.02, 0.01, 0.08, 0.02, 0.01, 0.03, 0.02, 0.03, 0.05, 0.06, 0.0, 0.02, 0.01,
  0.04, 0.06, 0.02, 0.02, 0.05, 0.0, 0.02, 0.12, 0.05, 0.04, 0.0, 0.05, 0.08,
  0.01, 0.01, 0.03, 0.02, 0.01, 0.03, 0.02, 0.04, 0.02, 0.06, 0.04, 0.01, 0.1,
  0.02, 0.0, 0.06, 0.12, 0.03, 0.06, 0.1, 0.0, 0.02, 0.03, 0.02, 0.01, 0.02,
  0.02, 0.03, 0.04, 0.01, 0.05, 0.06, 0.08, 0.09, 0.01, 0.0, 0.01, 0.07, 0.02,
  0.04, 0.03, 0.05, 0.06, 0.02, 0.05, 0.05, 0.06, 0.02, 0.05, 0.07, 0.12, 0.05,
  0.06, 0.02, 0.0, 0.02, 0.08, 0.04, 0.03, 0.0, 0.09, 0.04, 0.02, 0.08, 0.09,
  0.01, 0.01, 0.07, 0.07, 0.02, 0.02, 0.02, 0.02, 0.02, 0.05, 0.07, 0.0, 0.01,
  0.07, 0.02, 0.04, 0.04, 0.02, 0.02, 0.06, 0.03, 0.02, 0.08, 0.02, 0.07, 0.05,
  0.03, 0.03, 0.08, 0.06, 0.01, 0.08, 0.0, 0.05, 0.1, 0.04, 0.04, 0.08, 0.02,
  0.04, 0.01, 0.1, 0.02, 0.02, 0.07, 0.01, 0.0, 0.04, 0.01, 0.03, 0.03, 0.06,
  0.06, 0.02, 0.05, 0.04, 0.04, 0.04, 0.05, 0.09, 0.06, 0.05, 0.02, 0.04, 0.02,
  0.02, 0.03, 0.04, 0.01, 0.08, 0.02, 0.01, 0.0, 0.06, 0.04, 0.0, 0.02, 0.07,
  0.01, 0.04, 0.01, 0.04, 0.02, 0.01, 0.02, 0.05, 0.06, 0.0, 0.03, 0.01, 0.0,
  0.01, 0.05, 0.06, 0.05, 0.07, 0.02, 0.06, 0.03, 0.08, 0.09, 0.02, 0.04, 0.05,
  0.12, 0.02, 0.0, 0.01, 0.06, 0.02, 0.06, 0.04, 0.04, 0.08, 0.01, 0.02, 0.09,
  0.04, 0.02, 0.06, 0.03, 0.05, 0.09, 0.01, 0.04, 0.02, 0.02, 0.02, 0.0, 0.08,
  0.01, 0.02, 0.02, 0.02, 0.08, 0.06, 0.06, 0.08, 0.1, 0.05, 0.04, 0.05, 0.04,
  0.01, 0.04, 0.01, 0.03, 0.01, 0.02, 0.04, 0.06, 0.08, 0.05, 0.08, 0.02, 0.06,
  0.03, 0.05, 0.04, 0.05, 0.03, 0.06, 0.03, 0.07, 0.01, 0.09, 0.01, 0.02, 0.09,
  0.12, 0.03, 0.04, 0.05, 0.03, 0.04, 0.05, 0.03, 0.01, 0.09, 0.03, 0.06, 0.03,
  0.08, 0.09, 0.02, 0.04, 0.02, 0.04, 0.05, 0.04, 0.0, 0.02, 0.03, 0.04, 0.08,
  0.01, 0.02, 0.01, 0.01, 0.07, 0.04, 0.07, 0.03, 0.03, 0.06, 0.01, 0.06, 0.0,
  0.01, 0.01, 0.0, 0.06, 0.01, 0.07, 0.03, 0.01, 0.07, 0.0, 0.02, 0.06, 0.06,
  0.0, 0.04, 0.09, 0.03, 0.01, 0.01, 0.05, 0.02, 0.01, 0.04, 0.03, 0.09, 0.03,
  0.09, 0.06, 0.04, 0.03, 0.03, 0.1, 0.06, 0.01, 0.01, 0.02, 0.02, 0.09, 0.09,
  0.03, 0.0, 0.01, 0.04, 0.02, 0.04, 0.05, 0.02, 0.08, 0.0, 0.02, 0.06, 0.1,
  0.02, 0.12, 0.02, 0.04, 0.0, 0.07, 0.04, 0.08, 0.07, 0.01, 0.03, 0.01, 0.01,
  0.02, 0.01, 0.04, 0.08, 0.01, 0.07, 0.07, 0.06, 0.01, 0.05, 0.07, 0.01, 0.02,
  0.05, 0.02, 0.04, 0.06, 0.05, 0.06, 0.05, 0.02, 0.02, 0.06, 0.03, 0.07, 0.07,
  0.04, 0.03, 0.01, 0.04, 0.02, 0.02, 0.02, 0.03, 0.06, 0.09, 0.0, 0.07, 0.05,
  0.04, 0.03, 0.0, 0.08, 0.0, 0.09, 0.03, 0.04, 0.1, 0.12, 0.05, 0.04, 0.04,
  0.06, 0.08, 0.05, 0.05, 0.02, 0.02, 0.09, 0.07, 0.01, 0.06, 0.06, 0.0, 0.0,
  0.03, 0.01, 0.01, 0.02, 0.06, 0.02, 0.04, 0.12, 0.04, 0.01, 0.01, 0.09, 0.05,
  0.04, 0.04, 0.06, 0.08, 0.09, 0.03, 0.02, 0.02, 0.03, 0.03, 0.03, 0.04, 0.07,
  0.05, 0.04, 0.03, 0.05, 0.03, 0.1, 0.04, 0.03, 0.03, 0.07, 0.02, 0.04, 0.03,
  0.01, 0.02, 0.07, 0.02, 0.02, 0.01, 0.01, 0.08, 0.03, 0.05, 0.01, 0.05, 0.07,
  0.03, 0.06, 0.01, 0.12, 0.0, 0.08, 0.06, 0.04, 0.01, 0.04, 0.01, 0.06, 0.09,
  0.02, 0.03, 0.04, 0.03, 0.01, 0.06, 0.0, 0.06, 0.02, 0.03, 0.07, 0.09, 0.01,
  0.07, 0.05, 0.07, 0.09, 0.03, 0.01, 0.06, 0.04, 0.07, 0.01, 0.02, 0.05, 0.02,
  0.05, 0.07, 0.05, 0.04, 0.0, 0.04, 0.04, 0.08, 0.06, 0.06, 0.09, 0.07, 0.09,
  0.03, 0.04, 0.04, 0.02, 0.01, 0.03, 0.03, 0.03, 0.07, 0.02, 0.01, 0.0, 0.04,
  0.09, 0.06, 0.07, 0.04, 0.05, 0.06, 0.01, 0.08, 0.03, 0.03, 0.06, 0.03, 0.03,
  0.02, 0.02, 0.02, 0.02, 0.03, 0.05, 0.04, 0.03, 0.04, 0.04, 0.04, 0.08, 0.09,
  0.04, 0.1, 0.02, 0.03, 0.02, 0.03, 0.01, 0.07, 0.03, 0.06, 0.02, 0.06, 0.08,
  0.05, 0.02, 0.07, 0.03, 0.01, 0.07, 0.05, 0.01, 0.12, 0.03, 0.02, 0.05, 0.02,
  0.01, 0.01, 0.01, 0.07, 0.04, 0.04, 0.01, 0.1, 0.01, 0.03, 0.07, 0.04, 0.05,
  0.03, 0.03, 0.08, 0.03, 0.09, 0.03, 0.09, 0.12, 0.04, 0.05, 0.06, 0.04, 0.02,
  0.03, 0.03, 0.02, 0.1, 0.04, 0.09, 0.08, 0.04, 0.12, 0.04, 0.01, 0.03, 0.05,
  0.09, 0.04, 0.01, 0.06, 0.06, 0.04, 0.02, 0.03, 0.03, 0.06, 0.04, 0.07, 0.03,
  0.01, 0.04, 0.09, 0.01, 0.02, 0.03, 0.07, 0.06, 0.04, 0.07, 0.0, 0.09, 0.03,
  0.01, 0.03, 0.02, 0.04, 0.07, 0.06, 0.07, 0.05, 0.02, 0.01, 0.02, 0.06, 0.07,
  0.03, 0.05, 0.02, 0.05, 0.07, 0.06, 0.01, 0.05, 0.05, 0.05, 0.04, 0.01, 0.02,
  0.05, 0.03, 0.08, 0.02, 0.08, 0.01, 0.06, 0.0, 0.01, 0.04, 0.06, 0.04, 0.01,
  0.04, 0.08, 0.02, 0.02, 0.01, 0.05, 0.09, 0.07, 0.04, 0.02, 0.04, 0.02, 0.01,
  0.03, 0.04, 0.03, 0.09, 0.04, 0.01, 0.05, 0.01, 0.06, 0.01, 0.07, 0.0, 0.04,
  0.07, 0.03, 0.02, 0.02, 0.03, 0.02, 0.05, 0.03, 0.05, 0.08, 0.0, 0.01, 0.05,
  0.01, 0.01, 0.01, 0.02, 0.06, 0.02, 0.05, 0.04, 0.01, 0.05, 0.01, 0.01, 0.0,
  0.05, 0.01, 0.07, 0.03, 0.03, 0.08, 0.0, 0.04, 0.06, 0.08, 0.04, 0.07, 0.09,
  0.01, 0.07, 0.05, 0.04, 0.02, 0.01, 0.03, 0.02, 0.12, 0.02, 0.07, 0.04, 0.09,
  0.03, 0.04, 0.01, 0.06, 0.07, 0.0, 0.01, 0.04, 0.01, 0.01, 0.06, 0.02, 0.0,
  0.04, 0.03, 0.02, 0.06, 0.02, 0.06, 0.01, 0.09, 0.04, 0.01, 0.02, 0.02, 0.04,
  0.01, 0.07, 0.06, 0.05, 0.03, 0.02, 0.06, 0.03, 0.09, 0.05, 0.02, 0.02, 0.04,
  0.01, 0.04, 0.06, 0.02, 0.08, 0.08, 0.02, 0.03, 0.07, 0.06, 0.06, 0.07, 0.01,
  0.01, 0.03, 0.06, 0.01, 0.01, 0.04, 0.07, 0.0, 0.07, 0.03, 0.01, 0.01, 0.02,
  0.01, 0.03, 0.04, 0.01, 0.04, 0.02, 0.01, 0.01, 0.02, 0.05, 0.01, 0.03, 0.04,
  0.08, 0.12, 0.01, 0.01, 0.12, 0.08, 0.03, 0.01, 0.06, 0.04, 0.12, 0.0, 0.04,
  0.02, 0.09, 0.04, 0.12, 0.06, 0.0, 0.02, 0.01, 0.04, 0.03, 0.03, 0.02, 0.04,
  0.01, 0.02, 0.02, 0.01, 0.1, 0.02, 0.01, 0.03, 0.03, 0.02, 0.01, 0.01, 0.01,
  0.04, 0.09, 0.02, 0.02, 0.06, 0.03, 0.02, 0.06, 0.01, 0.06, 0.02, 0.03, 0.02,
  0.03, 0.02, 0.03, 0.0, 0.04, 0.07, 0.05, 0.06, 0.1, 0.05, 0.04, 0.08, 0.03,
  0.05, 0.05, 0.05, 0.06, 0.0, 0.09, 0.1, 0.0, 0.03, 0.06, 0.06, 0.02, 0.01,
  0.05, 0.04, 0.05, 0.03, 0.04, 0.03, 0.07, 0.05, 0.06, 0.03, 0.05, 0.07, 0.02,
  0.09, 0.01, 0.05, 0.09, 0.05, 0.02, 0.04, 0.03, 0.01, 0.03, 0.03, 0.04, 0.03,
  0.01, 0.03, 0.03, 0.02, 0.0, 0.03, 0.04, 0.08, 0.08, 0.03, 0.01, 0.05, 0.02,
  0.02, 0.03, 0.07, 0.09, 0.03, 0.05, 0.01, 0.02, 0.04, 0.12, 0.02, 0.02, 0.0,
  0.03, 0.09, 0.05, 0.05, 0.01, 0.0, 0.0, 0.01, 0.06, 0.06, 0.09, 0.1, 0.04,
  0.07, 0.01, 0.0, 0.06, 0.04, 0.03, 0.05, 0.08, 0.04, 0.02, 0.02, 0.01, 0.06,
  0.02, 0.02, 0.02, 0.03, 0.02, 0.08, 0.06, 0.01, 0.01, 0.03, 0.01, 0.03, 0.03,
  0.04, 0.1, 0.03, 0.0, 0.05, 0.03, 0.01, 0.09, 0.04, 0.01, 0.07, 0.05, 0.01,
  0.01, 0.01, 0.01, 0.0, 0.04, 0.01, 0.01, 0.0, 0.12, 0.02, 0.1, 0.06, 0.01,
  0.02, 0.08, 0.02, 0.04, 0.0, 0.07, 0.04, 0.02, 0.05, 0.06, 0.01, 0.03, 0.07,
  0.07, 0.02, 0.05, 0.02, 0.03, 0.12, 0.06, 0.05, 0.03, 0.02, 0.01, 0.02, 0.0,
  0.03, 0.01, 0.07, 0.07, 0.05, 0.04, 0.01, 0.06, 0.0, 0.04, 0.05, 0.05, 0.03,
  0.09, 0.04, 0.03, 0.04, 0.06, 0.04, 0.05, 0.05, 0.07, 0.0, 0.03, 0.04, 0.05,
  0.01, 0.09, 0.03, 0.07, 0.05, 0.04, 0.12, 0.05, 0.01, 0.06, 0.01, 0.09, 0.01,
  0.03, 0.05, 0.07, 0.03, 0.03, 0.01, 0.01, 0.04, 0.03, 0.09, 0.01, 0.03, 0.05,
  0.05, 0.07, 0.02, 0.01, 0.02, 0.07, 0.05, 0.02, 0.03, 0.03, 0.05, 0.02, 0.09,
  0.05, 0.03, 0.02, 0.03, 0.02, 0.08, 0.09, 0.02, 0.0, 0.07, 0.05, 0.04, 0.03,
  0.05, 0.0, 0.02, 0.01, 0.09, 0.04, 0.08, 0.06, 0.05, 0.09, 0.0, 0.02, 0.12,
  0.05, 0.04, 0.07, 0.05, 0.04, 0.0, 0.04, 0.04, 0.0, 0.08, 0.03, 0.02, 0.02,
  0.02, 0.03, 0.0, 0.04, 0.07, 0.0, 0.03, 0.01, 0.04, 0.03, 0.04, 0.09, 0.01,
  0.01, 0.02, 0.06, 0.07, 0.05, 0.07, 0.12, 0.04, 0.02, 0.03, 0.01, 0.01, 0.03,
  0.04, 0.05, 0.02, 0.04, 0.0, 0.07, 0.08, 0.02, 0.03, 0.0, 0.04, 0.02, 0.02,
  0.05, 0.05, 0.06, 0.03, 0.03, 0.05, 0.02, 0.06, 0.02, 0.06, 0.04, 0.05, 0.04,
  0.1, 0.01, 0.0, 0.06, 0.07, 0.03, 0.04, 0.05, 0.08, 0.02, 0.05, 0.01, 0.05,
  0.01, 0.05, 0.01, 0.01, 0.01, 0.06, 0.02, 0.02, 0.04, 0.03, 0.07, 0.04, 0.09,
  0.0, 0.06, 0.06, 0.02, 0.02, 0.02, 0.05, 0.01, 0.03, 0.12, 0.04, 0.06, 0.05,
  0.06, 0.03, 0.06, 0.01, 0.0, 0.04, 0.01, 0.05, 0.01, 0.05, 0.07, 0.03, 0.12,
  0.03, 0.01, 0.01, 0.03, 0.07, 0.01, 0.08, 0.07, 0.01, 0.01, 0.02, 0.07, 0.01,
  0.05, 0.02, 0.0, 0.05, 0.01, 0.08, 0.0, 0.07, 0.02, 0.08, 0.02, 0.06, 0.07,
  0.04, 0.02, 0.01, 0.01, 0.06, 0.02, 0.08, 0.03, 0.06, 0.02, 0.02, 0.0, 0.01,
  0.03, 0.07, 0.01, 0.05, 0.02, 0.01, 0.06, 0.07, 0.05, 0.05, 0.0, 0.01, 0.04,
  0.05, 0.03, 0.03, 0.02, 0.04, 0.01, 0.02, 0.01, 0.02, 0.0, 0.06, 0.04, 0.02,
  0.01, 0.01, 0.02, 0.05, 0.01, 0.07, 0.04, 0.03, 0.04, 0.08, 0.12, 0.03, 0.07,
  0.08, 0.09, 0.03, 0.04, 0.01, 0.02, 0.02, 0.0, 0.03, 0.02, 0.06, 0.07, 0.01,
  0.04, 0.06, 0.03, 0.03, 0.08, 0.06, 0.07, 0.08, 0.05, 0.06, 0.01, 0.07, 0.03,
  0.02, 0.04, 0.07, 0.04, 0.04, 0.02, 0.02, 0.05, 0.02, 0.04, 0.07, 0.07, 0.07,
  0.01, 0.08, 0.08, 0.05, 0.01, 0.04, 0.03, 0.07, 0.05, 0.02, 0.03, 0.01, 0.03,
  0.01, 0.07, 0.06, 0.0, 0.03, 0.02, 0.07, 0.03, 0.0, 0.02, 0.12, 0.05, 0.02,
  0.09, 0.05, 0.01, 0.07, 0.02, 0.02, 0.01, 0.02, 0.02, 0.07, 0.06, 0.01, 0.03,
  0.09, 0.07, 0.1, 0.01, 0.06, 0.01, 0.01, 0.03, 0.01, 0.06, 0.08, 0.04, 0.06,
  0.0, 0.01, 0.02, 0.0, 0.08, 0.02, 0.12, 0.07, 0.0, 0.02, 0.01, 0.04, 0.05,
  0.02, 0.07, 0.02, 0.06, 0.01, 0.04, 0.04, 0.05, 0.07, 0.01, 0.03, 0.04, 0.01,
  0.02, 0.05, 0.09, 0.03, 0.02, 0.04, 0.01, 0.02, 0.01, 0.08, 0.07, 0.06, 0.03,
  0.02, 0.01, 0.02, 0.02, 0.06, 0.02, 0.05, 0.06, 0.04, 0.03, 0.06, 0.06, 0.01,
  0.04, 0.01, 0.09, 0.02, 0.03, 0.02, 0.03, 0.02, 0.06, 0.05, 0.01, 0.04, 0.02,
  0.0, 0.1, 0.01, 0.04, 0.03, 0.12, 0.02, 0.05, 0.12, 0.04, 0.1, 0.02, 0.04,
  0.02, 0.01, 0.09, 0.01, 0.08, 0.04, 0.01, 0.01, 0.06, 0.03, 0.01, 0.01, 0.05,
  0.02, 0.09, 0.04, 0.0, 0.03, 0.01, 0.08, 0.06, 0.02, 0.03, 0.05, 0.01, 0.0,
  0.06, 0.01, 0.03, 0.02, 0.05, 0.05, 0.07, 0.01, 0.04, 0.02, 0.01, 0.02, 0.01,
  0.09, 0.03, 0.04, 0.04, 0.04, 0.05, 0.07, 0.02, 0.09, 0.02, 0.0, 0.03, 0.05,
  0.03, 0.01, 0.01, 0.04, 0.09, 0.04, 0.12, 0.03, 0.08, 0.03, 0.05, 0.1, 0.05,
  0.01, 0.04, 0.05, 0.05, 0.01, 0.0, 0.04, 0.02, 0.01, 0.12, 0.01, 0.01, 0.03,
  0.01, 0.1, 0.04, 0.04, 0.03, 0.02, 0.02, 0.05, 0.01, 0.06, 0.03, 0.04, 0.04,
  0.03, 0.02, 0.02, 0.09, 0.02, 0.04, 0.07, 0.02, 0.04, 0.02, 0.09, 0.06, 0.05,
  0.06, 0.01, 0.0, 0.04, 0.02, 0.04, 0.06, 0.01, 0.02, 0.06, 0.03, 0.02, 0.07,
  0.07, 0.01, 0.05, 0.01, 0.08, 0.03, 0.03, 0.05, 0.02, 0.0, 0.01, 0.01, 0.04,
  0.02, 0.06, 0.06, 0.0, 0.08, 0.04, 0.02, 0.01, 0.05, 0.03, 0.03, 0.04, 0.02,
  0.02, 0.03, 0.03, 0.01, 0.04, 0.02, 0.1, 0.03, 0.04, 0.06, 0.04, 0.03, 0.12,
  0.0, 0.01, 0.02, 0.01, 0.01, 0.01, 0.01, 0.12, 0.04, 0.04, 0.03, 0.0, 0.0,
  0.03, 0.08, 0.03, 0.02, 0.02, 0.01, 0.02, 0.01, 0.06, 0.0, 0.03, 0.02, 0.04,
  0.03, 0.04, 0.04, 0.04, 0.02, 0.01, 0.02, 0.06, 0.05, 0.01, 0.02, 0.0, 0.09,
  0.01, 0.01, 0.05, 0.05, 0.1, 0.07, 0.04, 0.03, 0.07, 0.02, 0.1, 0.0, 0.04,
  0.04, 0.06, 0.03, 0.01, 0.05, 0.02, 0.02, 0.06, 0.06, 0.0, 0.01, 0.01, 0.02,
  0.02, 0.0, 0.04, 0.12, 0.05, 0.02, 0.02, 0.07, 0.02, 0.06, 0.06, 0.01, 0.05,
  0.04, 0.06, 0.05, 0.03, 0.03, 0.1, 0.03, 0.03, 0.01, 0.02, 0.06, 0.06, 0.03,
  0.01, 0.01, 0.03, 0.04, 0.0, 0.05, 0.06, 0.0, 0.03, 0.05, 0.07, 0.02, 0.05,
  0.01, 0.1, 0.09, 0.05, 0.07, 0.02, 0.04, 0.05, 0.06, 0.05, 0.01, 0.04, 0.02,
  0.12, 0.1, 0.06, 0.06, 0.01, 0.0, 0.05, 0.05, 0.07, 0.04, 0.07, 0.01, 0.02,
  0.08, 0.04, 0.12, 0.02, 0.05, 0.01, 0.03, 0.02, 0.09, 0.02, 0.06, 0.0, 0.12,
  0.02, 0.07, 0.05, 0.03, 0.0, 0.09, 0.02, 0.08, 0.03, 0.1, 0.03, 0.12, 0.01,
  0.04, 0.03, 0.05, 0.06, 0.01, 0.03, 0.05, 0.07, 0.05, 0.08, 0.01, 0.01, 0.03,
  0.1, 0.02, 0.02, 0.02, 0.03, 0.12, 0.01, 0.04, 0.01, 0.02, 0.01, 0.02, 0.02,
  0.01, 0.0, 0.01, 0.04, 0.03, 0.04, 0.12, 0.02, 0.07, 0.04, 0.04, 0.04, 0.05,
  0.04, 0.0, 0.02, 0.07, 0.06, 0.06, 0.04, 0.06, 0.06, 0.1, 0.03, 0.06, 0.05,
  0.03, 0.08, 0.02, 0.09, 0.01, 0.02, 0.03, 0.05, 0.04, 0.02, 0.02, 0.02, 0.02,
  0.07, 0.02, 0.01, 0.04, 0.03, 0.02, 0.05, 0.12, 0.0, 0.01, 0.0, 0.02, 0.02,
  0.02, 0.01, 0.08, 0.01, 0.01, 0.04, 0.0, 0.04, 0.08, 0.04, 0.09, 0.02, 0.05,
  0.09, 0.06, 0.06, 0.04, 0.02, 0.07, 0.01, 0.05, 0.05, 0.07, 0.04, 0.03, 0.01,
  0.03, 0.05, 0.03, 0.01, 0.08, 0.01, 0.05, 0.06, 0.02, 0.01, 0.12, 0.03, 0.01,
  0.06, 0.03, 0.03, 0.01, 0.03, 0.01, 0.02, 0.04, 0.04, 0.05, 0.05, 0.08, 0.0,
  0.0, 0.05, 0.04, 0.02, 0.0, 0.07, 0.02, 0.03, 0.06, 0.08, 0.0, 0.01, 0.06,
  0.05, 0.02, 0.03, 0.06, 0.03, 0.06, 0.07, 0.12, 0.02, 0.03, 0.05, 0.02, 0.04,
  0.02, 0.05, 0.01, 0.0, 0.05, 0.0, 0.04, 0.04, 0.01, 0.07, 0.03, 0.03, 0.03,
  0.0, 0.05, 0.06, 0.04, 0.03, 0.0, 0.02, 0.04, 0.03, 0.06, 0.02, 0.08, 0.01,
  0.03, 0.0, 0.0, 0.03, 0.02, 0.03, 0.09, 0.04, 0.04, 0.04, 0.01, 0.0, 0.07,
  0.01, 0.03, 0.01, 0.06, 0.01, 0.02, 0.08, 0.0, 0.01, 0.01, 0.02, 0.04, 0.03,
  0.04, 0.06, 0.09, 0.12, 0.06, 0.01, 0.01, 0.08, 0.01, 0.04, 0.04, 0.0, 0.0,
  0.0, 0.02, 0.04, 0.06, 0.0, 0.05, 0.03, 0.02, 0.04, 0.01, 0.03, 0.03, 0.05,
  0.06, 0.09, 0.05, 0.03, 0.02, 0.01, 0.03, 0.12, 0.01, 0.09, 0.04, 0.02, 0.01,
  0.05, 0.02, 0.01, 0.06, 0.04, 0.12, 0.03, 0.06, 0.04, 0.06, 0.01, 0.01, 0.03,
  0.06, 0.09, 0.04, 0.01, 0.01, 0.12, 0.01, 0.01, 0.02, 0.02, 0.02, 0.01, 0.02,
  0.08, 0.06, 0.01, 0.05, 0.09, 0.02, 0.02, 0.02, 0.03, 0.03, 0.06, 0.08, 0.03,
  0.0, 0.06, 0.0, 0.02, 0.01, 0.01, 0.07, 0.09, 0.02, 0.01, 0.1, 0.12, 0.01,
  0.12, 0.08, 0.02, 0.09, 0.01, 0.03, 0.08, 0.02, 0.04, 0.03, 0.02, 0.02, 0.03,
  0.04, 0.05, 0.02, 0.03, 0.02, 0.01, 0.01, 0.04, 0.03, 0.04, 0.04, 0.03, 0.0,
  0.01, 0.04, 0.02, 0.04, 0.02, 0.05, 0.01, 0.08, 0.04, 0.01, 0.07, 0.02, 0.01,
  0.02, 0.02, 0.06, 0.04, 0.05, 0.09, 0.05, 0.05, 0.1, 0.06, 0.01, 0.01, 0.02,
  0.01, 0.03, 0.07, 0.02, 0.07, 0.06, 0.12, 0.01, 0.07, 0.08, 0.04, 0.01, 0.05,
  0.0, 0.02, 0.02, 0.09, 0.06, 0.03, 0.0, 0.02, 0.07, 0.02, 0.01, 0.0, 0.05,
  0.01, 0.02, 0.05, 0.04, 0.03, 0.12, 0.04, 0.02, 0.06, 0.01, 0.05, 0.09, 0.02,
  0.07, 0.12, 0.02, 0.06, 0.02, 0.03, 0.03, 0.05, 0.05, 0.02, 0.05, 0.03, 0.05,
  0.02, 0.04, 0.07, 0.03, 0.01, 0.05, 0.01, 0.01, 0.07, 0.02, 0.04, 0.04, 0.02,
  0.07, 0.0, 0.01, 0.04, 0.01, 0.01, 0.0, 0.05, 0.06, 0.04, 0.03, 0.06, 0.06,
  0.03, 0.01, 0.0, 0.1, 0.04, 0.12, 0.01, 0.12, 0.08, 0.01, 0.05, 0.07, 0.01,
  0.02, 0.04, 0.06, 0.05, 0.1, 0.12, 0.0, 0.01, 0.04, 0.03, 0.03, 0.07, 0.04,
  0.07, 0.0, 0.09, 0.04, 0.03, 0.03, 0.06, 0.04, 0.03, 0.02, 0.03, 0.02, 0.08,
  0.01, 0.01, 0.05, 0.0, 0.01, 0.02, 0.04, 0.03, 0.09, 0.05, 0.04, 0.02, 0.05,
  0.06, 0.04, 0.06, 0.02, 0.02, 0.05, 0.03, 0.07, 0.03, 0.07, 0.03, 0.01, 0.05,
  0.01, 0.1, 0.02, 0.0, 0.04, 0.09, 0.02, 0.01, 0.09, 0.08, 0.01, 0.01, 0.05,
  0.04, 0.02, 0.06, 0.01, 0.01, 0.03, 0.04, 0.01, 0.07, 0.01, 0.0, 0.01, 0.02,
  0.01, 0.03, 0.06, 0.12, 0.02, 0.07, 0.02, 0.06, 0.12, 0.07, 0.06, 0.02, 0.02,
  0.04, 0.07, 0.08, 0.03, 0.07, 0.07, 0.0, 0.03, 0.03, 0.1, 0.01, 0.01, 0.07,
  0.08, 0.01, 0.02, 0.0, 0.01, 0.01, 0.02, 0.03, 0.01, 0.05, 0.0, 0.0, 0.04,
  0.05, 0.01, 0.08, 0.04, 0.1, 0.08, 0.08, 0.01, 0.02, 0.05, 0.02, 0.04, 0.04,
  0.01, 0.03, 0.05, 0.01, 0.01, 0.03, 0.08, 0.09, 0.08, 0.02, 0.05, 0.0, 0.01,
  0.06, 0.02, 0.02, 0.02, 0.09, 0.02, 0.09, 0.01, 0.04, 0.05, 0.02, 0.0, 0.04,
  0.0, 0.08, 0.01, 0.06, 0.0, 0.07, 0.01, 0.03, 0.02, 0.09, 0.06, 0.03, 0.09,
  0.04, 0.01, 0.04, 0.04, 0.07, 0.03, 0.09, 0.03, 0.08, 0.01, 0.05, 0.01, 0.06,
  0.04, 0.0, 0.02, 0.04, 0.07, 0.04, 0.07, 0.02, 0.01, 0.06, 0.07, 0.06, 0.08,
  0.01, 0.0, 0.01, 0.01, 0.09, 0.04, 0.01, 0.02, 0.02, 0.03, 0.1, 0.05, 0.04,
  0.04, 0.0, 0.08, 0.03, 0.06, 0.02, 0.0, 0.06, 0.04, 0.01, 0.01, 0.08, 0.04,
  0.03, 0.0, 0.08, 0.03, 0.01, 0.02, 0.04, 0.02, 0.05, 0.04, 0.05, 0.01, 0.08,
  0.03, 0.04, 0.04, 0.03, 0.06, 0.06, 0.07, 0.0, 0.03, 0.08, 0.1, 0.0, 0.06,
  0.04, 0.06, 0.02, 0.05, 0.0, 0.01, 0.12, 0.03, 0.12, 0.12, 0.01, 0.02, 0.05,
  0.08, 0.01, 0.12, 0.05, 0.0, 0.09, 0.04, 0.0, 0.02, 0.02, 0.04, 0.01, 0.01,
  0.01, 0.02, 0.02, 0.03, 0.05, 0.02, 0.02, 0.01, 0.07, 0.01, 0.02, 0.02, 0.04,
  0.01, 0.0, 0.04, 0.02, 0.05, 0.04, 0.02, 0.03, 0.04, 0.02, 0.03, 0.05, 0.01,
  0.04, 0.08, 0.05, 0.06, 0.04, 0.04, 0.04, 0.01, 0.02, 0.03, 0.09, 0.06, 0.06,
  0.02, 0.07, 0.02, 0.04, 0.01, 0.02, 0.05, 0.04, 0.05, 0.02, 0.02, 0.02, 0.01,
  0.05, 0.04, 0.01, 0.03, 0.04, 0.05, 0.09, 0.0, 0.06, 0.02, 0.04, 0.02, 0.09,
  0.09, 0.04, 0.0, 0.02, 0.01, 0.01, 0.01, 0.06, 0.03, 0.1, 0.06, 0.05, 0.03,
  0.0, 0.04, 0.06, 0.01, 0.05, 0.08, 0.02, 0.08, 0.05, 0.04, 0.03, 0.03, 0.02,
  0.03, 0.1, 0.09, 0.01, 0.09, 0.02, 0.08, 0.03, 0.01, 0.04, 0.03, 0.09, 0.1,
  0.04, 0.01, 0.03, 0.02, 0.05, 0.07, 0.02, 0.06, 0.06, 0.05, 0.06, 0.04, 0.01,
  0.09, 0.01, 0.02, 0.01, 0.06, 0.03, 0.04, 0.01, 0.09, 0.0, 0.12, 0.03, 0.04,
  0.03, 0.04, 0.05, 0.05, 0.01, 0.01, 0.04, 0.05, 0.0, 0.04, 0.0, 0.01, 0.08,
  0.06, 0.06, 0.0, 0.04, 0.01, 0.03, 0.05, 0.01, 0.05, 0.03, 0.01, 0.01, 0.04,
  0.07, 0.04, 0.05, 0.01, 0.05, 0.01, 0.02, 0.06, 0.01, 0.01, 0.06, 0.06, 0.07,
  0.12, 0.03, 0.03, 0.04, 0.04, 0.03, 0.02, 0.05, 0.03, 0.04, 0.03, 0.03, 0.09,
  0.06, 0.01, 0.08, 0.03, 0.03, 0.02, 0.09, 0.08, 0.1, 0.03, 0.04, 0.0, 0.02,
  0.05, 0.05, 0.07, 0.02, 0.08, 0.01, 0.0, 0.03, 0.0, 0.08, 0.01, 0.01, 0.1,
  0.05, 0.04, 0.06, 0.0, 0.12, 0.03, 0.02, 0.04, 0.01, 0.08, 0.01, 0.12, 0.07,
  0.02, 0.01, 0.06, 0.04, 0.02, 0.07, 0.06, 0.07, 0.02, 0.01, 0.0, 0.02, 0.03,
  0.02, 0.06, 0.08, 0.05, 0.05, 0.0, 0.05, 0.08, 0.01, 0.02, 0.09, 0.0, 0.09,
  0.05, 0.01, 0.08, 0.01, 0.05, 0.02, 0.03, 0.01, 0.02, 0.0, 0.04, 0.06, 0.03,
  0.02, 0.04, 0.06, 0.0, 0.08, 0.01, 0.04, 0.02, 0.04, 0.06, 0.01, 0.01, 0.03,
  0.09, 0.02, 0.05, 0.02, 0.03, 0.09, 0.07, 0.07, 0.0, 0.07, 0.01, 0.02, 0.02,
  0.02, 0.09, 0.01, 0.09, 0.07, 0.1, 0.09, 0.06, 0.01, 0.09, 0.02, 0.08, 0.02,
  0.01, 0.01, 0.07, 0.08, 0.06, 0.01, 0.02, 0.04, 0.05, 0.02, 0.02, 0.0, 0.06,
  0.05, 0.03, 0.0, 0.04, 0.05, 0.05, 0.01, 0.04, 0.02, 0.04, 0.0, 0.07, 0.02,
  0.02, 0.03, 0.0, 0.05, 0.01, 0.03, 0.01, 0.03, 0.02, 0.06, 0.02, 0.03, 0.12,
  0.03, 0.05, 0.04, 0.01, 0.0, 0.01, 0.12, 0.12, 0.07, 0.01, 0.04, 0.01, 0.04,
  0.01, 0.02, 0.03, 0.02, 0.01, 0.06, 0.09, 0.06, 0.01, 0.02, 0.03, 0.01, 0.02,
  0.04, 0.05, 0.05, 0.06, 0.07, 0.03, 0.01, 0.09, 0.0, 0.06, 0.02, 0.01, 0.12,
  0.08, 0.04, 0.05, 0.04, 0.06, 0.02, 0.04, 0.02, 0.02, 0.04, 0.06, 0.01, 0.01,
  0.04, 0.05, 0.05, 0.01, 0.04, 0.01, 0.06, 0.09, 0.0, 0.01, 0.02, 0.01, 0.01,
  0.04, 0.1, 0.01, 0.02, 0.04, 0.02, 0.06, 0.0, 0.12, 0.05, 0.06, 0.12, 0.07,
  0.02, 0.08, 0.06, 0.01, 0.12, 0.0, 0.1, 0.04, 0.06, 0.06, 0.01, 0.06, 0.03,
  0.02, 0.0, 0.07, 0.01, 0.05, 0.02, 0.01, 0.09, 0.04, 0.02, 0.08, 0.06, 0.01,
  0.02, 0.01, 0.02, 0.01, 0.0, 0.03, 0.05, 0.05, 0.1, 0.03, 0.09, 0.02, 0.07,
  0.04, 0.03, 0.02, 0.05, 0.04, 0.03, 0.09, 0.06, 0.04, 0.04, 0.01, 0.1, 0.03,
  0.04, 0.01, 0.02, 0.01, 0.03, 0.05, 0.04, 0.01, 0.02, 0.01, 0.07, 0.02, 0.02,
  0.0, 0.05, 0.09, 0.04, 0.01, 0.03, 0.06, 0.05, 0.02, 0.04, 0.02, 0.07, 0.05,
  0.05, 0.03, 0.0, 0.02, 0.05, 0.01, 0.01, 0.01, 0.04, 0.06, 0.03, 0.01, 0.08,
  0.03, 0.02, 0.06, 0.1, 0.08, 0.08, 0.08, 0.03, 0.02, 0.05, 0.02, 0.06, 0.01,
  0.01, 0.04, 0.04, 0.08, 0.04, 0.02, 0.02, 0.03, 0.06, 0.04, 0.0, 0.03, 0.0,
  0.0, 0.03, 0.1, 0.04, 0.05, 0.01, 0.01, 0.07, 0.06, 0.04, 0.07, 0.01, 0.05,
  0.1, 0.09, 0.02, 0.07, 0.08, 0.06, 0.07, 0.05, 0.04, 0.02, 0.01, 0.05, 0.03,
  0.06, 0.04, 0.03, 0.05, 0.03, 0.04, 0.02, 0.02, 0.03, 0.02, 0.07, 0.03, 0.04,
  0.04, 0.04, 0.01, 0.05, 0.02, 0.02, 0.03, 0.01, 0.03, 0.02, 0.04, 0.02, 0.08,
  0.01, 0.08, 0.02, 0.08, 0.05, 0.08, 0.04, 0.08, 0.01, 0.03, 0.04, 0.03, 0.07,
  0.01, 0.03, 0.06, 0.03, 0.03, 0.03, 0.09, 0.05, 0.12, 0.08, 0.01, 0.1, 0.03,
  0.04, 0.06, 0.0, 0.03, 0.02, 0.01, 0.02, 0.07, 0.03, 0.05, 0.04, 0.1, 0.01,
  0.03, 0.03, 0.06, 0.02, 0.03, 0.01, 0.01, 0.06, 0.06, 0.04, 0.02, 0.0, 0.07,
  0.04, 0.1, 0.05, 0.03, 0.09, 0.1, 0.03, 0.01, 0.05, 0.09, 0.04, 0.04, 0.05,
  0.12, 0.01, 0.07, 0.01, 0.02, 0.03, 0.04, 0.02, 0.01, 0.01, 0.01, 0.04, 0.0,
  0.04, 0.04, 0.03, 0.09, 0.01, 0.02, 0.02, 0.02, 0.01, 0.07, 0.03, 0.0, 0.02,
  0.02, 0.01, 0.06, 0.05, 0.04, 0.03, 0.0, 0.02, 0.08, 0.01, 0.01, 0.07, 0.01,
  0.05, 0.01, 0.0, 0.02, 0.04, 0.04, 0.02, 0.02, 0.12, 0.0, 0.06, 0.01, 0.0,
  0.02, 0.08, 0.1, 0.02, 0.03, 0.01, 0.05, 0.02, 0.08, 0.0, 0.05, 0.01, 0.02,
  0.12, 0.08, 0.02, 0.0, 0.02, 0.04, 0.02, 0.02, 0.04, 0.06, 0.05, 0.02, 0.07,
  0.01, 0.04, 0.09, 0.02, 0.0, 0.02, 0.02, 0.01, 0.07, 0.03, 0.02, 0.03, 0.05,
  0.06, 0.0, 0.01, 0.03, 0.05, 0.08, 0.04, 0.02, 0.01, 0.06, 0.06, 0.03, 0.01,
  0.02, 0.03, 0.01, 0.01, 0.05, 0.01, 0.0, 0.0, 0.05, 0.07, 0.02, 0.03, 0.01,
  0.02, 0.03, 0.08, 0.02, 0.03, 0.06, 0.05, 0.07, 0.01, 0.01, 0.01, 0.12, 0.01,
  0.06, 0.02, 0.02, 0.02, 0.01, 0.04, 0.09, 0.05, 0.05, 0.04, 0.04, 0.01, 0.01,
  0.1, 0.06, 0.07, 0.05, 0.01, 0.05, 0.05, 0.07, 0.01, 0.01, 0.03, 0.05, 0.02,
  0.03, 0.01, 0.06, 0.0, 0.12, 0.04, 0.03, 0.03, 0.03, 0.08, 0.01, 0.01, 0.05,
  0.0, 0.05, 0.04, 0.01, 0.03, 0.0, 0.02, 0.06, 0.07, 0.09, 0.04, 0.01, 0.01,
  0.03, 0.06, 0.02, 0.05, 0.0, 0.09, 0.06, 0.04, 0.04, 0.02, 0.06, 0.02, 0.05,
  0.05, 0.06, 0.05, 0.0, 0.05, 0.04, 0.0, 0.06, 0.04, 0.03, 0.02, 0.01, 0.05,
  0.03, 0.01, 0.01, 0.08, 0.01, 0.07, 0.07, 0.07, 0.04, 0.09, 0.04, 0.02, 0.02,
  0.02, 0.0, 0.06, 0.03, 0.08, 0.03, 0.04, 0.04, 0.03, 0.02, 0.07, 0.01, 0.02,
  0.09, 0.05, 0.05, 0.07, 0.03, 0.01, 0.01, 0.01, 0.02, 0.03, 0.03, 0.02, 0.12,
  0.12, 0.01, 0.05, 0.03, 0.09, 0.01, 0.04, 0.05, 0.04, 0.03, 0.09, 0.03, 0.01,
  0.09, 0.03, 0.02, 0.09, 0.02, 0.03, 0.04, 0.02, 0.02, 0.01, 0.01, 0.0, 0.0,
  0.03, 0.08, 0.06, 0.02, 0.01, 0.08, 0.12, 0.01, 0.02, 0.02, 0.09, 0.01, 0.01,
  0.04, 0.0, 0.08, 0.0, 0.06, 0.01, 0.04, 0.0, 0.07, 0.03, 0.02, 0.02, 0.03,
  0.03, 0.05, 0.08, 0.12, 0.1, 0.02, 0.09, 0.01, 0.05, 0.1, 0.02, 0.03, 0.03,
  0.02, 0.01, 0.02, 0.04, 0.02, 0.04, 0.05, 0.01, 0.01, 0.07, 0.0, 0.04, 0.03,
  0.1, 0.01, 0.07, 0.06, 0.01, 0.02, 0.06, 0.07, 0.05, 0.03, 0.0, 0.09, 0.01,
  0.05, 0.02, 0.0, 0.03, 0.02, 0.03, 0.02, 0.01, 0.07, 0.03, 0.09, 0.01, 0.0,
  0.08, 0.03, 0.0, 0.04, 0.08, 0.01, 0.12, 0.03, 0.01, 0.07, 0.08, 0.04, 0.09,
  0.05, 0.03, 0.06, 0.07, 0.1, 0.02, 0.03, 0.05, 0.12, 0.01, 0.03, 0.04, 0.04,
  0.03, 0.0, 0.03, 0.03, 0.0, 0.07, 0.03, 0.05, 0.03, 0.01, 0.04, 0.01, 0.07,
  0.03, 0.01, 0.07, 0.01, 0.01, 0.0, 0.04, 0.09, 0.02, 0.04, 0.02, 0.07, 0.07,
  0.07, 0.01, 0.02, 0.01, 0.03, 0.04, 0.01, 0.01, 0.02, 0.08, 0.02, 0.05, 0.07,
  0.02, 0.12, 0.04, 0.01, 0.09, 0.01, 0.12, 0.01, 0.04, 0.0, 0.04, 0.04, 0.09,
  0.02, 0.01, 0.08, 0.06, 0.09, 0.01, 0.03, 0.1, 0.04, 0.0, 0.09, 0.0, 0.06,
  0.04, 0.02, 0.02, 0.02, 0.02, 0.02, 0.08, 0.12, 0.0, 0.02, 0.02, 0.06, 0.12,
  0.07, 0.02, 0.08, 0.0, 0.03, 0.01, 0.06, 0.07, 0.03, 0.01, 0.01, 0.04, 0.01,
  0.05, 0.03, 0.04, 0.03, 0.03, 0.06, 0.04, 0.01, 0.01, 0.06, 0.06, 0.03, 0.01,
  0.02, 0.04, 0.04, 0.06, 0.05, 0.05, 0.1, 0.06, 0.04, 0.06, 0.03, 0.04, 0.05,
  0.05, 0.01, 0.02, 0.02, 0.04, 0.05, 0.03, 0.01, 0.01, 0.09, 0.04, 0.06, 0.0,
  0.05, 0.01, 0.02, 0.01, 0.01, 0.02, 0.03, 0.12, 0.01, 0.01, 0.08, 0.05, 0.05,
  0.01, 0.0, 0.02, 0.01, 0.04, 0.02, 0.12, 0.0, 0.04, 0.01, 0.0, 0.01, 0.02,
  0.02, 0.03, 0.01, 0.03, 0.0, 0.02, 0.08, 0.02, 0.01, 0.04, 0.09, 0.01, 0.08,
  0.0, 0.04, 0.03, 0.05, 0.02, 0.02, 0.01, 0.01, 0.06, 0.01, 0.01, 0.08, 0.05,
  0.02, 0.02, 0.02, 0.06, 0.03, 0.05, 0.01, 0.03, 0.0, 0.08, 0.05, 0.01, 0.03,
  0.02, 0.02, 0.02, 0.01, 0.03, 0.05, 0.08, 0.02, 0.02, 0.01, 0.0, 0.06, 0.03,
  0.03, 0.01, 0.02, 0.04, 0.03, 0.08, 0.07, 0.04, 0.07, 0.05, 0.1, 0.02, 0.02,
  0.06, 0.03, 0.03, 0.03, 0.09, 0.06, 0.04, 0.05, 0.09, 0.02, 0.03, 0.12, 0.09,
  0.1, 0.02, 0.03, 0.0, 0.02, 0.02, 0.12, 0.04, 0.09, 0.03, 0.02, 0.03, 0.07,
  0.01, 0.06, 0.04, 0.07, 0.02, 0.06, 0.03, 0.05, 0.04, 0.02, 0.03, 0.09, 0.0,
  0.05, 0.04, 0.03, 0.09, 0.02, 0.05, 0.01, 0.04, 0.1, 0.01, 0.01, 0.04, 0.03,
  0.07, 0.04, 0.01, 0.01, 0.05, 0.05, 0.07, 0.05, 0.03, 0.04, 0.04, 0.0, 0.05,
  0.01, 0.04, 0.03, 0.03, 0.04, 0.06, 0.04, 0.03, 0.01, 0.01, 0.03, 0.02, 0.06,
  0.06, 0.05, 0.03, 0.03, 0.06, 0.06, 0.03, 0.01, 0.02, 0.09, 0.03, 0.01, 0.04,
  0.01, 0.08, 0.08, 0.04, 0.03, 0.0, 0.01, 0.02, 0.01, 0.01, 0.08, 0.01, 0.02,
  0.05, 0.04, 0.04, 0.01, 0.01, 0.06, 0.0, 0.01, 0.05, 0.06, 0.05, 0.03, 0.06,
  0.02, 0.06, 0.0, 0.04, 0.06, 0.07, 0.03, 0.02, 0.0, 0.02, 0.09, 0.04, 0.06,
  0.09, 0.04, 0.05, 0.02, 0.0, 0.0, 0.02, 0.05, 0.0, 0.07, 0.03, 0.07, 0.0,
  0.02, 0.02, 0.05, 0.02, 0.09, 0.02, 0.07, 0.01, 0.06, 0.01, 0.02, 0.07, 0.0,
  0.0, 0.04, 0.01, 0.07, 0.01, 0.03, 0.01, 0.08, 0.01, 0.02, 0.04, 0.07, 0.02,
  0.01, 0.04, 0.02, 0.01, 0.0, 0.06, 0.02, 0.08, 0.04, 0.07, 0.06, 0.0, 0.07,
  0.01, 0.06, 0.01, 0.06, 0.01, 0.05, 0.02, 0.04, 0.07, 0.02, 0.02, 0.0, 0.01,
  0.02, 0.08, 0.06, 0.03, 0.03, 0.02, 0.02, 0.01, 0.02, 0.02, 0.02, 0.0, 0.07,
  0.03, 0.06, 0.01, 0.08, 0.02, 0.01, 0.01, 0.04, 0.02, 0.09, 0.05, 0.04, 0.09,
  0.02, 0.0, 0.0, 0.06, 0.05, 0.03, 0.02, 0.05, 0.03, 0.03, 0.01, 0.03, 0.04,
  0.01, 0.05, 0.05, 0.09, 0.01, 0.04, 0.05, 0.0, 0.01, 0.06, 0.02, 0.06, 0.04,
  0.12, 0.08, 0.06, 0.0, 0.01, 0.1, 0.06, 0.06, 0.03, 0.01, 0.06, 0.02, 0.01,
  0.01, 0.0, 0.03, 0.03, 0.07, 0.04, 0.04, 0.03, 0.06, 0.06, 0.08, 0.12, 0.03,
  0.07, 0.05, 0.12, 0.01, 0.06, 0.01, 0.05, 0.07, 0.1, 0.06, 0.03, 0.07, 0.05,
  0.06, 0.03, 0.01, 0.05, 0.0, 0.02, 0.01, 0.1, 0.03, 0.01, 0.09, 0.04, 0.01,
  0.02, 0.03, 0.04, 0.04, 0.0, 0.02, 0.06, 0.03, 0.04, 0.07, 0.04, 0.01, 0.06,
  0.04, 0.02, 0.08, 0.01, 0.05, 0.0, 0.04, 0.05, 0.0, 0.02, 0.02, 0.03, 0.02,
  0.04, 0.02, 0.07, 0.06, 0.05, 0.02, 0.01, 0.08, 0.02, 0.04, 0.02, 0.04, 0.04,
  0.03, 0.01, 0.06, 0.01, 0.08, 0.05, 0.02, 0.07, 0.01, 0.1, 0.03, 0.01, 0.07,
  0.09, 0.03, 0.01, 0.06, 0.07, 0.04, 0.02, 0.1, 0.03, 0.02, 0.04, 0.12, 0.05,
  0.01, 0.03, 0.06, 0.04, 0.03, 0.1, 0.03, 0.1, 0.06, 0.07, 0.02, 0.01, 0.03,
  0.09, 0.05, 0.01, 0.09, 0.12, 0.02, 0.1, 0.02, 0.09, 0.01, 0.05, 0.02, 0.02,
  0.07, 0.07, 0.01, 0.01, 0.02, 0.01, 0.02, 0.08, 0.03, 0.03, 0.02, 0.01, 0.05,
  0.02, 0.02, 0.0, 0.07, 0.08, 0.01, 0.0, 0.09, 0.05, 0.07, 0.03, 0.0, 0.07,
  0.07, 0.1, 0.05, 0.0, 0.01, 0.09, 0.03, 0.02, 0.01, 0.05, 0.07, 0.02, 0.02,
  0.02, 0.12, 0.05, 0.08, 0.05, 0.01, 0.1, 0.04, 0.07, 0.05, 0.01, 0.02, 0.02,
  0.02, 0.07, 0.04, 0.01, 0.01, 0.06, 0.02, 0.05, 0.09, 0.02, 0.05, 0.07, 0.01,
  0.07, 0.08, 0.05, 0.02, 0.02, 0.0, 0.02, 0.12, 0.06, 0.03, 0.03, 0.07, 0.03,
  0.05, 0.02, 0.05, 0.04, 0.01, 0.02, 0.01, 0.06, 0.02, 0.01, 0.03, 0.04, 0.08,
  0.1, 0.04, 0.06, 0.02, 0.12, 0.08, 0.03, 0.02, 0.02, 0.07, 0.03, 0.05, 0.02,
  0.01, 0.02, 0.07, 0.08, 0.07, 0.06, 0.02, 0.07, 0.09, 0.02, 0.01, 0.1, 0.06,
  0.05, 0.02, 0.1, 0.01, 0.02, 0.01, 0.01, 0.12, 0.02, 0.01, 0.01, 0.0, 0.01,
  0.05, 0.05, 0.04, 0.05, 0.04, 0.12, 0.08, 0.02, 0.0, 0.01, 0.07, 0.02, 0.08,
  0.07, 0.01, 0.01, 0.05, 0.01, 0.05, 0.07, 0.02, 0.05, 0.05, 0.03, 0.06, 0.09,
  0.03, 0.04, 0.03, 0.08, 0.07, 0.1, 0.06, 0.01, 0.05, 0.04, 0.03, 0.01, 0.04,
  0.1, 0.0, 0.01, 0.05, 0.02, 0.03, 0.06, 0.0, 0.06, 0.02, 0.01, 0.04, 0.02,
  0.1, 0.01, 0.04, 0.02, 0.03, 0.01, 0.01, 0.07, 0.04, 0.01, 0.01, 0.07, 0.04,
  0.01, 0.07, 0.01, 0.02, 0.02, 0.09, 0.07, 0.06, 0.01, 0.05, 0.03, 0.04, 0.01,
  0.01, 0.05, 0.02, 0.07, 0.05, 0.02, 0.06, 0.1, 0.06, 0.04, 0.01, 0.02, 0.0,
  0.02, 0.09, 0.06, 0.01, 0.09, 0.02, 0.01, 0.05, 0.03, 0.03, 0.05, 0.03, 0.09,
  0.0, 0.03, 0.01, 0.01, 0.03, 0.1, 0.02, 0.04, 0.02, 0.07, 0.02, 0.03, 0.02,
  0.02, 0.03, 0.03, 0.05, 0.03, 0.06, 0.06, 0.04, 0.04, 0.05, 0.01, 0.03, 0.02,
  0.06, 0.06, 0.05, 0.04, 0.12, 0.02, 0.02, 0.05, 0.08, 0.04, 0.06, 0.02, 0.05,
  0.02, 0.0, 0.01, 0.05, 0.08, 0.01, 0.06, 0.02, 0.05, 0.02, 0.05, 0.06, 0.02,
  0.01, 0.04, 0.02, 0.05, 0.01, 0.06, 0.04, 0.03, 0.04, 0.04, 0.03, 0.1, 0.06,
  0.03, 0.01, 0.09, 0.02, 0.03, 0.08, 0.02, 0.02, 0.02, 0.04, 0.06, 0.06, 0.07,
  0.08, 0.04, 0.04, 0.01, 0.0, 0.05, 0.02, 0.01, 0.02, 0.04, 0.02, 0.05, 0.06,
  0.02, 0.02, 0.03, 0.01, 0.08, 0.03, 0.09, 0.01, 0.01, 0.03, 0.06, 0.02, 0.05,
  0.03, 0.05, 0.03, 0.03, 0.09, 0.03, 0.0, 0.02, 0.03, 0.02, 0.0, 0.02, 0.02,
  0.03, 0.01, 0.05, 0.0, 0.03, 0.04, 0.05, 0.02, 0.12, 0.0, 0.05, 0.03, 0.01,
  0.04, 0.01, 0.02, 0.02, 0.03, 0.04, 0.0, 0.02, 0.04, 0.06, 0.02, 0.05, 0.02,
  0.05, 0.02, 0.03, 0.07, 0.02, 0.02, 0.04, 0.08, 0.05, 0.0, 0.06, 0.05, 0.06,
  0.07, 0.04, 0.08, 0.07, 0.02, 0.08, 0.04, 0.08, 0.02, 0.03, 0.02, 0.05, 0.0,
  0.05, 0.03, 0.02, 0.05, 0.06, 0.04, 0.03, 0.04, 0.05, 0.02, 0.03, 0.03, 0.03,
  0.01, 0.02, 0.06, 0.01, 0.06, 0.04, 0.02, 0.08, 0.02, 0.01, 0.04, 0.01, 0.01,
  0.03, 0.05, 0.02, 0.04, 0.06, 0.06, 0.0, 0.05, 0.12, 0.04, 0.03, 0.04, 0.02,
  0.04, 0.02, 0.1, 0.04, 0.04, 0.01, 0.02, 0.07, 0.0, 0.05, 0.04, 0.02, 0.01,
  0.01, 0.05, 0.02, 0.12, 0.03, 0.03, 0.05, 0.01, 0.08, 0.09, 0.01, 0.06, 0.03,
  0.03, 0.12, 0.01, 0.07, 0.05, 0.04, 0.04, 0.06, 0.0, 0.03, 0.03, 0.02, 0.01,
  0.03, 0.05, 0.03, 0.06, 0.01, 0.0, 0.01, 0.02, 0.05, 0.0, 0.01, 0.04, 0.03,
  0.08, 0.02, 0.07, 0.09, 0.05, 0.0, 0.05, 0.07, 0.1, 0.08, 0.07, 0.0, 0.02,
  0.05, 0.05, 0.01, 0.02, 0.03, 0.1, 0.03, 0.08, 0.01, 0.07, 0.02, 0.08, 0.01,
  0.02, 0.02, 0.04, 0.05, 0.1, 0.08, 0.05, 0.07, 0.04, 0.08, 0.05, 0.12, 0.05,
  0.04, 0.01, 0.0, 0.04, 0.07, 0.05, 0.07, 0.02, 0.03, 0.02, 0.0, 0.04, 0.12,
  0.02, 0.01, 0.05, 0.04, 0.03, 0.06, 0.02, 0.02, 0.03, 0.01, 0.09, 0.02, 0.02,
  0.04, 0.01, 0.06, 0.07, 0.06, 0.04, 0.05, 0.01, 0.06, 0.08, 0.03, 0.01, 0.01,
  0.02, 0.03, 0.01, 0.02, 0.04, 0.0, 0.04, 0.01, 0.05, 0.02, 0.05, 0.06, 0.0,
  0.0, 0.05, 0.04, 0.08, 0.03, 0.02, 0.04, 0.05, 0.06, 0.04, 0.06, 0.04, 0.05,
  0.06, 0.12, 0.0, 0.01, 0.06, 0.04, 0.06, 0.1, 0.0, 0.02, 0.02, 0.06, 0.06,
  0.04, 0.04, 0.03, 0.02, 0.04, 0.03, 0.04, 0.03, 0.02, 0.06, 0.02, 0.06, 0.01,
  0.02, 0.03, 0.02, 0.01, 0.05, 0.02, 0.01, 0.0, 0.02, 0.07, 0.09, 0.06, 0.01,
  0.04, 0.04, 0.01, 0.01, 0.02, 0.04, 0.01, 0.08, 0.01, 0.02, 0.0, 0.03, 0.01,
  0.06, 0.07, 0.07, 0.05, 0.01, 0.04, 0.0, 0.03, 0.02, 0.04, 0.02, 0.07, 0.1,
  0.04, 0.01, 0.02, 0.12, 0.01, 0.03, 0.05, 0.03, 0.01, 0.03, 0.08, 0.04, 0.05,
  0.08, 0.04, 0.07, 0.0, 0.05, 0.04, 0.04, 0.08, 0.0, 0.03, 0.06, 0.03, 0.03,
  0.01, 0.02, 0.04, 0.04, 0.01, 0.0, 0.03, 0.08, 0.1, 0.04, 0.01, 0.02, 0.02,
  0.03, 0.0, 0.01, 0.04, 0.04, 0.02, 0.06, 0.03, 0.01, 0.02, 0.01, 0.06, 0.05,
  0.12, 0.03, 0.01, 0.05, 0.01, 0.03, 0.03, 0.0, 0.05, 0.0, 0.05, 0.03, 0.01,
  0.04, 0.01, 0.09, 0.03, 0.01, 0.01, 0.03, 0.03, 0.01, 0.02, 0.02, 0.01, 0.02,
  0.04, 0.05, 0.09, 0.01, 0.02, 0.04, 0.04, 0.07, 0.05, 0.01, 0.01, 0.02, 0.06,
  0.06, 0.06, 0.0, 0.06, 0.02, 0.04, 0.02, 0.01, 0.02, 0.03, 0.02, 0.02, 0.0,
  0.06, 0.0, 0.02, 0.0, 0.02, 0.02, 0.02, 0.0, 0.08, 0.02, 0.04, 0.04, 0.01,
  0.03, 0.04, 0.01, 0.05, 0.03, 0.01, 0.04, 0.02, 0.04, 0.05, 0.03, 0.02, 0.02,
  0.03, 0.09, 0.07, 0.1, 0.03, 0.07, 0.07, 0.08, 0.03, 0.0, 0.1, 0.04, 0.09,
  0.02, 0.05, 0.03, 0.06, 0.02, 0.06, 0.03, 0.02, 0.01, 0.1, 0.0, 0.02, 0.04,
  0.04, 0.07, 0.05, 0.01, 0.04, 0.01, 0.02, 0.03, 0.01, 0.01, 0.04, 0.02, 0.08,
  0.08, 0.03, 0.01, 0.09, 0.05, 0.03, 0.06, 0.06, 0.04, 0.05, 0.0, 0.05, 0.04,
  0.05, 0.04, 0.05, 0.04, 0.02, 0.03, 0.04, 0.03, 0.01, 0.02, 0.04, 0.01, 0.0,
  0.07, 0.06, 0.12, 0.02, 0.01, 0.04, 0.04, 0.02, 0.07, 0.05, 0.04, 0.12, 0.02,
  0.03, 0.03, 0.02, 0.04, 0.07, 0.0, 0.04, 0.07, 0.01, 0.05, 0.07, 0.04, 0.07,
  0.0, 0.03, 0.09, 0.06, 0.01, 0.02, 0.03, 0.05, 0.05, 0.0, 0.12, 0.02, 0.06,
  0.04, 0.01, 0.08, 0.07, 0.06, 0.04, 0.08, 0.09, 0.03, 0.03, 0.0, 0.05, 0.06,
  0.03, 0.0, 0.07, 0.03, 0.01, 0.09, 0.09, 0.02, 0.03, 0.01, 0.0, 0.01, 0.08,
  0.01, 0.04, 0.01, 0.04, 0.05, 0.03, 0.06, 0.01, 0.06, 0.03, 0.01, 0.0, 0.0,
  0.02, 0.02, 0.09, 0.01, 0.04, 0.12, 0.07, 0.05, 0.04, 0.02, 0.04, 0.0, 0.04,
  0.01, 0.03, 0.04, 0.01, 0.01, 0.01, 0.04, 0.09, 0.02, 0.01, 0.12, 0.01, 0.01,
  0.03, 0.09, 0.08, 0.09, 0.06, 0.06, 0.0, 0.09, 0.01, 0.03, 0.04, 0.08, 0.02,
  0.03, 0.05, 0.0, 0.02, 0.07, 0.07, 0.05, 0.04, 0.1, 0.09, 0.05, 0.03, 0.08,
  0.0, 0.05, 0.01, 0.06, 0.06, 0.04, 0.06, 0.06, 0.06, 0.02, 0.04, 0.02, 0.01,
  0.01, 0.05, 0.08, 0.01, 0.07, 0.03, 0.0, 0.05, 0.03, 0.04, 0.08, 0.02, 0.08,
  0.01, 0.02, 0.04, 0.04, 0.03, 0.03, 0.08, 0.03, 0.12, 0.08, 0.04, 0.05, 0.05,
  0.03, 0.03, 0.06, 0.0, 0.03, 0.03, 0.05, 0.01, 0.03, 0.02, 0.04, 0.05, 0.05,
  0.02, 0.06, 0.01, 0.0, 0.06, 0.07, 0.01, 0.04, 0.03, 0.03, 0.01, 0.01, 0.02,
  0.02, 0.06, 0.08, 0.12, 0.04, 0.08, 0.05, 0.02, 0.01, 0.01, 0.07, 0.05, 0.02,
  0.03, 0.07, 0.01, 0.01, 0.05, 0.01, 0.07, 0.04, 0.06, 0.0, 0.01, 0.03, 0.0,
  0.01, 0.07, 0.02, 0.08, 0.02, 0.12, 0.0, 0.01, 0.01, 0.07, 0.07, 0.01, 0.05,
  0.02, 0.03, 0.09, 0.01, 0.01, 0.01, 0.02, 0.03, 0.04, 0.02, 0.0, 0.05, 0.0,
  0.02, 0.02, 0.02, 0.05, 0.01, 0.01, 0.06, 0.04, 0.03, 0.07, 0.01, 0.02, 0.02,
  0.04, 0.0, 0.0, 0.09, 0.03, 0.03, 0.01, 0.07, 0.03, 0.05, 0.04, 0.04, 0.07,
  0.04, 0.03, 0.08, 0.05, 0.02, 0.02, 0.03, 0.01, 0.03, 0.05, 0.12, 0.04, 0.07,
  0.06, 0.08, 0.03, 0.02, 0.04, 0.04, 0.0, 0.05, 0.03, 0.03, 0.01, 0.04, 0.02,
  0.01, 0.03, 0.02, 0.01, 0.12, 0.02, 0.03, 0.02, 0.02, 0.01, 0.05, 0.1, 0.01,
  0.03, 0.03, 0.04, 0.01, 0.02, 0.06, 0.02, 0.1, 0.05, 0.01, 0.01, 0.08, 0.01,
  0.05, 0.07, 0.09, 0.0, 0.02, 0.04, 0.03, 0.1, 0.07, 0.04, 0.01, 0.01, 0.01,
  0.06, 0.02, 0.05, 0.04, 0.03, 0.04, 0.0, 0.02, 0.04, 0.02, 0.04, 0.02, 0.03,
  0.05, 0.06, 0.02, 0.01, 0.06, 0.04, 0.04, 0.1, 0.02, 0.06, 0.08, 0.0, 0.06,
  0.06, 0.04, 0.06, 0.02, 0.02, 0.08, 0.04, 0.06, 0.0, 0.08, 0.06, 0.03, 0.01,
  0.03, 0.05, 0.1, 0.05, 0.06, 0.01, 0.03, 0.04, 0.02, 0.04, 0.05, 0.04, 0.03,
  0.05, 0.08, 0.08, 0.12, 0.06, 0.06, 0.06, 0.05, 0.09, 0.02, 0.0, 0.08, 0.02,
  0.02, 0.02, 0.0, 0.08, 0.08, 0.03, 0.08, 0.01, 0.08, 0.04, 0.04, 0.01, 0.09,
  0.02, 0.03, 0.08, 0.07, 0.02, 0.01, 0.03, 0.08, 0.0, 0.0, 0.06, 0.02, 0.04,
  0.03, 0.02, 0.05, 0.01, 0.05, 0.03, 0.02, 0.04, 0.0, 0.03, 0.04, 0.02, 0.01,
  0.02, 0.03, 0.01, 0.02, 0.02, 0.03, 0.01, 0.01, 0.02, 0.0, 0.07, 0.02, 0.04,
  0.07, 0.04, 0.1, 0.05, 0.02, 0.02, 0.01, 0.01, 0.04, 0.04, 0.01, 0.02, 0.04,
  0.08, 0.07, 0.07, 0.05, 0.06, 0.04, 0.05, 0.0, 0.04, 0.02, 0.08, 0.01, 0.04,
  0.0, 0.1, 0.03, 0.05, 0.04, 0.0, 0.03, 0.06, 0.04, 0.02, 0.05, 0.05, 0.02,
  0.04, 0.06, 0.02, 0.01, 0.0, 0.06, 0.03, 0.0, 0.09, 0.0, 0.04, 0.06, 0.0, 0.0,
  0.02, 0.06, 0.03, 0.04, 0.01, 0.0, 0.04, 0.01, 0.03, 0.05, 0.01, 0.09, 0.05,
  0.02, 0.0, 0.01, 0.1, 0.06, 0.02, 0.1, 0.04, 0.01, 0.04, 0.09, 0.0, 0.07,
  0.02, 0.01, 0.01, 0.02, 0.03, 0.04, 0.06, 0.12, 0.02, 0.06, 0.01, 0.03, 0.09,
  0.1, 0.01, 0.04, 0.1, 0.02, 0.04, 0.02, 0.05, 0.01, 0.03, 0.02, 0.1, 0.07,
  0.03, 0.09, 0.0, 0.07, 0.01, 0.08, 0.06, 0.02, 0.01, 0.05, 0.02, 0.02, 0.03,
  0.01, 0.12, 0.02, 0.01, 0.02, 0.04, 0.01, 0.02, 0.02, 0.09, 0.06, 0.02, 0.01,
  0.02, 0.01, 0.04, 0.03, 0.03, 0.06, 0.09, 0.06, 0.02, 0.05, 0.07, 0.02, 0.03,
  0.04, 0.04, 0.04, 0.02, 0.0, 0.02, 0.06, 0.03, 0.07, 0.09, 0.02, 0.01, 0.01,
  0.06, 0.05, 0.08, 0.02, 0.12, 0.02, 0.01, 0.0, 0.05, 0.06, 0.09, 0.04, 0.05,
  0.01, 0.04, 0.02, 0.05, 0.04, 0.02, 0.03, 0.02, 0.0, 0.01, 0.05, 0.06, 0.08,
  0.03, 0.07, 0.1, 0.0, 0.1, 0.12, 0.04, 0.03, 0.01, 0.06, 0.04, 0.03, 0.05,
  0.09, 0.1, 0.04, 0.02, 0.02, 0.05, 0.01, 0.02, 0.04, 0.01, 0.02, 0.08, 0.12,
  0.01, 0.05, 0.02, 0.06, 0.02, 0.01, 0.01, 0.09, 0.06, 0.05, 0.05, 0.06, 0.02,
  0.05, 0.01, 0.0, 0.01, 0.02, 0.05, 0.02, 0.05, 0.02, 0.07, 0.02, 0.05, 0.06,
  0.04, 0.01, 0.05, 0.01, 0.03, 0.01, 0.02, 0.04, 0.08, 0.03, 0.01, 0.0, 0.07,
  0.09, 0.05, 0.01, 0.05, 0.06, 0.01, 0.03, 0.12, 0.09, 0.03, 0.0, 0.09, 0.02,
  0.08, 0.09, 0.04, 0.02, 0.04, 0.05, 0.07, 0.01, 0.04, 0.09, 0.02, 0.12, 0.0,
  0.07, 0.05, 0.06, 0.04, 0.01, 0.02, 0.02, 0.01, 0.09, 0.0, 0.03, 0.08, 0.02,
  0.02, 0.01, 0.01, 0.02, 0.02, 0.05, 0.01, 0.04, 0.05, 0.03, 0.04, 0.03, 0.02,
  0.01, 0.04, 0.05, 0.03, 0.08, 0.07, 0.03, 0.0, 0.02, 0.05, 0.06, 0.06, 0.07,
  0.02, 0.05, 0.01, 0.04, 0.01, 0.02, 0.01, 0.04, 0.02, 0.06, 0.03, 0.03, 0.07,
  0.12, 0.03, 0.03, 0.04, 0.09, 0.08, 0.08, 0.02, 0.03, 0.09, 0.03, 0.06, 0.01,
  0.03, 0.01, 0.02, 0.03, 0.04, 0.05, 0.04, 0.03, 0.06, 0.1, 0.08, 0.01, 0.04,
  0.04, 0.01, 0.03, 0.01, 0.04, 0.01, 0.06, 0.01, 0.05, 0.04, 0.0, 0.07, 0.0,
  0.03, 0.03, 0.07, 0.02, 0.05, 0.09, 0.02, 0.02, 0.01, 0.02, 0.03, 0.06, 0.02,
  0.07, 0.02, 0.07, 0.04, 0.02, 0.01, 0.08, 0.01, 0.05, 0.09, 0.01, 0.05, 0.05,
  0.03, 0.03, 0.05, 0.05, 0.04, 0.06, 0.03, 0.01, 0.06, 0.07, 0.02, 0.03, 0.02,
  0.06, 0.01, 0.01, 0.03, 0.04, 0.01, 0.01, 0.03, 0.03, 0.03, 0.06, 0.01, 0.07,
  0.01, 0.07, 0.05, 0.05, 0.07, 0.1, 0.04, 0.06, 0.05, 0.06, 0.01, 0.02, 0.07,
  0.02, 0.04, 0.09, 0.0, 0.03, 0.06, 0.05, 0.01, 0.05, 0.06, 0.01, 0.08, 0.05,
  0.0, 0.01, 0.0, 0.01, 0.06, 0.05, 0.03, 0.02, 0.04, 0.06, 0.04, 0.04, 0.09,
  0.01, 0.01, 0.0, 0.04, 0.12, 0.08, 0.05, 0.09, 0.02, 0.03, 0.03, 0.05, 0.01,
  0.04, 0.0, 0.0, 0.05, 0.04, 0.06, 0.01, 0.02, 0.0, 0.01, 0.0, 0.02, 0.06,
  0.05, 0.0, 0.04, 0.07, 0.02, 0.01, 0.02, 0.04, 0.02, 0.05, 0.04, 0.07, 0.03,
  0.01, 0.04, 0.01, 0.02, 0.1, 0.02, 0.03, 0.03, 0.01, 0.08, 0.03, 0.05, 0.02,
  0.02, 0.05, 0.09, 0.06, 0.0, 0.05, 0.02, 0.02, 0.01, 0.03, 0.02, 0.02, 0.04,
  0.03, 0.06, 0.07, 0.05, 0.04, 0.05, 0.07, 0.04, 0.0, 0.09, 0.04, 0.04, 0.01,
  0.02, 0.02, 0.12, 0.07, 0.08, 0.06, 0.05, 0.01, 0.01, 0.04, 0.0, 0.1, 0.04,
  0.02, 0.06, 0.09, 0.01, 0.01, 0.03, 0.03, 0.07, 0.04, 0.0, 0.06, 0.03, 0.06,
  0.04, 0.06, 0.01, 0.02, 0.1, 0.01, 0.05, 0.03, 0.03, 0.02, 0.03, 0.04, 0.05,
  0.04, 0.06, 0.08, 0.04, 0.07, 0.01, 0.05, 0.07, 0.03, 0.06, 0.02, 0.04, 0.04,
  0.06, 0.05, 0.01, 0.04, 0.06, 0.02, 0.08, 0.06, 0.09, 0.01, 0.05, 0.03, 0.01,
  0.12, 0.06, 0.03, 0.05, 0.03, 0.02, 0.06, 0.02, 0.06, 0.03, 0.05, 0.03, 0.05,
  0.03, 0.08, 0.12, 0.02, 0.06, 0.08, 0.01, 0.02, 0.03, 0.04, 0.06, 0.03, 0.03,
  0.04, 0.12, 0.12, 0.08, 0.0, 0.05, 0.01, 0.06, 0.08, 0.03, 0.07, 0.03, 0.02,
  0.02, 0.01, 0.09, 0.0, 0.03, 0.04, 0.07, 0.04, 0.0, 0.01, 0.04, 0.01, 0.01,
  0.05, 0.02, 0.02, 0.01, 0.04, 0.01, 0.03, 0.01, 0.02, 0.07, 0.06, 0.09, 0.07,
  0.09, 0.04, 0.09, 0.09, 0.01, 0.06, 0.03, 0.01, 0.04, 0.03, 0.05, 0.02, 0.04,
  0.07, 0.03, 0.03, 0.04, 0.03, 0.0, 0.03, 0.09, 0.01, 0.05, 0.08, 0.03, 0.02,
  0.04, 0.01, 0.04, 0.01, 0.09, 0.01, 0.06, 0.1, 0.03, 0.05, 0.05, 0.03, 0.06,
  0.04, 0.01, 0.03, 0.1, 0.07, 0.0, 0.08, 0.01, 0.02, 0.03, 0.04, 0.02, 0.01,
  0.05, 0.02, 0.01, 0.0, 0.05, 0.04, 0.0, 0.06, 0.12, 0.02, 0.04, 0.03, 0.09,
  0.08, 0.01, 0.01, 0.04, 0.04, 0.08, 0.02, 0.03, 0.02, 0.02, 0.1, 0.06, 0.02,
  0.01, 0.01, 0.03, 0.05, 0.04, 0.01, 0.01, 0.04, 0.08, 0.01, 0.03, 0.05, 0.06,
  0.07, 0.02, 0.01, 0.02, 0.08, 0.01, 0.0, 0.07, 0.04, 0.04, 0.01, 0.04, 0.05,
  0.02, 0.07, 0.03, 0.06, 0.06, 0.07, 0.02, 0.05, 0.0, 0.1, 0.01, 0.06, 0.01,
  0.02, 0.01, 0.05, 0.02, 0.03, 0.08, 0.01, 0.07, 0.02, 0.02, 0.03, 0.0, 0.02,
  0.02, 0.02, 0.02, 0.02, 0.07, 0.04, 0.04, 0.04, 0.02, 0.03, 0.0, 0.09, 0.07,
  0.02, 0.03, 0.01, 0.05, 0.01, 0.01, 0.07, 0.09, 0.04, 0.0, 0.02, 0.09, 0.06,
  0.04, 0.04, 0.04, 0.0, 0.05, 0.02, 0.04, 0.05, 0.01, 0.06, 0.12, 0.03, 0.02,
  0.07, 0.05, 0.04, 0.04, 0.04, 0.06, 0.09, 0.01, 0.02, 0.01, 0.02, 0.06, 0.01,
  0.12, 0.12, 0.01, 0.06, 0.04, 0.05, 0.06, 0.01, 0.03, 0.09, 0.03, 0.04, 0.01,
  0.06, 0.06, 0.06, 0.06, 0.02, 0.02, 0.04, 0.04, 0.06, 0.02, 0.07, 0.0, 0.04,
  0.01, 0.08, 0.06, 0.04, 0.01, 0.07, 0.01, 0.07, 0.02, 0.09, 0.06, 0.07, 0.02,
  0.03, 0.04, 0.02, 0.02, 0.04, 0.02, 0.02, 0.05, 0.07, 0.03, 0.04, 0.04, 0.04,
  0.02, 0.04, 0.03, 0.05, 0.02, 0.01, 0.04, 0.08, 0.09, 0.02, 0.03, 0.12, 0.05,
  0.02, 0.07, 0.02, 0.05, 0.01, 0.05, 0.01, 0.0, 0.05, 0.01, 0.06, 0.02, 0.07,
  0.01, 0.04, 0.04, 0.06, 0.04, 0.0, 0.04, 0.02, 0.01, 0.04, 0.02, 0.07, 0.01,
  0.03, 0.04, 0.05, 0.05, 0.0, 0.09, 0.01, 0.03, 0.1, 0.02, 0.06, 0.04, 0.01,
  0.05, 0.03, 0.04, 0.03, 0.09, 0.0, 0.01, 0.01, 0.01, 0.0, 0.03, 0.02, 0.01,
  0.01, 0.09, 0.06, 0.02, 0.07, 0.02, 0.01, 0.1, 0.07, 0.03, 0.03, 0.03, 0.05,
  0.01, 0.08, 0.06, 0.02, 0.03, 0.03, 0.08, 0.01, 0.04, 0.03, 0.02, 0.01, 0.02,
  0.06, 0.02, 0.01, 0.03, 0.08, 0.04, 0.0, 0.02, 0.01, 0.01, 0.05, 0.05, 0.02,
  0.04, 0.0, 0.01, 0.02, 0.02, 0.03, 0.01, 0.12, 0.01, 0.02, 0.01, 0.02, 0.02,
  0.04, 0.0, 0.02, 0.05, 0.05, 0.06, 0.09, 0.06, 0.0, 0.01, 0.05, 0.04, 0.05,
  0.05, 0.0, 0.02, 0.04, 0.04, 0.04, 0.03, 0.0, 0.04, 0.03, 0.01, 0.03, 0.06,
  0.01, 0.07, 0.06, 0.0, 0.01, 0.02, 0.08, 0.07, 0.04, 0.0, 0.02, 0.09, 0.04,
  0.02, 0.01, 0.03, 0.09, 0.02, 0.07, 0.04, 0.05, 0.04, 0.03, 0.04, 0.07, 0.0,
  0.03, 0.02, 0.02, 0.07, 0.09, 0.12, 0.0, 0.07, 0.04, 0.0, 0.09, 0.03, 0.05,
  0.03, 0.1, 0.04, 0.07, 0.01, 0.03, 0.01, 0.03, 0.02, 0.1, 0.03, 0.03, 0.02,
  0.03, 0.07, 0.05, 0.05, 0.05, 0.01, 0.03, 0.05, 0.08, 0.09, 0.01, 0.1, 0.01,
  0.05, 0.01, 0.02, 0.09, 0.04, 0.09, 0.02, 0.02, 0.02, 0.03, 0.0, 0.01, 0.01,
  0.04, 0.02, 0.01, 0.04, 0.07, 0.08, 0.02, 0.04, 0.03, 0.02, 0.0, 0.01, 0.08,
  0.05, 0.07, 0.08, 0.08, 0.08, 0.1, 0.01, 0.09, 0.1, 0.03, 0.0, 0.02, 0.03,
  0.03, 0.02, 0.12, 0.03, 0.09, 0.01, 0.02, 0.01, 0.1, 0.08, 0.05, 0.03, 0.03,
  0.01, 0.02, 0.08, 0.04, 0.01, 0.02, 0.12, 0.05, 0.04, 0.01, 0.05, 0.12, 0.02,
  0.07, 0.02, 0.01, 0.09, 0.0, 0.04, 0.01, 0.0, 0.01, 0.02, 0.06, 0.01, 0.0,
  0.09, 0.12, 0.01, 0.0, 0.04, 0.05, 0.05, 0.01, 0.03, 0.01, 0.02, 0.09, 0.03,
  0.04, 0.04, 0.1, 0.03, 0.01, 0.04, 0.02, 0.09, 0.02, 0.01, 0.02, 0.04, 0.05,
  0.04, 0.06, 0.07, 0.01, 0.07, 0.03, 0.06, 0.06, 0.02, 0.08, 0.02, 0.03, 0.05,
  0.05, 0.02, 0.06, 0.04, 0.05, 0.05, 0.06, 0.01, 0.03, 0.02, 0.01, 0.01, 0.08,
  0.02, 0.04, 0.04, 0.08, 0.01, 0.06, 0.01, 0.08, 0.05, 0.05, 0.01, 0.02, 0.04,
  0.03, 0.06, 0.0, 0.02, 0.03, 0.01, 0.04, 0.01, 0.02, 0.03, 0.05, 0.08, 0.01,
  0.0, 0.03, 0.02, 0.03, 0.05, 0.02, 0.01, 0.03, 0.04, 0.03, 0.03, 0.02, 0.03,
  0.01, 0.03, 0.01, 0.06, 0.06, 0.06, 0.03, 0.01, 0.08, 0.01, 0.09, 0.01, 0.0,
  0.03, 0.1, 0.0, 0.07, 0.0, 0.03, 0.05, 0.02, 0.01, 0.09, 0.07, 0.02, 0.03,
  0.12, 0.02, 0.05, 0.04, 0.03, 0.07, 0.02, 0.04, 0.03, 0.02, 0.0, 0.02, 0.03,
  0.06, 0.01, 0.02, 0.01, 0.03, 0.07, 0.02, 0.01, 0.06, 0.08, 0.02, 0.03, 0.02,
  0.02, 0.09, 0.03, 0.09, 0.02, 0.02, 0.04, 0.01, 0.02, 0.06, 0.0, 0.04, 0.01,
  0.08, 0.05, 0.04, 0.01, 0.1, 0.0, 0.02, 0.08, 0.0, 0.05, 0.01, 0.07, 0.02,
  0.05, 0.04, 0.03, 0.05, 0.02, 0.0, 0.01, 0.01, 0.04, 0.05, 0.02, 0.03, 0.02,
  0.0, 0.08, 0.05, 0.05, 0.07, 0.03, 0.03, 0.01, 0.01, 0.02, 0.06, 0.07, 0.03,
  0.02, 0.02, 0.03, 0.02, 0.05, 0.02, 0.06, 0.02, 0.01, 0.02, 0.02, 0.08, 0.04,
  0.12, 0.03, 0.02, 0.02, 0.02, 0.01, 0.03, 0.06, 0.04, 0.04, 0.02, 0.08, 0.05,
  0.03, 0.05, 0.0, 0.05, 0.01, 0.07, 0.04, 0.03, 0.02, 0.03, 0.03, 0.03, 0.02,
  0.04, 0.03, 0.05, 0.05, 0.02, 0.09, 0.06, 0.01, 0.05, 0.02, 0.01, 0.0, 0.01,
  0.01, 0.03, 0.01, 0.03, 0.01, 0.01, 0.05, 0.01, 0.07, 0.07, 0.0, 0.03, 0.01,
  0.0, 0.02, 0.05, 0.0, 0.09, 0.01, 0.01, 0.03, 0.01, 0.02, 0.01, 0.01, 0.0,
  0.03, 0.06, 0.01, 0.08, 0.02, 0.05, 0.03, 0.05, 0.03, 0.04, 0.04, 0.0, 0.01,
  0.03, 0.01, 0.07, 0.01, 0.02, 0.02, 0.09, 0.01, 0.06, 0.01, 0.04, 0.04, 0.05,
  0.02, 0.09, 0.04, 0.01, 0.02, 0.0, 0.09, 0.0, 0.02, 0.02, 0.06, 0.04, 0.08,
  0.06, 0.04, 0.01, 0.03, 0.04, 0.06, 0.05, 0.05, 0.0, 0.06, 0.07, 0.02, 0.02,
  0.02, 0.01, 0.05, 0.06, 0.07, 0.05, 0.0, 0.09, 0.01, 0.03, 0.0, 0.04, 0.05,
  0.02, 0.04, 0.07, 0.03, 0.01, 0.02, 0.1, 0.02, 0.02, 0.05, 0.03, 0.06, 0.05,
  0.0, 0.02, 0.07, 0.01, 0.05, 0.02, 0.01, 0.01, 0.04, 0.06, 0.06, 0.02, 0.06,
  0.06, 0.04, 0.05, 0.03, 0.01, 0.02, 0.03, 0.05, 0.08, 0.01, 0.02, 0.0, 0.05,
  0.04, 0.07, 0.06, 0.0, 0.05, 0.08, 0.0, 0.02, 0.06, 0.05, 0.02, 0.04, 0.04,
  0.04, 0.02, 0.06, 0.1, 0.07, 0.02, 0.03, 0.01, 0.01, 0.0, 0.05, 0.02, 0.09,
  0.04, 0.04, 0.03, 0.0, 0.01, 0.05, 0.05, 0.08, 0.0, 0.06, 0.01, 0.04, 0.03,
  0.03, 0.04, 0.02, 0.01, 0.04, 0.02, 0.05, 0.01, 0.07, 0.03, 0.09, 0.02, 0.04,
  0.06, 0.02, 0.04, 0.05, 0.02, 0.03, 0.02, 0.12, 0.01, 0.02, 0.01, 0.09, 0.0,
  0.06, 0.01, 0.01, 0.07, 0.05, 0.05, 0.1, 0.01, 0.07, 0.01, 0.03, 0.0, 0.03,
  0.05, 0.01, 0.04, 0.1, 0.0, 0.07, 0.08, 0.06, 0.08, 0.01, 0.04, 0.01, 0.02,
  0.09, 0.02, 0.02, 0.05, 0.1, 0.02, 0.05, 0.02, 0.04, 0.06, 0.02, 0.06, 0.12,
  0.02, 0.05, 0.01, 0.01, 0.05, 0.02, 0.04, 0.04, 0.07, 0.01, 0.04, 0.08, 0.02,
  0.02, 0.05, 0.06, 0.03, 0.02, 0.01, 0.04, 0.02, 0.0, 0.02, 0.01, 0.04, 0.07,
  0.08, 0.06, 0.03, 0.01, 0.02, 0.07, 0.09, 0.04, 0.09, 0.08, 0.05, 0.02, 0.04,
  0.04, 0.07, 0.0, 0.05, 0.04, 0.07, 0.02, 0.01, 0.05, 0.03, 0.07, 0.05, 0.06,
  0.01, 0.04, 0.09, 0.02, 0.02, 0.02, 0.0, 0.08, 0.08, 0.02, 0.12, 0.04, 0.0,
  0.08, 0.08, 0.05, 0.07, 0.0, 0.1, 0.04, 0.02, 0.03, 0.04, 0.01, 0.0, 0.01,
  0.05, 0.04, 0.01, 0.02, 0.06, 0.01, 0.01, 0.02, 0.04, 0.03, 0.01, 0.02, 0.0,
  0.08, 0.04, 0.04, 0.01, 0.01, 0.0, 0.04, 0.02, 0.06, 0.01, 0.04, 0.02, 0.03,
  0.03, 0.01, 0.12, 0.01, 0.02, 0.03, 0.01, 0.05, 0.02, 0.07, 0.06, 0.04, 0.06,
  0.05, 0.01, 0.01, 0.05, 0.07, 0.01, 0.08, 0.08, 0.04, 0.02, 0.06, 0.02, 0.07,
  0.05, 0.01, 0.0, 0.03, 0.0, 0.04, 0.01, 0.05, 0.08, 0.03, 0.07, 0.06, 0.05,
  0.07, 0.01, 0.01, 0.01, 0.02, 0.04, 0.05, 0.03, 0.01, 0.04, 0.02, 0.03, 0.03,
  0.07, 0.03, 0.04, 0.12, 0.02, 0.05, 0.0, 0.02, 0.01, 0.01, 0.07, 0.05, 0.05,
  0.01, 0.09, 0.01, 0.03, 0.01, 0.06, 0.02, 0.01, 0.03, 0.03, 0.07, 0.08, 0.03,
  0.03, 0.01, 0.1, 0.03, 0.06, 0.04, 0.01, 0.06, 0.1, 0.04, 0.07, 0.0, 0.01,
  0.02, 0.03, 0.03, 0.03, 0.01, 0.08, 0.12, 0.05, 0.1, 0.05, 0.08, 0.02, 0.0,
  0.02, 0.0, 0.04, 0.03, 0.03, 0.08, 0.06, 0.09, 0.04, 0.02, 0.01, 0.02, 0.04,
  0.04, 0.04, 0.02, 0.01, 0.12, 0.12, 0.05, 0.03, 0.03, 0.03, 0.03, 0.02, 0.04,
  0.01, 0.06, 0.0, 0.06, 0.04, 0.01, 0.12, 0.01, 0.03, 0.1, 0.02, 0.02, 0.01,
  0.03, 0.07, 0.06, 0.05, 0.01, 0.03, 0.0, 0.06, 0.03, 0.04, 0.05, 0.06, 0.08,
  0.02, 0.04, 0.04, 0.03, 0.07, 0.0, 0.03, 0.04, 0.01, 0.02, 0.03, 0.09, 0.02,
  0.02, 0.05, 0.05, 0.07, 0.01, 0.05, 0.05, 0.01, 0.04, 0.1, 0.03, 0.04, 0.02,
  0.01, 0.06, 0.07, 0.03, 0.01, 0.06, 0.07, 0.02, 0.02, 0.05, 0.02, 0.0, 0.02,
  0.01, 0.03, 0.01, 0.1, 0.05, 0.05, 0.03, 0.03, 0.08, 0.07, 0.01, 0.04, 0.03,
  0.02, 0.06, 0.02, 0.0, 0.02, 0.04, 0.08, 0.08, 0.01, 0.0, 0.08, 0.08, 0.03,
  0.04, 0.05, 0.03, 0.06, 0.04, 0.01, 0.08, 0.03, 0.06, 0.04, 0.02, 0.03, 0.09,
  0.01, 0.02, 0.0, 0.0, 0.02, 0.06, 0.04, 0.08, 0.01, 0.08, 0.06, 0.04, 0.0,
  0.01, 0.0, 0.01, 0.03, 0.03, 0.01, 0.05, 0.05, 0.03, 0.05, 0.06, 0.0, 0.03,
  0.04, 0.02, 0.02, 0.12, 0.06, 0.01, 0.01, 0.03, 0.06, 0.09, 0.08, 0.01, 0.05,
  0.02, 0.02, 0.01, 0.0, 0.05, 0.02, 0.06, 0.01, 0.07, 0.0, 0.03, 0.09, 0.07,
  0.03, 0.02, 0.08, 0.09, 0.03, 0.01, 0.01, 0.07, 0.02, 0.03, 0.02, 0.08, 0.09,
  0.03, 0.0, 0.02, 0.1, 0.02, 0.01, 0.07, 0.12, 0.02, 0.01, 0.0, 0.06, 0.04,
  0.01, 0.04, 0.02, 0.09, 0.06, 0.03, 0.04, 0.06, 0.02, 0.04, 0.06, 0.01, 0.06,
  0.04, 0.0, 0.09, 0.01, 0.04, 0.04, 0.0, 0.03, 0.04, 0.07, 0.04, 0.02, 0.0,
  0.06, 0.1, 0.01, 0.01, 0.03, 0.04, 0.08, 0.02, 0.05, 0.06, 0.01, 0.02, 0.03,
  0.02, 0.07, 0.04, 0.01, 0.07, 0.02, 0.03, 0.01, 0.06, 0.05, 0.02, 0.05, 0.09,
  0.0, 0.04, 0.07, 0.02, 0.01, 0.1, 0.0, 0.08, 0.05, 0.06, 0.05, 0.02, 0.05,
  0.05, 0.0, 0.03, 0.01, 0.03, 0.01, 0.07, 0.06, 0.05, 0.02, 0.03, 0.02, 0.03,
  0.01, 0.02, 0.01, 0.07, 0.04, 0.06, 0.07, 0.04, 0.04, 0.06, 0.1, 0.08, 0.05,
  0.07, 0.03, 0.02, 0.09, 0.04, 0.05, 0.09, 0.01, 0.04, 0.09, 0.05, 0.06, 0.04,
  0.03, 0.09, 0.02, 0.04, 0.0, 0.02, 0.0, 0.06, 0.01, 0.06, 0.05, 0.0, 0.04,
  0.02, 0.02, 0.0, 0.02, 0.02, 0.0, 0.04, 0.02, 0.02, 0.04, 0.08, 0.07, 0.03,
  0.02, 0.03, 0.01, 0.08, 0.03, 0.02, 0.06, 0.04, 0.04, 0.04, 0.05, 0.05, 0.0,
  0.05, 0.01, 0.12, 0.08, 0.03, 0.07, 0.02, 0.06, 0.12, 0.08, 0.06, 0.01, 0.06,
  0.03, 0.04, 0.01, 0.03, 0.01, 0.03, 0.02, 0.02, 0.03, 0.01, 0.03, 0.02, 0.01,
  0.09, 0.01, 0.02, 0.0, 0.07, 0.05, 0.07, 0.05, 0.06, 0.05, 0.03, 0.03, 0.02,
  0.0, 0.03, 0.07, 0.1, 0.01, 0.05, 0.04, 0.07, 0.03, 0.01, 0.02, 0.06, 0.03,
  0.03, 0.04, 0.02, 0.05, 0.02, 0.01, 0.03, 0.05, 0.04, 0.01, 0.1, 0.06, 0.02,
  0.02, 0.04, 0.03, 0.02, 0.05, 0.02, 0.0, 0.0, 0.02, 0.01, 0.04, 0.03, 0.01,
  0.01, 0.07, 0.04, 0.06, 0.01, 0.06, 0.02, 0.03, 0.09, 0.05, 0.06, 0.08, 0.0,
  0.01, 0.03, 0.02, 0.04, 0.01, 0.02, 0.03, 0.01, 0.04, 0.04, 0.01, 0.02, 0.06,
  0.02, 0.12, 0.05, 0.05, 0.02, 0.0, 0.12, 0.0, 0.04, 0.08, 0.03, 0.01, 0.02,
  0.06, 0.06, 0.0, 0.02, 0.03, 0.02, 0.07, 0.04, 0.05, 0.03, 0.01, 0.1, 0.06,
  0.09, 0.05, 0.03, 0.04, 0.04, 0.02, 0.04, 0.05, 0.06, 0.05, 0.12, 0.01, 0.12,
  0.06, 0.0, 0.04, 0.02, 0.04, 0.06, 0.02, 0.09, 0.07, 0.02, 0.01, 0.02, 0.04,
  0.09, 0.03, 0.04, 0.03, 0.02, 0.0, 0.09, 0.06, 0.02, 0.02, 0.09, 0.06, 0.02,
  0.01, 0.02, 0.01, 0.02, 0.04, 0.01, 0.01, 0.05, 0.02, 0.05, 0.03, 0.09, 0.08,
  0.04, 0.01, 0.03, 0.0, 0.03, 0.03, 0.04, 0.09, 0.09, 0.01, 0.04, 0.03, 0.04,
  0.05, 0.06, 0.02, 0.02, 0.07, 0.08, 0.04, 0.03, 0.07, 0.01, 0.0, 0.0, 0.06,
  0.04, 0.09, 0.03, 0.07, 0.0, 0.04, 0.0, 0.05, 0.0, 0.04, 0.02, 0.0, 0.05,
  0.01, 0.03, 0.06, 0.0, 0.09, 0.01, 0.02, 0.09, 0.05, 0.08, 0.01, 0.05, 0.12,
  0.06, 0.0, 0.01, 0.08, 0.09, 0.1, 0.05, 0.02, 0.01, 0.08, 0.05, 0.01, 0.02,
  0.1, 0.01, 0.05, 0.01, 0.05, 0.04, 0.1, 0.03, 0.09, 0.05, 0.09, 0.01, 0.03,
  0.03, 0.03, 0.08, 0.04, 0.02, 0.08, 0.03, 0.01, 0.05, 0.04, 0.0, 0.05, 0.02,
  0.04, 0.02, 0.04, 0.03, 0.06, 0.05, 0.02, 0.12, 0.05, 0.03, 0.0, 0.05, 0.01,
  0.06, 0.02, 0.04, 0.01, 0.09, 0.02, 0.02, 0.02, 0.01, 0.02, 0.02, 0.0, 0.03,
  0.01, 0.04, 0.08, 0.0, 0.03, 0.01, 0.02, 0.04, 0.1, 0.01, 0.05, 0.12, 0.05,
  0.06, 0.02, 0.0, 0.02, 0.02, 0.03, 0.1, 0.1, 0.08, 0.06, 0.02, 0.01, 0.08,
  0.04, 0.01, 0.07, 0.02, 0.04, 0.01, 0.0, 0.06, 0.09, 0.02, 0.08, 0.04, 0.05,
  0.06, 0.01, 0.07, 0.01, 0.07, 0.05, 0.03, 0.0, 0.03, 0.01, 0.07, 0.03, 0.01,
  0.01, 0.05, 0.01, 0.03, 0.03, 0.09, 0.07, 0.07, 0.02, 0.03, 0.02, 0.06, 0.04,
  0.1, 0.08, 0.03, 0.1, 0.01, 0.09, 0.03, 0.06, 0.04, 0.02, 0.08, 0.08, 0.01,
  0.05, 0.06, 0.01, 0.02, 0.03, 0.06, 0.05, 0.03, 0.02, 0.02, 0.1, 0.01, 0.04,
  0.1, 0.03, 0.02, 0.02, 0.03, 0.03, 0.02, 0.05, 0.02, 0.05, 0.02, 0.06, 0.02,
  0.06, 0.05, 0.09, 0.01, 0.03, 0.04, 0.04, 0.02, 0.04, 0.02, 0.03, 0.03, 0.08,
  0.05, 0.01, 0.02, 0.04, 0.01, 0.01, 0.05, 0.06, 0.1, 0.09, 0.04, 0.02, 0.09,
  0.02, 0.03, 0.07, 0.02, 0.03, 0.07, 0.01, 0.08, 0.01, 0.03, 0.08, 0.01, 0.05,
  0.09, 0.0, 0.02, 0.02, 0.02, 0.04, 0.12, 0.03, 0.07, 0.05, 0.09, 0.01, 0.05,
  0.01, 0.07, 0.02, 0.02, 0.06, 0.01, 0.07, 0.04, 0.06, 0.09, 0.12, 0.01, 0.05,
  0.01, 0.07, 0.12, 0.02, 0.0, 0.05, 0.03, 0.02, 0.02, 0.01, 0.01, 0.02, 0.03,
  0.02, 0.01, 0.07, 0.05, 0.03, 0.03, 0.08, 0.05, 0.06, 0.02, 0.02, 0.01, 0.0,
  0.0, 0.04, 0.04, 0.07, 0.01, 0.07, 0.04, 0.04, 0.0, 0.02, 0.09, 0.0, 0.03,
  0.0, 0.01, 0.07, 0.09, 0.0, 0.1, 0.01, 0.05, 0.07, 0.01, 0.03, 0.08, 0.02,
  0.06, 0.04, 0.02, 0.08, 0.1, 0.05, 0.05, 0.03, 0.01, 0.03, 0.01, 0.02, 0.01,
  0.07, 0.0, 0.06, 0.04, 0.01, 0.01, 0.02, 0.03, 0.06, 0.05, 0.05, 0.12, 0.05,
  0.07, 0.0, 0.01, 0.02, 0.05, 0.03, 0.09, 0.04, 0.1, 0.02, 0.0, 0.04, 0.01,
  0.01, 0.12, 0.02, 0.12, 0.05, 0.03, 0.06, 0.01, 0.02, 0.04, 0.01, 0.05, 0.03,
  0.02, 0.01, 0.08, 0.0, 0.02, 0.0, 0.04, 0.07, 0.01, 0.06, 0.01, 0.04, 0.04,
  0.04, 0.04, 0.06, 0.04, 0.01, 0.01, 0.01, 0.04, 0.05, 0.01, 0.01, 0.02, 0.01,
  0.04, 0.04, 0.04, 0.01, 0.0, 0.06, 0.04, 0.06, 0.05, 0.01, 0.03, 0.09, 0.04,
  0.02, 0.04, 0.03, 0.03, 0.04, 0.05, 0.08, 0.03, 0.04, 0.01, 0.0, 0.09, 0.04,
  0.06, 0.03, 0.05, 0.01, 0.03, 0.06, 0.01, 0.05, 0.07, 0.02, 0.03, 0.08, 0.05,
  0.0, 0.04, 0.04, 0.09, 0.01, 0.03, 0.03, 0.06, 0.04, 0.12, 0.05, 0.0, 0.12,
  0.01, 0.03, 0.05, 0.06, 0.1, 0.01, 0.01, 0.02, 0.1, 0.0, 0.0, 0.02, 0.05, 0.0,
  0.12, 0.09, 0.1, 0.06, 0.01, 0.06, 0.01, 0.02, 0.04, 0.03, 0.06, 0.07, 0.05,
  0.01, 0.04, 0.1, 0.01, 0.01, 0.03, 0.05, 0.05, 0.01, 0.02, 0.03, 0.05, 0.04,
  0.02, 0.04, 0.05, 0.07, 0.05, 0.03, 0.03, 0.05, 0.1, 0.01, 0.08, 0.01, 0.06,
  0.04, 0.04, 0.02, 0.05, 0.06, 0.02, 0.05, 0.05, 0.02, 0.04, 0.02, 0.01, 0.03,
  0.01, 0.01, 0.0, 0.06, 0.06, 0.05, 0.05, 0.03, 0.03, 0.12, 0.02, 0.01, 0.07,
  0.08, 0.02, 0.07, 0.07, 0.04, 0.0, 0.03, 0.0, 0.01, 0.01, 0.02, 0.02, 0.01,
  0.07, 0.04, 0.03, 0.06, 0.01, 0.04, 0.01, 0.03, 0.01, 0.07, 0.09, 0.04, 0.02,
  0.07, 0.06, 0.09, 0.09, 0.01, 0.05, 0.02, 0.02, 0.04, 0.03, 0.06, 0.05, 0.04,
  0.08, 0.01, 0.06, 0.01, 0.01, 0.06, 0.08, 0.03, 0.09, 0.01, 0.07, 0.05, 0.04,
  0.02, 0.06, 0.01, 0.03, 0.04, 0.08, 0.01, 0.04, 0.05, 0.01, 0.08, 0.04, 0.01,
  0.05, 0.08, 0.09, 0.01, 0.05, 0.02, 0.04, 0.06, 0.05, 0.08, 0.02, 0.04, 0.02,
  0.03, 0.03, 0.05, 0.0, 0.02, 0.0, 0.03, 0.04, 0.09, 0.12, 0.03, 0.01, 0.01,
  0.12, 0.04, 0.1, 0.05, 0.04, 0.05, 0.01, 0.02, 0.01, 0.05, 0.06, 0.03, 0.06,
  0.02, 0.05, 0.02, 0.07, 0.03, 0.04, 0.03, 0.02, 0.01, 0.01, 0.01, 0.05, 0.09,
  0.06, 0.01, 0.05, 0.04, 0.0, 0.0, 0.01, 0.04, 0.04, 0.07, 0.05, 0.01, 0.04,
  0.02, 0.01, 0.01, 0.03, 0.1, 0.04, 0.07, 0.04, 0.05, 0.06, 0.08, 0.07, 0.04,
  0.03, 0.05, 0.03, 0.08, 0.03, 0.05, 0.03, 0.06, 0.08, 0.04, 0.05, 0.02, 0.02,
  0.03, 0.05, 0.01, 0.01, 0.08, 0.04, 0.05, 0.02, 0.03, 0.06, 0.07, 0.07, 0.08,
  0.05, 0.12, 0.03, 0.01, 0.02, 0.01, 0.06, 0.05, 0.01, 0.03, 0.01, 0.02, 0.03,
  0.03, 0.01, 0.01, 0.04, 0.06, 0.01, 0.06, 0.01, 0.04
];

export default noiseAbs;
