import React from 'react';
import AlarmViewV2 from 'ecto-common/lib/Alarms/V2/AlarmViewV2';
import { useOperatorSelector } from 'js/reducers/storeOperator';

const AlarmsPageV2 = () => {
  const nodeId = useOperatorSelector((state) => state.general.nodeId);
  return <AlarmViewV2 nodeId={nodeId} />;
};

export default AlarmsPageV2;
