import React from 'react';
import styles from './EctoplannerBox.module.css';
import classNames from 'classnames';
import EctoplannerResultTitle from 'js/components/Ectoplanner/EctoplannerResultTitle';

interface EctoplannerBoxProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  withMargin?: boolean;
  style?: object;
}

/**
 * This box is used to mimic the appearance of the more complex Panel component
 * in the dashboards.
 *
 * TODO: Both this component and the dashboard component override the default padding of the PlainBox. Perhaps their value should be the default?
 */
const EctoplannerBox = ({
  title,
  children,
  withMargin = false,
  style
}: EctoplannerBoxProps) => {
  return (
    <div className={styles.boxWrapper}>
      <div
        className={classNames(styles.box, withMargin && styles.withMargin)}
        style={{ ...style }}
      >
        {title && <EctoplannerResultTitle>{title}</EctoplannerResultTitle>}
        {children}
      </div>
    </div>
  );
};

export default React.memo(EctoplannerBox);
