import React, { memo, useMemo } from 'react';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';

import T from 'ecto-common/lib/lang/Language';
import Heading from 'ecto-common/lib/Heading/Heading';
import StockChart from 'ecto-common/lib/Charts/StockChart';

import createConfig from 'js/components/EMPTools/PowerControl/Dispatch/Components/TemperatureImpactConfig';
import { SignalValueResponseModel3 } from 'ecto-common/lib/API/APIGen';

// Add milliseconds to now time so we have a chance to get the request to the server before it becomes invalid start date

const formatDate = (date: Moment) => moment(date).utc().format();

interface TemperatureImpactPreviewProps {
  schedulePreview?: SignalValueResponseModel3[];
  isLoading: boolean;
  hasError?: boolean;
  startDate: Moment;
  endDate: Moment;
}

const TemperatureImpactPreview = ({
  schedulePreview,
  isLoading,
  hasError,
  startDate,
  endDate
}: TemperatureImpactPreviewProps) => {
  // need date as strings due shallow dependency compare in useEffect below
  const _startDate = formatDate(startDate);
  const _endDate = formatDate(endDate);

  const config = useMemo<Highcharts.Options>(() => {
    const realStartDate = moment(_startDate);
    const realEndDate = moment(_endDate);
    return createConfig(
      realStartDate,
      realEndDate,
      schedulePreview,
      !isLoading
    );
  }, [schedulePreview, _startDate, _endDate, isLoading]);

  return (
    <>
      <Heading level={5}>
        {T.powercontrol.dispatch.schedule.header.impact}
      </Heading>

      <StockChart isLoading={isLoading} config={config} hasError={hasError} />
    </>
  );
};

// need to do deep compare due to date props
export default memo(TemperatureImpactPreview, _.isEqual);
