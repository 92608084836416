import useDetailedGraph, {
  extractPanelGraphData
} from 'js/components/DashboardPage/useDetailedGraph';
import React, { useCallback, useMemo } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';

/**
 * Creates a set of default menu options for panels
 * @returns {{menuOptions: [], menuComponent: JSX}}
 */
const useDashboardMenu = ({ onAddToPersonal = null }) => {
  const [
    showChartData,
    isChartDataAvailable,
    isChartDataReady,
    ChartPanelDataComponent
  ] = useDetailedGraph();

  const showChartPanelData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (panel: DashboardPanel, data: Record<string, any>) => {
      const [graphSignals, panelTitle, dateFrom, dateTo] =
        extractPanelGraphData(panel, data);
      showChartData(graphSignals, panelTitle, dateFrom, dateTo, null);
    },
    [showChartData]
  );

  const menuOptions = useMemo(
    () => [
      {
        icon: <Icons.Favorite />,
        label: T.dashboard.addpaneltopersonalbutton,
        action: onAddToPersonal,
        isVisible: () => onAddToPersonal != null,
        isEnabled: () => true
      },
      {
        icon: <Icons.Graph />,
        label: T.dashboard.showingraphs,
        action: showChartPanelData,
        isVisible: isChartDataAvailable,
        isEnabled: isChartDataReady
      }
    ],
    [
      showChartPanelData,
      isChartDataAvailable,
      isChartDataReady,
      onAddToPersonal
    ]
  );

  // Render all menu detail components
  const menuComponent = useMemo(() => {
    return <>{ChartPanelDataComponent}</>;
  }, [ChartPanelDataComponent]);

  return { menuOptions, menuComponent };
};

export default useDashboardMenu;
