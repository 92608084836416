/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type ListResponseOfJobMetadata = ListResponse & {
  items?: JobMetadata[];
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
};

export interface JobMetadata {
  jobId?: string;
  fileName?: string;
  jobName?: string;
  jobTypeKey?: string;
  jobSubTypeKey?: string;
  /** @format date-time */
  jobCreatedDateTime?: string;
  /** @format date-time */
  jobValidToDateTime?: string | null;
  jobVersion?: string;
  tenantId?: string;
  nodeId?: string;
}

export type ListResponse = object;

export interface JobDocument {
  /**
   * Job Identifer: Example NodeId+JobType
   * @minLength 1
   */
  jobId: string;
  /**
   * Name used in header to display the job. Should not include node name or node id
   * @minLength 1
   */
  jobName: string;
  /**
   * Description of the job, displayed in the UI together with the job data
   * @minLength 1
   */
  jobDescription: string;
  /**
   * Job version number. Should be increased with configuration changes, re training etc.
   * @minLength 1
   * @default "1"
   */
  jobVersion: string;
  /**
   * Date when job created, example when ML training is completed.
   * @format date-time
   * @minLength 1
   */
  jobCreatedDateTime: string;
  /** Referer to the data soruce as a URI */
  sourceUri?: string | null;
  /**
   * Key  for the job type, see examples for valid values
   * @minLength 1
   * @pattern ^(mlmodel|nonmlmodel|integration)$
   */
  jobTypeKey: string;
  /**
   * Used to desire what type of extra data is displayed in the UI and translation of JobSubTypeName
   * @minLength 1
   * @pattern ^(integration|lag|ebm|nonmlmodel|ols)$
   */
  jobSubTypeKey: string;
  /**
   * English translation of the description of the JobSubType in human readable format
   * @minLength 1
   */
  jobSubTypeName: string;
  /**
   * Tenant Identifier
   * @minLength 1
   */
  tenantId: string;
  /**
   * Node identifier
   * @minLength 1
   */
  nodeId: string;
  /**
   * Data Point Ids the job is writing the result to. Ectocloud currently only supports timeseries data job.
   * @minItems 1
   */
  outputDataPointIds: string[];
  /** Data Points that we are trying to predict/forecast/estimate */
  targetDataPointIds?: string[];
  /** Data point identifiers that could be interesting to see during comparison. */
  relatedDataPointIds?: string[];
  /**
   * For how long time the job definition/training is valid, example 7 months if we expect a retraining each 6th month.
   * @format date-time
   */
  jobValidToDateTime?: string | null;
  /** Information about the training of a ML model */
  trainingOutput?: TrainingOutput | null;
  /** Configuration for LAG model */
  lagOutput?: LagOutput | null;
}

export interface TrainingOutput {
  /** @format float */
  r2?: number | null;
  /** @format float */
  rmse?: number | null;
  /**
   * DataTime for first data sample used in training
   * @format date-time
   * @minLength 1
   */
  trainingDataStartDate: string;
  /**
   * DataTime for last data sample used in training
   * @format date-time
   * @minLength 1
   */
  trainingDataEndDate: string;
  /**
   * Number of data samples used in training
   * @format int32
   * @min 0
   */
  trainingDataNumberOfObservations: number;
  mostImportantFeatures?: MostImportantFeature[] | null;
}

export interface MostImportantFeature {
  /**
   * Uniqe Key for the feature, can be dataPointId or other ML internal key
   * @minLength 1
   */
  featureKey: string;
  /**
   * English name for the feature, should not include any GUID or not readable for a human
   * @minLength 1
   */
  displayName: string;
  /** @format float */
  weight: number;
  /** If the feature exists in ectocloud as a dataPoint, the reference to the datapoint id sholud be included here */
  dataPointId?: string | null;
  /** Optional reference to a uploaded document/image for this feature */
  filename?: string | null;
}

export interface LagOutput {
  /**
   * Reference to the laged datapoint
   * @minLength 1
   */
  lagedDataPointId: string;
  /**
   * How long time back in time the data point is laged in the weigh (example: 86400 seconds, 1) if all data is based on the last day at same time
   * @minItems 1
   */
  weights: LagWeight[];
}

export interface LagWeight {
  /**
   * Lag time in seconds
   * @format int32
   */
  lag: number;
  /**
   * Weight
   * @format float
   */
  weight: number;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export type ListResponseOfRunMetadata = ListResponse & {
  items?: RunMetadata[];
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
};

export interface RunMetadata {
  jobId?: string;
  fileName?: string;
  jobSubTypeKey?: string;
  /** @format date-time */
  runValidToDateTime?: string | null;
  jobVersion?: string;
  errorMessage?: string | null;
  /** @format date-time */
  firstValueDateTime?: string | null;
  /** @format date-time */
  lastValueDateTime?: string | null;
  /** @format date-time */
  runDateTime?: string;
  success?: boolean;
}

export interface RunDocument {
  /** @minLength 1 */
  tenantId: string;
  /** @minLength 1 */
  nodeId: string;
  /** @minLength 1 */
  jobId: string;
  /** @minLength 1 */
  jobVersion: string;
  /**
   * @minLength 1
   * @pattern ^(integration|lag|ebm|nonmlmodel|ols)$
   */
  jobSubTypeKey: string;
  /** Indication if the run has failed or is a success. If failed, an error message is expected, otherwise data is expected. */
  success: boolean;
  /** Error message is expected if the run has failed. */
  errorMessage?: string | null;
  /**
   * Date time when the run was performed
   * @format date-time
   * @minLength 1
   */
  runDateTime: string;
  /**
   * Date and time for the first data sample of generated/fetched timeseries data
   * @format date-time
   * @minLength 1
   */
  firstValueDateTime: string;
  /**
   * Date and time for the last data sample of generated/fetched timeseries data
   * @format date-time
   * @minLength 1
   */
  lastValueDateTime: string;
  /**
   * Date and time for the data no longer is valid. Example, if we expect a new forecast every 6 hour, the valid time can be 7 hours.
   * @format date-time
   * @minLength 1
   */
  runValidToDateTime: string;
  /** Reference to the trigger for the job, the job can be triggerd by a timer, other job or alarm for example. */
  triggerUri?: string | null;
  timeseriesData?: RunTimeseries[] | null;
  /** Optional debug information from the service. Not intended to be displayed in the UI. */
  debugInformation?: Record<string, string>;
}

export interface RunTimeseries {
  /** @minLength 1 */
  dataPointId: string;
  /**
   * List of data points and the values
   * @minItems 1
   */
  values: DataValueObject[];
}

export interface DataValueObject {
  /**
   * @format date-time
   * @minLength 1
   */
  dateTime: string;
  /** @format decimal */
  floatValue?: number | null;
}

export interface JobsListCurrentJobsParams {
  /** The continuation token. */
  continuationToken?: string | null;
  /**
   * Optional size of the page. If no page size is supplied then all jobs will be returned.
   *             If the supplied value is to large then the default Azure page size will be returned.
   * @format int32
   * @min 1
   * @max 1000
   * @default 50
   */
  pageSize?: number;
  /** The node identifier. */
  nodeId: string;
}

export interface JobsListHistoricalJobsParams {
  /** The continuation token. */
  continuationToken?: string | null;
  /**
   * Optional size of the page. If no page size is supplied then all jobs will be returned.
   *             If the supplied value is to large then the default Azure page size will be returned.
   * @format int32
   * @min 1
   * @max 1000
   * @default 50
   */
  pageSize?: number;
  /** The job identifier. */
  jobId: string;
  /** The node identifier. */
  nodeId: string;
}

export interface JobsListRunsParams {
  /**
   * Optional size of the page. If no page size is supplied then all runs will be returned.
   *             If the supplied value is to large then the default Azure page size will be returned.
   * @format int32
   * @default 50
   */
  pageSize?: number;
  /** The continuation token. */
  continuationToken?: string | null;
  /** The job identifier. */
  jobId: string;
  /** The node identifier. */
  nodeId: string;
}

/**
 * @title Job Management API
 * @version v2
 * @baseUrl https://app-ec-jobmanagement-test-weu-001-hfkk.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpointEmptyWithPath,
  apiEndpointEmptyWithPathUsingQueryParams
} from './APIUtils';

const apiTitle = APIGenType.JobsAPIGenV2;

export interface JobsListCurrentJobsPath {
  /** The node identifier. */
  nodeId: string;
}

export interface JobsGetCurrentJobPath {
  nodeId: string;
  jobId: string;
}

export interface JobsListHistoricalJobsPath {
  /** The job identifier. */
  jobId: string;
  /** The node identifier. */
  nodeId: string;
}

export interface JobsDownloadHistoricalJobPath {
  /** The job identifier. */
  jobId: string;
  /** Name of the file. */
  fileName: string;
  /** The node identifier. */
  nodeId: string;
}

export interface JobsListRunsPath {
  /** The job identifier. */
  jobId: string;
  /** The node identifier. */
  nodeId: string;
}

export interface JobsDownloadRunPath {
  /** The job identifier. */
  jobId: string;
  /** Name of the file. */
  fileName: string;
  /** The node identifier. */
  nodeId: string;
}

const JobsAPIGenV2 = {
  Jobs: {
    /**
 * @description It is the files found in the blob storage folder {tenant-id}/{node-id}/jobs/current/ that will be returned. * @summary Gets a list of current jobs. The data can be returned in a paged manner
using the continuationToken and pageSize.
*/
    listCurrentJobs: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<JobsListCurrentJobsParams, keyof JobsListCurrentJobsPath>,
      ListResponseOfJobMetadata,
      any,
      JobsListCurrentJobsPath
    >(
      Method.GET,
      ({ nodeId }: JobsListCurrentJobsPath) =>
        `/api/v2/jobs/node/${nodeId}/current`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getCurrentJob: apiEndpointEmptyWithPath<
      JobDocument,
      ProblemDetails,
      JobsGetCurrentJobPath
    >(
      Method.GET,
      ({ nodeId, jobId }: JobsGetCurrentJobPath) =>
        `/api/v2/jobs/node/${nodeId}/current/${jobId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Gets a list of historical jobs. The data can be returned in a paged manner
using the continuationToken and pageSize.
*/
    listHistoricalJobs: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<JobsListHistoricalJobsParams, keyof JobsListHistoricalJobsPath>,
      ListResponseOfJobMetadata,
      any,
      JobsListHistoricalJobsPath
    >(
      Method.GET,
      ({ jobId, nodeId }: JobsListHistoricalJobsPath) =>
        `/api/v2/jobs/node/${nodeId}/${jobId}/historical`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns a historical job
     */
    downloadHistoricalJob: apiEndpointEmptyWithPath<
      JobDocument,
      ProblemDetails,
      JobsDownloadHistoricalJobPath
    >(
      Method.GET,
      ({ jobId, fileName, nodeId }: JobsDownloadHistoricalJobPath) =>
        `/api/v2/jobs/node/${nodeId}/${jobId}/historical/${fileName}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @description It is the files found in the blob storage folder {tenant-id}/{node-id}/jobs/{job-id}/runs/ that will be returned. * @summary Gets a list of runs for a specific job. The data can be returned in a paged manner
using the continuationToken and pageSize.
*/
    listRuns: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<JobsListRunsParams, keyof JobsListRunsPath>,
      ListResponseOfRunMetadata,
      any,
      JobsListRunsPath
    >(
      Method.GET,
      ({ jobId, nodeId }: JobsListRunsPath) =>
        `/api/v2/jobs/node/${nodeId}/${jobId}/runs`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns the Run for a job and node
     */
    downloadRun: apiEndpointEmptyWithPath<
      RunDocument,
      ProblemDetails,
      JobsDownloadRunPath
    >(
      Method.GET,
      ({ jobId, fileName, nodeId }: JobsDownloadRunPath) =>
        `/api/v2/jobs/node/${nodeId}/${jobId}/runs/${fileName}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default JobsAPIGenV2;
