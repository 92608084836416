import React, { useCallback, useContext, useMemo, useState } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { getNewSignalCollectionName } from 'js/modules/signalCollections/signalCollections';
import { SignalCollectionActions } from 'js/modules/signalCollections/signalCollections';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import UUID from 'uuidjs';
import GraphModal from './GraphModal';
import UnsavedChangesDialog from 'ecto-common/lib/UnsavedChangesDialog/UnsavedChangesDialog';
import T from 'ecto-common/lib/lang/Language';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import { panelsWithCharts } from 'ecto-common/lib/Dashboard/panels';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { DashboardPanel, PanelTarget } from 'ecto-common/lib/Dashboard/Panel';
import { SignalValuesDataSourceResult } from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import { useCommonDispatch } from 'ecto-common/lib/reducers/storeCommon';
import { SeriesInterval } from 'ecto-common/lib/types/EctoCommonTypes';
import { TelemetryZoomRange } from 'js/modules/signalCollections/signalCollections';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

/**
 * Convert data source signals into a format that we use in the Graph page
 * @param signals
 */
const convertSignalsToGraphSignals = (
  signals: SignalValuesDataSourceResult
): ChartSignal[] => {
  return _.map(signals.signalValues, (signal, index) => {
    return {
      chartSignalId: UUID.generate(),
      item: {
        ...signals.signalInfo.signals[signal.signalId]
      },
      group: _.omit(
        {
          ...signals.signalInfo.signalProviders[
            signals.signalInfo.signalIdToProviderId[signal.signalId]
          ]
        },
        'signals'
      ),
      color: signal.signalInput.color,
      index
    };
  });
};

type PanelTargetAndSignalSourceData = {
  target: PanelTarget;
  data: SignalValuesDataSourceResult;
};

const signalVariables = (
  panel: DashboardPanel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>
): Record<string, PanelTargetAndSignalSourceData> => {
  // Get all variables that has a signal source from the current panel
  return _.reduce(
    panel.targets,
    (result, target, key) => {
      if (_.isObject(target) && target.sourceType === DataSourceTypes.SIGNALS) {
        result[key] = { target, data: data[key] };
      }
      return result;
    },
    {} as Record<string, PanelTargetAndSignalSourceData>
  );
};

const isChartDataAvailable = (panel: DashboardPanel) => {
  return _.includes(panelsWithCharts, panel.type);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isChartDataReady = (panel: DashboardPanel, data: Record<string, any>) => {
  const firstVariable = _.head(_.map(signalVariables(panel, data)));
  return !firstVariable?.data?.isLoading;
};

export const extractPanelGraphData = (
  panel: DashboardPanel,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>
): [
  chartSignals: ChartSignal[],
  title: string,
  dateFrom: number | Moment,
  dateTo: number | Moment
] => {
  // Get all variables that has a signal source from the current panel
  // Use the first signal variable as the source for the graph
  // TODO: Future usage might want to show multiple graph; here is the place to handle it
  const firstVariable = _.head(_.map(signalVariables(panel, data)));
  return [
    convertSignalsToGraphSignals(firstVariable?.data),
    panel.title,
    firstVariable.data.dateFrom,
    firstVariable.data.dateTo
  ];
};

/**
 * Creates a function that takes graph signals, collection title and dateFrom/dateTo as argument and shows a detail graph page
 * @returns {[((function(*, *): void)|*), (function), unknown]} [showChartPanelData, component] where
 * showChartPanelData is the callback function and component needs to be rendered in the tree so it can be used as
 * dialog.
 */

type ShowChartDataFunction = (
  graphSignals: ChartSignal[],
  collectionTitle: string,
  dateFrom: number | Moment,
  dateTo: number | Moment,
  seriesInterval: SeriesInterval
) => void;

type UseDetailedGraphResult = [
  ShowChartDataFunction,
  (panel: DashboardPanel) => boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (panel: DashboardPanel, data: Record<string, any>) => boolean,
  React.ReactNode
];

const useDetailedGraph = ({
  startAsSaved = true
} = {}): UseDetailedGraphResult => {
  const dispatch = useCommonDispatch();
  const signalCollectionActions = useMemo(
    () => bindActionCreators(SignalCollectionActions, dispatch),
    [dispatch]
  );
  const tempCollections = useOperatorSelector(
    (state) => state.signalCollections.tempCollections
  );
  const signalCollections = useOperatorSelector(
    (state) => state.signalCollections.signalCollections
  );
  const changedSignalCollections = useOperatorSelector(
    (state) => state.signalCollections.changedSignalCollections
  );
  const { contextSettings } = useContext(TenantContext);
  const [isDialogVisible, showGraphDialog, _hideGraphDialog] =
    useDialogState(false);
  const [collectionId, setCollectionId] = useState(UUID.generate());
  const [
    isUnsavedDialogVisible,
    showUnsavedChangesDialog,
    hideUnsavedChangesDialog
  ] = useDialogState(false);

  const hasUnsavedChanges = useMemo(
    () => changedSignalCollections[collectionId],
    [changedSignalCollections, collectionId]
  );

  const resetState = useCallback(() => {
    _hideGraphDialog();
    hideUnsavedChangesDialog();
    signalCollectionActions.setTemporaryCollection(collectionId, false);

    setCollectionId(null);
  }, [
    _hideGraphDialog,
    hideUnsavedChangesDialog,
    collectionId,
    signalCollectionActions
  ]);

  const closeAndDelete = useCallback(() => {
    // If this is false, it means that the user has saved the temporary graph explicitly.
    // If not saved, and if no changes have been applied, remove it from storage.
    if (tempCollections[collectionId] === true) {
      signalCollectionActions.confirmDeleteSignalCollection(
        contextSettings,
        collectionId
      );
    }

    resetState();
  }, [
    tempCollections,
    collectionId,
    resetState,
    signalCollectionActions,
    contextSettings
  ]);

  const hideGraphDialog = useCallback(() => {
    if (hasUnsavedChanges) {
      showUnsavedChangesDialog();
    } else {
      closeAndDelete();
    }
  }, [closeAndDelete, hasUnsavedChanges, showUnsavedChangesDialog]);

  const saveChanges = useCallback(() => {
    signalCollectionActions.saveSignalCollection(contextSettings, collectionId);
    resetState();
  }, [resetState, collectionId, signalCollectionActions, contextSettings]);

  const [customZoomRange, setCustomZoomRange] =
    useState<TelemetryZoomRange | null>(null);
  const [customSeriesInterval, setCustomSeriesInterval] =
    useState<SeriesInterval | null>(null);

  const component = useMemo(() => {
    return (
      <>
        <UnsavedChangesDialog
          message={T.graphs.panel.unsavedchanges}
          isOpen={isUnsavedDialogVisible}
          onCancel={hideUnsavedChangesDialog}
          onDiscardChanges={closeAndDelete}
          onSave={saveChanges}
        />
        <GraphModal
          key={collectionId}
          isOpen={isDialogVisible}
          initialSeriesInterval={customSeriesInterval}
          initialZoomRange={customZoomRange}
          onModalClose={hideGraphDialog}
        />
      </>
    );
  }, [
    isUnsavedDialogVisible,
    hideUnsavedChangesDialog,
    closeAndDelete,
    saveChanges,
    isDialogVisible,
    customSeriesInterval,
    customZoomRange,
    hideGraphDialog,
    collectionId
  ]);

  const showChartData = useCallback(
    (
      graphSignals: ChartSignal[],
      collectionTitle: string,
      dateFrom: number | Moment,
      dateTo: number | Moment,
      seriesInterval: SeriesInterval
    ) => {
      const id = UUID.generate();
      setCollectionId(id);
      signalCollectionActions.createSignalCollection(
        getNewSignalCollectionName(signalCollections, null, collectionTitle),
        false,
        id
      );

      if (dateFrom !== -1 && dateTo !== -1) {
        setCustomZoomRange({
          dateFrom: moment(dateFrom).valueOf(),
          dateTo: moment(dateTo).valueOf(),
          seriesInterval
        });
      } else {
        setCustomZoomRange(null);
      }

      setCustomSeriesInterval(seriesInterval);

      signalCollectionActions.updateCollection(graphSignals);
      signalCollectionActions.setTemporaryCollection(id, true);
      // At this  point the collection is marked as 'unsaved'

      if (startAsSaved) {
        // Set the current selection as 'saved' so we can use the 'has unsaved graph' logic
        // only when we first modify the collection
        signalCollectionActions.resetCurrentCollection();
      }
      showGraphDialog();
    },
    [showGraphDialog, signalCollectionActions, signalCollections, startAsSaved]
  );

  return useMemo(
    () => [showChartData, isChartDataAvailable, isChartDataReady, component],
    [showChartData, component]
  );
};

export default useDetailedGraph;
