function getDemAttrList() {
  let attrList = [
    //// STATE: ENERGY DEMANDS

    // Base project data
    { name: 'projectNameDem', val: '', m: ['init', 'resetDem', 'sMain'] },
    {
      name: 'projectDescriptionDem',
      val: '',
      m: ['init', 'resetDem', 'sMain']
    },
    {
      name: 'networkOption',
      val: 'separate',
      m: ['init', 'resetDem', 'sMain']
    }, // 'separate' or 'lowEx'; lowEx means here: 5GDHC
    { name: 'considerHolidays', val: true, m: ['init', 'resetDem', 'sMain'] },
    {
      name: 'considerTimeChange',
      val: false,
      m: ['init', 'resetDem', 'sMain']
    },
    { name: 'firstDayOfYear', val: 'monday', m: ['init', 'resetDem', 'sMain'] },
    { name: 'country', val: 'germany', m: ['init', 'resetDem', 'sMain'] },
    { name: 'city', val: 'berlin', m: ['init', 'resetDem', 'sMain'] },
    { name: 'countryData', val: {}, m: ['init', 'resetDem', 'sMain'] },
    { name: 'cityData', val: {}, m: ['init', 'resetDem', 'sMain'] },

    { name: 'weatherAirTemp', val: [], m: ['init', 'resetDem', 'sMain'] },

    // Thermal network modal
    { name: 'showNetworkSeparateModal', val: false, m: ['init', 'resetDem'] },
    { name: 'showNetworkLowExModal', val: false, m: ['init', 'resetDem'] },
    { name: 'meanNetwTemp', val: 16, m: ['init', 'resetDem', 'sMain'] },
    { name: 'netwTempProfile', val: [], m: ['init', 'resetDem', 'sMain'] },
    {
      name: 'netwTempProfileUploaded',
      val: false,
      m: ['init', 'resetDem', 'sMain']
    },
    { name: 'heatLossesAbs', val: 0, m: ['init', 'resetDem', 'sMain'] },
    { name: 'heatLossesRel', val: 10, m: ['init', 'resetDem', 'sMain'] },
    { name: 'heatLossesAbsReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'heatLossesRelReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'heatPumpWork', val: 1, m: ['init', 'resetDem', 'sMain'] },
    { name: 'diversityFactorHeat', val: 1, m: ['init', 'resetDem', 'sMain'] },
    { name: 'coldLossesAbs', val: 0, m: ['init', 'resetDem', 'sMain'] },
    { name: 'coldLossesRel', val: 10, m: ['init', 'resetDem', 'sMain'] },
    { name: 'coldLossesAbsReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'coldLossesRelReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'coldPumpWork', val: 1, m: ['init', 'resetDem', 'sMain'] },
    { name: 'diversityFactorCold', val: 1, m: ['init', 'resetDem', 'sMain'] },
    { name: 'lowExPumpWork', val: 1, m: ['init', 'resetDem', 'sMain'] },
    {
      name: 'netwInsulation',
      val: 'insulated',
      m: ['init', 'resetDem', 'sMain']
    }, // 'insulated' or 'uninsulated'
    {
      name: 'groundTempOption',
      val: 'variable',
      m: ['init', 'resetDem', 'sMain']
    }, // 'variable' or 'const'
    { name: 'tempGroundConst', val: 8, m: ['init', 'resetDem', 'sMain'] }, // °C
    { name: 'installationDepth', val: 1, m: ['init', 'resetDem', 'sMain'] }, // in m
    {
      name: 'thConductivityGround',
      val: 1.5,
      m: ['init', 'resetDem', 'sMain']
    }, // in W/(m K)
    {
      name: 'heatLossPipeDiameter',
      val: 250,
      m: ['init', 'resetDem', 'sMain']
    }, // in mm
    { name: 'networkLength', val: 1, m: ['init', 'resetDem', 'sMain'] }, // in km
    { name: 'diversityFactorLowEx', val: 1, m: ['init', 'resetDem', 'sMain'] },

    // Pipe sizing modal
    { name: 'deltaTPipeSizing', val: 10, m: ['init', 'resetDem', 'sMain'] },
    {
      name: 'pipeRoughnessPipeSizing',
      val: 0.1,
      m: ['init', 'resetDem', 'sMain']
    },
    {
      name: 'sizingSeparateNetwType',
      val: 'heat',
      m: ['init', 'resetDem', 'sMain']
    }, // If separate networks: Sizing for heating or cooling network?

    // Building
    { name: 'floorArea', val: 10000, m: ['init', 'resetDem', 'sBldg'] },
    { name: 'buildingName', val: '', m: ['init', 'resetDem', 'sBldg'] },
    {
      name: 'buildingType',
      val: 'residential',
      m: ['init', 'resetDem', 'sBldg']
    },
    { name: 'buildingSubtype', val: '', m: ['init', 'resetDem', 'sBldg'] },
    { name: 'checkCalcSpaceHeat', val: true, m: ['init', 'resetDem', 'sBldg'] },
    { name: 'checkCalcDhw', val: true, m: ['init', 'resetDem', 'sBldg'] },
    { name: 'checkCalcSpaceCool', val: true, m: ['init', 'resetDem', 'sBldg'] },
    {
      name: 'checkCalcProcessCool',
      val: false,
      m: ['init', 'resetDem', 'sBldg']
    },
    { name: 'checkCalcPlugLoads', val: true, m: ['init', 'resetDem', 'sBldg'] },
    { name: 'checkCalcEmob', val: false, m: ['init', 'resetDem', 'sBldg'] },

    // Space heating
    { name: 'annDemSpecHeat', val: 100, m: ['init', 'resetDem'] },
    { name: 'annDemTotHeat', val: 1000, m: ['init', 'resetDem'] },
    { name: 'checkPeakKnownHeat', val: false, m: ['init', 'resetDem'] },
    { name: 'peakDemSpecHeat', val: 50, m: ['init', 'resetDem'] },
    { name: 'peakDemTotHeat', val: 500, m: ['init', 'resetDem'] },
    { name: 'fullLoadHrsHeat', val: 2000, m: ['init', 'resetDem'] },
    { name: 'checkHeatingPeriod', val: false, m: ['init', 'resetDem'] },
    { name: 'heatingPeriodBegin', val: '10', m: ['init', 'resetDem'] },
    { name: 'heatingPeriodEnd', val: '4', m: ['init', 'resetDem'] },
    { name: 'annDemSpecHeatReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'annDemTotHeatReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'peakDemSpecHeatReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'peakDemTotHeatReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'fullLoadHrsHeatReadOnly', val: false, m: ['init', 'resetDem'] },

    // Domestic hot water
    { name: 'annDemSpecDhw', val: 25, m: ['init', 'resetDem'] },
    { name: 'annDemTotDhw', val: 250, m: ['init', 'resetDem'] },
    { name: 'shareDhw', val: 20, m: ['init', 'resetDem'] },
    { name: 'annDemSpecDhwReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'annDemTotDhwReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'shareDhwReadOnly', val: false, m: ['init', 'resetDem'] },

    // Space cooling
    { name: 'annDemSpecCool', val: 100, m: ['init', 'resetDem'] },
    { name: 'annDemTotCool', val: 1000, m: ['init', 'resetDem'] },
    { name: 'checkPeakKnownCool', val: false, m: ['init', 'resetDem'] },
    { name: 'peakDemSpecCool', val: 50, m: ['init', 'resetDem'] },
    { name: 'peakDemTotCool', val: 500, m: ['init', 'resetDem'] },
    { name: 'fullLoadHrsCool', val: 2000, m: ['init', 'resetDem'] },
    { name: 'checkCoolingPeriod', val: false, m: ['init', 'resetDem'] },
    { name: 'coolingPeriodBegin', val: '4', m: ['init', 'resetDem'] },
    { name: 'coolingPeriodEnd', val: '10', m: ['init', 'resetDem'] },
    { name: 'annDemSpecCoolReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'annDemTotCoolReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'peakDemSpecCoolReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'peakDemTotCoolReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'fullLoadHrsCoolReadOnly', val: false, m: ['init', 'resetDem'] },

    // Process cooling
    { name: 'annDemSpecProcessCool', val: 25, m: ['init', 'resetDem'] },
    { name: 'annDemTotProcessCool', val: 250, m: ['init', 'resetDem'] },
    {
      name: 'annDemSpecProcessCoolReadOnly',
      val: false,
      m: ['init', 'resetDem']
    },
    {
      name: 'annDemTotProcessCoolReadOnly',
      val: false,
      m: ['init', 'resetDem']
    },

    // Plug loads
    { name: 'annDemSpecPlugLoads', val: 25, m: ['init', 'resetDem'] },
    { name: 'annDemTotPlugLoads', val: 250, m: ['init', 'resetDem'] },
    {
      name: 'annDemSpecPlugLoadsReadOnly',
      val: false,
      m: ['init', 'resetDem']
    },
    { name: 'annDemTotPlugLoadsReadOnly', val: false, m: ['init', 'resetDem'] },

    // E-mobility
    { name: 'distanceEmob', val: 200, m: ['init', 'resetDem'] },
    { name: 'consumptionEmob', val: 15, m: ['init', 'resetDem'] },
    { name: 'annDemTotEmob', val: 110, m: ['init', 'resetDem'] },
    { name: 'annDemTotEmobReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'distanceEmobReadOnly', val: false, m: ['init', 'resetDem'] },
    { name: 'consumptionEmobReadOnly', val: false, m: ['init', 'resetDem'] },

    // Building energy system parameters
    {
      name: 'besLowExCopSpaceHeat',
      val: 'const',
      m: ['init', 'resetDem', 'sBldg']
    }, // 'const' or 'carnot' or 'productData'
    {
      name: 'besLowExHpSpaceHeatCop',
      val: 4,
      m: ['init', 'resetDem', 'sBldg']
    },
    {
      name: 'heatPumpTypeCopSpaceHeat',
      val: 'nibe_f1155_12',
      m: ['init', 'resetDem', 'sBldg']
    },
    {
      name: 'besLowExHpSpaceHeatSupplyTemp',
      val: 35,
      m: ['init', 'resetDem', 'sBldg']
    },
    {
      name: 'besLowExHpSpaceHeatSupplyTempSliding',
      val: true,
      m: ['init', 'resetDem', 'sBldg']
    },
    {
      name: 'besLowExHpSpaceHeatCarnotEta',
      val: 40,
      m: ['init', 'resetDem', 'sBldg']
    }, // %
    {
      name: 'besLowExElHeaterEnabled',
      val: false,
      m: ['init', 'resetDem', 'sBldg']
    },
    {
      name: 'besLowExElHeaterMaxLoad',
      val: 30,
      m: ['init', 'resetDem', 'sBldg']
    }, // %

    { name: 'besLowExCopDhw', val: 'const', m: ['init', 'resetDem', 'sBldg'] }, // 'const' or 'carnot' or 'productData'
    { name: 'besLowExHpDhwCop', val: 2.5, m: ['init', 'resetDem', 'sBldg'] },
    {
      name: 'heatPumpTypeCopDhw',
      val: 'nibe_f1155_12',
      m: ['init', 'resetDem', 'sBldg']
    },
    {
      name: 'besLowExHpDhwSupplyTemp',
      val: 60,
      m: ['init', 'resetDem', 'sBldg']
    },
    {
      name: 'besLowExHpDhwCarnotEta',
      val: 40,
      m: ['init', 'resetDem', 'sBldg']
    }, // %

    {
      name: 'lowExCoolingOption',
      val: 'lowExDrc',
      m: ['init', 'resetDem', 'sBldg']
    }, // 'lowExDrc' or 'lowExCc'
    { name: 'besLowExCopCool', val: 'const', m: ['init', 'resetDem', 'sBldg'] }, // 'const' or 'carnot'
    { name: 'besLowExCcCop', val: 3, m: ['init', 'resetDem', 'sBldg'] },
    {
      name: 'chillerTypeCopCool',
      val: 'nibe_f1155_12',
      m: ['init', 'resetDem', 'sBldg']
    },
    { name: 'besLowExCcSupplyTemp', val: 6, m: ['init', 'resetDem', 'sBldg'] },
    { name: 'besLowExCcCarnotEta', val: 40, m: ['init', 'resetDem', 'sBldg'] }, // %

    {
      name: 'besLowExPeakChillerEnabled',
      val: false,
      m: ['init', 'resetDem', 'sBldg']
    },
    {
      name: 'besLowExPeakChillerMaxLoad',
      val: 30,
      m: ['init', 'resetDem', 'sBldg']
    }, // %
    {
      name: 'besLowExPeakChillerCop',
      val: 5,
      m: ['init', 'resetDem', 'sBldg']
    },

    // Building energy system KPIs
    { name: 'hxHeatCap', val: 0, m: ['init', 'resetDem'] }, // Heat exchanger for heating separated networks
    { name: 'hxHeatGen', val: 0, m: ['init', 'resetDem'] },
    { name: 'hxCoolCap', val: 0, m: ['init', 'resetDem'] }, // Heat exchanger for cooling (separated networks)
    { name: 'hxCoolGen', val: 0, m: ['init', 'resetDem'] },

    { name: 'hpShLowExCap', val: 0, m: ['init', 'resetDem'] }, // Space heating heat pump (low ex)
    { name: 'hpShLowExGen', val: 0, m: ['init', 'resetDem'] },
    { name: 'hpShLowExElDem', val: 0, m: ['init', 'resetDem'] },
    { name: 'hpShLowExScop', val: 0, m: ['init', 'resetDem'] },

    { name: 'elHeaterShLowExCap', val: 0, m: ['init', 'resetDem'] }, // Space heating heat pump (low ex)
    { name: 'elHeaterShLowExGen', val: 0, m: ['init', 'resetDem'] },

    { name: 'hpDhwLowExCap', val: 0, m: ['init', 'resetDem'] }, // DHW heat pump (low ex)
    { name: 'hpDhwLowExGen', val: 0, m: ['init', 'resetDem'] },
    { name: 'hpDhwLowExElDem', val: 0, m: ['init', 'resetDem'] },
    { name: 'hpDhwLowExScop', val: 0, m: ['init', 'resetDem'] },

    { name: 'ccLowExCap', val: 0, m: ['init', 'resetDem'] }, // Chiller (low ex)
    { name: 'ccLowExGen', val: 0, m: ['init', 'resetDem'] },
    { name: 'ccLowExElDem', val: 0, m: ['init', 'resetDem'] },
    { name: 'ccLowExScop', val: 0, m: ['init', 'resetDem'] },

    { name: 'drcLowExCap', val: 0, m: ['init', 'resetDem'] }, // Direct cooling (low ex)
    { name: 'drcLowExGen', val: 0, m: ['init', 'resetDem'] },

    { name: 'peakChillerLowExCap', val: 0, m: ['init', 'resetDem'] }, // Capacity of peak-load chiller
    { name: 'peakChillerLowExGen', val: 0, m: ['init', 'resetDem'] }, // Cooling generation of peak-load chiller
    { name: 'peakChillerLowExElDEm', val: 0, m: ['init', 'resetDem'] }, // Electricity demand of peak-load chiller
    { name: 'peakChillerLowExScop', val: 0, m: ['init', 'resetDem'] }, // Seasonal COP of peak-load chiller

    { name: 'docBldgLowEx', val: 0, m: ['init', 'resetDem'] },
    { name: 'wasteHeatUsedLowEx', val: 0, m: ['init', 'resetDem'] },

    // DISTRICT PROFILES
    // Sum of all import profiles of all buildings
    {
      name: 'allBldgsImportProfile',
      val: {
        heat: [], // Sum of heatImportProfile of all buildings
        cool: [], // Sum of coolImportProfile of all buildings
        elec: [] // Sum of elImportProfile of all buildings
      },
      m: ['init', 'resetDem']
    },

    // Load profiles at energy hub (including losses and diversity)
    {
      name: 'ehLoadProfiles',
      val: {
        heat: [],
        cool: [],
        elec: []
      },
      m: ['init', 'resetDem', 'sMain']
    },

    // Building list with all buildings
    { name: 'buildingList', val: {}, m: ['init', 'resetDem'] },

    // BUILDING PROFILES
    // Heating profile
    { name: 'spaceHeatInputs', val: {}, m: ['init', 'resetDem', 'sBldg'] }, // Input parameters for the demand profile calculation
    { name: 'spaceHeatProfile', val: [], m: ['init', 'resetDem'] },
    { name: 'spaceHeatProfileUploaded', val: false, m: ['init', 'resetDem'] },

    { name: 'dhwInputs', val: {}, m: ['init', 'resetDem', 'sBldg'] },
    { name: 'dhwProfile', val: [], m: ['init', 'resetDem'] },
    { name: 'dhwProfileUploaded', val: false, m: ['init', 'resetDem'] },

    { name: 'heatParamsChanged', val: true, m: ['init', 'resetDem'] },

    // Cooling profile
    { name: 'spaceCoolInputs', val: {}, m: ['init', 'resetDem', 'sBldg'] },
    { name: 'spaceCoolProfile', val: [], m: ['init', 'resetDem'] },
    { name: 'spaceCoolProfileUploaded', val: false, m: ['init', 'resetDem'] },

    { name: 'processCoolInputs', val: {}, m: ['init', 'resetDem', 'sBldg'] },
    { name: 'processCoolProfile', val: [], m: ['init', 'resetDem'] },
    { name: 'processCoolProfileUploaded', val: false, m: ['init', 'resetDem'] },

    { name: 'coolParamsChanged', val: true, m: ['init', 'resetDem'] },

    // Electricity profile
    { name: 'plugLoadsInputs', val: {}, m: ['init', 'resetDem', 'sBldg'] },
    { name: 'plugLoadsProfile', val: [], m: ['init', 'resetDem'] },
    { name: 'plugLoadsProfileUploaded', val: false, m: ['init', 'resetDem'] },

    { name: 'emobInputs', val: {}, m: ['init', 'resetDem', 'sBldg'] },
    { name: 'emobProfile', val: [], m: ['init', 'resetDem'] },
    { name: 'emobProfileUploaded', val: false, m: ['init', 'resetDem'] },

    { name: 'elecParamsChanged', val: true, m: ['init', 'resetDem'] },

    // Energy imports from networks to building
    { name: 'heatImportProfile', val: [], m: ['init', 'resetDem'] },
    { name: 'coolImportProfile', val: [], m: ['init', 'resetDem'] },
    { name: 'elImportProfile', val: [], m: ['init', 'resetDem'] },

    // ADDITIONAL MODALS

    // New project modal
    { name: 'projectNameNewProj', val: '', m: ['init', 'resetDem'] },
    { name: 'countryNewProj', val: 'germany', m: ['init', 'resetDem'] },
    { name: 'cityNewProj', val: 'berlin', m: ['init', 'resetDem'] },
    {
      name: 'airTempNewProjectUserTimeSeries',
      val: [],
      m: ['init', 'resetDem']
    }, // Only used within 'new poject' and 'upload' modal
    { name: 'airTempNewProjectUploaded', val: false, m: ['init', 'resetDem'] } // Only used within 'new poject' and 'upload' modal
  ];

  // Additional items for day profiles modal
  const hourList = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23
  ];
  const dayTypesList = ['workday', 'saturday', 'sunday'];
  dayTypesList.forEach((dayType) => {
    hourList.forEach((h) => {
      attrList.push({
        name: 'dayProfiles_' + dayType + '_' + h,
        val: 0,
        m: ['init']
      });
    });
  });

  return attrList;
}

const exportedFunctions = { getDemAttrList };
export default exportedFunctions;
