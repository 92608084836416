import React, { Fragment } from 'react';

import Heading from 'ecto-common/lib/Heading/Heading';
import T from 'ecto-common/lib/lang/Language';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';

import styles from '../ScheduleModal.module.css';
import { formatHoursUTC } from '../utils/dateUtils';

interface DurationControlProps {
  duration: number;
  onChange(duration: number): void;
}

const DurationControl = ({ onChange, duration }: DurationControlProps) => {
  const durations = [0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const durationOptions: GenericSelectOption<number>[] = durations.map((d) => ({
    value: d,
    label:
      formatHoursUTC(d) + ' ' + T.powercontrol.dispatch.schedule.option.hours
  }));

  const durationOption = durationOptions.find((x) => x.value === duration);

  return (
    <Fragment>
      <Heading level={5}>
        {T.powercontrol.dispatch.schedule.header.duration}
      </Heading>
      <div>
        <div className={styles.flexboxContainer}>
          <label>{T.powercontrol.dispatch.schedule.option.stop}</label>
          <div className={styles.select}>
            <Select<GenericSelectOption<number>, false>
              isSearchable={false}
              name="form-field-name"
              maxMenuHeight={200}
              menuPlacement="auto"
              value={durationOption}
              options={durationOptions}
              onChange={(e) => onChange(e.value)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default React.memo(DurationControl);
