import React from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import Button from 'ecto-common/lib/Button/Button';
import GreyButton from 'ecto-common/lib/Button/GreyButton';

interface DashboardConfirmSaveModalProps {
  onConfirm?(saveAll: boolean): void;
  onModalClose: () => void;
  isOpen?: boolean;
  isLoading?: boolean;
}

const DashboardConfirmSaveModal = ({
  onConfirm,
  onModalClose,
  isOpen,
  isLoading
}: DashboardConfirmSaveModalProps) => {
  return (
    <ActionModal
      compact
      onModalClose={onModalClose}
      isOpen={isOpen}
      headerIcon={Icons.Save}
      title={T.dashboard.confirmsave.title}
      isLoading={isLoading}
      footer={
        <ModalFooter>
          <Button onClick={() => onConfirm(true)} disabled={isLoading}>
            <Icons.Save />
            {T.dashboard.confirmsave.saveall}
          </Button>
          <Button onClick={() => onConfirm(false)} disabled={isLoading}>
            <Icons.Save />
            {T.dashboard.confirmsave.savecurrent}
          </Button>
          <GreyButton onClick={onModalClose} disabled={isLoading}>
            {T.common.cancel}
          </GreyButton>
        </ModalFooter>
      }
      onConfirmClick={() => {}}
    >
      {T.dashboard.confirmsave.message}
    </ActionModal>
  );
};

export default DashboardConfirmSaveModal;
