import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import React from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import Heading from 'ecto-common/lib/Heading/Heading';
import balancingImage from './assets/balancing_graph.png';
import T from 'ecto-common/lib/lang/Language';
import styles from './EctoplannerBalancingGraphInfoDialog.module.css';

type EctoplannerBalancingGraphInfoDialogProps = {
  isOpen: boolean;
  onModalClose: () => void;
};

// Since this is unlikely to be translated and would be hard to split up into separate localization strings, I will keep
// this in English without localization.
const EctoplannerBalancingGraphInfoDialog = ({
  isOpen,
  onModalClose
}: EctoplannerBalancingGraphInfoDialogProps) => {
  return (
    <ActionModal
      isOpen={isOpen}
      large
      title="Balancing of heating and cooling demands in ectogrid™ networks"
      disableCancel
      onConfirmClick={onModalClose}
      onModalClose={onModalClose}
      headerIcon={Icons.InfoCircle}
      actionText={T.common.done}
      messageBodyClassName={styles.body}
    >
      <Heading level={4}>Technical specification ectogrid™</Heading>
      <ul>
        <li>
          One low temperature thermal grid for distribution of heating and
          cooling consisting of one warm and one cold pipe, capable of sharing
          low temperature excess energy.
        </li>
        <li>
          Decentralized pumping makes it possible to have bidirectional sharing
          excess energy between buildings and balance the grid in a flexible way
        </li>
        <li>
          Decentralized heat pumps that cool down buildings by turning down the
          temperature from the cold pipe of the grid and heat up buildings by
          turning up the temperature from the warm pipe
        </li>
        <li>
          The passive balancing tank act as both hydraulic balance in the grid
          and thermal flexibility as demand varies between heating and cooling.
        </li>
        <li>
          Local energy sources and units that adds or withdraws energy from the
          system, i.e. low temp waste heat source, a geothermal energy system,
          energy from air or water etc.
        </li>
      </ul>
      <Heading level={4} withMarginTop>
        How does the balancing of heating and cooling demands work in ectogrid™
        networks?
      </Heading>
      <p>
        The balancing is simplified by not adding more energy into the ectogrid
        than necessary, since it will always prioritize useful excess energy. To
        break it down there are two balancing effects can be distinguished: On
        the one hand, heating and cooling demands that occur in one building at
        the same time can be balanced (
        <strong>Internal balancing in each building</strong>). On the other
        hand, heating and cooling demands that occur in different buildings can
        be balanced by the ectogrid network (
        <strong>Balancing between buildings through the ectogrid</strong>).
      </p>
      <p>
        The basic principle is the same in both cases: waste heat from the cold
        supply can be used as a heat source at the evaporator of the heat pump.
        In this way, the waste heat does not have to be dissipated and less
        external heat needs be provided for the evaporator of the heat pump.
      </p>
      <p>
        In the Ectoplanner the balancing of heating and cooling demands in
        ectogrid™ networks is quantified and illustrated.
      </p>
      <Heading level={4} withMarginTop>
        Internal balancing in each building
      </Heading>
      <p>
        In buildings, heating and cooling demands can be balanced if they occur
        simultaneously. For cooling, either a heat exchanger can be installed or
        a chiller. In both cases, a waste heat flow is generated, which can be
        partially or completely used by the heat pump (at the evaporator).
        <u>
          In this way, the waste heat that is fed into the network and the heat
          that is drawn from the network are reduced
        </u>
        . As a result, at a given time, either only surplus heat is fed into the
        network or only heat is drawn from the network. With a transversal heat
        pump setup both sides of the heat pump, evaporator and condenser will
        directly feed the building heating and cooling demand with a increased
        COP compared to a parallel setup.
      </p>
      <Heading level={4} withMarginTop>
        Balancing between buildings through the ectogrid
      </Heading>
      <p>
        If at a given time at least one building feeds heat into the network and
        at least one other building draws heat from the network, there is a
        balancing between buildings.
        <u>
          In the case of a perfect balance, the heat quantities fed in and drawn
          are equal in total across all buildings
        </u>
        . In this case, no mass flow would pass through the energy center and
        the district would be completely thermally balanced. However, this ideal
        case occurs only at a few points in the year. Usually, only a part of
        the heating or cooling demands in the districts is directly balanced and
        the rest must be covered by the energy center.
      </p>
      <Heading level={4} withMarginTop>
        Example calculation
      </Heading>
      <p>
        The energy center loads are the amount of energy, heating and cooling
        that will supply to the grid. Energy center includes all selected forms
        of active balancing technologies and the passive balancing tank. The
        supply between the passive, active balancing is optimized when starting
        the full optimization on the calculation bottom.
      </p>
      <img id="balancingImageInfo" src={balancingImage} alt="Balancing graph" />
      <p>
        <label htmlFor="balancingImageInfo">
          Figure 1: Balancing on thermal demands
        </label>
      </p>
      <p>
        The first column <strong>Demand of all buildings</strong> shows the
        total heating and cooling demands of all buildings, including space
        heating, domestic hot water and space-/process cooling. It shows the
        original energy demands of buildings, thus demands that brought by
        buildings themselves. The second column
        <strong>Import of all buildings</strong> shows the numerical sum of the
        heating, cooling and electricity imported from the grid. The second
        column does include the effect of internal balancing at each building,
        leading to decrease amount of energy, compared to the first column.
        without taking into account of the balancing between buildings through
        the ectogrid. In other words, the second column show the aggregated sum
        values of the energy meters that is placed at each of those buildings.
        The third column <strong>Energy center loads</strong>, shows reduced
        heating and cooling due to the balancing between buildings. The energy
        quantities in this column are the net demands of the district, which are
        covered by an energy center. The full calculation will optimize the most
        cost-efficient system in regard to CAPEX and OPEX.
      </p>
      <br />
    </ActionModal>
  );
};

export default React.memo(EctoplannerBalancingGraphInfoDialog);
