import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';

import EditEctoplannerTechnologies from 'js/components/Ectoplanner/EditEctoplannerTechnologies';
import EditEctoplannerBuildings from 'js/components/Ectoplanner/EditEctoplannerBuildings';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import styles from './EditEctoplannerProject.module.css';

import {
  AbsorptionChillerBuSections,
  AccuTankBuSections,
  AquiferStorageBuSections,
  BatteryBuSections,
  CHPBuSections,
  ColdStorageBuSections,
  CompressionChillerBuSections,
  DistrictCoolingBuSections,
  DistrictHeatingBuSections,
  EcologicalImpactSections,
  EconomicParametersSections,
  ElectricalHeaterBuSections,
  EnergyCostsSections,
  GasBoilerBuSections,
  getBuildingNproSections,
  GSHeatPumpBuSections,
  HeatStorageBuSections,
  InvestmentSections,
  LocationSections,
  NetworkSections,
  PhotovoltaicSections,
  ReferenceSystemSections,
  RevHeatPumpBuSections,
  SensitivitySections,
  SimpleHeatPumpBuSections,
  SolarThermalSections,
  WasteCoolingSections,
  WasteHeatSections
} from 'js/components/Ectoplanner/EctoplannerModels';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

import {
  EctoplannerBuildStatus,
  EctoplannerBuildStatusProgress,
  EctoplannerFormEnvironment,
  EctoplannerFormError
} from 'js/components/Ectoplanner/EctoplannerTypes';
import { WeatherCountryResponse } from 'ecto-common/lib/API/EctoplannerAPIGen';
import { OptionWithIcon } from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import {
  ModelBoolFunctionProperty,
  ModelFormSectionType,
  ModelStringFunctionProperty
} from 'ecto-common/lib/ModelForm/ModelPropType';
import _ from 'lodash';
import {
  flattenModelFormSections,
  getPathFromModelKeyFunc,
  getPathStringFromModelKeyFunc
} from 'ecto-common/lib/ModelForm/formUtils';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useMutation } from '@tanstack/react-query';
import { compileFormPromise } from 'js/components/Ectoplanner/EctoplannerModelUtils';
import { modelFormSectionsToModelsWithParent } from '../../../../../ecto-common/lib/ModelForm/formUtils';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import Notice from 'ecto-common/lib/Notice/Notice';
import Heading from 'ecto-common/lib/Heading/Heading';
import { EctoplannerProgressBar } from 'js/components/Ectoplanner/EctoplannerResultView';

// Make sure that we do not use any react elements as labels in our sections => make it more strict
type ModelFormSectionPropTypeWithString = Omit<
  ModelFormSectionType<EctoplannerForm, EctoplannerFormEnvironment>,
  'label'
> & { label: string };

type BalancingTechnologiesSelectorProps = {
  form: EctoplannerForm;
  setForm: Dispatch<SetStateAction<EctoplannerForm>>;
  modelSections: ModelFormSectionPropTypeWithString[];
};

type BalancingTechnologiesSelectorOption = GenericSelectOption<string> & {
  keyPath: string;
};

// Instead of showing each section as a separate box with enable button, show a selector with all section types
// so that the user can add which ones it needs interactively instead. Conserves space and makes it easier to
// see which sections are enabled.
const BalancingTechnologiesSelector = ({
  form,
  setForm,
  modelSections
}: BalancingTechnologiesSelectorProps) => {
  const options: BalancingTechnologiesSelectorOption[] = useMemo(() => {
    return _.orderBy(
      modelSections.map((section) => {
        return {
          label: section.label,
          value: section.label,
          keyPath: getPathStringFromModelKeyFunc(
            section.models.find((model) =>
              getPathStringFromModelKeyFunc(model.key).endsWith('.enabled')
            )?.key
          )
        };
      }),
      'label'
    );
  }, [modelSections]);

  const value: BalancingTechnologiesSelectorOption[] = useMemo(() => {
    return _.filter(options, (option) => _.get(form, option.keyPath) === true);
  }, [form, options]);

  const onChange = useCallback(
    (newValues: BalancingTechnologiesSelectorOption[]) => {
      setForm((oldForm) => {
        const newForm = { ...oldForm };

        for (let option of options) {
          _.set(newForm, option.keyPath, false);
        }

        for (let option of newValues) {
          _.set(newForm, option.keyPath, true);
        }

        return newForm;
      });
    },
    [options, setForm]
  );

  return (
    <div className={styles.balancingSelector}>
      <KeyValueSelectableInput<BalancingTechnologiesSelectorOption, true>
        options={options}
        value={value}
        keyText={T.ectoplanner.technologies}
        onChange={onChange}
        isMulti
      />
    </div>
  );
};

export const balancingUnitTechnologies = _.orderBy(
  [
    ...RevHeatPumpBuSections('revHeatPumpBu'),
    ...RevHeatPumpBuSections('revHeatPumpBu2'),
    ...AquiferStorageBuSections,
    ...GSHeatPumpBuSections,
    ...SimpleHeatPumpBuSections,
    ...ElectricalHeaterBuSections,
    ...CompressionChillerBuSections,
    ...AbsorptionChillerBuSections,
    ...CHPBuSections,
    ...GasBoilerBuSections,
    ...PhotovoltaicSections,
    ...SolarThermalSections,
    ...DistrictHeatingBuSections,
    ...DistrictCoolingBuSections,
    ...WasteHeatSections('wasteHeat'),
    ...WasteHeatSections('wasteHeat2'),
    ...WasteCoolingSections,
    ...BatteryBuSections,
    ...HeatStorageBuSections,
    ...ColdStorageBuSections
  ],
  'label'
);

export const balancingUnitTechKeypaths = _.compact(
  balancingUnitTechnologies.map((section) =>
    getPathStringFromModelKeyFunc(
      section.models.find((model) =>
        getPathStringFromModelKeyFunc(model.key).endsWith('.enabled')
      )?.key
    )
  )
);

export const ectoplannerTechnologies = [
  {
    icon: <Icons.TemperatureImpact />,
    title: T.ectoplanner.sections.balancingunit,
    value: 'balancingunit',
    customControl: BalancingTechnologiesSelector,
    modelSections: balancingUnitTechnologies
  },
  {
    icon: <Icons.Network />,
    value: 'grid',
    title: T.ectoplanner.sections.grid,
    modelSections: [...AccuTankBuSections, ...NetworkSections]
  },
  {
    icon: <Icons.File />,
    title: T.ectoplanner.sections.modelparams,
    value: 'modelparams',
    modelSections: [
      ...EnergyCostsSections,
      ...EconomicParametersSections,
      ...EcologicalImpactSections,
      ...ReferenceSystemSections,
      ...SensitivitySections,
      ...InvestmentSections
    ]
  }
];

export type OptionWithIconAndView = OptionWithIcon & {
  view: React.ReactNode;
};

const EmptyErrors: EctoplannerFormError[] = [];
const EmptyErrorSections: Record<string, EctoplannerFormError[]> = {};

export function useEctoplannerFormValidation({
  form,
  cityData,
  projectId
}: {
  form: EctoplannerForm;
  cityData: object;
  projectId: string;
}) {
  const [allSectionsFlat, allModels] = useMemo(() => {
    const allSections: ModelFormSectionType<EctoplannerForm>[] = _.concat(
      LocationSections,
      _.flatMap(
        ectoplannerTechnologies,
        (technology) => technology.modelSections
      ),
      getBuildingNproSections(form?.buildings ?? [], cityData)
    );

    return [
      flattenModelFormSections(allSections),
      modelFormSectionsToModelsWithParent(allSections)
    ];
  }, [cityData, form?.buildings]);

  const [formErrors, formErrorSections] = useMemo(() => {
    const environment = { projectId: projectId };
    const ret: EctoplannerFormError[] =
      form == null
        ? []
        : _.filter(
            _.map(allModels, (model) => {
              const value = _.get(form, getPathFromModelKeyFunc(model.key));

              let errorText = (
                model.errorText as ModelStringFunctionProperty<
                  EctoplannerForm,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  any,
                  EctoplannerFormEnvironment
                >
              )(value, form, environment, model);

              if (errorText == null && model.hasError != null) {
                let hasError = false;
                if (_.isFunction(model.hasError)) {
                  hasError = (
                    model.hasError as ModelBoolFunctionProperty<
                      EctoplannerForm,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      any,
                      EctoplannerFormEnvironment
                    >
                  )(value, form, environment, model);
                } else if (_.isBoolean(model.hasError)) {
                  hasError = model.hasError;
                }

                if (hasError) {
                  errorText = T.ectoplanner.form.shared.error;
                }
              }

              const sectionWithSubsection = (
                subSection: ModelFormSectionType<EctoplannerForm>
              ) =>
                allSectionsFlat.find((section) =>
                  section.sections?.find(
                    (childSection) => childSection === subSection
                  )
                );

              let prefix = '';

              if (errorText != null) {
                let parentSection = sectionWithSubsection(model.section);

                while (parentSection != null) {
                  prefix = parentSection.label + ' > ' + prefix;
                  parentSection = sectionWithSubsection(parentSection);
                }
              }

              return {
                sectionTitle: prefix + model.section.label,
                errorLabel: model.label,
                errorKeyPath: getPathStringFromModelKeyFunc(model.key),
                errorText
              };
            }),
            (error) => error.errorText != null
          );

    // To avoid re-rendering
    if (ret.length === 0) {
      return [EmptyErrors, EmptyErrorSections];
    }

    return [ret, _.groupBy(ret, 'sectionTitle')];
  }, [allModels, allSectionsFlat, form, projectId]);

  const hasErrors = !_.isEmpty(formErrorSections);

  return [hasErrors, formErrors, formErrorSections] as const;
}

export function useEctoplannerValidation({
  form,
  currentWeatherStationId,
  hasErrors
}: {
  form: EctoplannerForm;
  hasErrors: boolean;
  currentWeatherStationId: string;
}) {
  const hasNoTechs =
    form != null &&
    !_.some(
      balancingUnitTechKeypaths,
      (keyPath) => _.get(form, keyPath) === true
    );
  const hasEmptyProject =
    currentWeatherStationId == null || form?.buildings?.length === 0;
  const component = (
    <>
      {' '}
      {hasNoTechs && (
        <ErrorNotice className={styles.errorSection}>
          {' '}
          {T.ectoplanner.form.notechs.label}{' '}
        </ErrorNotice>
      )}
      {hasErrors && !hasEmptyProject && (
        <ErrorNotice className={styles.errorSection}>
          {' '}
          {T.ectoplanner.invalidform.label}{' '}
        </ErrorNotice>
      )}
      {hasEmptyProject && (
        <Notice className={styles.errorSection}>
          {' '}
          {T.ectoplanner.form.emptyform.label}{' '}
        </Notice>
      )}
    </>
  );

  return [component, hasNoTechs] as const;
}

export function useEctoplannerFormCalculation({
  form,
  setForm,
  hasErrors,
  cityData,
  airTemp,
  buildId
}: {
  form: EctoplannerForm;
  setForm: Dispatch<SetStateAction<EctoplannerForm>>;
  hasErrors: boolean;
  cityData?: object;
  airTemp?: string;
  buildId?: string;
}) {
  // This is a bit messy: When we do a calculation, the result of that will change the form state (the form
  // includes the results from the calculation). However, a calculation will also be triggered by other form
  // changes (that result from user input). We do not want to create a loop where a change in the form causes a
  // calculation, which causes a change in the form, and so on... So we explicitly set calculationTrigger for
  // the explicit cases where form changes should result in a recalculation. We also use a ref to keep track
  // of the last known calculation trigger, so that we can check the value during render, but only the first time it changes.
  const [calculationTrigger, setCalculationTrigger] = useReducer(
    (x) => x + 1,
    0
  );
  const lastCalculationTrigger = useRef(-1);
  const calculationIndex = useRef(0);
  const lastBuildIdRef = useRef<string>(buildId);
  const [checksums, setChecksums] = useState<Record<string, string>>({});

  const stableDebounce = useMemo(() => {
    return _.debounce((func: () => void) => {
      func();
    }, 300);
  }, []);

  const calculateProfilesMutation = useMutation(compileFormPromise, {
    onSuccess: (data) => {
      // Need to make sure that a more recent calculation hasn't been triggered. If so, we should not update data.
      if (calculationIndex.current === data.calculationIndex) {
        setForm(data.form);
        setChecksums((oldChecksums) => ({
          ...oldChecksums,
          [buildId]: data.checksum
        }));
      }
    }
  });

  // If form has changed, and we have set the trigger calculation flag, we should trigger a calculation.
  // But do it through the debounce so that we don't spam calculations when the user updates the form
  // quickly.
  // However, if we do it for the first time, we should not debounce it. Wait for an explicit
  // trigger (calculationTrigger > 0) which will happen once the form and weather + cityData
  // has been fetched (so migrations / building type verification etc can happen)
  if (
    (calculationTrigger !== lastCalculationTrigger.current ||
      lastBuildIdRef.current !== buildId) &&
    airTemp != null &&
    cityData != null &&
    form != null &&
    calculationTrigger > 0
  ) {
    lastBuildIdRef.current = buildId;
    lastCalculationTrigger.current = calculationTrigger;

    if (calculationTrigger === 1 && !hasErrors) {
      calculateProfilesMutation.mutate({
        inputForm: { ...form },
        calculationIndex: calculationIndex.current,
        cityData,
        airTemp
      });
    } else {
      stableDebounce(() => {
        if (!hasErrors) {
          calculateProfilesMutation.mutate({
            inputForm: { ...form },
            calculationIndex: calculationIndex.current,
            cityData,
            airTemp
          });
        }
      });
    }
  }

  const triggerCalculation = useCallback(() => {
    calculationIndex.current++;
    setCalculationTrigger();
  }, []);

  return [triggerCalculation, checksums[buildId]] as const;
}

export function useEctoplannerFormOptions({
  projectId,
  form,
  setFormFromUserInput,
  formErrors,
  weatherCountries,
  cityData
}: {
  projectId: string;
  form: EctoplannerForm;
  setForm: Dispatch<SetStateAction<EctoplannerForm>>;
  setFormFromUserInput: Dispatch<SetStateAction<EctoplannerForm>>;
  formErrors: EctoplannerFormError[];
  weatherCountries: WeatherCountryResponse[];
  cityData: object;
}): OptionWithIconAndView[] {
  return useMemo(() => {
    const formOptions = [
      {
        icon: <Icons.Building />,
        label: T.ectoplanner.form.shared.buildings,
        value: 'buildings',
        view: form && (
          <EditEctoplannerBuildings
            formErrors={formErrors}
            form={form}
            setFormFromUserInput={setFormFromUserInput}
            projectId={projectId}
            weatherCountries={weatherCountries}
            cityData={cityData}
          />
        )
      },
      ...ectoplannerTechnologies.map(
        ({ customControl, icon, title, value, modelSections }) => ({
          icon,
          label: title,
          value,
          view: form && (
            <>
              {customControl &&
                React.createElement(customControl, {
                  form,
                  setForm: setFormFromUserInput,
                  modelSections
                })}
              <EditEctoplannerTechnologies
                form={form}
                setForm={setFormFromUserInput}
                sections={modelSections}
                projectId={projectId}
                key={value}
              />
            </>
          )
        })
      )
    ];
    return formOptions;
  }, [
    cityData,
    form,
    formErrors,
    projectId,
    setFormFromUserInput,
    weatherCountries
  ]);
}

export const getEctoplannerInfoView = (
  isRunningCalculation: boolean,
  noSolutionFound: boolean,
  noResults: boolean,
  status: EctoplannerBuildStatus,
  containerClassName: string
) => {
  const notCalculated =
    status == null || status === EctoplannerBuildStatus.Created;

  if (noSolutionFound && !isRunningCalculation) {
    return (
      <div className={containerClassName}>
        <Heading level={3}>
          {T.ectoplanner.calculations.calculationfailed}
        </Heading>
        {T.ectoplanner.calculations.nosolutionfound}
      </div>
    );
  } else if (isRunningCalculation) {
    return (
      <div className={containerClassName}>
        {T.ectoplanner.calculations.calculating}

        <EctoplannerProgressBar
          isLoading
          progress={EctoplannerBuildStatusProgress[status] ?? 0}
        />
      </div>
    );
  } else if (notCalculated) {
    return (
      <div className={containerClassName}>
        <Heading level={3}>{T.ectoplanner.calculations.notcalculated}</Heading>
        {T.ectoplanner.calculations.notcalculatedinfo}
      </div>
    );
  } else if (noResults) {
    return (
      <div className={containerClassName}>
        <Heading level={3}>
          {T.ectoplanner.calculations.calculationfailed}
        </Heading>
        {T.common.unknownerror}
      </div>
    );
  }

  return null;
};
