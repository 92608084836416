import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import ToastContainer, {
  toastStore
} from 'ecto-common/lib/Toast/ToastContainer';
import Layout from 'ecto-common/lib/Layout/Layout';
import ContentArea from 'ecto-common/lib/Layout/ContentArea/ContentArea';
import DocumentationInApp from 'ecto-common/lib/Documentation/DocumentationInApp';
import { setUserSettings } from 'js/actions/settings';
import BaseContainer from 'ecto-common/lib/BaseContainer/BaseContainer';
import { cancellablePromiseList } from 'ecto-common/lib/API/API';
import { useDispatchResultRequest } from 'ecto-common/lib/hooks/useDispatchPromise';
import T from 'ecto-common/lib/lang/Language';
import API from 'ecto-common/lib/API/API';
import { AuthenticationErrorComponent } from 'ecto-common/lib/AuthenticationWrapper/AuthenticationWrapper';
import { setSignalTypes } from 'ecto-common/lib/actions/setSignalTypes';
import { setEnums } from 'ecto-common/lib/actions/getEnums';
import { setSignalTypeFolders } from 'ecto-common/lib/actions/setSignalTypeFolders';
import useAuthentication, {
  AuthenticatedArea
} from 'ecto-common/lib/hooks/useAuthentication';

import { operatorRoutes } from 'js/containers/OperatorRoutes';
import EctoplannerResultPage from 'js/components/Ectoplanner/EctoplannerResultPage';
import { setNodeDashboardCollectionRelations } from 'js/actions/setDashboardRelations';
import { setDefaultDashboardCollection } from 'js/actions/setDefaultDashboardCollection';

import styles from 'js/containers/MainContainer.module.css';
import 'css/global.css';
import IdentityServiceAPI from 'ecto-common/lib/utils/IdentityServiceAPI';
import LoadingScreenWithMenu from 'ecto-common/lib/LoadingScreen/LoadingScreenWithMenu';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import TenantContainer from 'ecto-common/lib/Application/TenantContainer';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import { setNodes } from 'ecto-common/lib/actions/getNodes';
import { setNodeTags } from 'ecto-common/lib/actions/getNodeTags';
import { setEquipmentTypes } from 'ecto-common/lib/actions/getEquipmentTypes';
import {
  useOperatorSelector,
  useOperatorDispatch
} from 'js/reducers/storeOperator';
import IdentityServiceAPIGenV2, {
  TenantUserSettingsModel
} from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import locationChange from 'ecto-common/lib/actions/locationChange';
import { useLocation } from 'react-router';
import { CACHE_KEY_NODES } from 'ecto-common/lib/utils/cacheKeys';
import { EctoplannerResultsRoute } from '../utils/routeConstants';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import {
  HTTP_STATUS_NOT_FOUND,
  ResourceType
} from 'ecto-common/lib/constants/index';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { AuthError } from '@azure/msal-browser';
import { setSelectedLanguage } from 'ecto-common/lib/utils/localStorageUtil';
import {
  useCommonDispatch,
  useCommonSelector
} from 'ecto-common/lib/reducers/storeCommon';
import { SET_UI_LANGUAGE } from 'ecto-common/lib/actions/actionTypes';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';
import APIGen from 'ecto-common/lib/API/APIGen';

export function getNodesPromise(contextSettings: ApiContextSettings) {
  return cancellablePromiseList([
    API.Nodes.getAllNodes(contextSettings),
    API.Nodes.getGrids(contextSettings)
  ] as const);
}

const CoreWrapper = React.memo(() => {
  const { tenantId } = useContext(TenantContext);
  const [hasError, setHasError] = useState(false);
  const nodeTree = useOperatorSelector((state) => state.general.nodeTree);
  const { userId } = useContext(UserContext);

  const dispatch = useOperatorDispatch();
  const apiEnvironment = getApiEnvironment();
  const { isAuthenticated, instance, currentAccount } = useAuthentication(
    apiEnvironment.scopes.gateway
  );

  const [isLoadingNode, loadNodes] = useDispatchResultRequest(
    getNodesPromise,
    setNodes,
    setHasError,
    CACHE_KEY_NODES
  );
  const [isLoadingNodeTags, loadNodeTags] = useDispatchResultRequest(
    API.Nodes.getNodeTags,
    setNodeTags,
    setHasError
  );

  const updateSettings = useCallback(
    (result: TenantUserSettingsModel) => {
      const settings =
        result?.settings && typeof result?.settings === 'string'
          ? JSON.parse(result.settings)
          : {};
      dispatch(setUserSettings(settings));
    },
    [dispatch]
  );

  const clearSettings = useCallback(() => {
    dispatch(setUserSettings({}));
  }, [dispatch]);

  const handleDashboardError = useCallback(
    (error: { response?: { status: number } }) => {
      if (error?.response?.status === HTTP_STATUS_NOT_FOUND) {
        dispatch(setDefaultDashboardCollection(null));
      } else {
        setHasError(true);
      }
    },
    [dispatch]
  );

  const [, loadSettings] = useDispatchResultRequest(
    IdentityServiceAPI.TenantUser.getSettings,
    updateSettings,
    clearSettings
  );
  const [isLoadingDefaultDashboardCollection, loadDefaultDashboardCollection] =
    useDispatchResultRequest(
      APIGen.Dashboard.getDefaultDashboardCollectionView.promise,
      setDefaultDashboardCollection,
      null,
      null,
      handleDashboardError
    );
  const [
    isLoadingNodeDashboardCollectionRelations,
    loadNodeDashboardCollectionRelations
  ] = useDispatchResultRequest(
    API.Dashboard.getAllRelations,
    setNodeDashboardCollectionRelations,
    setHasError
  );
  const [isLoadingEquipmentTypes, loadEquipmentTypes] =
    useDispatchResultRequest(
      API.Equipments.getEquipmentTypes,
      setEquipmentTypes,
      setHasError
    );
  const [isLoadingSignalTypes, loadSignalTypes] = useDispatchResultRequest(
    API.SignalTypes.getAllSignalTypes,
    setSignalTypes,
    setHasError
  );
  const [isLoadingEnums, loadEnums] = useDispatchResultRequest(
    API.Enums.getEnums,
    setEnums,
    setHasError
  );
  const [isLoadingSignalTypeFolders, loadSignalTypeFolders] =
    useDispatchResultRequest(
      API.SignalTypeFolders.getAllSignalTypeFolders,
      setSignalTypeFolders,
      setHasError
    );

  useEffect(() => {
    /**
     * Make sure that account is logged in
     */
    if (userId && tenantId) {
      loadSettings();
      loadNodes(tenantId); // TODO: Replace caching mechanism with react-query
      loadNodeTags();
      loadEquipmentTypes();
      loadEnums();
      loadSignalTypes();
      loadSignalTypeFolders();
      loadNodeDashboardCollectionRelations();
      loadDefaultDashboardCollection();
    }
  }, [
    loadEquipmentTypes,
    loadNodeTags,
    loadNodes,
    loadSettings,
    loadEnums,
    loadSignalTypes,
    userId,
    loadSignalTypeFolders,
    loadNodeDashboardCollectionRelations,
    loadDefaultDashboardCollection,
    tenantId
  ]);

  useEffect(() => {
    if (hasError && isAuthenticated) {
      toastStore.addErrorToast(T.common.baserequesterror);
    } else if (!isAuthenticated) {
      setHasError(false);
    }
  }, [hasError, isAuthenticated, setHasError]);

  const isLoading =
    isLoadingNodeTags ||
    isLoadingEquipmentTypes ||
    isLoadingSignalTypes ||
    isLoadingSignalTypeFolders ||
    isLoadingNodeDashboardCollectionRelations ||
    isLoadingDefaultDashboardCollection ||
    isLoadingEnums ||
    (isLoadingNode && nodeTree.length === 0);

  if (isLoading) {
    return <LoadingScreenWithMenu isLoading />;
  }

  return (
    <BaseContainer
      routes={operatorRoutes}
      msalConfiguration={instance}
      currentAccount={currentAccount}
    />
  );
});

const Container = React.memo(() => {
  const apiEnvironment = getApiEnvironment();
  const { isLoadingTenants, tenantsFailedToLoad, tenantResources } =
    useContext(TenantContext);
  const {
    isLoading: authenticationIsLoading,
    errorMessage,
    instance,
    currentAccount,
    isLoggingOut
  } = useAuthentication(apiEnvironment.scopes.gateway);
  const dispatch = useCommonDispatch();
  const language = useCommonSelector((state) => state.general.language);

  let _errorMessage = errorMessage;

  const userSettingsQuery =
    IdentityServiceAPIGenV2.User.getUserSettings.useQuery({
      enabled: !!currentAccount && !_errorMessage,
      refetchOnWindowFocus: false
    });

  const isLoading =
    isLoadingTenants ||
    authenticationIsLoading ||
    isLoggingOut ||
    userSettingsQuery.isLoading;

  if (tenantsFailedToLoad) {
    _errorMessage = AuthError.createUnexpectedError(
      T.tenants.error.failedtoload
    );
  }

  useEffect(() => {
    if (userSettingsQuery.data?.userSettings?.language) {
      dispatch({
        type: SET_UI_LANGUAGE,
        payload: userSettingsQuery.data?.userSettings?.language
      });
      setSelectedLanguage(userSettingsQuery.data?.userSettings?.language);
      if (language !== userSettingsQuery.data?.userSettings?.language) {
        window.location.reload();
      }
    }
    if (userSettingsQuery.data?.userSettings?.experimentalFeatures) {
      featureFlagStore.update(
        userSettingsQuery.data?.userSettings?.experimentalFeatures,
        false
      );
    }
  }, [
    dispatch,
    language,
    userSettingsQuery.data?.userSettings?.experimentalFeatures,
    userSettingsQuery.data?.userSettings?.language
  ]);

  let content: React.ReactNode = null;
  if (isLoading) {
    content = <LoadingScreenWithMenu isLoading />;
  } else if (hasAccessToResource(ResourceType.CORE, tenantResources)) {
    content = <CoreWrapper />;
  } else if (currentAccount && !_errorMessage) {
    content = (
      <BaseContainer
        routes={operatorRoutes}
        msalConfiguration={instance}
        currentAccount={currentAccount}
      />
    );
  }

  return (
    <>
      {!_errorMessage ? (
        <div className={styles.baseContainer}>{content}</div>
      ) : (
        <AuthenticationErrorComponent error={_errorMessage} />
      )}
    </>
  );
});

const OperatorContentArea = () => {
  const { tenantId } = useContext(TenantContext);

  return (
    <Switch>
      {process.env.DEV_BUILD && (
        <Route
          path="/:tenantId/documentation/:category?"
          component={DocumentationInApp}
          exact
        />
      )}
      <Route
        path={EctoplannerResultsRoute.path}
        component={EctoplannerResultPage}
        exact
      />
      <Route path="*">
        <Layout
          leftSidebar={undefined}
          contentArea={
            <ContentArea>
              <Container key={tenantId} />
              <ToastContainer />
            </ContentArea>
          }
        />
      </Route>
    </Switch>
  );
};

const MainContainer = () => {
  const dispatch = useOperatorDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(locationChange(location));
  }, [dispatch, location]);

  return (
    <AuthenticatedArea>
      <TenantContainer>
        <OperatorContentArea />
      </TenantContainer>
    </AuthenticatedArea>
  );
};

export default MainContainer;
