import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import React from 'react';
import styles from './EditEctoplannerProject.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import Heading from 'ecto-common/lib/Heading/Heading';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import { EctoplannerFormError } from 'js/components/Ectoplanner/EctoplannerTypes';
import _ from 'lodash';

const ErrorTableColumns = [
  {
    dataKey: 'errorLabel',
    label: T.ectoplanner.calculations.formerrorsetting,
    flexGrow: 4
  },
  {
    dataKey: 'errorText',
    label: T.ectoplanner.form.shared.error,
    flexGrow: 6
  }
];

const EctoplannerFormErrorsModal = ({
  isOpen,
  onModalClose,
  formErrorSections
}: {
  isOpen: boolean;
  onModalClose: () => void;
  formErrorSections: Record<string, EctoplannerFormError[]>;
}) => {
  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      disableCancel
      title={T.ectoplanner.calculations.formerrorstitle}
      onConfirmClick={onModalClose}
      headerIcon={Icons.Error}
      className={styles.errorModal}
    >
      <div>
        {_.map(formErrorSections, (errors, errorSectionTitle) => (
          <div key={errorSectionTitle} className={styles.errorSection}>
            <Heading level={3}>{errorSectionTitle}</Heading>
            <DataTable columns={ErrorTableColumns} data={errors} />
          </div>
        ))}
      </div>
    </ActionModal>
  );
};

export default React.memo(EctoplannerFormErrorsModal);
