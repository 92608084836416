import React, { Dispatch, Fragment, SetStateAction, useCallback } from 'react';
import T from 'ecto-common/lib/lang/Language';

import DataOptions, {
  DEFAULT_DATA_OPTIONS,
  OptionKey,
  OptionType
} from './DataOptions';
import DateRangeSelector from './DateRangeSelector';
import {
  DateRangeType,
  defaultTimeRange
} from 'ecto-common/lib/utils/dateUtils';
import styles from './ExportOptions.module.css';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { AggregationType, SamplingInterval } from 'ecto-common/lib/API/APIGen';
import { FileFormatOptions } from 'js/components/ExportData/types';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';

export type ExportOptionsType = {
  [OptionKey.SAMPLING]: SamplingInterval;
  [OptionKey.FORMAT]: FileFormatOptions;
  [OptionKey.AGGREGATION]: AggregationType;
  dateFrom: number;
  dateTo: number;
  selectedSignals: ChartSignal[];
};

export const defaultExportOptions = (): ExportOptionsType => ({
  ...DEFAULT_DATA_OPTIONS,
  ...defaultTimeRange(),
  selectedSignals: []
});

type ExportOptionsProps = {
  expandingWidthDateInputs?: boolean;
  options: ExportOptionsType;
  setOptions: Dispatch<SetStateAction<ExportOptionsType>>;
};

const ExportOptions = ({
  options,
  setOptions,
  expandingWidthDateInputs = false
}: ExportOptionsProps) => {
  const onValuesChanged = useCallback(
    (values: OptionType | DateRangeType) => {
      setOptions((oldOptions) => ({
        ...oldOptions,
        ...values
      }));
    },
    [setOptions]
  );

  return (
    <Fragment>
      <DataOptions onOptionsChanged={onValuesChanged} />
      <KeyValueGeneric
        keyText={T.exportoptions.daterange}
        valueItemClassName={styles.rangeContainer}
      >
        <DateRangeSelector
          defaultRange={options}
          onRangeChanged={onValuesChanged}
          expandingItemWidth={expandingWidthDateInputs}
        />
      </KeyValueGeneric>
    </Fragment>
  );
};

export default ExportOptions;
