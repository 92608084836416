import React, { useContext, useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { SignalCollectionActions } from 'js/modules/signalCollections/signalCollections';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import Button from 'ecto-common/lib/Button/Button';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import {
  useOperatorSelector,
  useOperatorDispatch
} from 'js/reducers/storeOperator';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const GraphConfirmSaveDialog = () => {
  const dispatch = useOperatorDispatch();
  const signalCollectionActions = useMemo(
    () => bindActionCreators(SignalCollectionActions, dispatch),
    [dispatch]
  );
  const saveNeedsConfirm = useOperatorSelector(
    (state) => state.signalCollections.saveNeedsConfirm
  );
  const { contextSettings } = useContext(TenantContext);

  return (
    <ActionModal
      compact
      onModalClose={() => signalCollectionActions.setSaveNeedsConfirm(false)}
      isOpen={saveNeedsConfirm}
      headerIcon={Icons.Save}
      title={T.graphs.confirmsave.title}
      footer={
        <ModalFooter>
          <Button
            onClick={() =>
              signalCollectionActions.confirmSaveSignalCollectionSignals(
                contextSettings,
                false
              )
            }
          >
            <Icons.Save />
            {T.graphs.confirmsave.saveall}
          </Button>
          <Button
            onClick={() =>
              signalCollectionActions.confirmSaveSignalCollectionSignals(
                contextSettings,
                true
              )
            }
          >
            <Icons.Save />
            {T.graphs.confirmsave.savecurrent}
          </Button>
          <GreyButton
            onClick={() => signalCollectionActions.setSaveNeedsConfirm(false)}
          >
            {T.common.cancel}
          </GreyButton>
        </ModalFooter>
      }
      onConfirmClick={() => {}}
    >
      {T.graphs.confirmsave.message}
    </ActionModal>
  );
};

export default GraphConfirmSaveDialog;
