import React from 'react';

import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import HelpPaths from 'ecto-common/help/tocKeys';

import GraphEditor from 'js/containers/GraphEditor';

const GraphsPage = () => (
  <ToolbarContentPage
    title={T.location.tabs.graphs}
    wrapContent={false}
    helpPath={HelpPaths.docs.operator.graphs}
    removeTopMargin
  >
    <GraphEditor />
  </ToolbarContentPage>
);

export default GraphsPage;
