import React from 'react';
import { useSelector } from 'react-redux';
import { CommonRootState } from 'ecto-common/lib/reducers/storeCommon';
import { EquipmentResponseModel } from 'ecto-common/lib/API/APIGen';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { getEquipmentName } from 'ecto-common/lib/utils/equipmentTypeUtils';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import styles from './ProcessMap.module.css';
import ProcessMapHeaderColumn from 'ecto-common/lib/ProcessMap/ProcessMapHeaderColumn';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';
import T from 'ecto-common/lib/lang/Language';

const ProcessMapEquipmentHeader = () => {
  const equipmentId = useSelector(
    (state: CommonRootState) => state.general.equipmentId
  );
  const equipmentMap = useSelector(
    (state: CommonRootState) => state.general.equipmentMap
  );
  const equipmentTypes = useSelector(
    (state: CommonRootState) => state.general.equipmentTypes
  );

  if (equipmentId) {
    const equipment: EquipmentResponseModel = getNodeFromMap(
      equipmentMap,
      equipmentId
    );

    if (equipment) {
      const equipmentTypeText = getEquipmentName(
        equipment.equipmentTypeId,
        equipmentTypes
      );

      return (
        <PlainBox className={styles.equipmentInfo}>
          <ProcessMapHeaderColumn
            title={T.equipment.equipmenttype}
            subtitle={equipmentTypeText}
          />
          <ProcessMapHeaderColumn
            title={T.equipment.description}
            subtitle={
              !isNullOrWhitespace(equipment.description) ? (
                <CopyToClipboardTooltip valueToCopy={equipment.description}>
                  {' '}
                  {equipment.description}
                </CopyToClipboardTooltip>
              ) : (
                T.equipment.nodescription
              )
            }
          />
        </PlainBox>
      );
    }
  }

  return null;
};

export default React.memo(ProcessMapEquipmentHeader);
