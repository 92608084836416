import { Moment } from 'moment';
import React, { Dispatch, SetStateAction } from 'react';
import DateInput from '../DateInput/DateInput';
import T from '../lang/Language';
import styles from './ToolbarDateTimePicker.module.css';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';

type ToolbarDateTimePickerProps = {
  fromDate?: Moment;
  setFromDate: Dispatch<SetStateAction<Moment>>;
  label?: string;
  placeholder?: string;
};

const ToolbarDateTimePicker = ({
  fromDate,
  setFromDate,
  label = T.common.dateinput.from,
  placeholder = null
}: ToolbarDateTimePickerProps) => {
  return (
    <ToolbarItem className={styles.toolbarItemDatePicker}>
      <DateInput
        textFieldClassName={styles.dateTextField}
        timeFormat
        clearButton
        value={fromDate}
        onChange={setFromDate}
        label={label}
        expandingWidth
        placeholder={placeholder}
        allowEmptyValue
      />
    </ToolbarItem>
  );
};

export default React.memo(ToolbarDateTimePicker);
