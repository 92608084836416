import UUID from 'uuidjs';
import _ from 'lodash';

import { updateSettings } from 'js/actions/settings';
import { createReducer } from 'ecto-common/lib/utils/reducerUtils';
import { createAction } from 'ecto-common/lib/utils/actionUtils';
import T from 'ecto-common/lib/lang/Language';

import { DEFAULT_GRAPH_SETTINGS } from './types';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import {
  ChartSignal,
  GRAPH_SETTINGS_STORAGE_KEY,
  migrateGraphCollections
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import {
  GraphCollectionType,
  SeriesInterval
} from 'ecto-common/lib/types/EctoCommonTypes';

const sortSignalCollections = (signalCollections: GraphCollectionType[]) =>
  sortByLocaleCompare(signalCollections, 'name');

import UndoManager from 'ecto-common/lib/utils/UndoManager';
import { OperatorDispatch, OperatorGetState } from 'js/reducers/storeOperator';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

//
// Signal collection settings
//
const _getDuplicateName = (
  currentName: string,
  state: SignalCollectionsReducerProps
) => {
  let regexStr = '( ' + T.graphs.duplicatesuffix + ' )(\\(\\d+\\))$';
  let regex = new RegExp(regexStr, 'gm');
  let suffixRegexStr = ' ' + T.graphs.duplicatesuffix + '$';
  let suffixRegex = new RegExp(suffixRegexStr, 'gm');

  let name = currentName;

  if (regex.test(currentName)) {
    name = currentName.replace(regex, '');
  } else if (suffixRegex.test(currentName)) {
    name = currentName.replace(suffixRegex, '');
  }

  let retName = name + ' ' + T.graphs.duplicatesuffix;

  if (_getSignalCollectionWithName(state, retName) == null) {
    return retName;
  }

  let index = 2;

  retName = name + ' ' + T.graphs.duplicatesuffix + ' (' + index + ')';

  while (_getSignalCollectionWithName(state, retName) != null) {
    index++;
    retName = name + ' ' + T.graphs.duplicatesuffix + ' (' + index + ')';
  }

  return retName;
};

export const getNewSignalCollectionName = (
  collections: GraphCollectionType[],
  deletedId: string = null,
  name = T.graphs.newgraphname
) => {
  const signalCollectionsWithNewName = collections.filter(
    (x) => (x.name ?? '').indexOf(name) !== -1 && x.id !== deletedId
  );

  if (signalCollectionsWithNewName.length === 0) {
    return name;
  }

  const digitSuffixes = signalCollectionsWithNewName
    .map((x) => parseInt((x.name ?? '').replace(/\D/g, ''), 10))
    .filter((x) => !isNaN(x));
  const previousMax: number = _.max(digitSuffixes) || 1;

  return name + ' ' + (previousMax + 1);
};

/**
 * Determines number of seconds for interval
 * @param interval
 * @returns {number} Number of seconds for interval
 */
export const SeriesIntervalToRange = (interval: SeriesInterval) => {
  if (interval === SeriesInterval.HOUR) {
    return 60 * 60 * 1000;
  } else if (interval === SeriesInterval.DAY) {
    return 24 * 60 * 60 * 1000;
  } else if (interval === SeriesInterval.WEEK) {
    return 7 * 24 * 60 * 60 * 1000;
  } else if (interval === SeriesInterval.MONTH) {
    return 30 * 24 * 60 * 60 * 1000;
  } else if (interval === SeriesInterval.YEAR) {
    return 365 * 24 * 60 * 60 * 1000;
  }

  return -1;
};

export const SeriesIntervalToString = (): [
  interval: string,
  label: string
][] => {
  return [
    [SeriesInterval.HOUR, T.operatorchart.interval.i1h],
    [SeriesInterval.DAY, T.operatorchart.interval.i1d],
    [SeriesInterval.WEEK, T.operatorchart.interval.i1w],
    [SeriesInterval.MONTH, T.operatorchart.interval.i1m],
    [SeriesInterval.YEAR, T.operatorchart.interval.i1y],
    [SeriesInterval.ALL, T.operatorchart.interval.iall]
  ];
};

//
// Edit signal collection
//
const SIGNAL_COLLECTIONS_UPDATE_COLLECTION =
  'SIGNAL_COLLECTIONS_UPDATE_COLLECTION';
const SIGNAL_COLLECTIONS_EDIT_SIGNAL_COLLECTION =
  'SIGNAL_COLLECTIONS_EDIT_SIGNAL_COLLECTION';
const SIGNAL_COLLECTIONS_CHANGE_SIGNAL_COLLECTION =
  'SIGNAL_COLLECTIONS_CHANGE_SIGNAL_COLLECTION';
const SIGNAL_COLLECTIONS_CREATE_SIGNAL_COLLECTION =
  'SIGNAL_COLLECTIONS_CREATE_SIGNAL_COLLECTION';
const SIGNAL_COLLECTIONS_DUPLICATE_SIGNAL_COLLECTION =
  'SIGNAL_COLLECTIONS_DUPLICATE_SIGNAL_COLLECTION';
const SIGNAL_COLLECTIONS_SET_CONFIRM_DELETE_SIGNAL_COLLECTION_ID =
  'SIGNAL_COLLECTIONS_SET_CONFIRM_DELETE_SIGNAL_COLLECTION_ID';
const SIGNAL_COLLECTIONS_DELETE_SIGNAL_COLLECTION =
  'SIGNAL_COLLECTIONS_DELETE_SIGNAL_COLLECTION';
const SIGNAL_COLLECTIONS_SHOW_SIGNAL_SELECTOR =
  'SIGNAL_COLLECTIONS_SHOW_SIGNAL_SELECTOR';
const SIGNAL_COLLECTIONS_SET_USER_COLLECTIONS =
  'SIGNAL_COLLECTIONS_SET_USER_COLLECTIONS';

const SIGNAL_COLLECTIONS_RESET_CURRENT_COLLECTION =
  'SIGNAL_COLLECTIONS_RESET_CURRENT_COLLECTION';

//
// Pixel points
//
const SIGNAL_COLLECTIONS_SET_SAVE_NEEDS_CONFIRM =
  'SIGNAL_COLLECTIONS_SET_SAVE_NEEDS_CONFIRM';

const SIGNAL_COLLECTIONS_SET_IS_SAVING_COLLECTIONS =
  'SIGNAL_COLLECTIONS_SET_IS_SAVING_COLLECTIONS';

// Undo/Redo
const SIGNAL_COLLECTIONS_CURRENT_COLLECTION_UNDO =
  'SIGNAL_COLLECTIONS_CURRENT_COLLECTION_UNDO';
const SIGNAL_COLLECTIONS_CURRENT_COLLECTION_REDO =
  'SIGNAL_COLLECTIONS_CURRENT_COLLECTION_REDO';

const SIGNAL_COLLECTIONS_SET_TEMPORARY_COLLECTION =
  'SIGNAL_COLLECTIONS_SET_TEMPORARY_COLLECTION';
const SIGNAL_COLLECTIONS_SET_TEMPORARY_COLLECTIONS =
  'SIGNAL_COLLECTIONS_SET_TEMPORARY_COLLECTIONS';

export type TelemetryZoomRange = {
  dateFrom: number;
  dateTo: number;
  seriesInterval?: SeriesInterval;
};

export type SignalCollectionsReducerProps = {
  // Collection IDs that have been changed (compared to what is saved on backend)
  changedSignalCollections: Record<string, boolean>;
  // Collection IDs that has been saved to the backend at least once
  committedSignalCollections: Record<string, boolean>;
  // Current working list of collections
  signalCollections: GraphCollectionType[];

  canUndo: boolean;
  canRedo: boolean;
  selectedSignalCollectionId: string;
  confirmDeleteId: string;
  saveNeedsConfirm: boolean;
  isEditingNewCollection: boolean;

  // List of collections matching the state on backend
  _backendSignalCollections: GraphCollectionType[];

  // temporary collections
  tempCollections: Record<string, boolean>;

  isSavingCollections: boolean;

  undoManagers: Record<string, UndoManager<GraphCollectionType>>;
};

const initialState: SignalCollectionsReducerProps = {
  // Collection IDs that have been changed (compared to what is saved on backend)
  changedSignalCollections: {},
  // Collection IDs that has been saved to the backend at least once
  committedSignalCollections: {},
  // Current working list of collections
  signalCollections: [],

  selectedSignalCollectionId: null,
  confirmDeleteId: null,
  saveNeedsConfirm: false,
  isEditingNewCollection: false,

  // List of collections matching the state on backend
  _backendSignalCollections: [],

  // temporary collections
  tempCollections: {},

  isSavingCollections: false,

  // Undo
  undoManagers: {},

  canUndo: false,
  canRedo: false
};

function _getPatchedSignalCollections(
  signalCollections: GraphCollectionType[],
  updatedCollection: GraphCollectionType
) {
  let patched = false;

  let newCollections = signalCollections.map((collection) => {
    if (collection.id === updatedCollection.id) {
      patched = true;
      return updatedCollection;
    }
    return collection;
  });

  if (!patched) {
    newCollections.push(updatedCollection);
  }

  return newCollections;
}

/**
 * Update collection in signalCollections. E.g if name/signals changes
 * @param state
 * @param updatedCollection The collection that needs updating in signalCollections
 * @returns {*} New state
 */
const getStateWithCollectionUpdate = (
  state: SignalCollectionsReducerProps,
  updatedCollection: GraphCollectionType
) => {
  const signalCollections = _getPatchedSignalCollections(
    state.signalCollections,
    updatedCollection
  );

  let changedSignalCollections = {
    ...state.changedSignalCollections,
    [updatedCollection.id]: true
  };

  return {
    ...state,
    changedSignalCollections,
    signalCollections
  };
};

/**
 * Create a storage container for collections to be stored in settings
 * @param collections
 * @returns {{}}
 */
const createCollectionsStorage = (collections: GraphCollectionType[]) => {
  return { [GRAPH_SETTINGS_STORAGE_KEY]: { collections } };
};

const _getSignalCollectionWithName = (
  state: SignalCollectionsReducerProps,
  name: string
) => {
  return state.signalCollections.find(_equalName(name));
};

export const getSelectedSignalCollection = (
  state: SignalCollectionsReducerProps
): GraphCollectionType => {
  return state.signalCollections.find(
    _equalId(state.selectedSignalCollectionId)
  );
};

const _transformSelectedSignalCollection = (
  state: SignalCollectionsReducerProps,
  transform: (input: GraphCollectionType) => GraphCollectionType
): SignalCollectionsReducerProps => {
  let selectedSignalCollection = getSelectedSignalCollection(state);

  if (selectedSignalCollection) {
    return getStateWithCollectionUpdate(
      state,
      transform(_.cloneDeep(selectedSignalCollection))
    );
  }

  return state;
};

const _equalName = (name: string) => (other: GraphCollectionType) =>
  other.name === name;
const _equalId = (id: string) => (other: GraphCollectionType) =>
  other.id === id;
const _notEqualId = (id: string) => (other: GraphCollectionType) =>
  other.id !== id;

const handleUndoRedo = (
  state: SignalCollectionsReducerProps,
  undoManager: UndoManager<GraphCollectionType>,
  func: (callback: (value: GraphCollectionType) => void) => void
) => {
  let newData: GraphCollectionType = null;
  func((newValues: GraphCollectionType) => {
    newData = newValues;
  });

  const updatedState = _transformSelectedSignalCollection(
    state,
    (selectedSignalCollection: GraphCollectionType) => {
      return { ...selectedSignalCollection, ...newData };
    }
  );

  return {
    ...updatedState,
    canUndo: undoManager.canUndo,
    canRedo: undoManager.canRedo
  };
};

const getOrCreateUndoManager = (
  state: SignalCollectionsReducerProps
): [
  undoManager: UndoManager<GraphCollectionType>,
  undoManagers: Record<string, UndoManager<GraphCollectionType>>
] => {
  const selectedId = state.selectedSignalCollectionId;
  const data = getSelectedSignalCollection(state);
  let undoManagers = state.undoManagers;
  if (undoManagers[selectedId] == null) {
    undoManagers = { ...undoManagers };
    if (data != null) {
      undoManagers[selectedId] = new UndoManager([_.cloneDeep(data)]);
    } else {
      undoManagers[selectedId] = new UndoManager([]);
    }
  } else if (data != null && !undoManagers[selectedId].hasHistory) {
    undoManagers = { ...undoManagers };
    undoManagers[selectedId] = new UndoManager([_.cloneDeep(data)]);
  }

  const undoManager = undoManagers[selectedId];
  return [undoManager, undoManagers];
};

const addUndo = (
  state: SignalCollectionsReducerProps,
  result: SignalCollectionsReducerProps
) => {
  const [undoManager, undoManagers] = getOrCreateUndoManager(state);

  undoManager.add(getSelectedSignalCollection(result));

  return {
    ...result,
    undoManagers,
    canUndo: undoManager.canUndo,
    canRedo: undoManager.canRedo
  };
};

export default createReducer(initialState, {
  //
  // Reduce from others action
  //
  [SIGNAL_COLLECTIONS_SET_USER_COLLECTIONS]: (
    state,
    { userSignalCollections: unsortedUserSignalCollections }
  ) => {
    let userSignalCollections = sortSignalCollections(
      unsortedUserSignalCollections
    );

    userSignalCollections = migrateGraphCollections(userSignalCollections);
    let selectedSignalCollectionId = state.selectedSignalCollectionId;
    let signalCollections = _.cloneDeep(state.signalCollections);

    userSignalCollections.forEach((userCollection: GraphCollectionType) => {
      if (signalCollections.findIndex(_equalId(userCollection.id)) === -1) {
        signalCollections.push(userCollection);
      }
    });

    let changedSignalCollections: Record<string, boolean> = {};

    signalCollections.forEach((signalCollection: GraphCollectionType) => {
      const savedSignalCollection = userSignalCollections.find(
        _equalId(signalCollection.id)
      );

      if (!savedSignalCollection) {
        // Means this collection is not saved in any form
        // and don't update tempCollections
        if (state.tempCollections[signalCollection.id] !== true) {
          changedSignalCollections[signalCollection.id] = true;
        }
      } else if (!_.isEqual(savedSignalCollection, signalCollection)) {
        // Means this collection exists in settings, but we have an unsaved
        // newer version in our state tree
        changedSignalCollections[signalCollection.id] = true;
      }
    });
    signalCollections = sortSignalCollections(signalCollections);
    let firstCollectionId =
      userSignalCollections.length > 0 ? userSignalCollections[0].id : null;

    // Maintain selection of previous selected collection, if possible, else revert to firstCollection
    if (
      signalCollections.findIndex(_equalId(selectedSignalCollectionId)) === -1
    ) {
      selectedSignalCollectionId = firstCollectionId;
    }

    if (signalCollections.length === 0) {
      let newId = UUID.generate();
      signalCollections.push({
        id: newId,
        name: getNewSignalCollectionName(signalCollections),
        signals: []
      });
      selectedSignalCollectionId = newId;
      changedSignalCollections[newId] = true;
    }

    return {
      ...state,
      signalCollections,
      changedSignalCollections,
      _backendSignalCollections: _.cloneDeep(userSignalCollections),
      committedSignalCollections: userSignalCollections.reduce(
        (cur, x) => {
          cur[x.id] = true;
          return cur;
        },
        {} as Record<string, boolean>
      ),
      selectedSignalCollectionId
    };
  },

  //
  // Signal collection reducers
  //
  [SIGNAL_COLLECTIONS_SET_TEMPORARY_COLLECTIONS]: (
    state,
    { tempCollections }
  ) => {
    return { ...state, tempCollections };
  },
  [SIGNAL_COLLECTIONS_SET_TEMPORARY_COLLECTION]: (state, { id, value }) => {
    if (!value) {
      const tempCollections = { ...state.tempCollections };
      delete tempCollections[id];
      return { ...state, tempCollections };
    }
    return {
      ...state,
      tempCollections: { ...state.tempCollections, [id]: value }
    };
  },
  [SIGNAL_COLLECTIONS_DUPLICATE_SIGNAL_COLLECTION]: (state) => {
    const currentCollection = getSelectedSignalCollection(state);

    if (!currentCollection) {
      return state;
    }

    const id = UUID.generate();
    let name = _getDuplicateName(currentCollection.name, state);

    let collection = { ...currentCollection, name, id };
    const signalCollections = [...state.signalCollections, collection];

    return {
      ...state,
      selectedSignalCollectionId: collection.id,
      changedSignalCollections: {
        ...state.changedSignalCollections,
        [collection.id]: true
      },
      signalCollections: sortSignalCollections(signalCollections)
    };
  },
  [SIGNAL_COLLECTIONS_CREATE_SIGNAL_COLLECTION]: (
    state,
    { name, isEditingNewCollection = true, id }
  ) => {
    const collection = {
      id: id ?? UUID.generate(),
      name,
      signals: [] as ChartSignal[]
    };
    const signalCollections = [...state.signalCollections, collection];

    return {
      ...state,
      selectedSignalCollectionId: collection.id,
      isEditingNewCollection,
      changedSignalCollections: {
        ...state.changedSignalCollections,
        [collection.id]: true
      },
      signalCollections: sortSignalCollections(signalCollections)
    };
  },
  [SIGNAL_COLLECTIONS_SHOW_SIGNAL_SELECTOR]: (
    state,
    { showSignalSelector }
  ) => {
    return {
      ...state,
      isEditingNewCollection: false,
      showSignalSelector
    };
  },
  [SIGNAL_COLLECTIONS_CHANGE_SIGNAL_COLLECTION]: (state, { id }) => {
    const newState = { ...state, selectedSignalCollectionId: id };
    const [undoManager] = getOrCreateUndoManager(newState);
    return {
      ...newState,
      canUndo: undoManager.canUndo,
      canRedo: undoManager.canRedo
    };
  },
  [SIGNAL_COLLECTIONS_CURRENT_COLLECTION_UNDO]: (state) => {
    const undoManager = state.undoManagers[state.selectedSignalCollectionId];

    return handleUndoRedo(state, undoManager, undoManager.undo);
  },
  [SIGNAL_COLLECTIONS_CURRENT_COLLECTION_REDO]: (state) => {
    const undoManager = state.undoManagers[state.selectedSignalCollectionId];
    return handleUndoRedo(state, undoManager, undoManager.redo);
  },
  [SIGNAL_COLLECTIONS_RESET_CURRENT_COLLECTION]: (state) => {
    const updatedState = _transformSelectedSignalCollection(
      state,
      (selectedSignalCollection) => {
        const backendData = state._backendSignalCollections.find(
          (x) => x.id === selectedSignalCollection.id
        );

        if (backendData) {
          return { ...selectedSignalCollection, ...backendData };
        }

        return selectedSignalCollection;
      }
    );

    return {
      ...updatedState,
      changedSignalCollections: {
        ...state.changedSignalCollections,
        [state.selectedSignalCollectionId]: false
      }
    };
  },

  [SIGNAL_COLLECTIONS_EDIT_SIGNAL_COLLECTION]: (state, { name, settings }) => {
    return addUndo(
      state,
      _transformSelectedSignalCollection(state, (selectedSignalCollection) => {
        return { ...selectedSignalCollection, name, settings };
      })
    );
  },
  [SIGNAL_COLLECTIONS_DELETE_SIGNAL_COLLECTION]: (state, { id }) => {
    const signalCollections = state.signalCollections.filter(_notEqualId(id));
    return {
      ...state,
      signalCollections,
      changedSignalCollections: {
        ...state.changedSignalCollections,
        [id]: false
      }
    };
  },
  [SIGNAL_COLLECTIONS_SET_CONFIRM_DELETE_SIGNAL_COLLECTION_ID]: (
    state,
    { id }
  ) => {
    return { ...state, confirmDeleteId: id };
  },
  [SIGNAL_COLLECTIONS_SET_SAVE_NEEDS_CONFIRM]: (
    state,
    { saveNeedsConfirm }
  ) => {
    return { ...state, saveNeedsConfirm };
  },
  [SIGNAL_COLLECTIONS_UPDATE_COLLECTION]: (
    state,
    { signals, name, settings }
  ) => {
    const _signals = _.map(signals, _cleanSignal);
    return addUndo(
      state,
      _transformSelectedSignalCollection(state, (selectedSignalCollection) => {
        return {
          ...selectedSignalCollection,
          signals: _signals,
          name: name === undefined ? selectedSignalCollection.name : name,
          settings:
            settings === undefined
              ? selectedSignalCollection.settings
              : settings
        } as GraphCollectionType;
      })
    );
  },
  [SIGNAL_COLLECTIONS_SET_IS_SAVING_COLLECTIONS]: (state, payload) => {
    return {
      ...state,
      isSavingCollections: payload.isSaving
    };
  }
});

const _cleanSignal = (signal: ChartSignal): ChartSignal =>
  _.omit(signal, ['parent']);

const _cleanCollection = (collection: GraphCollectionType) => {
  return { ...collection, signals: collection.signals.map(_cleanSignal) };
};

const _deleteSignalCollection = createAction(
  SIGNAL_COLLECTIONS_DELETE_SIGNAL_COLLECTION,
  'id'
);

const _saveSignalCollections = (
  contextSettings: ApiContextSettings,
  signalCollections: GraphCollectionType[]
) => {
  return (dispatch: OperatorDispatch, getState: OperatorGetState) => {
    const { settings } = getState();

    const newSignalCollection = signalCollections.map(_cleanCollection);
    const newSettings = {
      ...settings,
      ...createCollectionsStorage(newSignalCollection)
    };

    dispatch(updateSettings(contextSettings, newSettings)).then(() => {
      dispatch(
        SignalCollectionActions.setUserSignalCollections(signalCollections)
      );
    });
  };
};

export const SignalCollectionActions = {
  setTemporaryCollections: createAction(
    SIGNAL_COLLECTIONS_SET_TEMPORARY_COLLECTIONS,
    'tempCollections'
  ),
  setTemporaryCollection: createAction(
    SIGNAL_COLLECTIONS_SET_TEMPORARY_COLLECTION,
    'id',
    'value'
  ),
  updateCollection: createAction(
    SIGNAL_COLLECTIONS_UPDATE_COLLECTION,
    'signals',
    'name',
    'settings'
  ),

  createSignalCollection: createAction(
    SIGNAL_COLLECTIONS_CREATE_SIGNAL_COLLECTION,
    'name',
    'isEditingNewCollection',
    'id'
  ),

  duplicateSignalCollection: createAction(
    SIGNAL_COLLECTIONS_DUPLICATE_SIGNAL_COLLECTION
  ),

  changeSignalCollection: (id: string) => {
    return (dispatch: OperatorDispatch, getState: OperatorGetState) => {
      const { signalCollections } = getState();
      const signalCollection = _.find(signalCollections.signalCollections, {
        id
      });
      const settings = signalCollection?.settings ?? DEFAULT_GRAPH_SETTINGS;
      dispatch({
        type: SIGNAL_COLLECTIONS_CHANGE_SIGNAL_COLLECTION,
        payload: { id, settings }
      });
    };
  },

  setUserSignalCollections: createAction(
    SIGNAL_COLLECTIONS_SET_USER_COLLECTIONS,
    'userSignalCollections'
  ),
  setSignalCollectionNameAndSettings: createAction(
    SIGNAL_COLLECTIONS_EDIT_SIGNAL_COLLECTION,
    'name',
    'settings'
  ),

  showSignalSelector: createAction(
    SIGNAL_COLLECTIONS_SHOW_SIGNAL_SELECTOR,
    'showSignalSelector'
  ),
  resetCurrentCollection: () => {
    return (dispatch: OperatorDispatch) => {
      return dispatch({ type: SIGNAL_COLLECTIONS_RESET_CURRENT_COLLECTION });
    };
  },
  undoCurrentCollection: () => {
    return (dispatch: OperatorDispatch) => {
      dispatch({ type: SIGNAL_COLLECTIONS_CURRENT_COLLECTION_UNDO });
    };
  },
  redoCurrentCollection: () => {
    return (dispatch: OperatorDispatch) => {
      dispatch({ type: SIGNAL_COLLECTIONS_CURRENT_COLLECTION_REDO });
    };
  },
  setSaveNeedsConfirm: createAction(
    SIGNAL_COLLECTIONS_SET_SAVE_NEEDS_CONFIRM,
    'saveNeedsConfirm'
  ),
  saveSignalCollection: (contextSettings: ApiContextSettings, id: string) => {
    return (dispatch: OperatorDispatch, getState: OperatorGetState) => {
      const { signalCollections } = getState();
      const signalCollection = _.find(signalCollections.signalCollections, {
        id
      });

      // Merge original collections with selected collection
      if (signalCollection) {
        const collectionsToSave = _getPatchedSignalCollections(
          signalCollections._backendSignalCollections,
          signalCollection
        );
        dispatch(_saveSignalCollections(contextSettings, collectionsToSave));
      }
    };
  },
  confirmSaveSignalCollectionSignals: (
    contextSettings: ApiContextSettings,
    saveSelectedOnly: boolean
  ) => {
    return (dispatch: OperatorDispatch, getState: OperatorGetState) => {
      dispatch(SignalCollectionActions.setSaveNeedsConfirm(false));
      const { signalCollections } = getState();
      let collectionsToSave: GraphCollectionType[] = [];

      const currentSignalCollection =
        getSelectedSignalCollection(signalCollections);

      if (saveSelectedOnly) {
        // Merge original collections with selected collection
        if (currentSignalCollection) {
          collectionsToSave = _getPatchedSignalCollections(
            signalCollections._backendSignalCollections,
            currentSignalCollection
          );
        } else {
          collectionsToSave = signalCollections._backendSignalCollections;
        }
      } else {
        collectionsToSave = signalCollections.signalCollections;
      }

      const newTempCollections = _.pickBy(
        signalCollections.tempCollections,
        (_value, key) => {
          return !_.find(collectionsToSave, ['id', key]);
        }
      );

      dispatch(
        SignalCollectionActions.setTemporaryCollections(newTempCollections)
      );
      dispatch(_saveSignalCollections(contextSettings, collectionsToSave));
    };
  },
  setIsSavingCollections: createAction(
    SIGNAL_COLLECTIONS_SET_IS_SAVING_COLLECTIONS,
    'isSaving'
  ),
  saveSignalCollectionSignals: (contextSettings: ApiContextSettings) => {
    return (dispatch: OperatorDispatch, getState: OperatorGetState) => {
      const { signalCollections } = getState();
      let numUnsaved = Object.values(
        signalCollections.changedSignalCollections
      ).filter((x) => x === true).length;

      if (numUnsaved === 1) {
        dispatch(
          SignalCollectionActions.confirmSaveSignalCollectionSignals(
            contextSettings,
            true
          )
        );
      } else if (numUnsaved > 0) {
        dispatch(SignalCollectionActions.setSaveNeedsConfirm(true));
      } else if (
        signalCollections.tempCollections[
          signalCollections.selectedSignalCollectionId
        ] === true
      ) {
        dispatch(
          SignalCollectionActions.confirmSaveSignalCollectionSignals(
            contextSettings,
            true
          )
        );
      }
    };
  },
  prepareToDeleteSignalCollection: createAction(
    SIGNAL_COLLECTIONS_SET_CONFIRM_DELETE_SIGNAL_COLLECTION_ID,
    'id'
  ),

  confirmDeleteSignalCollection: (
    contextSettings: ApiContextSettings,
    id: string
  ) => {
    if (id == null) {
      return SignalCollectionActions.prepareToDeleteSignalCollection(null);
    }

    return (dispatch: OperatorDispatch, getState: OperatorGetState) => {
      dispatch(SignalCollectionActions.prepareToDeleteSignalCollection(null));

      const signalCollections: SignalCollectionsReducerProps =
        getState().signalCollections;
      const {
        _backendSignalCollections,
        selectedSignalCollectionId,
        signalCollections: allSignalCollections
      } = signalCollections;
      const lastIndex = signalCollections.signalCollections.findIndex(
        (x) => x.id === id
      );

      if (lastIndex === -1) {
        return;
      }

      let newIndex = Math.max(0, lastIndex - 1);

      dispatch(_deleteSignalCollection(id));

      if (selectedSignalCollectionId === id) {
        let newCollections = signalCollections.signalCollections.filter(
          _notEqualId(id)
        );

        if (newCollections.length === 0) {
          dispatch(
            SignalCollectionActions.createSignalCollection(
              getNewSignalCollectionName(allSignalCollections, id),
              false
            )
          );
        } else {
          newIndex = Math.min(newIndex, newCollections.length - 1);
          const newSelectedCollection =
            signalCollections.signalCollections[newIndex];
          dispatch(
            SignalCollectionActions.changeSignalCollection(
              newSelectedCollection.id
            )
          );
        }
      }

      if (_backendSignalCollections.findIndex(_equalId(id)) !== -1) {
        // Only remove from settings if it exists on backend
        const newSignalCollections = _backendSignalCollections.filter(
          _notEqualId(selectedSignalCollectionId)
        );
        dispatch(_saveSignalCollections(contextSettings, newSignalCollections));
      }
    };
  }
};
