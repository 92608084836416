import React from 'react';
import styles from './EventBox.module.css';
import classNames from 'classnames';

interface EventBoxProps {
  children?: React.ReactNode;
  className?: string;
}

const EventBox = ({ children, className }: EventBoxProps) => {
  return (
    <div className={classNames(styles.eventBox, className)}>{children}</div>
  );
};

export default EventBox;
