import React from 'react';
import moment from 'moment';

import T from 'ecto-common/lib/lang/Language';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import ProcessMapHeaderColumn from 'ecto-common/lib/ProcessMap/ProcessMapHeaderColumn';
import styles from 'ecto-common/lib/ProcessMap/ProcessMapView.module.css';
import { getLatestDataPoint } from 'ecto-common/lib/SignalsTable/signalsTableUtils';

import { getSignalTypeNameWithUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import { ProcessMapViewSignal } from 'ecto-common/lib/ProcessMap/ProcessMapView';
import { LastSignalValuesResultWithMetadata } from '../Dashboard/panels/SignalListPanel';
import {
  TimeFormats,
  getDefaultDateTimeFormat
} from 'ecto-common/lib/utils/dateUtils';

const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) =>
  e.stopPropagation();

interface ProcessMapHeaderProps {
  signalData?: LastSignalValuesResultWithMetadata;
  currentSignal?: ProcessMapViewSignal;
}

const ProcessMapHeader = ({
  signalData,
  currentSignal
}: ProcessMapHeaderProps) => {
  const currentSignalData = signalData[currentSignal?.signalId];

  let timeVal = T.equipment.novalue;
  let stepVal: number | string = T.equipment.novalue;

  if (currentSignal) {
    const dataPoint = getLatestDataPoint(currentSignalData);
    if (dataPoint?.time) {
      timeVal = moment(dataPoint?.time)
        .tz(DEFAULT_TIMEZONE)
        .format(getDefaultDateTimeFormat(TimeFormats.SECONDS));
    }

    stepVal = currentSignalData?.step ?? T.equipment.novalue;
  }

  let headerText;
  let signalTypeText = '-';

  if (currentSignal?.signalType && currentSignal?.unit) {
    signalTypeText = getSignalTypeNameWithUnit(
      currentSignal.signalType,
      currentSignal.unit
    );
  }

  if (currentSignal) {
    headerText = (
      <span className={styles.selectedSignal}>{currentSignal.name}</span>
    );
  } else {
    headerText = <span className={styles.unselectedSignal}>-</span>;
  }

  return (
    <div className={styles.header} onClick={stopPropagation}>
      <ProcessMapHeaderColumn
        title={T.equipment.displayname}
        subtitle={headerText}
      />
      <ProcessMapHeaderColumn
        title={T.equipment.signaltype}
        subtitle={signalTypeText}
      />
      <ProcessMapHeaderColumn
        title={T.equipment.lastupdate}
        subtitle={timeVal}
      />
      <ProcessMapHeaderColumn
        title={T.equipment.updatethreshold}
        subtitle={stepVal}
      />
    </div>
  );
};

export default ProcessMapHeader;
