// init:  Initial state in App.js

function getMainAttrList() {
  let attrList = [
    // Common state variables for demand and energy hub page
    { name: 'countryCityDict', val: { germany: ['berlin'] }, m: ['init'] } // must contain 'germany'/'berlin' for initial mounting
  ];

  return attrList;
}

const exportedFunctions = { getMainAttrList };
export default exportedFunctions;
