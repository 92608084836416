import {
  BuildingTemperatureImpactPreviewResponseModel,
  SignalProviderTelemetryValueResponseModel
} from 'ecto-common/lib/API/APIGen';
import _ from 'lodash';

export const IMPACT_RANGE = Object.freeze({
  max: 0.5,
  min: -0.5
});

export const convertToGraphValue = ({
  time,
  value
}: SignalProviderTelemetryValueResponseModel) => [
  new Date(time).getTime(),
  value
];

export const impactRangeData = (data: { value: number; time: string }[]) => {
  const maxValue = _.maxBy(data, 'value');
  const minValue = _.minBy(data, 'value');

  return {
    max: maxValue?.value,
    maxTime: maxValue?.time,
    min: minValue?.value,
    minTime: minValue?.time
  };
};

export const isImpactSignalOutsideRange = (
  response: BuildingTemperatureImpactPreviewResponseModel[]
) => {
  const firstImpact = _.first(response);
  const signalValues = _.get(firstImpact, 'temperatureImpactSignalValues');
  const previewImpact = impactRangeData(signalValues);
  return (
    previewImpact.min < IMPACT_RANGE.min || previewImpact.max > IMPACT_RANGE.max
  );
};
