import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import LoadingScreen from 'ecto-common/lib/LoadingScreen/LoadingScreen';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import API from 'ecto-common/lib/API/API';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import { ExportDataParams } from 'js/utils/routeConstants';

const ExportDownloadPage = () => {
  const params = useParams<ExportDataParams>();
  const [redirect, setRedirect] = useState(false);

  const [isLoading, getDownloadUrl] = usePromiseCall({
    promise: API.Signals.getExportDownload,
    onSuccess: (result) => {
      window.location.href = result.downloadUrl;
      setRedirect(true);
    },
    onError: () => {
      toastStore.addErrorToast(T.exportdownload.error);
    },
    initiallyLoading: true
  });

  useEffect(() => {
    getDownloadUrl(params.exportId);
  }, [getDownloadUrl, params.exportId]);

  if (redirect) {
    return <Redirect to={'/'} />;
  }
  return isLoading && <LoadingScreen isLoading />;
};

export default ExportDownloadPage;
