import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import styles from './SignalsToolbar.module.css';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import useDetailedGraph from 'js/components/DashboardPage/useDetailedGraph';
import ExportDataDialog from 'js/components/ExportData/ExportDataDialog';
import _ from 'lodash';
import DropdownButton, {
  DropdownButtonOptionType
} from 'ecto-common/lib/DropdownButton/DropdownButton';
import {
  ChartSignal,
  getNodeName
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import {
  SeriesInterval,
  SingleGridNode
} from 'ecto-common/lib/types/EctoCommonTypes';
import { Moment } from 'moment';
import ToolbarDateTimePicker from 'ecto-common/lib/ToolbarDateTimePicker/ToolbarDateTimePicker';
import {
  FullSignalProviderResponseModel,
  EquipmentResponseModel
} from 'ecto-common/lib/API/APIGen';
import LogViewModal from 'js/components/Logs/LogViewModal';
import ManualAlarmsAPIGen, {
  CreateAlarmRequest
} from 'ecto-common/lib/API/ManualAlarmsAPIGen';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { modelFormIsValid } from 'ecto-common/lib/ModelForm/validateForm';

const nodeNameWithSignalProviderName = (
  signalProvider: Omit<FullSignalProviderResponseModel, 'signals'>,
  nodeMap: Record<string, SingleGridNode>,
  equipmentMap: Record<string, EquipmentResponseModel>
) => {
  let ret = getNodeName(signalProvider, nodeMap, equipmentMap);

  if (ret !== signalProvider.signalProviderName) {
    ret += ' - ' + signalProvider.signalProviderName;
  }

  return ret;
};

interface SignalsToolbarProps {
  nodeId: string;
  searchFilter?: string;
  fromDate?: Moment;
  setFromDate: Dispatch<SetStateAction<Moment>>;
  setSearchFilter?(searchFilter: string): void;
  selectedSignalIds?: Record<string, ChartSignal>;
  setSelectedSignalIds?: Dispatch<SetStateAction<Record<string, ChartSignal>>>;
}

const AlarmSeverityLevelsV2 = {
  HIGH: 801,
  MEDIUM: 401,
  LOW: 1
} as const;

const AlarmSeverityTextV2 = {
  [AlarmSeverityLevelsV2.HIGH]: 'A',
  [AlarmSeverityLevelsV2.MEDIUM]: 'B',
  [AlarmSeverityLevelsV2.LOW]: 'C'
};

export type AlarmSeverityLevelV2 =
  (typeof AlarmSeverityLevelsV2)[keyof typeof AlarmSeverityLevelsV2];

const severityOptions = [
  {
    label: AlarmSeverityTextV2[AlarmSeverityLevelsV2.HIGH],
    value: AlarmSeverityLevelsV2.HIGH
  },
  {
    label: AlarmSeverityTextV2[AlarmSeverityLevelsV2.MEDIUM],
    value: AlarmSeverityLevelsV2.MEDIUM
  },
  {
    label: AlarmSeverityTextV2[AlarmSeverityLevelsV2.LOW],
    value: AlarmSeverityLevelsV2.LOW
  }
];

const createAlarmsModels: ModelDefinition<CreateAlarmRequest>[] = [
  {
    modelType: ModelType.TEXT,
    label: T.alarms.columns.comment,
    key: (input) => input.message,
    autoFocus: true,
    hasError: isNullOrWhitespace,
    placeholder: T.equipment.addalarm.placeholder
  },
  {
    modelType: ModelType.OPTIONS,
    label: T.alarms.columns.severity,
    key: (input) => input.severity,
    options: severityOptions
  }
];

const SignalsToolbar = ({
  nodeId,
  searchFilter,
  setSearchFilter,
  selectedSignalIds,
  setSelectedSignalIds,
  fromDate,
  setFromDate
}: SignalsToolbarProps) => {
  const numSelectedSignals = _.keys(selectedSignalIds).length;
  const [showingExportData, showExportData, hideExportData] =
    useDialogState(false);
  const [showChartData, , , ChartComponent] = useDetailedGraph();
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);
  const [createAlarmsRequest, setCreateAlarmsRequest] =
    useState<CreateAlarmRequest>(null);
  const selectedSignalsList = useMemo(
    () => _.values(selectedSignalIds),
    [selectedSignalIds]
  );

  const showInGraph = useCallback(() => {
    toastStore.clear();
    showChartData(selectedSignalsList, 'Signals', -1, -1, SeriesInterval.MONTH);
  }, [selectedSignalsList, showChartData]);

  const signalOptions = useMemo(() => {
    const signals = _.values(selectedSignalIds);

    let ret: DropdownButtonOptionType[] = _.map(signals, (signal) => ({
      icon: <Icons.Close />,
      label: signal.item.name,
      subtitle: nodeNameWithSignalProviderName(
        signal.group,
        nodeMap,
        equipmentMap
      ),
      disableCloseOnClick: true,
      action: (e) => {
        e.stopPropagation();
        _.defer(() => {
          setSelectedSignalIds((oldSelectedSignalIds) =>
            _.omit(oldSelectedSignalIds, signal.item.signalId)
          );
        });
      }
    }));

    if (ret.length > 0) {
      ret.splice(0, 0, {
        icon: <Icons.Close />,
        label: T.equipment.searchbar.clear,
        action: () => {
          _.defer(() => {
            setSelectedSignalIds({});
          });
        }
      });
    }

    return ret;
  }, [selectedSignalIds, setSelectedSignalIds, equipmentMap, nodeMap]);

  const onShowExportData = useCallback(() => {
    toastStore.clear();
    showExportData();
  }, [showExportData]);

  const [logsOpen, showLogs, hideLogs] = useDialogState(false);
  const selectedIds = useMemo(
    () => Object.keys(selectedSignalIds),
    [selectedSignalIds]
  );

  const dropOptions: DropdownButtonOptionType[] = useMemo(() => {
    return [
      {
        icon: <Icons.Graph />,
        label: T.equipment.searchbar.showingraph,
        action: showInGraph
      },
      {
        icon: <Icons.Download />,
        label: T.equipment.searchbar.export,
        action: onShowExportData
      },
      {
        icon: <Icons.Message />,
        label: T.equipment.searchbar.showlogs,
        action: showLogs
      }
    ];
  }, [onShowExportData, showInGraph, showLogs]);

  const addAlarmMutation =
    ManualAlarmsAPIGen.ManualAlarm.alarmsCreateManual.useMutation({
      onSuccess: () => {
        toastStore.addSuccessToast(T.equipment.addalarm.addedalarm);
        setCreateAlarmsRequest(null);
      },
      onError: () => {
        toastStore.addErrorToast(T.common.unknownerror);
      }
    });

  let alarmInfoText: React.ReactNode = '';

  if (createAlarmsRequest != null) {
    if (selectedIds.length === 0) {
      alarmInfoText = T.format(
        T.equipment.addalarm.nodeformat,
        <strong key="name">
          {nodeMap[createAlarmsRequest.nodeId]?.name ?? ''}
        </strong>
      );
    } else if (selectedIds.length === 1) {
      alarmInfoText = T.format(
        T.equipment.addalarm.nodeformat,
        <strong key="name">
          {selectedSignalIds[createAlarmsRequest.signalId]?.item?.name ?? ''}
        </strong>
      );
    } else {
      alarmInfoText = T.format(
        T.equipment.addalarm.nodeformatwarning,
        <strong key="name">
          {nodeMap[createAlarmsRequest.nodeId]?.name ?? ''}
        </strong>
      );
    }
  }

  const isValid =
    createAlarmsRequest != null &&
    modelFormIsValid(createAlarmsModels, createAlarmsRequest);

  return (
    <>
      <ToolbarSearch
        value={searchFilter}
        wrapperClassName={styles.searchField}
        placeholder={T.equipment.searchbar.searchplaceholder}
        onChange={setSearchFilter}
      />
      <ToolbarFlexibleSpace />
      <ToolbarItem>
        <Button
          onClick={() => {
            let reqSignalId: string = null;

            if (selectedIds.length === 1) {
              reqSignalId = selectedIds[0];
            }

            setCreateAlarmsRequest({
              nodeId,
              signalId: reqSignalId,
              severity: AlarmSeverityLevelsV2.MEDIUM,
              message: ''
            });
          }}
        >
          <Icons.Alarm />
          {T.equipment.addalarm.title}...
        </Button>
      </ToolbarItem>
      <ToolbarDateTimePicker
        fromDate={fromDate}
        setFromDate={setFromDate}
        placeholder={T.equipment.searchbar.dateplaceholder}
        label={T.equipment.searchbar.datefrom}
      />
      <ToolbarItem>
        <DropdownButton
          options={signalOptions}
          disabled={numSelectedSignals === 0}
          optionsMenuClassName={styles.optionsMenu}
          withArrow
        >
          <span key={numSelectedSignals}>
            {T.format(
              T.equipment.searchbar.selectedsignalsformat,
              numSelectedSignals
            )}
          </span>
        </DropdownButton>
      </ToolbarItem>
      <ToolbarItem>
        <DropdownButton
          options={dropOptions}
          disabled={numSelectedSignals === 0}
        >
          <Icons.AnalyzeAndExport /> {T.equipment.analyzeandexport}
        </DropdownButton>
      </ToolbarItem>

      <LogViewModal
        isOpen={logsOpen}
        onModalClose={hideLogs}
        nodeId={nodeId}
        selectedSignalIds={selectedIds}
      />
      <ExportDataDialog
        isOpen={showingExportData}
        selectedSignals={selectedSignalsList}
        onModalClose={hideExportData}
      />
      {ChartComponent}
      <ActionModal
        title={T.equipment.addalarm.title}
        headerIcon={Icons.Alarm}
        isOpen={createAlarmsRequest != null}
        onModalClose={() => setCreateAlarmsRequest(null)}
        disableActionButton={!isValid}
        isLoading={addAlarmMutation.isLoading}
        onConfirmClick={() => {
          addAlarmMutation.mutate(createAlarmsRequest);
        }}
      >
        <ModelForm
          input={createAlarmsRequest}
          models={createAlarmsModels}
          setInput={setCreateAlarmsRequest}
        />
        <p>{alarmInfoText}</p>
      </ActionModal>
    </>
  );
};

export default React.memo(SignalsToolbar);
