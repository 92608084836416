import React, { useContext, useMemo } from 'react';
import Page, { PageNavLink, PageProps } from 'ecto-common/lib/Page/Page';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import { UserDashboardsSubPage } from 'js/utils/routeConstants';
import { ResourceType } from 'ecto-common/lib/constants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { navLinksFilteredByTenantResources } from 'ecto-common/lib/utils/tenantUtil';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';

const nodePath = (tenantId: string, nodeId: string, page: string) =>
  `/${tenantId}/home/${nodeId}/${page}`;

const navLinks: PageNavLink[] = [
  {
    title: T.sidebar.sections.home,
    links: [
      {
        path: (tenantId: string, nodeId: string) =>
          nodePath(tenantId, nodeId, 'dashboard'),
        name: T.location.tabs.dashboard,
        icon: <Icons.Dashboard />,
        resourceTypes: [ResourceType.CORE],
        hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
      },
      {
        path: (tenantId: string, nodeId: string) =>
          nodePath(tenantId, nodeId, UserDashboardsSubPage),
        name: T.location.tabs.userdashboards,
        icon: <Icons.Favorite />,
        resourceTypes: [ResourceType.CORE],
        hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
      }
    ]
  },
  {
    title: T.sidebar.sections.manage,
    links: [
      {
        path: (tenantId: string, nodeId: string) =>
          nodePath(tenantId, nodeId, 'tools'),
        name: T.location.tabs.powercontrol,
        icon: <Icons.Tool />,
        resourceTypes: [ResourceType.CORE, ResourceType.POWER_CONTROL],
        hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
      },
      {
        path: (tenantId: string, nodeId: string) =>
          nodePath(tenantId, nodeId, 'alarms'),
        name: T.location.tabs.alarms,
        icon: <Icons.AlarmBell />,
        resourceTypes: [ResourceType.CORE],
        hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
      },
      {
        path: (tenantId: string, nodeId: string) =>
          nodePath(tenantId, nodeId, 'newalarms'),
        name: T.location.tabs.alarms + ' V2',
        icon: <Icons.AlarmBell />,
        featureFlag: 'newalarms',
        resourceTypes: [ResourceType.CORE], // TODO: Should have separate resource
        hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
      },
      {
        path: (tenantId: string, nodeId: string) =>
          nodePath(tenantId, nodeId, 'signalproviders'),
        name: T.location.tabs.signalproviders,
        icon: <Icons.Signal />,
        resourceTypes: [ResourceType.CORE],
        hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
      },
      {
        path: (tenantId: string, nodeId: string) =>
          nodePath(tenantId, nodeId, 'jobs'),
        name: T.location.tabs.jobs,
        icon: <Icons.DataJobs />,
        resourceTypes: [ResourceType.CORE],
        hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
      },
      {
        path: (tenantId: string, nodeId: string) =>
          nodePath(tenantId, nodeId, 'logs'),
        name: T.location.tabs.logs,
        icon: <Icons.Logs />,
        resourceTypes: [ResourceType.CORE],
        hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
      }
    ]
  },
  {
    title: T.sidebar.sections.visualize,
    links: [
      {
        resourceTypes: [ResourceType.CORE],
        path: (tenantId: string, nodeId: string) =>
          nodePath(tenantId, nodeId, 'graphs'),
        name: T.location.tabs.graphs,
        icon: <Icons.BarGraph />,
        hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
      },
      {
        name: T.location.tabs.exportpage,
        resourceTypes: [ResourceType.CORE],
        path: (tenantId: string) => `/${tenantId}/exportdata/`,
        icon: <Icons.Download />,
        hideIfHasResourceTypes: [ResourceType.TEMPLATE_MANAGEMENT]
      },
      {
        name: 'Ectoplanner',
        resourceTypes: [ResourceType.ECTOPLANNER],
        path: (tenantId: string) => `/${tenantId}/ectoplanner/`,
        icon: <Icons.EctoplannerLogo />
      }
    ]
  }
];

const OperatorPage = (props: PageProps) => {
  const { tenantResources } = useContext(TenantContext);

  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );

  const allNavLinks = useMemo(
    () =>
      navLinksFilteredByTenantResources(
        navLinks,
        tenantResources,
        featureFlagState
      ),
    [tenantResources, featureFlagState]
  );

  return <Page navLinks={allNavLinks} {...props} />;
};

export default OperatorPage;
