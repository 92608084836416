import moment from 'moment';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import {
  TimeFormats,
  getDefaultDateTimeFormat
} from 'ecto-common/lib/utils/dateUtils';

export const HOUR_MILLIS = 3600 * 1000;

export const formatTime = (date: moment.MomentInput) =>
  moment(date).format('HH:mm');

export const formatDate = (date: moment.MomentInput) =>
  moment(date).format('YYYY-MM-DD');

export const formatDateFull = (date: moment.MomentInput) =>
  moment(date).format('YYYY-MM-DD HH:mm');

export const formatHoursUTC = (offset: number) =>
  moment(offset * HOUR_MILLIS)
    .utc()
    .format('HH:mm');

export const parseDateTime = (input: moment.MomentInput) =>
  moment(input, 'YYYY-MM-DD HH:mm', true);

export function formatScheduleDate(date: moment.MomentInput) {
  return moment
    .utc(date)
    .tz(DEFAULT_TIMEZONE)
    .format(getDefaultDateTimeFormat(TimeFormats.LONG_TIME));
}
