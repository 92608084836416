import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import { SegmentControlItem } from './SegmentControlItem';
import SegmentControl, { SegmentControlProps } from './SegmentControl';

export type ArraySegmentControlItem = {
  text: React.ReactNode;
  onClick: MouseEventHandler<HTMLDivElement>;
};

type ArraySegmentControlProps = SegmentControlProps & {
  /**
   * The items to render in the segment control. Each item must have a name and a onClick handler.
   */
  items?: ArraySegmentControlItem[];
  /**
   * A function which is called for each item in order to determine whether or not it is active.
   */
  isActive?(item: ArraySegmentControlItem, index: number): boolean;
  /**
   * Used to override the appearance of the items. Should be a valid CSS class name.
   */
  itemClassName?: string;
};

/**
 * An ArraySegmentControl is a convenience wrapper around a SegmentControl. Instead of explicitly rendering each item you pass it a list of object as a prop. Each object must have an onClick handler and a text property. Passing it as a list prop makes it easier to map input from your source data.
 */
export const ArraySegmentControl = ({
  items,
  isActive,
  expanding = false,
  itemClassName = null,
  ...otherSegmentProps
}: ArraySegmentControlProps) => {
  return (
    <SegmentControl expanding={expanding} {...otherSegmentProps}>
      {items.map((item, index) => {
        const { onClick, text, ...otherProps } = item;
        return (
          <SegmentControlItem
            active={isActive(item, index)}
            key={index}
            expanding={expanding}
            className={classNames(itemClassName)}
            onClick={onClick}
            {...otherProps}
          >
            {text}
          </SegmentControlItem>
        );
      })}
    </SegmentControl>
  );
};
