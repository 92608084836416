import React from 'react';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import { SignalValueResponseModel } from 'ecto-common/lib/API/APIGen';

interface ScheduleSignalsProps {
  signals: SignalValueResponseModel[];
}

const ScheduleSignals = ({ signals }: ScheduleSignalsProps) => {
  return (
    <>
      {signals.map((signal, idx) => {
        const { signalId, value, name } = signal;

        return (
          <span key={`signal-title-${signalId}`}>
            <strong>{name}:</strong> {formatNumberUnit(value, null)}
            {signals.length - 1 === idx ? '' : ', '}
          </span>
        );
      })}
      ;
    </>
  );
};

export default ScheduleSignals;
