import { CustomModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import {
  EctoplannerForm,
  EctoplannerSolarAreaDefinition
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import EctoplannerArrayEditor, {
  EctoplannerArrayEditorField
} from 'js/components/Ectoplanner/EctoplannerArrayEditor';
import defaultForm from './assets/defaultForm.json';
import { EctoplannerFormEnvironment } from 'js/components/Ectoplanner/EctoplannerTypes';

type EctoplannerSolarAreaEditorProps = Omit<
  CustomModelEditorProps<EctoplannerForm, EctoplannerFormEnvironment>,
  'rawValue'
> & {
  rawValue: EctoplannerSolarAreaDefinition[];
};

const EctoplannerSolarAreaEditor = (props: EctoplannerSolarAreaEditorProps) => {
  const fields: EctoplannerArrayEditorField<EctoplannerSolarAreaDefinition>[] =
    [
      {
        label: T.ectoplanner.form.photovoltaic.headers.area,
        dataKey: 'area',
        type: 'number'
      },
      {
        label: T.ectoplanner.form.photovoltaic.headers.azimuth,
        dataKey: 'azimuth',
        type: 'number'
      },
      {
        label: T.ectoplanner.form.photovoltaic.headers.elevation,
        dataKey: 'elevation',
        type: 'number'
      }
    ];

  return (
    <EctoplannerArrayEditor<
      EctoplannerForm,
      object,
      EctoplannerSolarAreaDefinition
    >
      {...props}
      fields={fields}
      defaultItem={defaultForm.photovoltaic.areaList[0]}
    />
  );
};

export default React.memo(EctoplannerSolarAreaEditor);
