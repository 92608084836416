import React from 'react';
import _ from 'lodash';
import LogView from 'js/components/Logs/LogView';
import useLogViewToolbar, { SearchType } from './useLogViewToolbar';
import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';

type LogViewContentProps = {
  nodeId: string;
  selectedSignalIds?: string[];
  search?: SearchType;
  onSearchChanged?: (search: SearchType) => void;
};

const LogViewContent = ({
  nodeId,
  selectedSignalIds,
  search,
  onSearchChanged
}: LogViewContentProps) => {
  const {
    toolbarItems,
    selectedActityLogTypes,
    sortDirection,
    orderBy,
    onSortChange,
    currentPage,
    searchString
  } = useLogViewToolbar({
    nodeId,
    search,
    onSearchChanged,
    enableAdd: false,
    enableLogTypeSelection: false
  });

  return (
    <>
      <Toolbar>{toolbarItems}</Toolbar>
      <LogView
        nodeId={nodeId}
        selectedSignalIds={selectedSignalIds}
        activityLogTypes={selectedActityLogTypes}
        sortDirection={sortDirection}
        orderBy={orderBy}
        onSortChange={onSortChange}
        currentPage={currentPage}
        searchString={searchString}
        onPageChange={(newPage: number) => onSearchChanged({ page: newPage })}
      />
    </>
  );
};

export default LogViewContent;
